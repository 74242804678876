import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../../styles/global';

const ProfileName = styled.div`
  font-family: Aileron Heavy;
  text-transform: capitalize;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin5}px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
  }
  // #endregion
`;

const ProfileTitle = styled.div`
  font-family: Cooper Hewitt Semibold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin5}px;
  color: ${$.color.blue6};

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 16px;
    width: 50%;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 15px;
    width: 70%;
  }
  // #endregion
`;

const Profile = ({ name, qualifications, title }) => (
  <>
    <ProfileName>{name}</ProfileName>
    <ProfileTitle>{title}</ProfileTitle>
  </>
);

Profile.defaultProps = {
  title: '',
  name: '',
  qualifications: [],
};

Profile.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  qualifications: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default Profile;
