import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../../../../styles/global';
import Util from '../../../../../../utils';
import { Context } from '../Context';

const Container = styled.div`
  ${({ position }) => `
  overflow: hidden;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 35%;
    height: inherit;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 30%;
    height: inherit;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding3 * 2}px);
    height: 250px;
    padding: 0 ${$.layout().padding3}px;
    text-align: ${position};
    display: flex;
    flex-direction: column;
    align-items: ${position === 'left' ? 'flex-start' : 'flex-end'};
  }
  // #endregion
`}
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    max-width: 250px;
    height: inherit;
  }
  // #endregion
`;

const RectImage = ({ img, index, className }) => (
  <Context.Consumer>
    {({ setClicked, items, images }) => {
      const { position, story } = items[index];
      const imgFluid = images.filter(
        ({ node: { childImageSharp } }) =>
          img.indexOf(childImageSharp.resize.originalName) > -1
      )[0];
      const image = getImage(imgFluid.node.childImageSharp);
      const bgImage = convertToBgImage(image);

      return (
        <Container
          className={className}
          position={position}
          onClick={() => {
            if (!story) {
              return;
            }
            // Only allow user to tap on image if on mobile
            if (typeof window !== 'undefined' && Util.isMobile() === true) {
              setClicked((prev) => (prev === index ? -1 : index));
            }
          }}
        >
          <StyledBackgroundImage Tag="div" fluid={[bgImage.fluid]} />
        </Container>
      );
    }}
  </Context.Consumer>
);

RectImage.defaultProps = {
  index: 0,
  img: '',
  className: '',
};

RectImage.propTypes = {
  index: PropTypes.number,
  img: PropTypes.string,
  className: PropTypes.string,
};

export default RectImage;
