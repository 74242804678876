import styled from 'styled-components';

import $ from '../../../styles/global';
import CheckmarkIcon from '../../../assets/images/tertiary/aws/checkmark.svg';
import SGCCLogo from '../../../assets/images/tertiary/sgcc-logo.svg';
import AppleConsultantsNetworkLogo from '../../../assets/images/tertiary/index/apple_consultants_network.svg';

const Section = styled.section`
  background-color: ${$.color.white};
`;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.black};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const List = styled.div`
  & > *:not(:last-child) {
    margin-bottom: ${$.layout().margin4}px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    width: 24px;
    height: 24px;
    align-self: flex-start;
    margin-right: ${$.layout().margin4}px;
  }

  & > div {
    font-family: Lato Bold;
    font-size: 18px;
    line-height: 1.3em;
    width: calc(100% - 24px - ${$.layout().margin4}px);
  }
`;

const SGCCLogoContainer = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  & > svg {
    width: 80px;
  }
`;

const AppleConsultantsNetworkLogoContainer = styled.div`
  margin-top: ${$.layout().margin2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    width: 200px;
  }
`;

const Why = () => (
  <Section>
    <Container>
      <SGCCLogoContainer>
        <SGCCLogo />
      </SGCCLogoContainer>
      <Title>Why Attend</Title>
      <List>
        <Row>
          <CheckmarkIcon />
          <div>Get officially certified with Apple</div>
        </Row>
        <Row>
          <CheckmarkIcon />
          <div>
            Gain the prototyping skills needed to bring your killer tech idea to
            life
          </div>
        </Row>
        <Row>
          <CheckmarkIcon />
          <div>
            Boost your resumes for Poly, University, internship and job
            applications
          </div>
        </Row>
        <Row>
          <CheckmarkIcon />
          <div>
            Obtain the skills needed to take part in hackathons, business
            challenges and startup competitions
          </div>
        </Row>
      </List>
      <AppleConsultantsNetworkLogoContainer>
        <AppleConsultantsNetworkLogo />
      </AppleConsultantsNetworkLogoContainer>
    </Container>
  </Section>
);

export default Why;
