import styled from 'styled-components';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import $ from '../../../../../styles/global';
import Util from '../../../../../utils';
import GridView from '../../../../GridView';
import Profile from './Profile';
import Description from './Description';
import teamMembersData from '../items.json';

const Container = styled.div`
  display: none;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: block;
  }
  // #endregion
`;

const Desktop = ({ onlyIncludes, expandProfileFirst }) => (
  <Container>
    <GridView
      data={teamMembersData
        .filter(({ anchor }) => {
          if (onlyIncludes.length > 0 && onlyIncludes.includes(anchor)) {
            return true;
          }
          if (onlyIncludes.length === 0) {
            return true;
          }
          return false;
        })
        .map(
          ({
            name,
            qualifications,
            education,
            workExperience,
            description,
            avatarURL,
            descImg,
            title,
            anchor,
          }) => ({
            bubble: {
              avatarURL: Util.importOneImage(avatarURL),
              overlayText: 'Click to view profile',
              anchor,
              children: <Profile name={name} title={title} />,
            },
            dropdown: {
              avatarURL: descImg
                ? Util.importOneImage(descImg)
                : Util.importOneImage(avatarURL),
              maxHeight: `${
                400 +
                description.length / 10 +
                qualifications.length * 100 +
                workExperience.length * 100
              }px`,
              children: (
                <Description
                  title={title}
                  name={name}
                  desc={description}
                  qualifications={qualifications}
                  education={education}
                  workExperience={workExperience}
                />
              ),
            },
            key: shortid.generate(),
          })
        )}
      expandProfileFirst={expandProfileFirst}
    />
  </Container>
);

Desktop.propTypes = {
  onlyIncludes: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandProfileFirst: PropTypes.string.isRequired,
};

export default Desktop;
