import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';

/**
 * General button. Pass in your own event handlers or colors, or use default.
 * This button changes color when clicked.
 * @params {string} [normalColor] - Background color for normal button. Defaults to blue6.
 * @params {string} [clickedColor] - Background color for clicked button. Defaults to orange3.
 * @params {string} [normalText] - Color of the text. Defaults to white.
 * @params {string} [clickedText] - Color of the clicked text. Defaults to white.
 */

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
`;

const StyledButton = styled.button`
  background-color: ${({ normalColor }) => normalColor || $.color.blue5};
  padding: 12px 10px;
  color: ${({ normalText }) => normalText || $.color.white};
  font-family: Lato Bold;
  font-size: 16px;
  border: none;
  min-width: 120px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: all 0.5s ${$.easingFn.standard};

  &:hover {
    cursor: pointer;
    box-shadow: ${$.dropShadow.normal};
    background-color: ${({ clickedColor }) => clickedColor || $.color.blue4};
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: ${({ clickedText }) => clickedText || $.color.white};
    background-color: ${({ clickedColor }) => clickedColor || $.color.blue4};
    box-shadow: ${$.dropShadow.normal};
  }
`;

const Button = ({ children, link, ...newProps }) =>
  link ? (
    <StyledButton {...newProps}>
      <StyledLink to={link}>{children}</StyledLink>
    </StyledButton>
  ) : (
    <StyledButton {...newProps}>{children}</StyledButton>
  );

Button.defaultProps = {
  link: '',
  children: <></>,
};

Button.propTypes = {
  link: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
};

export default Button;
