import { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import RotatingDownChevron from '../Chevron/RotatingDownChevron';
import Utils from '../../utils';

const Container = styled.div`
  margin-bottom: 20px;
`;

const Question = styled.div`
  background: radial-gradient(
    103.83% 111.89% at 25.6% 78.1%,
    #f7814d 44.99%,
    #fdb483 96.49%
  );
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 16px;
  font-family: Lato Regular;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: ${$.dropShadow.normal};

  & > svg {
    width: 16px;
    height: 16px;
  }

  & > span {
    width: calc(100% - 16px);
  }
`;

const Answer = styled.div`
  opacity: 0;
  max-height: 0;
  transition: all 0.55s ease-in-out;
  font-size: 15px;
  font-family: Lato Light;
  padding: 0 10px;
  pointer-events: none;

  strong {
    font-family: Lato Bold;
  }

  &.show {
    opacity: 1;
    pointer-events: initial;
    max-height: 1000px;
  }
`;

const QnADropdown = ({ ques, children }) => {
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const containerRef = useRef(null);
  const counterRef = useRef({ total: 0 });

  Utils.useOutsideClick(containerRef, () => {
    setShow(false);
    counterRef.total += 1;
  });

  return (
    <Container ref={containerRef}>
      <Question
        onClick={() => {
          setShow((prev) => !prev);
          setCounter((prev) => prev + 1);
        }}
      >
        <span>{ques}</span>
        <RotatingDownChevron counter={counter} />
      </Question>
      <Answer className={show ? 'show' : ''}>{children}</Answer>
    </Container>
  );
};

QnADropdown.defaultProps = {
  ques: '',
  children: '',
};

QnADropdown.propTypes = {
  ques: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
};

export default QnADropdown;
