import styled from 'styled-components';

import $ from '../../../styles/global';
import AppDevWithSwiftLogo from '../../../assets/images/tertiary/app-dev-with-swift-logo.png';

const Section = styled.section`
  overflow: hidden;
  background-color: ${$.color.blue5};
`;

const Container = styled.div`
  margin: 0 !important;
  width: 100vw !important;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    & > * {
      padding: 100px 0;
    }
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    & > * {
      padding: 100px 0;
    }
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    height: 100%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    height: 100%;
  }
  // #endregion
`;

const Info = styled.div`
  width: calc(600px - 30px);
  padding-left: 30px;
  position: relative;
  left: 30px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: calc(60% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    position: relative;
    left: 0;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 120px;
    padding-bottom: 100px;
    position: relative;
    left: 0;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 120px;
    padding-bottom: 100px;
    position: relative;
    left: 0;
  }
  // #endregion
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Description = styled.div`
  p {
    font-family: Lato Regular;
    font-size: 18px;
    color: ${$.color.white};
    opacity: 0.8;
    line-height: 1.3em;
  }

  a {
    font-family: Lato Bold;
    color: ${$.color.white};
    font-size: 17.8px;

    &:visited {
      color: ${$.color.white};
    }
  }
`;

const OverlayContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${$.color.white};
  width: 370px;
  padding-left: calc((100vw - 970px) / 2);

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: calc(40% - ${$.layout().padding2}px);
    padding-left: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 370px;

  & > img {
    width: 200px;
    height: 240px;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
  }
  // #endregion
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 330px 50px 330px 0;
  border-color: transparent ${$.color.blue5} transparent transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    border-width: 320px 50px 320px 0;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    border-width: 60px 50vw 0 50vw;
    border-color: ${$.color.white} transparent transparent transparent;
    bottom: -60px;
    top: initial;
    right: initial;
    left: 0;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    border-width: 60px 50vw 0 50vw;
    border-color: ${$.color.white} transparent transparent transparent;
    bottom: -60px;
    top: initial;
    right: initial;
    left: 0;
  }
  // #endregion
`;

const About = () => (
  <Section>
    <Container>
      <InnerContainer>
        <OverlayContainer>
          <ImageContainer>
            <img src={AppDevWithSwiftLogo} alt="AWS Cloud Practitioner Logo" />
          </ImageContainer>
          <Triangle />
        </OverlayContainer>
        <Info>
          <Title>About the iOS App Development with Swift Bootcamp</Title>
          <Description>
            <p>
              Experience a week-long 30-hour accelerated programme in Swift
              Programming and iOS App Development. Learn about how App
              Development with Swift enables growth technologies from an expert
              Apple Professional Learning Provider and get officially certified
              with Apple. (A Macbook will be provided for each student during
              the course)
            </p>
            <p>
              Prepare for Apple&apos;s official{' '}
              <a
                href="https://developer.apple.com/learn/curriculum/"
                target="_blank"
                rel="noopener noreferrer"
              >
                App Development with Swift - Certified User
              </a>{' '}
              Exam and earn a recognised IT credential demonstrating your
              ability to develop apps for the Apple ecosystem (all students can
              schedule to take the official exam at our centre on their own time
              after the course)
            </p>
          </Description>
        </Info>
      </InnerContainer>
    </Container>
  </Section>
);

export default About;
