import styled from 'styled-components';

import $ from '../../../../styles/global';
import RotatingDownChevron from '../../../Chevron/RotatingDownChevron';

const DROPDOWNTEXTWIDTH = '173.2px';
const DOWNCHEVRONWIDTH = '18px';

const DropdownText = styled.div`
  font-family: Aileron Heavy;
  width: ${DROPDOWNTEXTWIDTH};
  position: relative;
  cursor: pointer;
  color: ${$.color.blue3};

  &:first-child {
    margin-left: ${$.layout().margin4}px;
  }
  &:last-child {
    margin-right: ${$.layout().margin4}px;
  }

  &.active {
    .menu {
      opacity: 1;
      pointer-events: initial;
      transform: translateY(0);
      z-index: 9999;
    }
  }
`;

const PillContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${$.layout().padding4}px 0;
`;

const PillText = styled.div`
  font-size: 13.5px;
  ${$.ellipsis};
  width: calc(100% - ${DOWNCHEVRONWIDTH});
`;

const StyledRotatingDownChevron = styled(RotatingDownChevron)`
  fill: ${$.color.orange1};
  stroke: ${$.color.orange1};
  height: 6px;
`;

export { DropdownText, PillContainer, PillText, StyledRotatingDownChevron };
