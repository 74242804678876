import PropTypes from 'prop-types';

import SEO from '../components/seo';
import BuildOn from '../components/partners/BuildOn';

const BuildOnPage = ({ location }) => (
  <>
    <SEO
      location={location}
      title="Build On"
      description="Build On Hackathon by Amazon Web Services"
    />
    <BuildOn />
  </>
);

BuildOnPage.defaultProps = {
  location: {},
};

BuildOnPage.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default BuildOnPage;
