import { StaticImage } from 'gatsby-plugin-image';

const SMUAcademyImage = (
  <StaticImage
    src="../../assets/images/professional-training/smuacademy.png"
    alt="SMU Academy image"
  />
);

const AWSTrainingAndCertificationImage = (
  <StaticImage
    src="../../assets/images/professional-training/aws-training-and-certification.png"
    alt="AWS Training and Certification image"
  />
);

const AppleAppDevelopmentWithSwiftImage = (
  <StaticImage
    src="../../assets/images/professional-training/app-development-with-swift.png"
    alt="AWS Training and Certification image"
  />
);

const GoogleCloudImage = (
  <StaticImage
    src="../../assets/images/professional-training/google-cloud.png"
    alt="Google Cloud image"
  />
);

const buttonMessages = {
  smu: 'Visit Course Page',
  internal: 'Enquire Now',
};

const Items = [
  {
    value: 'aws',
    tab: 'AWS',
    courses: [
      {
        title:
          'Career Transition Programme: Software Development with the Amazon Web Services (AWS) Cloud',
        desc: 'Mid-career transition programme to train software developers proficient with the AWS Cloud. Taught at the Singapore Management University by a team of AWS Authorised Instructors and industry professionals.',
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Singapore Management University',
        buttonMessage: buttonMessages.smu,
        link: '/courses/smu/sctp-advanced-certificate-software-development-amazon-web-services-aws-cloud/',
        fee: '$19,620* (as low as $1,386 using SkillsFuture Credits)',
      },
      {
        title: 'Advanced Certificate in AWS Cloud Architecting and DevOps',
        desc: "Want to gain the skills required to capitalise on global opportunities in the brisk job market for AWS Cloud talent? Look no further as this programme will teach you how to design and utilise functionality on Amazon Web Services, the world's largest provider of Cloud Services (AWS).",
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Singapore Management University',
        buttonMessage: buttonMessages.smu,
        link: '/courses/smu/advanced-certificate-aws-cloud-architecting-and-devops/',
        fee: '$10,464* (as low as $1,219.20 using SkillsFuture Credits)',
      },
      {
        title: 'Architecting on AWS',
        desc: 'Students learn how to optimize the AWS Cloud by understanding how AWS services fit into cloud-based solutions. In addition, students explore AWS Cloud best practices and design patterns for architecting optimal IT solutions on AWS, and build a variety of infrastructures in guided, hands-on activities.',
        image: AWSTrainingAndCertificationImage,
        level: 'Basic',
        venue: 'Tanjong Pagar Campus',
        buttonMessage: buttonMessages.smu,
        link: '/courses/aws/architecting-on-aws/',
        fee: '$2,779.50 (GST included)',
      },
      {
        title: 'AWS Cloud Practitioner Essentials',
        desc: 'The AWS Cloud Practitioner Essentials course is an introductory level course designed to provide participants with a foundational understanding of the AWS Cloud. The course covers the core AWS services, including computing, storage, databases, and networking.',
        image: AWSTrainingAndCertificationImage,
        level: 'Basic',
        venue: 'Tanjong Pagar Campus',
        buttonMessage: buttonMessages.smu,
        link: '/courses/aws/aws-cloud-practitioner-essentials/',
        fee: '$1,090 (GST included)',
      },
      {
        title:
          'Certificate in Technology Foundations: Establishing Foundation in Cloud Services and Applications',
        desc: "This programme introduces critical technology through the world's largest provider of Cloud Computing - Amazon Web Services (AWS).",
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Singapore Management University',
        buttonMessage: buttonMessages.smu,
        link: '/courses/smu/certificate-technology-foundations-establishing-foundation-cloud-services-and-applications/',
        fee: '$1,744* (as low as <b>$203.20</b> using SkillsFuture Credits)',
      },
      {
        title: 'Advanced Certificate in AWS Cloud Services and Technicals',
        desc: 'The demand for Cloud Computing continues to rise exponentially amid the digital transformation of organisations. Gaining expertise in Amazon Web Services (AWS), the leading provider of Cloud Computing services, has become a necessity for IT professionals.',
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Singapore Management University',
        buttonMessage: buttonMessages.smu,
        link: 'mailto:enquiries@sgcodecampus.com?subject=Advanced Certificate in AWS Cloud Services and Technicals',
        fee: 'Refer to course page',
      },
    ],
  },
  {
    value: 'apple',
    tab: 'Apple',
    courses: [
      {
        title:
          'Career Transition Programme: Advanced Certificate in iOS Application Development with Swift Programming',
        desc: 'Mid-career transition programme to train iOS mobile application developers proficient with the Swift programming language. Taught at the Singapore Management University by a team of Apple Professional Learning Specialists and industry professionals.',
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Singapore Management University',
        buttonMessage: buttonMessages.smu,
        link: '/apple/sctp-advanced-certificate-ios-app-development-swift-programming/',
        fee: '$19,620* (as low as <b>$1,386</b> using SkillsFuture Credits)',
      },
      {
        title: 'Apple App Development with Swift - Certified User',
        desc: "Prepare for Apple's official App Development with Swift - Certified User Exam and earn a recognised IT credential demonstrating your ability to develop apps for the Apple ecosystem. 45-hour intensive course taught by an Apple Professional Learning Specialist.",
        image: AppleAppDevelopmentWithSwiftImage,
        level: 'Basic',
        venue: 'Tanjong Pagar Campus',
        buttonMessage: buttonMessages.internal,
        link: 'mailto:enquiries@sgcodecampus.com?subject=Apple App Development with Swift - Certified User',
        fee: 'Enquire now',
      },
    ],
  },
  {
    value: 'python',
    tab: 'Python',
    courses: [
      {
        title: 'Professional Certificate in Python Programming',
        desc: "Learn how Python can optimise your digital solution development cycle and workflow with SMU's Professional Certificate in Python Programming.",
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Online',
        buttonMessage: buttonMessages.smu,
        link: '/courses/smu/professional-certificate-python-programming/',
        fee: '$10,464* (as low as <b>$1,219.20</b> using SkillsFuture Credits)',
      },
    ],
  },
  {
    value: 'data',
    tab: 'Data Analytics',
    courses: [
      {
        title: 'Advanced Certificate in Applied Data Analytics',
        desc: 'Learn to use a variety of data techniques drawn from the fields of machine learning, data visualisation, Big Data and many others to interpret large and complex datasets in an applied setting.',
        image: SMUAcademyImage,
        level: 'Intermediate',
        venue: 'Online',
        buttonMessage: buttonMessages.smu,
        link: '/courses/smu/advanced-certificate-applied-data-analytics/',
        fee: '$10,464* (as low as <b>$1,219.20</b> using SkillsFuture Credits)',
      },
      {
        title:
          'Certificate in Technology Foundations: Unearthing the Potential of Databases, Big Data and Data Analytics',
        desc: 'Collecting data through technology has changed the game of analysis in businesses today. With accessible tools, companies are able to churn and gain reliable insights from Big Data efficiently.',
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Singapore Management University',
        buttonMessage: buttonMessages.smu,
        link: '/courses/smu/certificate-technology-foundations-unearthing-potential-databases-big-data-and-data/',
        fee: '$1,744* (as low as <b>$203.20</b> using SkillsFuture Credits)',
      },
    ],
  },
  {
    value: 'ml',
    tab: 'Machine Learning',
    courses: [
      {
        title:
          'Certificate in Technology Foundations: Jump Start in Artificial Intelligence, Machine Learning and Deep Learning',
        desc: 'Some solutions are not about looking far but looking deep into what’s in front of us. Delve into the depths of Artificial Intelligence (AI), Machine Learning (ML) and Deep Learning (DL) as these powerful tools not only reveal insights beneficial to businesses, but help discover opportunities, create efficiencies and value.',
        image: SMUAcademyImage,
        level: 'Basic',
        venue: 'Singapore Management University',
        buttonMessage: buttonMessages.smu,
        link: '/courses/smu/certificate-technology-foundations-jump-start-artificial-intelligence-machine-learning-and/',
        fee: '$1,744* (as low as <b>$203.20</b> using SkillsFuture Credits)',
      },
    ],
  },
];

export default Items;
