import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import $ from '../styles/global';
import LoginForm from '../components/schools/LoginForm';
import SignOut from '../components/schools/SignOut';

const Section = styled.section``;

const Container = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const VideoContainer = styled.div`
  margin-bottom: 32px;
`;

const VideoTitle = styled.div`
  font-family: Aileron Heavy;
  font-size: 50px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;

  // #region
  @media ${$.device.mobile} {
    font-size: 42px;
  }
  // #endregion
`;

const IFrameContainer = styled.div``;

const StyledIFrame = styled.iframe`
  width: 970px;
  height: 546px;

  @media ${$.device.tablet} {
    width: 100%;
  }

  @media ${$.device.mobile} {
    width: 100%;
  }
`;

const Lesson = ({ pageContext: { title, videoId } }) => {
  const videoLoginStatus = useSelector((state) => state.videoLoginStatus);

  return (
    <Section>
      <Container>
        {videoLoginStatus.loggedIn ? (
          <>
            <SignOut />
            <VideoContainer>
              <VideoTitle>{title}</VideoTitle>
              <IFrameContainer>
                <StyledIFrame
                  title="Sample snippet of our online lesson"
                  src={`https://player.vimeo.com/video/${videoId}?muted=1`}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </IFrameContainer>
            </VideoContainer>
          </>
        ) : (
          <LoginForm />
        )}
      </Container>
    </Section>
  );
};

Lesson.propTypes = {
  pageContext: PropTypes.shape({
    videoId: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};

export default Lesson;
