import styled from 'styled-components';

import $ from '../../../../styles/global';
import OrangeTent from '../../../../assets/images/awsaccelerator2021/orange-tent.svg';
import BlueSchool from '../../../../assets/images/awsaccelerator2021/blue-school.svg';
// import Arrow from '../../../../assets/icons/thin-arrow-right.svg';
import Download from '../../../../assets/icons/download.svg';

const Container = styled.div`
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    z-index: 0;

    & > * {
      width: calc(50% - 16px);
    }
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: stretch stretch;
    gap: 16px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    > * {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: ${$.layout().margin3}px;
      }
    }
  }
  // #endregion
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${$.layout().padding4}px;
`;

const Asset = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: -10%;

  > svg {
    width: 300px;
    opacity: 0.1;
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    > svg {
      transform: scale(1.2);
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 40%;
    > svg {
      transform: scale(1.3);
    }
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 25px;
  margin-bottom: ${$.layout().margin3}px;
  position: relative;
  z-index: 1;
`;

const GoToButton = styled.a`
  display: inline-block;
  text-decoration: none;
  width: 100%;
  position: relative;
  z-index: 1;

  > button {
    transition: all 0.35s ${$.easingFn.standard};
    border-radius: 10px;
    font-size: 15px;
    width: 100%;
    font-family: Lato Bold;
    padding: 8px 16px;
    color: ${$.color.white};
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > svg {
      fill: ${$.color.white};
      width: 24px;
      height: 24px;
    }
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      > button {
        cursor: pointer;
      }
    }
  }
  // #endregion
`;

const ApplicationFormCard = styled.div`
  box-shadow: 0px 8px 12px rgba(21, 63, 135, 0.3);
  border-radius: 10px;
  background-color: ${$.color.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  position: relative;

  ${Title} {
    color: ${$.color.blue4};
  }

  ${GoToButton} {
    > button {
      background-color: ${$.color.blue4};
    }

    // NOTE: Desktop
    // #region
    @media ${$.device.desktop} {
      &:hover {
        > button {
          background-color: ${$.color.blue5};
        }
      }
    }
    // #endregion
  }
`;

const SubmitApplicationCard = styled.div`
  box-shadow: 0px 8px 12px rgba(224, 71, 8, 0.3);
  border-radius: 10px;
  background-color: ${$.color.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  position: relative;

  ${Title} {
    color: ${$.color.orange3};
  }

  ${GoToButton} {
    > button {
      background-color: ${$.color.orange3};
    }

    // NOTE: Desktop
    // #region
    @media ${$.device.desktop} {
      &:hover {
        > button {
          background-color: ${$.color.orange4};
        }
      }
    }
    // #endregion
  }
`;

const TwoSessions = () => (
  <Container>
    <ApplicationFormCard>
      <Asset>
        <BlueSchool />
      </Asset>
      <Info>
        <Title>
          Bootcamp & Accelerator
          <br />
          Info Deck
        </Title>
        <GoToButton download href="/data-science-bootcamp-accelerator.pdf">
          <button type="button">
            Download here!
            <Download />
          </button>
        </GoToButton>
      </Info>
    </ApplicationFormCard>
    <SubmitApplicationCard>
      <Asset>
        <OrangeTent />
      </Asset>
      <Info>
        <Title>
          Applications are now closed for 2021.
          <br />
          Good luck to all applicants.
        </Title>
        {/* <GoToButton
            target="_blank"
            rel="noreferrer noopener"
            href="https://forms.gle/Nz2W7cB3i73AGHHr9"
          >
            <button type="button">
              Apply here!
              <Arrow />
            </button>
          </GoToButton> */}
      </Info>
    </SubmitApplicationCard>
  </Container>
);

export default TwoSessions;
