import styled from 'styled-components';

import $ from '../../../styles/global';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout().padding1}px 0px;
`;

const Title = styled.h1`
  font-family: Aileron Heavy;
  font-size: 34px;
  color: ${$.color.black2};
  margin-bottom: ${$.layout().margin3}px;
`;

const Description = styled.div``;

const StyledUL = styled.ul``;

const StyledList = styled.li``;

const StyledLink = styled.a`
  font-family: Lato Bold;
  color: ${$.color.blue5};
  text-decoration: none;
`;

const MicroWorkshop = () => (
  <Section>
    <Container>
      <Title>Lecture materials and resources</Title>
      <Description>
        <StyledUL>
          <StyledList>
            <StyledLink
              href="https://ian-smu-data-analytics-2020.s3-ap-southeast-1.amazonaws.com/smu_microworkshop.pdf"
              download
              target="_blank"
              rel="noreferrer noopener"
            >
              Lecture slides
            </StyledLink>
          </StyledList>
        </StyledUL>
      </Description>
    </Container>
  </Section>
);

export default MicroWorkshop;
