import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../styles/global';

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 35px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;
  text-align: center;

  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
  }
  // #endregion
`;

const Paragraph = styled.p`
  color: ${$.color.black2};
  font-family: Lato Regular;
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin2}px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: ${$.color.blue5};
  font-family: Lato Bold;
  text-decoration: none;
`;

const Micro = () => (
  <Section>
    <Container>
      <Title>Applied Data Analytics Micro Workshop</Title>
      <Paragraph>
        {`This online workshop was jointly organised by the SMU Alumni Association and SMU Academy. Materials, note and resources from the 8 Sep 2020 session can be found `}
        <StyledLink to="/partners/smu/micro-workshop">here</StyledLink>
        {'.'}
      </Paragraph>
    </Container>
  </Section>
);

export default Micro;
