import styled from 'styled-components';

import $ from '../../styles/global';
import Utils from '../../utils';
import items from './items.json';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const TableContainer = styled.div`
  overflow: scroll;
  margin-top: ${$.layout().margin3}px;
`;

const Table = styled.table`
  width: 100%;
  min-width: 970px;
  border-collapse: collapse;

  td {
    padding: 15px;
  }

  thead {
    overflow: hidden;
    background-color: ${$.color.black2};
    font-family: Lato Bold;
    color: ${$.color.white};
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;

    td {
      &:not(:last-child) {
        border-right: 1px solid ${$.color.white};
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
        width: 50%;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  tbody {
    overflow: hidden;
    font-family: Lato Regular;
    font-size: 16px;

    em {
      font-style: italic;
    }

    tr {
      td {
        &:first-child {
        }
        &:not(:last-child) {
          border-right: 1px solid ${$.color.black2};
        }

        &[data-column='1'] {
          font-size: 18px;
          font-family: Lato Bold;
          text-align: left;
          outline: 0.5px solid ${$.color.black2};
        }

        &[data-last-date='last'] {
          border-bottom: 1px solid ${$.color.black2};
        }

        &[data-even='even'] {
          background-color: ${$.color.gray3};
        }
      }
    }
  }
`;

const Subtitle = styled.p`
  // font-family: Lato Bold;
  font-size: 18px;
  color: ${$.color.black2};
  opacity: 1;
  margin-bottom: ${$.layout().margin4}px;

  & > span {
    color: ${$.color.red};
  }

  & a {
    color: ${$.color.orange4};
    &:visited {
      color: ${$.color.orange4};
    }
  }
`;

const StyledOutgoingLink = styled.a`
  display: block;
  color: ${$.color.blue3};
  text-decoration: underline;
  &:visited {
    color: ${$.color.blue3};
  }
`;

const StyledCourseName = styled.div`
  font-size: 18px;
  font-family: Lato Bold;
  margin-bottom: 10px;
`;

const StyledUnorderedList = styled.ul`
  padding: 0;
  margin: 0;
`;

const StyledListItem = styled.li`
  margin-bottom: 10px;
`;

const itemsWithKeys = Utils.addKeys(items);

const ScheduleTable = () => (
  <Section>
    <Container>
      <Subtitle>
        Unlock the potential of Amazon Web Services (AWS), the world's most
        widely adopted on-demand cloud platform, with our comprehensive training
        courses at SG Code Campus. AWS offers unparalleled scalability and cost
        efficiency, empowering large enterprises and startups to manage
        applications, databases, and services without the hassle of procuring
        servers or IT infrastructure in advance.
      </Subtitle>
      <Subtitle>
        In today's competitive economic landscape, a deep understanding of AWS
        cloud management is crucial for IT professionals seeking to stay ahead.
        Our AWS training equips you with the skills to design, deploy, and
        operate infrastructure and applications on the AWS cloud platform,
        regardless of your level of expertise.
      </Subtitle>
      <Subtitle>
        Led by our team of experienced AWS Authorized Anstructors, our courses
        cater to individuals at every stage of their AWS journey, whether you're
        just starting out or aiming for professional certification. Choose from
        flexible delivery methods, including classroom/on-premise training or
        live online virtual instructor-led sessions, to suit your learning
        preferences. It's time to elevate your career and get certified with SG
        Code Campus.
      </Subtitle>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <td>Course Name</td>
              <td>Scheduled Dates</td>
            </tr>
          </thead>
          <tbody>
            {itemsWithKeys.map(({ name, dates, awsClassroomPages, id }) => {
              return dates.map((date, index) => {
                return (
                  <tr key={`${name}_${date}`}>
                    {index === 0 && (
                      <td data-column="1" rowSpan={dates.length}>
                        {
                          // Render name as a normal text if there is multiple classroom pages
                          awsClassroomPages && awsClassroomPages.length > 1 && (
                            <StyledCourseName>{name}</StyledCourseName>
                          )
                        }
                        {
                          // Render URL directly if there is only one URL
                          awsClassroomPages && awsClassroomPages.length === 1 && (
                            <StyledOutgoingLink
                              href={awsClassroomPages[0].url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {name}
                            </StyledOutgoingLink>
                          )
                        }
                        {
                          // Render list of URLs if there are multiple URLs
                          awsClassroomPages && awsClassroomPages.length > 1 && (
                            <StyledUnorderedList>
                              {awsClassroomPages.map(({ url, name }) => {
                                return (
                                  <StyledListItem>
                                    <StyledOutgoingLink
                                      href={url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {name}
                                    </StyledOutgoingLink>
                                  </StyledListItem>
                                );
                              })}
                            </StyledUnorderedList>
                          )
                        }
                      </td>
                    )}
                    <td
                      data-last-date={index === dates.length - 1 ? 'last' : ''}
                      data-even={index % 2 === 0 ? 'even' : ''}
                    >
                      {date}
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  </Section>
);

export default ScheduleTable;
