import styled from 'styled-components';

import $ from '../../../../styles/global';
import ImageContainer from '../../../Level2Banner/ImageContainer';
import Banner2 from '../../../../assets/images/whyus/students/banner2.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: -90px;
    left: 240px;

    & > svg {
      width: 236px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tabletA} {
    position: absolute;
    top: -40px;
    left: 300px;

    & > svg {
      width: 170px;
    }
  }

  @media ${$.device.tabletB} {
    position: absolute;
    top: -50px;
    left: 340px;

    & > svg {
      width: 180px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Image2Container = () => <Container image={Banner2} />;

export default Image2Container;
