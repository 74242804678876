import styled from 'styled-components';

import $ from '../../../styles/global';
import EmailIcon from '../../../assets/icons/check.svg';
import RightChevronIcon from '../../../assets/icons/thin-arrow-right.svg';

const Container = styled.div`
  width: 50%;
  box-shadow: ${$.dropShadow.normal};
  background-color: ${$.color.white};
  border-radius: 5px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Title = styled.div`
  padding: ${$.layout().padding3}px;
  color: ${$.color.orange2};
  border-bottom: 2px solid #eff4f8;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-family: Lato Semibold;
  font-size: 16px;
`;

const Info = styled.div`
  & > p {
    text-align: center;
    font-size: 17px;
    color: ${$.color.black2};
    line-height: 1.3em;
    margin: 0;
    padding: ${$.layout().padding2}px 60px;
    &:first-of-type {
      font-size: 20px;
      opacity: 0.8;
    }
    &:last-of-type {
      font-size: 17px;
      opacity: 0.6;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > p {
      padding: ${$.layout().padding2}px 30px;
    }
  }
  // #endregion
`;

const PricingMention = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 ${$.layout().padding2}px;
  color: ${$.color.black2};
  font-family: Lato Semibold;
  line-height: 1em;
  font-size: 50px;
`;

const List = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: ${$.layout().padding4}px ${$.layout().padding2}px 60px
    ${$.layout().padding2}px;

  & > *:not(:last-child) {
    margin-bottom: ${$.layout().margin4}px;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 80%;
  }
  // #endregion
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    margin-right: ${$.layout().margin4}px;
    width: 24px;
    height: 24px;
    fill: ${$.color.orange3};
    align-self: flex-start;
  }

  & > div {
    font-size: 17px;
    color: ${$.color.black2};
    opacity: 0.8;
    line-height: 1.3em;
    width: calc(100% - 24px - ${$.layout().margin4}px);
  }
`;

const StyledLink = styled.a`
  padding: ${$.layout().padding3}px;
  background-color: #eff4f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${$.color.black2};
  }

  & > div {
    margin-right: ${$.layout().margin4}px;
    font-family: Lato Bold;
    font-size: 16px;
    letter-spacing: 2px;
    color: ${$.color.black2};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > div {
      width: calc(100% - 20px - ${$.layout().margin4}px);
    }
  }
  // #endregion
`;

const LeftCard = () => (
  <Container>
    <Title>In-demand skills</Title>
    <Info>
      {/* Experience a 85 hour programme in Data Science with Python and
                explore Deep Learning through devices like the AWS DeepLens and
                DeepComposer. */}
      <p>Amazon Web Services (AWS) finds that over</p>
      <PricingMention>80%</PricingMention>
      <p>
        of business clients care that their staff are Certified. Learners report
        of:
      </p>
      <List>
        <Row>
          <EmailIcon />
          <div>Greater earning potential and job security</div>
        </Row>
        <Row>
          <EmailIcon />
          <div>Improved productivity</div>
        </Row>
        <Row>
          <EmailIcon />
          <div>Increased operational skills</div>
        </Row>
      </List>
      <StyledLink
        target="_blank"
        rel="noreferrer noopener"
        href="https://aws.amazon.com/blogs/training-and-certification/aws-training-and-certification-provide-positive-roi-to-learners/"
      >
        <div>Read the report here</div>
        <RightChevronIcon />
      </StyledLink>
    </Info>
  </Container>
);

export default LeftCard;
