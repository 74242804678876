import PropTypes from 'prop-types';

import SEO from '../components/seo';
import Banner from '../components/schools/Banner';
import Videos from '../components/schools/Videos';

const Schools = ({ location }) => (
  <>
    <SEO
      keywords={[
        'python programming singapore',
        'python programming',
        'data analytics course',
        'data analytics courses singapore',
        'data science course',
      ]}
      title="Data Science Analytics & Python Programming Courses Singapore"
      location={location}
    />
    <Banner />
    <Videos />
  </>
);

Schools.defaultProps = {
  location: {},
};

Schools.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default Schools;
