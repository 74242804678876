import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Section = styled.section`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: center;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    height: 320px;
  }
  // #endregion
`;

const Container = styled.div`
  display: flex;
  padding: ${$.layout().padding3}px 0;
  flex-direction: row;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    align-items: center;
    justify-content: space-between;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    align-items: center;
    justify-content: space-between;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column-reverse;
  }
  // #endregion
`;

const TitleContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: 0 ${$.layout().padding3}px;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    > svg {
      width: 250px;
      text-align: center;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    > svg {
      width: 250px;
      text-align: center;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${$.layout().margin4}px;
    text-align: center;
  }
  // #endregion
`;

const Title = styled(({ children, className, showH2 }) =>
  showH2 === true ? (
    <h2 className={className}>{children}</h2>
  ) : (
    <h1 className={className}>{children}</h1>
  )
)`
  font-family: Aileron Heavy;
  color: ${({ titleColor }) => titleColor};
  margin-bottom: ${$.layout().margin5}px;
  font-size: 34px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 49px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    text-align: center;
  }
  // #endregion
`;

const Subtitle = styled(({ children, className, showText }) =>
  showText === true ? (
    <div className={className}>{children}</div>
  ) : (
    <h2 className={className}>{children}</h2>
  )
)`
  color: ${({ textColor }) => textColor};
  line-height: 1.4em;
  font-size: 18px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 16px;
    text-align: center;
  }
  // #endregion
`;

const Banner = ({
  title,
  subtitle,
  image: Image,
  textColor,
  backgroundColor,
  titleColor,
}) => (
  <Section backgroundColor={backgroundColor}>
    <Container>
      <TitleContainer>
        <Title titleColor={titleColor}>{title}</Title>
        <Subtitle textColor={textColor}>{subtitle}</Subtitle>
      </TitleContainer>
      <ImageContainer>
        <Image />
      </ImageContainer>
    </Container>
  </Section>
);

Banner.defaultProps = {
  title: '',
  subtitle: '',
  backgroundColor: $.color.white,
  titleColor: $.color.blue3,
  textColor: $.color.blue5,
  image: <></>,
};

Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.func,
  ]),
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
};

export default Banner;
