import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.h1`
  font-family: Aileron Heavy;
  font-size: 50px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;

  // #region
  @media ${$.device.mobile} {
    font-size: 42px;
  }
  // #endregion
`;

const Subtitle = styled.h2`
  font-family: Aileron Heavy;
  font-size: 40px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;

  // #region
  @media ${$.device.mobile} {
    font-size: 35px;
  }
  // #endregion
`;

const Paragraph = styled.p`
  color: ${$.color.black2};
  font-family: Lato Regular;
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin3}px;

  strong {
    font-family: Lato Bold;
  }

  a {
    text-decoration: none;
    color: ${$.color.blue5};
    font-family: Lato Bold;
    &:visited {
      color: ${$.color.blue5};
    }
  }
`;

const ImgContainer = styled.div`
  margin: ${$.layout().margin2}px 0;

  > * {
    width: 100%;
    height: 100%;
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 100vw;
    position: relative;
    left: -${$.layout().margin3}px;
    height: 500px;
    object-fit: center;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100vw;
    position: relative;
    left: -${$.layout().margin3}px;
    height: 350px;
    object-fit: center;
  }
  // #endregion
`;

const MainWriteUp = () => (
  <Section>
    <Container>
      <Title>Authorised Amazon Training Partner</Title>
      <Paragraph>
        {'As of early 2020, SG Code Campus is proud to be an '}
        <strong>authorised Training Partner</strong>
        {' in the '}
        <strong>Amazon Web Services (AWS) Partner Network</strong>
        {
          ', with a mandate to offer official AWS Certification and Training courses in Cloud Computing to youth and adult learners in the Singapore market. '
        }
      </Paragraph>
      <ImgContainer>
        <StaticImage
          src="../../../assets/images/partners/aws/AWS_Classroom1.jpg"
          alt="AWS Training Partner banner image"
        />
      </ImgContainer>
      <Subtitle>What is Cloud Computing?</Subtitle>
      <Paragraph>
        Ever used Google Docs to write a report, Apple iCloud to store your
        favorite photos or created that smashing new playlist on Spotify? If the
        answer is yes, then you have unwittingly consumed services from the
        mysterious Cloud.
      </Paragraph>
      <Paragraph>
        {
          "Put simply, Cloud Computing refers to using other people's computers and servers to run your computing needs, usually over the internet. Amazon Web Services (AWS) is the world's largest Cloud provider, operating hundreds of thousands of computers at data centre locations all over the world. AWS provides developers with the computing, networking and storage infrastructure they need to build and deploy that shiny new mobile/web app or run technology companies - learn how tech giants like "
        }
        <a
          href="https://aws.amazon.com/solutions/case-studies/grab/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Grab
        </a>
        {' and '}
        <a href="https://aws.amazon.com/solutions/case-studies/netflix-case-study/">
          Netflix
        </a>
        {' leverage on AWS to build their success.'}
      </Paragraph>
      <ImgContainer>
        <StaticImage
          src="../../../assets/images/partners/aws/BuildOn.jpg"
          alt="AWS Training Partner image"
        />
      </ImgContainer>
      <Subtitle>Why choose us?</Subtitle>
      <Paragraph>
        {'As an '}
        <strong>authorised Training Partner in the AWS Partner Network</strong>
        {', we are proud to offer the '}
        <strong>first youth coding programme</strong>
        {
          '  in ASEAN that leads to official Amazon Training, Certification and Credentials.'
        }
      </Paragraph>
      <Paragraph>
        {
          'After developing a foundation in Python and JavaScript programming in our acclaimed '
        }
        <Link to="/youth-programmes/principles/">
          Principles course roadmap
        </Link>
        {', students will move to the '}
        <Link to="/youth-programmes/principles-X-advanced-programming/">
          Principles X programme
        </Link>
        {
          '. Completion of this programme leads to a Certificate of Completion issued by AWS and will prepare students to sit for the AWS-proctored exam which confers the AWS Cloud Practitioner credentials (#8 of the '
        }
        <a
          href="https://www.forbes.com/sites/louiscolumbus/2020/02/10/15-top-paying-it-certifications-in-2020"
          target="_blank"
          rel="noopener noreferrer"
        >
          Forbes 2020 list
        </a>
        {
          ' of the top IT certifications in the world); exam fee to be paid separately; '
        }
      </Paragraph>
      <Subtitle>Competitions</Subtitle>
      <Paragraph>
        Since 2019, AWS has held the annual Build On hackathon for students in
        Secondary Schools, local Polytechnics and Universities as a platform for
        competitors to learn about cloud computing through developing
        applications to solve real-world challenges.
      </Paragraph>
      <Paragraph>
        The 2020 edition of the competition promises to be larger than ever with
        participants from 5 ASEAN countries including Singapore competing for
        top accolades.
      </Paragraph>
      <Paragraph>
        {'Find out more  '}
        <Link to="/build-on/">here</Link>
        {'.'}
      </Paragraph>
    </Container>
  </Section>
);

export default MainWriteUp;
