import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import $ from '../../../styles/global';
import { modalUpdate } from '../../../actions';
import useActions from '../../../utils/customHooks/useActions';
import ClearIcon from '../../../assets/images/awsaccelerator2022/clear.svg';

const Container = styled.div`
  ${({ show, windowHeight }) => `
  opacity: ${show ? 1 : 0};
  z-index: ${show ? '9999999' : '-1'};
  pointer-events: ${show ? 'auto' : 'none'};
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: ${windowHeight}px;
`}
`;

const CloseButton = styled.div`
  margin-bottom: ${$.layout().margin4}px;
  margin-right: 5px;
  align-self: flex-end;

  & > svg {
    width: 24px;
    height: 24px;
    fill: ${$.color.white};
  }
`;

const InnerContainer = styled.div`
  width: 80vw;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 90vw;
    max-width: 1000px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 90vw;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: 90vw;
    max-width: 700px;
  }
  // #endregion
`;

const IframeContainer = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  width: 100%;
  background-color: ${$.color.black2};

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Modal = () => {
  const state = useSelector(({ modalData }) => ({ modalData }));
  const [updateModal] = useActions([modalUpdate]);
  const windowHeight = typeof window !== 'undefined' ? window.scrollY : 0;

  return (
    <>
      {state.modalData.show && (
        <Helmet
          bodyAttributes={{
            class: 'show-modal',
          }}
        />
      )}
      <Container show={state.modalData.show} windowHeight={windowHeight}>
        <InnerContainer>
          <CloseButton
            onClick={() => {
              updateModal({ show: false, video: state.modalData.video });
            }}
          >
            <ClearIcon />
          </CloseButton>
          {state.modalData.show && (
            <IframeContainer>
              <iframe
                src={state.modalData.video}
                allowFullScreen
                frameBorder="0"
              />
            </IframeContainer>
          )}
        </InnerContainer>
      </Container>
    </>
  );
};

export default Modal;
