import PropTypes from 'prop-types';
import styled from 'styled-components';

import SEO from '../components/seo';
import {
  Banner,
  MiniNavBar,
  LearnWithPartner,
  SkillsYoullGet,
  CourseCardWithContext,
  Overview,
  CourseOutline,
  WhatWillYouLearn,
  Syllabus,
  StartDate,
  CourseDuration,
  WhoShouldAttend,
  CourseFeesFunding,
  LearnFromIndustryExperts,
  Provider,
} from '../components/courses';

const MainLayout = styled.section`
  margin-bottom: ${({ theme }) => theme.layout().margin1}px;
`;

const Grid = styled.div`
  display: grid;
  @media ${({ theme }) => theme.device.desktop} {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: ${({ theme }) => theme.layout().padding1}px
      ${({ theme }) => theme.layout().padding3}px;

    grid-template-areas:
      'learnWithPartner learnWithPartner learnWithPartner courseCard courseCard'
      'skillsYoullGet skillsYoullGet skillsYoullGet courseCard courseCard'
      'overview overview overview overview overview'
      ${({ whatWillYouLearn }) => {
        if (whatWillYouLearn.length > 0) {
          return `'whatWillYouLearn whatWillYouLearn whatWillYouLearn whatWillYouLearn whatWillYouLearn'`;
        }
      }}
      ${({ syllabus }) => {
        if (syllabus.length > 0) {
          return `'syllabus syllabus syllabus syllabus syllabus'`;
        }
      }}
      ${({ courseOutline }) => {
        return courseOutline.length > 0
          ? `'courseOutline courseOutline courseOutline courseDuration courseDuration'
          'courseOutline courseOutline courseOutline courseFeesFunding courseFeesFunding'
          'courseOutline courseOutline courseOutline whoShouldAttend whoShouldAttend'`
          : `'whoShouldAttend whoShouldAttend whoShouldAttend courseDuration courseDuration'
          'whoShouldAttend whoShouldAttend whoShouldAttend courseFeesFunding courseFeesFunding'`;
      }}
      'learnFromIndustryExperts learnFromIndustryExperts learnFromIndustryExperts learnFromIndustryExperts learnFromIndustryExperts';
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'learnWithPartner courseCard'
      'skillsYoullGet courseCard'
      'overview overview'
      ${({ whatWillYouLearn }) => {
        if (whatWillYouLearn.length > 0) {
          return `'whatWillYouLearn whatWillYouLearn'`;
        }
      }}
      ${({ syllabus }) => {
        if (syllabus.length > 0) {
          return `'syllabus syllabus'`;
        }
      }}
      ${({ courseOutline }) => {
        return courseOutline.length > 0
          ? `'courseOutline courseDuration'
          'courseOutline courseFeesFunding'
          'courseOutline whoShouldAttend'`
          : `'courseDuration courseDuration'
          'whoShouldAttend whoShouldAttend'
          'courseFeesFunding courseFeesFunding'`;
      }}
      'learnFromIndustryExperts learnFromIndustryExperts';
    grid-gap: ${({ theme }) => theme.layout().padding1}px
      ${({ theme }) => theme.layout().padding4}px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'courseCard'
      'learnWithPartner'
      'skillsYoullGet'
      'overview'
      ${({ whatWillYouLearn }) => {
        if (whatWillYouLearn.length > 0) {
          return `'whatWillYouLearn'`;
        }
      }}
      ${({ syllabus }) => {
        if (syllabus.length > 0) {
          return `'syllabus'`;
        }
      }}
      ${({ courseOutline }) => {
        return courseOutline.length > 0
          ? `'courseOutline'
          'courseDuration'
          'courseFeesFunding'
          'whoShouldAttend'`
          : `'courseDuration'
          'whoShouldAttend'
          'courseFeesFunding'`;
      }}
      'learnFromIndustryExperts';
    grid-gap: ${({ theme }) => theme.layout().padding1}px
      ${({ theme }) => theme.layout().padding4}px;
  }
`;

const IndividualCoursePage = ({
  pageContext: {
    trainingPartners,
    slug,
    title,
    description,
    learningGoals,
    coursePartnerLink,
    whatWillYouLearn,
    syllabus,
    skillPills,
    courseCard,
    overview,
    courseOutline,
    whoShouldAttend,
    instructors,
  },
}) => {
  return (
    <>
      <Provider
        value={{
          trainingPartners,
          slug,
          title,
          description,
          learningGoals,
          coursePartnerLink,
          whatWillYouLearn,
          syllabus,
          skillPills,
          courseCard,
          overview,
          courseOutline,
          whoShouldAttend,
          instructors,
        }}
      >
        <SEO title={title} />
        <Banner />
        <MiniNavBar />
        <MainLayout>
          <Grid
            courseOutline={courseOutline}
            whatWillYouLearn={whatWillYouLearn}
            syllabus={syllabus}
          >
            <LearnWithPartner />
            <SkillsYoullGet />
            <Overview />
            <CourseCardWithContext />
            {courseOutline.length > 0 && <CourseOutline />}
            {/* <StartDate /> */}
            {whatWillYouLearn.length > 0 && <WhatWillYouLearn />}
            {syllabus.length > 0 && <Syllabus />}
            <CourseDuration />
            <WhoShouldAttend />
            <CourseFeesFunding />
            <LearnFromIndustryExperts />
          </Grid>
        </MainLayout>
      </Provider>
    </>
  );
};

IndividualCoursePage.defaultProps = {
  pageContext: {},
};

IndividualCoursePage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    learningGoals: PropTypes.arrayOf(PropTypes.string),
    coursePartnerLink: PropTypes.string,
    skillPills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    courseCard: PropTypes.shape({
      image: PropTypes.string,
      nextStartDate: PropTypes.shape({
        upcomingModule: PropTypes.string,
        date: PropTypes.string,
        day: PropTypes.string,
      }),
      modulesInformation: PropTypes.string,
      courseLevel: PropTypes.string,
      feesInformation: PropTypes.string,
    }),
    overview: PropTypes.string,
    courseOutline: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.string,
        date: PropTypes.string,
      })
    ),
    whoShouldAttend: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    instructors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        credentials: PropTypes.string,
        profileLink: PropTypes.string,
        image: PropTypes.string,
        bio: PropTypes.string,
      })
    ),
  }),
};

export default IndividualCoursePage;
