import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useStaticQuery, graphql, Link } from 'gatsby';

import './slick-carousel.css';
import $ from '../../../styles/global';
import Card from './Card';
import Utils from '../../../utils';

const Section = styled.section`
  padding: 80px 0;
  background-color: transparent;
`;

const Container = styled.div`
  position: relative;
  z-index: 0;
`;

const SliderContainer = styled.div`
  margin: 0 auto !important;
  width: 100%;
  max-width: 1200px;
`;

const TitleContainer = styled.div`
  margin-bottom: ${$.layout().margin3}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  // #endregion
`;

const BlogButton = styled(Link)`
  display: block;
  text-decoration: none;
  outline: none;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      > button {
        cursor: pointer;
        background-color: ${$.color.blue4};
      }
    }
  }
  // #endregion

  > button {
    transition: all 0.35s ${$.easingFn.standard};
    padding: 10px 25px;
    border-radius: 30px;
    background-color: ${$.color.blue5};
    color: ${$.color.white};
    border: none;
    font-size: 15px;
  }
`;

const Title = styled.h2`
  font-size: 40px;
  font-family: Aileron Heavy;
  margin-right: ${$.layout().margin3}px;
  color: ${$.color.black2};
  word-break: break-all;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-right: 0;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const ComingSoon = styled.div`
  box-shadow: ${$.dropShadow.normal};
  color: ${$.color.black2};
  font-family: Lato Italic;
  font-size: 24px;
  text-align: center;
  background-color: ${$.color.white};
  border-radius: 20px;
  width: calc(100% - ${$.layout().padding2 * 2}px);
  padding: ${$.layout().padding2}px;
`;

const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  li {
    width: 12px;
    height: 12px;
    background-color: ${$.color.gray2};
    border-radius: 99px;
    margin: 0 15px;
    &.slick-active {
      background-color: ${$.color.black2};
    }
    button {
      width: inherit;
      height: inherit;
      padding: 0;
      &::before {
        display: none;
      }
    }
  }
`;

const BlogUpdates = () => {
  const { allGhostPost } = useStaticQuery(graphql`
    {
      allGhostPost(
        filter: { tags: { elemMatch: { slug: { regex: "/deepracer/" } } } }
      ) {
        edges {
          node {
            feature_image
            title
            slug
          }
        }
      }
    }
  `);
  const [totalCards, setTotalCards] = useState(3);

  useEffect(() => {
    const cb = () => {
      if (Utils.isMobile()) {
        setTotalCards(1);
        return;
      }

      if (Utils.isTablet()) {
        setTotalCards(2);
        return;
      }

      setTotalCards(
        allGhostPost.edges.length <= 3 ? allGhostPost.edges.length : 3
      );
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', cb);
      cb();
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', cb);
      }
    };
  }, []);

  return (
    <Section>
      <Container>
        <TitleContainer>
          <Title>Relevant Blog Updates</Title>
          <BlogButton
            to="/blog/tag/deepracer/"
            title="View all of our blog posts tagged as #deepracer"
          >
            <button type="button">{`#deepracer`}</button>
          </BlogButton>
        </TitleContainer>
      </Container>
      {allGhostPost.edges.length > 0 ? (
        <SliderContainer>
          <Slider
            infinite
            dots
            appendDots={(dots) => <DotsContainer>{dots}</DotsContainer>}
            arrows={false}
            slidesToShow={totalCards}
            slidesToScroll={1}
            swipeToSlide
          >
            {allGhostPost.edges.map(
              ({ node: { feature_image: image, slug, title } }, index) => (
                <Card
                  slug={slug}
                  title={title}
                  img={image}
                  key={`aws_deepracer_blog_${index}`}
                />
              )
            )}
          </Slider>
        </SliderContainer>
      ) : (
        <Container>
          <ComingSoon>Stay tuned!</ComingSoon>
        </Container>
      )}
    </Section>
  );
};

export default BlogUpdates;
