import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/seo';
import { Events, Provider as EventsProvider } from '../components/EventsPage';
import Utils from '../utils';

const EventsPage = () => {
  const {
    allGhostPage: { edges: events },
  } = useStaticQuery(
    graphql`
      query {
        allGhostPage {
          edges {
            node {
              id
              html
              title
              tags {
                id
                name
              }
              slug
              excerpt
              twitter_description
              feature_image
              codeinjection_head
            }
          }
        }
      }
    `
  );

  return (
    <>
      <EventsProvider
        value={{
          events: Utils.parseGhostPagesToEvents(events),
        }}
      >
        <SEO
          keywords={['SG Code Campus', 'Events']}
          title="Events"
          description="Events at SG Code Campus"
        />
        <Events />
      </EventsProvider>
    </>
  );
};

export default EventsPage;
