import { createContext } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

const Provider = ({ children, value }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
};

export default Context;

export { Provider };
