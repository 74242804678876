import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../../styles/global';
import ProgrammeCards from '../../ProgrammeCards';

const Section = styled.section`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin: ${$.layout().margin3}px 0;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    > div {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  // #endregion
`;

const ProgrammeCardsContainer = () => (
  <Section>
    <Fade bottom fraction={0.1} distance="100px">
      <ProgrammeCards />
    </Fade>
  </Section>
);

export default ProgrammeCardsContainer;
