import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../../styles/global';
import DownChevron from '../../../../../assets/icons/down-chevron.svg';

/**
 * This part is to fix hidden box shadows due to overflow clipping the container
 */
const Container = styled.div`
  // overflow-y: hidden;
  padding: 0px ${$.layout().padding5}px ${$.layout().padding5}px;
  margin: 0px -${$.layout().padding5}px ${$.layout().margin3}px;
  position: relative;
  user-select: none;
`;

const DownChevronContainer = styled.div`
  ${({ rotationCounter }) => `
  width: 20px;
  height: 20px;
  transform: rotate(${rotationCounter * 180}deg);
  transition: transform 0.5s ${$.easingFn.standard};

  & > svg {
    fill: ${$.color.white};
  }
`}
`;

const QuestionContainer = styled.div`
  ${({ dropdownState }) => `
  width: calc(100% - ${$.layout().padding3 * 2}px);
  border-radius: ${$.border().radius1}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${$.layout().padding3}px;
  background-color: ${dropdownState ? $.color.orange3 : $.color.blue3};
  color: ${$.color.white};
  margin-bottom: ${dropdownState ? $.layout().margin3 : 0}px;
  box-shadow: ${$.dropShadow.normal};
  transition: background-color 0.5s ${$.easingFn.standard},
    margin-bottom 0.5s ${$.easingFn.standard};
  will-change: background-color, margin-bottom;

  &:hover {
    box-shadow: ${$.dropShadow.repressed};
  }
`}
`;

const Question = styled.div`
  flex: 1;
`;

const AnswerContainer = styled.div`
  overflow-y: hidden;
  padding: 0px ${$.layout().padding5}px ${$.layout().padding5}px;
  margin: 0px -${$.layout().padding5}px -${$.layout().padding5}px;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ${$.easingFn.standard},
    opacity 0.5s ${$.easingFn.standard};
  will-change: max-height, opacity;

  &.show {
    max-height: 150px;
    opacity: 1;
  }

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    &.show {
      max-height: 300px;
      opacity: 1;
    }
  }
  // #endregion
`;

const Answer = styled.div`
  word-break: normal;
  hyphens: auto;
  position: relative;
  background-color: ${$.color.white};
  border-radius: ${$.border().radius1}px;
  box-shadow: ${$.dropShadow.normal};
  padding: ${$.layout().padding3}px;
  width: calc(100% - ${$.layout().padding3 * 2}px);

  a {
    font-family: Lato Bold;
    text-decoration: none;
    color: ${$.color.blue5};
    &:visited {
      color: ${$.color.blue5};
    }
  }

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding4}px;
    width: calc(100% - ${$.layout().padding4 * 2}px);
  }
  // #endregion
`;

const Pill = ({ qas: { question, answer } }) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [rotationCounter, setRotationCounter] = useState(0);

  return (
    <Container>
      <QuestionContainer
        onClick={() => {
          setDropdownState((prev) => !prev);
          setRotationCounter((prev) => prev + 1);
        }}
        dropdownState={dropdownState}
      >
        <Question>{question}</Question>
        <DownChevronContainer
          dropdownState={dropdownState}
          rotationCounter={rotationCounter}
        >
          <DownChevron />
        </DownChevronContainer>
      </QuestionContainer>
      <AnswerContainer className={dropdownState ? 'show' : ''}>
        <Answer dangerouslySetInnerHTML={{ __html: answer }} />
      </AnswerContainer>
    </Container>
  );
};

Pill.defaultProps = {
  qas: {
    question: '',
    answer: '',
  },
};

Pill.propTypes = {
  qas: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
};

export default Pill;
