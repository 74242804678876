import styled from 'styled-components';

import $ from '../../../../styles/global';
import ImageContainer from '../../../Level2Banner/ImageContainer';
import Banner4 from '../../../../assets/images/whyus/students/banner4.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: 0px;
    right: -70px;

    & > svg {
      width: 382px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tabletA} {
    position: absolute;
    top: 20px;
    right: -140px;
    width: 55%;

    & > svg {
      width: 100%;
    }
  }

  @media ${$.device.tabletB} {
    position: absolute;
    top: 0px;
    right: -30px;
    min-width: 180px;
    width: 50%;

    & > svg {
      width: 100%;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Image4Container = () => <Container image={Banner4} />;

export default Image4Container;
