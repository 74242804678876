import styled from 'styled-components';

import $ from '../../../styles/global';
import LeftCard from './LeftCard';
import SlantedBackground from './SlantedBackground';
import RightCard from './RightCard';

const Section = styled.section``;

const Container = styled.div`
  margin: 0 !important;
  width: 100vw !important;
  height: 800px;
  position: relative;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    height: 1500px;
  }
  // #endregion
`;

const Title = styled.h1`
  font-family: Lato Bold;
  font-size: 45px;
  color: ${$.color.black2};
  text-align: left;
  margin-bottom: ${$.layout().margin1}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    font-size: 40px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1100px;
  margin: 0 calc((100vw - 1100px) / 2);
  padding: 100px 0 120px 0;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    margin: 0 ${$.layout().margin3}px;
    padding: 80px 0 100px 0;
  }
  // #endregion
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const Banner = () => (
  <Section>
    <Container>
      <SlantedBackground />
      <InnerContainer>
        <Title>
          Get officially certified with tech giants to prepare for your dream
          career
        </Title>
        <Cards>
          <LeftCard />
          <RightCard />
        </Cards>
      </InnerContainer>
    </Container>
  </Section>
);

export default Banner;
