import PropTypes from 'prop-types';

import SEO from '../../components/seo';
import Banner from '../../components/MiniBanner';
import Recommender from '../../components/courserecommender/Recommender';

const CourseRecommender = ({ location }) => (
  <>
    <SEO
      location={location}
      keywords={[
        'coding course recommendation',
        'which coding course for my child',
        'what coding course for my kid',
      ]}
      title="Course Recommender"
      description="Check out this coding course recommender to see which learning path your child should take."
    />
    <Banner
      title="Course Recommender"
      subtitle="Not sure where to begin your child's coding journey? Let us help you out!"
    />
    <Recommender />
  </>
);

CourseRecommender.defaultProps = {
  location: {},
};

CourseRecommender.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default CourseRecommender;
