import styled from 'styled-components';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';

import $ from '../../../../styles/global';

const alphabetList = [...'abcdefghijklmnopqrstuvwxyz'.split(''), 'Others'];

const Container = styled.div``;

const AlphabetContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 30px;
  color: ${$.color.blue4};
  text-transform: capitalize;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${$.color.blue3};
  font-family: Lato Bold;
  margin: ${$.layout().margin4}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Tags = ({ data }) => (
  <Container>
    {alphabetList
      .filter((letter) => {
        if (
          data.filter(({ node: { name } }) => {
            if (
              alphabetList.indexOf(name[0].toLowerCase()) !== -1 &&
              name[0].toLowerCase() === letter
            ) {
              return true;
            }
            return false;
          }).length !== 0
        ) {
          return true;
        }
        if (
          letter === 'Others' &&
          data.filter(({ node: { name } }) => {
            if (alphabetList.indexOf(name[0].toLowerCase()) === -1) {
              return true;
            }
            return false;
          }).length !== 0
        ) {
          return true;
        }
        return false;
      })
      .map((letter) => (
        <Fade bottom distance="100px" key={shortid.generate()}>
          <AlphabetContainer>
            <Title>{letter}</Title>
            <LinksContainer>
              {data.map(({ node: { name, slug, postCount } }) => {
                if (
                  name[0].toLowerCase() === letter ||
                  (letter === 'Others' &&
                    alphabetList.indexOf(name[0].toLowerCase()) === -1)
                ) {
                  return (
                    <StyledLink
                      key={shortid.generate()}
                      to={`/blog/tag/${slug}/`}
                    >
                      {`${name} (${postCount})`}
                    </StyledLink>
                  );
                }
                return '';
              })}
            </LinksContainer>
          </AlphabetContainer>
        </Fade>
      ))}
  </Container>
);

Tags.defaultProps = {
  data: [],
};

Tags.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        postCount: PropTypes.number,
      }),
    })
  ),
};

export default Tags;
