import styled from 'styled-components';

import $ from '../../../../styles/global';

const Container = styled.div`
  width: 100%;
  overflow: scroll;
  z-index: 1;
  position: relative;
  margin-bottom: ${$.layout().margin3}px;
`;

const Table = styled.table`
  border: 1px solid ${$.color.black};
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;

  & td {
    min-width: 80px;
    padding: ${$.layout().padding5}px;
    border: 1px solid ${$.color.black};
  }

  & tr.highlight {
    background-color: ${$.color.gray1};
    font-family: Lato Bold;
  }

  & tr.center {
    background-color: ${$.color.black};
    text-align: center;
    color: ${$.color.white};
  }
`;

const AcceleratorTable = () => (
  <Container>
    <Table>
      <thead>
        <tr className="highlight">
          <td>Session</td>
          <td>Term Week</td>
          <td>Day</td>
          <td>Date</td>
          <td>Time</td>
          <td>Hours</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>June Holidays</td>
          <td>Sat</td>
          <td>19th June</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>2</td>
          <td>June Holidays</td>
          <td>Sat</td>
          <td>26th June</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Term 3 Week 1</td>
          <td>Sat</td>
          <td>3rd Jul</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Term 3 Week 2</td>
          <td>Sat</td>
          <td>10th Jul</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Term 3 Week 3</td>
          <td>Sat</td>
          <td>17th Jul</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Term 3 Week 4</td>
          <td>Sat</td>
          <td>24th Jul</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Term 3 Week 5</td>
          <td>Sat</td>
          <td>31st Jul</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Term 3 Week 6</td>
          <td>Sat</td>
          <td>7th Aug</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Term 3 Week 7</td>
          <td>Sat</td>
          <td>14th Aug</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Term 3 Week 8</td>
          <td>Sat</td>
          <td>21st Aug</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Term 3 Week 9</td>
          <td>Sat</td>
          <td>28th Aug</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Term 3 Week 10</td>
          <td>Sat</td>
          <td>4th Sep</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr className="center">
          <td colSpan="7">BREAK</td>
        </tr>
        <tr>
          <td>13</td>
          <td>Term 4 Week 6</td>
          <td>Sat</td>
          <td>30th Oct</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>14</td>
          <td>Term 4 Week 7</td>
          <td>Sat</td>
          <td>6th Nov</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>15</td>
          <td>Term 4 Week 8</td>
          <td>Sat</td>
          <td>13th Nov</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>16</td>
          <td>Nov - Dec Holidays</td>
          <td>Mon</td>
          <td>15th Nov</td>
          <td>10:00AM - 12:30PM & 1:30PM - 4:00PM</td>
          <td>5</td>
        </tr>
        <tr>
          <td>17</td>
          <td>Nov - Dec Holidays</td>
          <td>Tue</td>
          <td>16th Nov</td>
          <td>10:00AM - 12:30PM & 1:30PM - 4:00PM</td>
          <td>5</td>
        </tr>
        <tr>
          <td>18</td>
          <td>Nov - Dec Holidays</td>
          <td>Wed</td>
          <td>17th Nov</td>
          <td>10:00AM - 12:30PM & 1:30PM - 4:00PM</td>
          <td>5</td>
        </tr>
        <tr>
          <td>19</td>
          <td>Nov - Dec Holidays</td>
          <td>Sat</td>
          <td>20th Nov</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>20</td>
          <td>Nov - Dec Holidays</td>
          <td>Sat</td>
          <td>27th Nov</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>21</td>
          <td>Nov - Dec Holidays</td>
          <td>Sat</td>
          <td>4th Dec</td>
          <td>1:00PM - 4:30PM</td>
          <td>3.5</td>
        </tr>
        <tr>
          <td>22</td>
          <td>Nov - Dec Holidays</td>
          <td>Sat</td>
          <td>11th Dec</td>
          <td>10:00AM - 12:30PM & 1:00PM - 4:30PM</td>
          <td>5</td>
        </tr>
        <tr>
          <td>23</td>
          <td>Nov - Dec Holidays</td>
          <td>Sat</td>
          <td>18th Dec</td>
          <td>10:00AM - 12:30PM & 1:00PM - 4:30PM</td>
          <td>5</td>
        </tr>
      </tbody>
    </Table>
  </Container>
);

export default AcceleratorTable;
