import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import TopSlider from './TopSlider';
import BottomSlider from './BottomSlider';
import { Provider } from './Context';

const Divider = styled.div`
  height: 2px;
  background-color: ${$.color.blue5};
  opacity: 0.5;
  width: 100%;
  margin-bottom: ${$.layout().margin3}px;
`;

const SliderView = ({ data, className, expandProfileFirst }) => {
  const [current, setCurrent] = useState(
    expandProfileFirst
      ? data.reduce((prev, { bubble: { anchor } }, index) => {
          return anchor === expandProfileFirst ? index : prev;
        }, 0)
      : 0
  );
  const [desc, setDesc] = useState(data[current].description);

  return (
    <Provider
      value={{
        current,
        setCurrent,
        desc,
        setDesc,
        data,
      }}
    >
      <TopSlider className={className} />
      <Divider className={className} />
      <BottomSlider className={className} />
    </Provider>
  );
};

SliderView.defaultProps = {
  data: [],
  className: '',
};

SliderView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  expandProfileFirst: PropTypes.string.isRequired,
};

export default SliderView;
