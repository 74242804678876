import styled from 'styled-components';

import $ from '../../../styles/global';
import AWSCloudPractitionerLogo from '../../../assets/images/tertiary/aws-certified-cloud-practitioner-logo.png';

const Section = styled.section`
  background-color: ${$.tertiary.orange};
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  width: 100vw !important;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    & > * {
      padding: 100px 0;
    }
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    & > * {
      padding: 100px 0;
    }
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    height: 100%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    height: 100%;
  }
  // #endregion
`;

const Info = styled.div`
  width: calc(500px - ${$.layout().padding3}px);
  padding-left: ${$.layout().padding3}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: calc(40% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 120px;
    padding-bottom: 100px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 120px;
    padding-bottom: 100px;
  }
  // #endregion
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Description = styled.div`
  p {
    font-family: Lato Regular;
    font-size: 18px;
    color: ${$.color.white};
    opacity: 0.8;
    line-height: 1.3em;
  }
`;

const OverlayContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${$.color.blue5};
  width: calc(470px + ((100vw - 970px) / 2));

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: calc(60% - ${$.layout().padding2}px);
    padding-left: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 470px;
  padding-left: calc((100vw - 970px) / 2);

  & > img {
    width: 300px;
    height: 300px;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding2 * 2}px);
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
  }
  // #endregion
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 322px 80px 322px 0;
  border-color: transparent ${$.tertiary.orange} transparent transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    border-width: 375px 80px 375px 0;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    border-width: 60px 50vw 0 50vw;
    border-color: ${$.color.blue5} transparent transparent transparent;
    bottom: -60px;
    top: initial;
    right: initial;
    left: 0;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    border-width: 60px 50vw 0 50vw;
    border-color: ${$.color.blue5} transparent transparent transparent;
    bottom: -60px;
    top: initial;
    right: initial;
    left: 0;
  }
  // #endregion
`;

const About = () => (
  <Section>
    <Container>
      <OverlayContainer>
        <ImageContainer>
          <img
            src={AWSCloudPractitionerLogo}
            alt="AWS Cloud Practitioner Logo"
          />
        </ImageContainer>
        <Triangle />
      </OverlayContainer>
      <Info>
        <Title>About the AWS Cloud & AI Bootcamp</Title>
        <Description>
          <p>
            Pick up Python, Machine Learning, Cloud Computing in a well-designed
            and coherent bootcamp running over a week.
          </p>
          <p>
            Prepare for the AWS Cloud Practitioner Exam and earn one of the
            world most sought after IT credentials (all students will be
            provided with an exam voucher to sit for the exam on their own time
            after the course)
          </p>
          <p>
            Certificate of Completion issued by AWS for Cloud Practitioner
            Essentials Programme culminates in a Firechat, sharing and
            networking session with industry professionals at the Lifelong
            Learning Institute (LLI) in Payar Lebar.
          </p>
        </Description>
      </Info>
    </Container>
  </Section>
);

export default About;
