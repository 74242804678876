import SEO from '../../components/seo';
import Banner from '../../components/DatascienceAccelerator2022/Banner';
import About from '../../components/DatascienceAccelerator2022/About';
import Learn from '../../components/DatascienceAccelerator2022/Learn';
import SignUp from '../../components/DatascienceAccelerator2022/SignUp';
import Testimonials from '../../components/DatascienceAccelerator2022/Testimonials';
import ScheduleTable from '../../components/DatascienceAccelerator2022/Schedule/ScheduleTable';
import ScheduleBanner from '../../components/DatascienceAccelerator2022/Schedule/Banner';

const DatascienceAccelerator = () => (
  <>
    <SEO title="Data Science Accelerator 2024" />
    <Banner />
    <About />
    <Learn />
    <ScheduleBanner />
    <ScheduleTable />
    <SignUp />
    <Testimonials />
  </>
);

export default DatascienceAccelerator;
