import { graphql } from 'gatsby';

import Seo from '../../components/seo';
import { Provider } from '../../components/courses';
import { Banner, Cards } from '../../components/courses/coursesPage';

export const query = graphql`
  query {
    allCoursesJson(
      filter: { trainingPartners: { elemMatch: { id: { eq: "aws" } } } }
    ) {
      edges {
        node {
          id
          trainingPartners {
            id
            name
          }
          title
          slug
          description
          learningGoals
          coursePartnerLink
          skillPills {
            id
            name
          }
          courseCard {
            image
            nextStartDate {
              upcomingModule
              date
              day
            }
            modulesInformation
            courseLevel
            feesInformation
          }
          overview
          courseOutline {
            id
            description
            link
            date
          }
          whoShouldAttend {
            id
            name
          }
          instructors {
            id
            name
            credentials
            profileLink
            image
            bio
          }
        }
      }
    }

    allFile(filter: { relativePath: { eq: "partners/aws/aws.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const SMUCoursesIndexPage = ({ data }) => {
  return (
    <>
      <Provider
        value={{
          trainingPartner: 'aws',
          courses: data.allCoursesJson.edges.map(({ node }) => node),
          banner: {
            title: 'AWS Training and Certification',
            className: 'aws',
            description:
              "Enhance your IT career with professional training and certifications from an AWS Training Partner. Master essential skills in cloud computing, data science, and DevOps through our comprehensive courses. Learn from experienced AWS Certified instructors who provide practical knowledge and hands-on experience to help you excel in today's competitive tech landscape.",
            image: data.allFile.edges[0].node.childImageSharp.gatsbyImageData,
          },
        }}
      >
        <Seo title="AWS Training and Certification | SG Code Campus" />
        <Banner />
        <Cards />
      </Provider>
    </>
  );
};

export default SMUCoursesIndexPage;
