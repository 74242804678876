import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from '../context';
import EventsContainer from './EventsContainer';
import EventCard from './EventCard';

const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.layout().margin1}px;
`;

const Events = () => {
  const { events } = useContext(Context);

  return (
    <Section>
      <EventsContainer>
        {events.map((event) => (
          <EventCard key={event.id} {...event} />
        ))}
      </EventsContainer>
    </Section>
  );
};

export default Events;
