import styled from 'styled-components';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import items from './items.json';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const TableContainer = styled.div`
  overflow: scroll;
  margin-top: ${$.layout().margin3}px;
`;

const Table = styled.table`
  width: 100%;
  min-width: 970px;
  border-collapse: collapse;

  td {
    padding: 15px;
  }

  thead {
    overflow: hidden;
    background-color: ${$.color.black2};
    font-family: Lato Bold;
    color: ${$.color.white};
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 1px;
    td {
      &:not(:last-child) {
        border-right: 1px solid ${$.color.white};
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  tbody {
    overflow: hidden;
    font-family: Lato Regular;
    font-size: 16px;

    em {
      font-style: italic;
    }

    tr {
      td {
        &:first-child {
          font-size: 18px;
          font-family: Lato Bold;
          text-align: center;
        }
        &:not(:last-child) {
          border-right: 1px solid ${$.color.black2};
        }
        &:first-child {
          border-radius: 10px 0 0 10px;
        }
        &:last-child {
          border-radius: 0 10px 10px 0;
        }
      }
    }
    tr:nth-child(even) {
      background-color: ${$.accelerator.grey};
      td:not(:last-child) {
        border-right: 1px solid ${$.color.black2};
      }
    }
  }
`;

const Subtitle = styled.p`
  font-family: Lato Bold;
  font-size: 18px;
  color: ${$.color.black2};
  opacity: 1;
  margin-bottom: ${$.layout().margin4}px;

  & > span {
    color: ${$.color.red};
  }

  & a {
    color: ${$.color.orange4};
    &:visited {
      color: ${$.color.orange4};
    }
  }
`;

const itemsWithKeys = Utils.addKeys(items);

const ScheduleTable = () => (
  <Section>
    <Container>
      <Subtitle>
        Sessions will be held on every Saturday starting 16th Mar 2024 to 17th
        Jan 2025 according to the streams below unless stated otherwise in the
        schedule.
      </Subtitle>
      <ol>
        <li>Track A, 10:00am - 1:00pm (SG Code Campus, Tanjong Pagar)</li>
        <li>Track B, 2:00pm - 5:00pm (SG Code Campus, Tanjong Pagar)</li>
      </ol>
      <Subtitle>
        Schedule is tentative and subject to change. If you have any questions,
        please contact us at{' '}
        <a
          href="mailto:datascience@sgcodecampus.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          datascience@sgcodecampus.com
        </a>
      </Subtitle>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <td>Session</td>
              <td>Date</td>
              <td>Track A</td>
              <td>Track B</td>
              <td>Remarks</td>
            </tr>
          </thead>
          <tbody>
            {itemsWithKeys.map(
              ({ date, bothTracks, trackA, trackB, remarks }, index) => (
                <tr key={date}>
                  <td>{index + 1}</td>
                  <td>{date}</td>
                  {bothTracks ? (
                    <td colSpan="2" style={{ textAlign: 'center' }}>
                      {trackA}
                    </td>
                  ) : (
                    <>
                      <td>{trackA}</td>
                      <td>{trackB}</td>
                    </>
                  )}
                  <td dangerouslySetInnerHTML={{ __html: remarks }} />
                </tr>
              )
            )}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  </Section>
);

export default ScheduleTable;
