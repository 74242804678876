import styled from 'styled-components';

import $ from '../../../styles/global';

const Section = styled.section`
  background-color: transparent;
`;

const Container = styled.div``;

const Dots = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  > * {
    background-color: ${$.color.blue5};
    width: 10px;
    height: 10px;
    border-radius: 999px;
    margin-bottom: ${$.layout().margin4}px;
    &:first-child {
      margin-top: ${$.layout().margin4}px;
    }
  }
`;

const DotsDivider = () => (
  <Section>
    <Container>
      <Dots>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </Dots>
    </Container>
  </Section>
);

export default DotsDivider;
