import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import Util from '../../utils';
import JobCard from './JobCard';

const Section = styled.section`
  margin: ${$.layout().margin3}px 0;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    > * {
      margin: 0 !important;
      width: 100vw !important;
    }
  }
  // #endregion
`;

const Container = styled.div`
  text-align: center;
`;

const TopMessage = styled.h2`
  font-family: Aileron Heavy;
  color: ${$.color.orange5};
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin3}px;
  font-size: 20px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin: 0 ${$.layout().margin3}px ${$.layout().margin3}px
      ${$.layout().margin3}px;
  }
  // #endregion
`;

const BottomMessage = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.orange5};
  line-height: 1.4em;
  font-size: 20px;
  a {
    text-decoration: none;
    color: ${$.color.blue4};
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion
`;

const jobs = Util.addKeys([
  {
    title: 'Teaching Instructors (Full-Time/Part-Time)',
    description:
      'We&apos;re looking for instructors to spread the love of coding and inspire our youth to harness technology in pursuit of their dreams!<br />If you are:<ul><li>An experienced educator with a strong desire to learn more about technology OR</li><li>An experienced programmer with an interest in teaching kids and teens</li><li>Fluent in English and good at communicating your ideas</li><li>A great team player who&apos;s friendly, energetic and always willing to try something new</li></ul>And:<ul><li>Able to commit 3-6 hours per weekend for a minimum period of 6 months OR</li><li>Able to commit 3-6 hours daily from Monday to Fridays during local school holiday periods for at least 3 weeks, for a minimum period of 1 year</li></ul>We&apos;d love to hear from you! Email us at <a href="mailto:joinus@sgcodecampus.com">joinus@sgcodecampus.com</a> with your resume now!',
    image: 'joinus/instructorjob.jpg',
  },
]);

const Content = () => (
  <Section>
    <Fade bottom distance="100px">
      <Container>
        <TopMessage>
          Want to be a part of the SG Code Campus team? Check out our current
          openings here.
        </TopMessage>
        {jobs.map(({ title, description, key, image }) => (
          <JobCard
            title={title}
            description={description}
            image={Util.importOneImage(image)}
            key={key}
          />
        ))}
        <BottomMessage>
          Don&apos;t see anything suitable, but think you&apos;d be a perfect
          fit for us anyway? We&apos;d love to hear from you still! Send us your
          resume at{' '}
          <a href="mailto:joinus@sgcodecampus.com">joinus@sgcodecampus.com</a>{' '}
          and tell us why you have what it takes!
        </BottomMessage>
      </Container>
    </Fade>
  </Section>
);

export default Content;
