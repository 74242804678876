import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../../styles/global';
import Util from '../../../../../../utils';
import RectImage from './RectImage';
import RectBox from './RectBox';
import { Context } from '../Context';

const LeftImageBox = styled(RectImage)``;

const RightImageBox = styled(RectImage)``;

const StyledInfoBox = styled(RectBox)``;

const Container = styled.div`
  ${({ bgColor, story, position }) => `
  overflow: hidden;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    height: 300px;
    width: 100%;
    background-color: ${bgColor};
    position: relative;

    ${LeftImageBox} {
      transition: opacity 0.4s ease;
      order: 0;
      opacity: 1;
      ${position === 'left' ? '' : 'position: absolute; top: 0; right: 0;'}
    }

    ${RightImageBox} {
      transition: opacity 0.4s ease;
      order: 2;
      position: absolute;
      top: 0;
      opacity: 0;
      ${position === 'left' ? 'left: 0;' : 'right: 0;'}
    }

    ${StyledInfoBox} {
      order: 1;
    }

    &.clicked {
      ${LeftImageBox} {
        opacity: 0;
      }

      ${RightImageBox} {
        opacity: 1;
      }
    }

    ${LeftImageBox}, ${RightImageBox} {
      > * {
        ${story ? 'transform: scale(1, 1);' : ''}
        transition: transform 0.3s ${$.easingFn.standard};
      }
    }

    &:hover {
      ${story ? 'cursor: pointer;' : ''}
      ${LeftImageBox}, ${RightImageBox} {
        > * {
          ${story ? 'transform: scale(1.1, 1.1);' : ''}
        }
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 260px;
    width: 100%;
    background-color: ${bgColor};

    ${LeftImageBox} {
      order: 0;
      opacity: 1;
      transition: opacity 0.4s ease;
      ${position === 'left' ? '' : 'position: absolute; top: 0; right: 0;'}
    }

    ${RightImageBox} {
      order: 2;
      position: absolute;
      top: 0;
      opacity: 0;
      transition: opacity 0.4s ease;
      ${position === 'left' ? 'left: 0;' : 'right: 0;'}
    }

    ${StyledInfoBox} {
      order: 1;
    }

    &.clicked {
      ${LeftImageBox} {
        opacity: 0;
      }

      ${RightImageBox} {
        opacity: 1;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    min-height: 400px;
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
    position: relative;

    ${LeftImageBox} {
      position: absolute;
      transition: all 0.5s ease-in-out;
      top: 0;
      opacity: 1;
      z-index: 2;
    }

    ${RightImageBox} {
      position: absolute;
      transition: all 0.5s ease-in-out;
      top: 0;
      opacity: 0;
      z-index: 2;
    }

    ${StyledInfoBox} {
      transition: all 0.5s ease-in-out;
    }

    &.clicked {
      ${LeftImageBox} {
        opacity: 0;
      }

      ${RightImageBox} {
        opacity: 1;
      }
    }
  }
  // #endregion
`}
`;

const Rectangle = ({ index }) => (
  <Context.Consumer>
    {({ clicked, setClicked, items, allColors }) => {
      const { bgColor } = allColors[index];
      const { firstImg, story, secondImg, position = 'left' } = items[index];
      const anchor = items.length - index;

      return (
        <Container
          bgColor={bgColor}
          className={clicked === index ? `${anchor} clicked` : anchor}
          position={position}
          title={`anchor: ${anchor}`}
          story={story}
          onClick={() => {
            if (!story) {
              return;
            }
            // Allow user to tap onto the whole container if on desktop or tablet.
            if (typeof window !== 'undefined' && Util.isMobile() === false) {
              setClicked((prev) => (prev === index ? -1 : index));
            }
          }}
        >
          <LeftImageBox img={firstImg} index={index} position={position} />
          <RightImageBox img={secondImg} index={index} position={position} />
          <StyledInfoBox index={index} />
        </Container>
      );
    }}
  </Context.Consumer>
);

Rectangle.defaultProps = {
  index: 0,
};

Rectangle.propTypes = {
  index: PropTypes.number,
};

export default Rectangle;
