import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../styles/global';
import DownChevron from '../../../../assets/icons/down-chevron.svg';

/**
 * This part is to fix hidden box shadows due to overflow clipping the container
 */
const Container = styled.div`
  padding: 0px ${$.layout().padding5}px ${$.layout().padding5}px;
  position: relative;
  user-select: none;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin: 0px -${$.layout().padding5}px ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin: 0px -${$.layout().padding5}px ${$.layout().margin5}px;
  }
  // #endregion
`;

const DownChevronContainer = styled.div`
  width: 20px;
  height: 20px;
  transform: rotate(${({ rotationCounter }) => `${rotationCounter * 180}deg`});
  transition: transform 0.5s ${$.easingFn.standard};

  & > svg {
    fill: ${$.color.white};
  }
`;

const QuestionContainer = styled.div`
  width: calc(100% - ${$.layout().padding3 * 2}px);
  border-radius: ${$.border().radius1}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${$.layout().padding3}px;
  background-color: ${({ dropdownState }) =>
    dropdownState ? $.color.orange3 : $.color.blue3};
  color: ${$.color.white};
  margin-bottom: ${({ dropdownState }) =>
    dropdownState ? $.layout().margin3 : 0}px;
  box-shadow: ${$.dropShadow.normal};
  transition: background-color 0.5s ${$.easingFn.standard},
    margin-bottom 0.5s ${$.easingFn.standard},
    box-shadow 0.5s ${$.easingFn.standard};

  &:hover {
    box-shadow: ${$.dropShadow.repressed};
  }
`;

const Question = styled.div`
  flex: 1;
`;

const AnswerContainer = styled.div`
  opacity: ${({ dropdownState }) => (dropdownState ? '1' : '0')};
  overflow-y: hidden;
  padding: 0px ${$.layout().padding5}px ${$.layout().padding5}px;
  margin: 0px -${$.layout().padding5}px -${$.layout().padding5}px;
  transition: max-height 0.5s ${$.easingFn.standard},
    opacity 0.5s ${$.easingFn.standard};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    max-height: ${({ dropdownState }) => (dropdownState ? '200px' : '0px')};
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    max-height: ${({ dropdownState }) => (dropdownState ? '200px' : '0px')};
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    max-height: ${({ dropdownState }) => (dropdownState ? '350px' : '0px')};
  }
  // #endregion
`;

const Answer = styled.div`
  position: relative;
  background-color: ${$.color.white};
  border-radius: ${$.border().radius1}px;
  box-shadow: ${$.dropShadow.normal};
  color: ${$.color.blue4};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding3}px;
    width: calc(100% - ${$.layout().padding3 * 2}px);
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding4}px;
    width: calc(100% - ${$.layout().padding4 * 2}px);
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding4}px;
    width: calc(100% - ${$.layout().padding4 * 2}px);
  }
  // #endregion
`;

const Pill = ({ qas: { question, answer } }) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [rotationCounter, setRotationCounter] = useState(0);

  return (
    <Container>
      <QuestionContainer
        onClick={() => {
          setDropdownState((prev) => !prev);
          setRotationCounter((prev) => prev + 1);
        }}
        dropdownState={dropdownState}
      >
        <Question>{question}</Question>
        <DownChevronContainer
          dropdownState={dropdownState}
          rotationCounter={rotationCounter}
        >
          <DownChevron />
        </DownChevronContainer>
      </QuestionContainer>
      <AnswerContainer dropdownState={dropdownState}>
        <Answer>{answer}</Answer>
      </AnswerContainer>
    </Container>
  );
};

Pill.defaultProps = {
  qas: {
    question: '',
    answer: '',
  },
};

Pill.propTypes = {
  qas: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default Pill;
