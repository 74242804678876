import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import { modalUpdate } from '../../../actions';
import useActions from '../../../utils/customHooks/useActions';
import PlayIcon from '../../../assets/images/awsaccelerator2022/play.svg';

const Container = styled.div`
  width: 30%;

  & > span {
    color: ${$.accelerator.blue};
    font-family: Lato Bold;
    font-size: 18px;
    margin-bottom: ${$.layout().margin4}px;
  }

  & > p {
    font-size: 16px;
    font-family: Lato Regular;
    line-height: 1.3em;
    color: ${$.color.black2};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin2}px;

    & > div:first-child {
      height: 300px;
    }
  }
  // #endregion
`;

const Overlay = styled.div`
  position: relative;
  margin-bottom: ${$.layout().margin3}px;

  & > * {
    width: 100%;
    height: 250px;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > * {
      height: 300px;
    }
  }
  // #endregion
`;

const Hover = styled.div`
  background-color: ${$.color.black2};
  opacity: 0.3;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ${$.easingFn.standard};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > svg {
    fill: ${$.color.white};
    opacity: 0;
    width: 50px;
    height: 50px;
    transform: scale(2);
    transition: opacity 0.5s ${$.easingFn.standard};
  }

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
      & > svg {
        opacity: 1;
      }
    }
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    opacity: 0.5;
    & > svg {
      opacity: 1;
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    opacity: 0.5;
    & > svg {
      opacity: 1;
    }
  }
  // #endregion
`;

const Card = ({ image, name, desc, video }) => {
  const [updateModal] = useActions([modalUpdate]);

  return (
    <Container>
      <Overlay>
        {image}
        <Hover
          onClick={() => {
            updateModal({ show: true, video });
          }}
        >
          <PlayIcon />
        </Hover>
      </Overlay>
      <span>{name}</span>
      <p>{desc}</p>
    </Container>
  );
};

Card.defaultProps = {
  image: <></>,
  name: '',
  desc: '',
  video: '',
};

Card.propTypes = {
  image: PropTypes.element,
  name: PropTypes.string,
  desc: PropTypes.string,
  video: PropTypes.string,
};

export default Card;
