import styled from 'styled-components';

import $ from '../../styles/global';

const Section = styled.section`
  background-color: ${$.color.orange3};
`;

const Container = styled.div`
  padding: 50px 0;
`;

const Title = styled.h1`
  font-size: 40px !important;
  font-family: Aileron Heavy !important;
  color: ${$.color.white};
`;

const Banner = () => (
  <Section>
    <Container>
      <Title>SGCC Summer Scratch Competition</Title>
    </Container>
  </Section>
);

export default Banner;
