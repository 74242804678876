import PropTypes from 'prop-types';

import WayOfThinking from '../components/whyus/philosophy/WayOfThinking';
import Proficiencies from '../components/whyus/philosophy/Proficiencies';
import ConceptualUnderstanding from '../components/whyus/philosophy/ConceptualUnderstanding';
import ProgrammeCards from '../components/whyus/philosophy/ProgrammeCardsContainer';
import SolvingProblems from '../components/whyus/philosophy/SolvingProblems';
import BuildBetterFuture from '../components/whyus/philosophy/BuildBetterFuture';
import Banner from '../components/MiniBanner';
import SEO from '../components/seo';

const Philosophy = ({ location }) => (
  <>
    <SEO
      location={location}
      keywords={[
        'best coding curriculum for kids Singapore',
        'best coding curriculum for teens Singapore',
        'structured coding curriculum for kids and teens',
        'coding curriculum philosophy for kids and teens',
        'best computer science curriculum kids',
      ]}
    />
    <Banner
      title="Our Curriculum Philosophy"
      subtitle="Defining a best-in-class computer science curriculum for kids and teens"
    />
    <WayOfThinking />
    <Proficiencies />
    <ConceptualUnderstanding />
    <ProgrammeCards />
    <SolvingProblems />
    <BuildBetterFuture />
  </>
);

Philosophy.defaultProps = {
  location: {},
};

Philosophy.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default Philosophy;
