import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import UpChevron from '../assets/icons/up-chevron.svg';
import $ from '../styles/global';

const Container = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 9999;
`;

const Button = styled.button`
  position: absolute;
  border-radius: 999px;
  pointer-events: none;
  border: none;
  opacity: 0;
  transition: opacity 0.5s ${$.easingFn.standard};
  bottom: 20px;
  padding: 20px;
  right: 160px;
  cursor: pointer;
  background-color: ${$.color.orange3};

  > svg {
    width: 20px;
    height: 16px;
    fill: ${$.color.white};
  }

  &.active {
    opacity: 0.4;
    pointer-events: initial;
  }

  &:focus {
    outline: none;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover.active {
      opacity: 1;
    }
  }
  // #endregion
`;

const BackToTop = ({ className }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const scrollCallback = () => {
      const bodyHeight = window.innerHeight * 0.1;

      setShow(window.scrollY >= bodyHeight);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', scrollCallback);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', scrollCallback);
      }
    };
  }, []);

  return (
    <Container>
      <Button
        className={show === true ? `active ${className}` : className}
        title="Back to top"
        onClick={() => {
          if (typeof window !== 'undefined') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }}
      >
        <UpChevron />
      </Button>
    </Container>
  );
};

BackToTop.defaultProps = {
  className: '',
};

BackToTop.propTypes = {
  className: PropTypes.string,
};

export default BackToTop;
