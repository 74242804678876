const initialState = {
  show: false,
};

export default (state = initialState, { payload, type }) => {
  const newState = { ...state };
  switch (type) {
    case 'ACADA_SHOW_POPUP':
      newState.show = payload.show;
      return newState;
    default:
      return state;
  }
};
