import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../styles/global';

const Container = styled.div`
  position: relative;
  background-color: ${({ path }) =>
    path === 1 ? $.color.white : 'transparent'};
  padding-top: ${$.layout().padding3}px;
  padding-bottom: ${$.layout().padding3}px;
  box-shadow: ${({ path }) => (path ? $.dropShadow.normal : 'none')};
  z-index: 10;

  > * {
    margin-right: ${$.layout().margin4}px;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    position: ${({ path }) => (path === 1 ? 'relative' : 'absolute')};
    ${({ path }) => (path === 1 ? '' : 'bottom: 70px;')}
    margin-left: -${$.layout().margin2}px;
    margin-right: -${$.layout().margin2}px;
    padding-left: ${$.layout().padding1}px;
    padding-right: ${$.layout().padding1}px;

    > * {
      margin-right: ${$.layout().margin3}px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    position: ${({ path }) => (path === 1 ? 'relative' : 'absolute')};
    ${({ path }) => (path === 1 ? '' : 'bottom: 50px;')}
    ${({ path }) =>
      path === 1
        ? `
    margin-left: -${$.layout().margin4}px;
    margin-right: -${$.layout().margin4}px;
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
      `
        : `
    margin-left: -${$.layout().margin3}px;
    margin-right: -${$.layout().margin3}px;
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
      `}

    > * {
      margin-right: ${$.layout().margin3}px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    align-self: flex-start;
    margin-left: -${$.layout().padding3}px;
    margin-right: -${$.layout().padding3}px;
    padding: 0px ${$.layout().padding3}px;
    ${({ path }) =>
      path === 1
        ? `
    margin-left: -${$.layout().margin4}px;
    margin-right: -${$.layout().margin4}px;
    padding: ${$.layout().padding3}px ${$.layout().padding4}px
      `
        : `
    margin-left: -${$.layout().padding3}px;
    margin-right: -${$.layout().padding3}px;
    padding: 0px ${$.layout().padding3}px;
      `}

    > * {
      margin-right: ${$.layout().margin3}px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // #endregion
`;

const StyledLink = styled(Link)`
  display: inline-block;
  font-family: Cooper Hewitt Medium;
  text-decoration: none;
  color: ${({ path }) => (path === 1 ? $.color.blue5 : $.color.blue2)};
  text-transform: uppercase;
  letter-spacing: 2px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 16px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 15px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 14px;
  }
  // #endregion
`;

const NavBar = ({ path }) => {
  const pathLogic =
    path !== '/blog/' &&
    !path.includes('/blog/tag/') &&
    !path.includes('/blog/tags/')
      ? 1
      : 0;

  return (
    <Container path={pathLogic}>
      <StyledLink to="/blog/" path={pathLogic}>
        Home
      </StyledLink>
      <StyledLink to="/blog/tags/" path={pathLogic}>
        Explore Tags
      </StyledLink>
    </Container>
  );
};

NavBar.propTypes = {
  path: PropTypes.string.isRequired,
};

export default NavBar;
