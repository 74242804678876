import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import $ from '../styles/global';
import Utils from '../utils';

const Section = styled.section`
  background-color: ${$.color.orange3};

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding2}px 0;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding2}px 0;
  }
  // #endregion
`;

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: grid;
    grid-template-columns: 150px auto;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 'StudentImageContainer Title' 'StudentImageContainer Subtitle' 'StudentImageContainer Age';
    grid-column-gap: ${$.layout().margin3}px;
    padding: ${$.layout().padding3}px 0px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  // #endregion
`;

const StudentImageContainer = styled.div`
  width: 150px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    border-radius: inherit;
    width: 100%;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    grid-area: StudentImageContainer;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    order: 2;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 2;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const Title = styled.h2`
  font-family: Cooper Hewitt Bold;
  color: ${$.color.blue4};
  text-transform: uppercase;
  margin-bottom: ${$.layout().margin4}px;
  letter-spacing: 2px;
  font-size: 18px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    grid-area: Title;
    font-size: 26px;
    letter-spacing: 3px;
    margin-top: ${$.layout().margin5}px;
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    order: 1;
    width: 80%;
    text-align: center;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 1;
    width: 80%;
    text-align: center;
  }
  // #endregion
`;

const Description = styled.div`
  font-family: Lato Bold;
  font-size: 22px;
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin3}px;
  color: ${$.color.white};

  ${$.br}

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    grid-area: Subtitle;
    flex-grow: 2;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    order: 3;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 3;
    margin-bottom: ${$.layout().margin4}px;
    overflow: hidden;
    transition: all 0.5s ${$.easingFn.standard};
    ${({ clamp }) => (clamp === true ? 'max-height: 150px;' : '')}

    &.expand {
      max-height: ${({ maxHeight }) => maxHeight}px;
    }
  }
  // #endregion
`;

const Age = styled.div`
  grid-area: Age;
  font-family: Aileron Bold;
  font-size: 18px;
  color: ${$.color.blue4};

  // NOTE: Tablets
  @media ${$.device.tablet} {
    order: 4;
  }

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 5;
    align-self: flex-start;
  }
  // #endregion
`;

const Expand = styled.div`
  display: none;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: inline-block;
    padding-bottom: ${$.layout().padding4}px;
    align-self: flex-start;
    font-family: Cooper Hewitt Semibold;
    letter-spacing: 2px;
    color: ${$.color.blue2};
    text-transform: uppercase;
    order: 4;
    font-size: 15px;
  }
  // #endregion
`;

const Comment = ({ items: { by, age, school, comment, image }, title }) => {
  const byList = [];
  const [expandState, setExpandState] = useState(false);
  const [clamp, setClamp] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const descRef = useRef(null);

  if (!!by === true) {
    byList.push(by);
  }
  if (!!school === true) {
    byList.push(school);
  }
  if (!!age === true) {
    byList.push(`${age} years old`);
  }

  useEffect(() => {
    if (descRef.current.getBoundingClientRect().height >= 300) {
      setClamp(true);
      setMaxHeight(descRef.current.getBoundingClientRect().height + 100);
    }
  }, []);

  return (
    <Section>
      <Fade bottom distance="100px">
        <Container>
          <StudentImageContainer>
            <img
              src={Utils.importOneImage(image)}
              alt={`${byList.join(', ')} comment about current coding course`}
            />
          </StudentImageContainer>
          <Title>{title}</Title>
          <Description
            ref={descRef}
            clamp={clamp}
            maxHeight={maxHeight}
            className={expandState === true ? 'expand' : ''}
            dangerouslySetInnerHTML={{
              __html: comment,
            }}
          />
          {clamp === true && (
            <Expand
              onClick={() => {
                setExpandState((prev) => !prev);
              }}
            >
              {expandState ? 'Show less' : '...Read more'}
            </Expand>
          )}
          <Age>{byList.join(', ')}</Age>
        </Container>
      </Fade>
    </Section>
  );
};

Comment.defaultProps = {
  items: {},
  title: 'Hear from our Students!',
};

Comment.propTypes = {
  title: PropTypes.string,
  items: PropTypes.shape({
    by: PropTypes.string,
    age: PropTypes.string,
    school: PropTypes.string,
    comment: PropTypes.string,
    image: PropTypes.string,
  }),
};

export default Comment;
