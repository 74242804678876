import { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

import $ from '../../../styles/global';
import CarAnimated from '../../../assets/images/awsaccelerator2021/car-animated.json';

const Section = styled.section`
  background-color: transparent;
`;

const Container = styled.div``;

const Card = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: ${$.dropShadow.normal};
  overflow: hidden;
  margin-bottom: 20px;
`;

const TopBorder = styled.div`
  background-color: ${$.color.blue5};
  width: 100%;
  height: 15px;
`;

const TitleContainer = styled.div`
  background-color: ${$.color.blue1};
  position: relative;
  padding: ${$.layout().padding2}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding3}px;
  }
  // #endregion
`;

const Pill = styled.div`
  padding: 8px 20px;
  font-size: 14px;
  color: ${$.color.white};
  display: inline-block;
  border-radius: 25px;
  background-color: ${$.color.blue5};
  margin-bottom: ${$.layout().margin3}px;
`;

const Title = styled.h2`
  font-family: Aileron Heavy;
  font-size: 40px;
  color: ${$.color.black2};
  margin-bottom: ${$.layout().margin3}px;
  width: 60%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 35px;
    width: 80%;
  }
  // #endregion
`;

const CarAnimationContainer = styled.div`
  width: 30%;
  height: 200px;
  bottom: -30%;
  right: 5%;
  position: absolute;
  overflow: hidden;

  > div {
    position: absolute;
    width: 300%;
    height: 300%;
    top: -100%;
    left: -100%;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 35%;
    bottom: -35%;
  }
  // #endregion
`;

const Content = styled.div`
  background-color: ${$.color.white};
  padding: ${$.layout().padding2}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding3}px;
  }
  // #endregion
`;

const ContentTitle = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  margin: ${$.layout().margin2}px 0 ${$.layout().margin4}px 0;
  color: ${$.color.black2};
  font-family: Lato Bold;
  line-height: 1.3em;
`;

const ContentText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.4em;
  color: ${$.color.black2};
`;

const PhaseCard = ({ number, title, content }) => {
  const animationRef = useRef(null);

  useEffect(() => {
    const animateCar = lottie.loadAnimation({
      container: animationRef.current, // current instance of our container!
      animationData: CarAnimated, // animation file!
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });

    return () => {
      animateCar.destroy();
    };
  }, []);

  return (
    <Section>
      <Container>
        <Card>
          <TopBorder />
          <TitleContainer>
            <Pill>{`Phase ${number}`}</Pill>
            <Title>{title}</Title>
            <CarAnimationContainer>
              <div ref={animationRef} />
            </CarAnimationContainer>
          </TitleContainer>
          <Content>
            {content.map(({ title: contentTitle, text, key }) => (
              <Fragment key={key}>
                <ContentTitle>{contentTitle}</ContentTitle>
                <ContentText>{text}</ContentText>
              </Fragment>
            ))}
          </Content>
        </Card>
      </Container>
    </Section>
  );
};

PhaseCard.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
};

export default PhaseCard;
