import FAQPill from './FAQPills/Pill';
import addKeys from '../../../../utils/addKeys';

const faq = addKeys([
  {
    question:
      'What are the requirements for joining the Bootcamp and Accelerator Programmes?',
    answer:
      'All applicants must be attending a local Secondary or Junior College in 2021. Students interested in the Bootcamp will need to form teams of 5 within the same school and have their entry submitted via the school’s teacher-in-charge. Applications for the Accelerator are to be submitted individually but with approval from a school teacher.',
  },
  {
    question:
      'I can only commit to the first 2 days of the programme, can I still sign up?',
    answer:
      'Applicants are required to attend the full duration of each programme, for the Bootcamp (31 May - 6 June) and the Accelerator (Saturday afternoons from Jun-Nov, Nov - Dec holidays). Please do not apply if you are not able to commit to this programme, the full schedule is available <a href="/datascience-bootcamp-accelerator-2021/" target="_blank" rel="noopener noreferrer">here</a>.',
  },
  {
    question:
      'I do not have experience in coding, would I be at a disadvantage?',
    answer:
      'Applicants do not need to have any prior coding experience for the Bootcamp. The Accelerator however, requires students to have either completed the Bootcamp or have prior Python knowledge. Students will need to pass an online quiz to be selected for the Accelerator programme.',
  },
  {
    question: 'Where will lessons be held?',
    answer:
      'Due the COVID-19 safe distancing measures, the Bootcamp will be held online only. The Accelerator venue (online and/or physical) will be confirmed later in the year.',
  },
]);

const FAQ = () => (
  <>
    {faq.map(({ key, question, answer }) => (
      <FAQPill key={key} qas={{ question, answer }} />
    ))}
  </>
);

export default FAQ;
