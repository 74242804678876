import { useContext } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Context } from '../context';
import CourseCard from '../CourseCard';

const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.layout().margin1}px;
`;

const Container = styled.div``;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${({ theme }) => theme.layout().padding4}px;
  justify-items: stretch;
  align-items: stretch;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const CourseCardContainerLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;

  & > * {
    flex: 1;
  }
`;

const Cards = () => {
  const { courses } = useContext(Context);

  return (
    <Section>
      <Container>
        <CardsContainer>
          {courses.map((course) => (
            <CourseCardContainerLink
              key={course.id}
              to={`/courses${course.slug}`}
            >
              <CourseCard
                title={course.title}
                coursePartnerLink={course.coursePartnerLink}
                image={course.courseCard.image}
                upcomingModule={course.courseCard.nextStartDate.upcomingModule}
                date={course.courseCard.nextStartDate.date}
                day={course.courseCard.nextStartDate.day}
                modulesInformation={course.courseCard.modulesInformation}
                courseLevel={course.courseCard.courseLevel}
                feesInformation={course.courseCard.feesInformation}
                coursesPage
              />
            </CourseCardContainerLink>
          ))}
        </CardsContainer>
      </Container>
    </Section>
  );
};

export default Cards;
