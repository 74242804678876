import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import AWSPartnerSGCCLogo from '../../../assets/images/tertiary/aws-partner-sgcc-logo.svg';

const Section = styled.section`
  background-color: ${$.tertiary.orange};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    height: 100%;
    padding: 50px 0 0 0;
  }
  // #endregion
`;

const Description = styled.div`
  width: 50%;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100vw - ${$.layout().margin3 * 2}px);
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
    padding-bottom: 50px;
  }
  // #endregion
`;

const Pills = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${$.layout().margin4}px;

  & > *:not(:last-child) {
    margin-right: ${$.layout().margin5}px;
  }
`;

const Pill = styled.h2`
  display: inline-block;
  background-color: ${$.color.white};
  color: ${$.color.orange4};
  font-size: 15px;
  font-family: Lato Bold;
  border-radius: 20px;
  margin-bottom: ${$.layout().margin5}px;
  padding: ${$.layout().padding5}px ${$.layout().padding4}px;
`;

const Title = styled.h1`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Subtitle = styled.h3`
  font-family: Lato Regular;
  font-size: 18px;
  color: ${$.color.white};
  opacity: 0.6;
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin3}px;
`;

const AWSPartnerDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    color: ${$.color.white};
    font-size: 17px;
    font-family: Lato Bold;
    line-height: 1.3em;
  }

  svg {
    margin-bottom: ${$.layout().margin3}px;
    height: 100px;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: flex-start;

    svg {
      width: 100%;
      margin-right: 0;
      margin-bottom: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const Image = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 800px;
  right: -10%;
  position: relative;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: calc(50% + ${$.layout().margin3}px);
    right: -${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    width: 100vw;
    left: 0;
    height: auto;

    & > :nth-child(2) {
      height: 400px;
    }
  }
  // #endregion
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 400px 0 400px 100px;
  border-color: transparent transparent transparent ${$.tertiary.orange};
  position: absolute;
  z-index: 1;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    border-width: 60px 50vw 0 50vw;
    border-color: ${$.tertiary.orange} transparent transparent transparent;
  }
  // #endregion
`;

const Banner = () => (
  <Section>
    <Container>
      <Description>
        <Pills>
          <Pill>Amazon Web Services</Pill>
          <Pill>Official Certification</Pill>
          <Pill>Python</Pill>
          <Pill>AI/Cloud</Pill>
          <Pill>For Beginners</Pill>
        </Pills>
        <Title>
          For current and prospective University and Polytechnic students
          interested in a <u>future</u> in tech
        </Title>
        <Subtitle>
          Experience a week-long 33-hour accelerated programme in Python
          Programming, Machine learning and Cloud Computing on the Amazon Web
          Services (AWS) Cloud. Learn about how AWS enables growth technologies
          from expert AWS Authorised Instructors and get officially certified
          with AWS
        </Subtitle>
        <AWSPartnerDescription>
          <AWSPartnerSGCCLogo />
          <span>
            Organised by SG Code Campus (an Authorised Training Partner in the
            AWS Partner Network)
          </span>
        </AWSPartnerDescription>
      </Description>

      <Image>
        <Triangle />
        <StaticImage
          src="../../../assets/images/tertiary/aws/tertiary-image-banner.png"
          alt="Tertiary"
        />
      </Image>
    </Container>
  </Section>
);

export default Banner;
