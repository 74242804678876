import { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import Util from '../../utils';

const RelativeWrap = styled.div`
  ${({ row }) => `
  grid-area: dropdown_${row};
  position: relative;
`}
`;

const Container = styled.div`
  ${({ maxHeight, dropdownState, currentBubble, row }) => `
  overflow: hidden;
  max-height: ${
    dropdownState && Math.floor(currentBubble / 3) === row ? maxHeight : '0px'
  };
  opacity: ${dropdownState ? '1' : '0'};
  margin-left: calc(-1 * (100vw - 970px) / 2);
  margin-right: calc(-1 * (100vw - 970px) / 2);
  background-color: ${$.color.blue4};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: max-height 0.5s ${$.easingFn.standard},
    opacity 0.5s ${$.easingFn.standard};
`}
`;

const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  margin-top: 40px;
  margin-right: ${$.layout().margin3}px;
  align-self: flex-start;
  justify-self: flex-start;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Arrow = styled.div`
  ${({ row, currentBubble, dropdownState }) => `
  opacity: ${
    dropdownState && Math.floor(currentBubble / 3) === row ? '1' : '0'
  };
  width: 0px;
  height: 0px;
  border-bottom: 15px solid ${$.color.blue4};
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -15px;
  left: ${`${
    32 * (currentBubble % 3) + 302 * (currentBubble % 3) + 302 / 2 - 30 / 2
  }px`};
  z-index: -1;
  transition: opacity 0.5s ${$.easingFn.standard};
`}
`;

const Dropdown = ({
  row,
  currentBubble,
  dropdownState,
  setDropdownState,
  anchor,
  dropdown: { avatarURL, maxHeight, children },
}) => {
  const wrapperRef = useRef(null);
  const clickedOnBubble = (target, bubbles) => {
    let i;
    let bubbleNode;
    let clickedBubble = false;
    const clickedTarget = target.className === '' ? target.parentNode : target;
    const clickedTargetClassName = clickedTarget.className
      .split(' ')
      .reduce((prev, curr) => `${prev}${curr !== '' ? `.${curr}` : ''}`, '');

    for (i = 0; i < bubbles.length; i += 1) {
      bubbleNode = bubbles[i];
      if (
        (bubbleNode.className === clickedTarget.className ||
          bubbleNode.querySelector(clickedTargetClassName)) &&
        clickedBubble === false
      ) {
        clickedBubble = true;
      }
    }

    return clickedBubble;
  };

  // We need to check manually if the user has clicked on a bubble container before proceeding
  // 1. Clicked on dropdown -> Nothing happens
  // 2. Clicked on bubble -> Nothing happens
  // 3. Clicked anything besides dropdown and bubble -> Close dropdown
  Util.useOutsideClick(wrapperRef, (event) => {
    // to prevent the whole site from shutting down, we use try and catch...
    try {
      // We need to search for the node in DOM when the func runs, because
      // React rerenders and our stored variable pointing to DOM node will be stale
      const gridviewParent =
        typeof document !== 'undefined'
          ? document.querySelector('.desktop-gridview')
          : '';
      const gridviewBubbles = gridviewParent
        ? gridviewParent.querySelectorAll('.bubble-gridview')
        : '';
      const isVisible = wrapperRef.current.getBoundingClientRect().height;

      // Only run if the dropdown is visible, and we have the DOM nodes at hand
      if (
        isVisible &&
        gridviewParent &&
        clickedOnBubble(event.target, gridviewBubbles) === false
      ) {
        setDropdownState(false);
      }
    } catch (err) {
      // console.error(err);
    }
  });

  return (
    <RelativeWrap row={row} ref={wrapperRef} className="dropdown-gridview">
      <Container
        row={row}
        dropdownState={dropdownState}
        currentBubble={currentBubble}
        maxHeight={maxHeight}
      >
        {currentBubble !== null ? (
          <>
            <Arrow
              row={row}
              dropdownState={dropdownState}
              currentBubble={currentBubble}
            />
            <ImageContainer>
              <img src={avatarURL} alt={`${anchor} profile img`} />
            </ImageContainer>
            {children}
          </>
        ) : (
          ''
        )}
      </Container>
    </RelativeWrap>
  );
};

Dropdown.defaultProps = {
  row: 0,
  currentBubble: 0,
  dropdownState: false,
  setDropdownState: () => {},
  anchor: '',
  dropdown: {
    avatarURL: '',
    maxHeight: '0px',
    children: <></>,
  },
};

Dropdown.propTypes = {
  row: PropTypes.number,
  anchor: PropTypes.string,
  setDropdownState: PropTypes.func,
  currentBubble: PropTypes.number,
  dropdownState: PropTypes.bool,
  dropdown: PropTypes.shape({
    avatarURL: PropTypes.string,
    maxHeight: PropTypes.string,
    children: PropTypes.element,
  }),
};

export default Dropdown;
