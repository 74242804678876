import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import SearchIcon from '../../../assets/icons/search.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 44px;
  color: ${$.color.blue4};

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 36px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const SearchBar = styled.div`
  font-family: Lato Regular;
`;

const StyledLabel = styled.div`
  position: relative;
  border-radius: ${$.border().radius1}px;
  box-shadow: ${$.dropShadow.normal};
  overflow: hidden;
  transition: all 0.4s ${$.easingFn.standard};
  &:hover {
    box-shadow: ${$.dropShadow.repressed};
  }

  & > svg {
    height: calc(100% - ${$.layout().margin5 * 2}px);
    margin: ${$.layout().margin5}px;
    width: auto;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: all 0.4s ${$.easingFn.standard};
    fill: ${$.color.gray2};
  }
`;

const StyledInput = styled.input`
  height: 30px;
  width: 130px;
  border-width: 0;
  font-size: 16px;
  outline: none;
  overflow: hidden;
  align-self: stretch;
  background-color: ${$.color.white};
  transition: all 0.4s ${$.easingFn.standard};
  color: ${$.color.blue4};
  padding: ${$.layout().padding5}px ${$.layout().padding5}px
    ${$.layout().padding5}px ${$.layout().padding4 + 38 + $.layout().padding5}px;

  &::placeholder {
    font-family: Cooper Hewitt Medium;
    color: ${$.color.gray1};
    font-size: 17px;
    opacity: 1;
    transition: opacity 0.4s ${$.easingFn.standard};
  }

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    opacity: 0.5;
    transition: opacity 0.4s ${$.easingFn.standard};
  }

  &:focus ~ svg {
    transform: rotate(90deg);
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    min-width: 130px;

    &:focus {
      min-width: 220px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    min-width: 130px;

    &:focus {
      min-width: 220px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding4 * 2 + 38}px);
  }
  // #endregion
`;

const TitleBar = ({ searchQuery, setSearchQuery, totalCount }) => (
  <Container>
    <Title>{`Tags (${totalCount})`}</Title>
    <SearchBar>
      <StyledLabel>
        <StyledInput
          type="text"
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          value={searchQuery}
          placeholder="Search tags"
          spellCheck={false}
        />
        <SearchIcon />
      </StyledLabel>
    </SearchBar>
  </Container>
);

TitleBar.defaultProps = {
  searchQuery: '',
  setSearchQuery: () => {},
};

TitleBar.propTypes = {
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  totalCount: PropTypes.number.isRequired,
};

export default TitleBar;
