import Seo from '../../components/seo';
import ProfessionalTrainingCoursePage from '../../components/ProfessionalTrainingCoursePage';

const PTCoursePage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <ProfessionalTrainingCoursePage />
  </>
);

export default PTCoursePage;
