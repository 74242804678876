import styled from 'styled-components';

import $ from '../../../styles/global';
import Utils from '../../../utils';
// import SGCCLogo from '../../../assets/images/tertiary/sgcc-logo.svg';
// import AWSPartnerLogo from '../../../assets/images/tertiary/aws-partner-logo.svg';

const Section = styled.section``;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.black};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const TableContainer = styled.div`
  overflow: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;

  thead {
    tr {
      color: ${$.color.white};
      font-family: Lato Bold;
      font-size: 20px;
      td {
        padding: ${$.layout().padding3}px;
        background-color: ${$.color.black2};
        &:first-child {
          border-radius: 10px 0 0 10px;
        }
        &:last-child {
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }

  tbody {
    tr {
      font-family: Lato Bold;
      font-size: 18px;
      line-height: 1.3em;
      td {
        padding: ${$.layout().padding3}px;
        &:last-child {
          text-align: center;
        }
      }

      &:nth-child(even) {
        td {
          background-color: ${$.accelerator.grey};
          &:first-child {
            border-radius: 10px 0 0 10px;
          }
          &:last-child {
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
`;

// const SGCCLogoContainer = styled.div`
//   margin-bottom: ${$.layout().margin3}px;

//   & > svg {
//     width: 80px;
//   }
// `;

// const AWSPartnerLogoContainer = styled.div`
//   margin-top: ${$.layout().margin2}px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-end;

//   & > svg {
//     width: 100px;
//   }
// `;

const items = Utils.addKeys([
  {
    course: 'Introduction to Python Programming',
    desc: 'This module teaches fundamental concepts in Python programming, including functions, control flow and basic data structures. No prior coding experience required.',
    hours: 12,
  },
  {
    course: 'Coding Your First Machine Learning App',
    desc: 'Applying concepts from the previous module, participants will learn to power up a web application using machine learning services provided by AWS.',
    hours: 10,
  },
  {
    course: 'Cloud Practitioner Essentials',
    desc: 'This module provides an overall understanding of the Amazon Web Services (AWS) Cloud, independent of specific technical roles. Participants will learn about AWS Cloud concepts, AWS services, security, architecture, pricing and support to build your AWS Cloud knowledge. This course also ,helps you prepare for the AWS Certified Cloud Practitioner exam.',
    hours: 8,
  },
]);

const Programme = () => (
  <Section>
    <Container>
      <Title>Programme Details</Title>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <td>Module</td>
              <td>Description</td>
              <td>Hours</td>
            </tr>
          </thead>
          <tbody>
            {items.map(({ desc, course, hours, key }) => (
              <tr key={key}>
                <td>{course}</td>
                <td>{desc}</td>
                <td>{hours}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  </Section>
);

export default Programme;
