import Seo from '../../components/seo';
import AdvancedTech from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/AdvancedTech';

const AdvancedTechPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <AdvancedTech />
  </>
);

export default AdvancedTechPage;
