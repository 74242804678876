import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../styles/global';
import Button from '../../Button';

const Section = styled.section`
  background-color: ${$.color.blue1};
`;

const Container = styled.div`
  padding: ${$.layout().padding2}px 0;
  font-family: Aileron Heavy;
  line-height: 1.4em;
  text-align: center;
  color: ${$.color.orange3};
  font-size: 22px;

  a {
    font-style: italic;
    text-decoration: none;
    color: ${$.color.blue3};
    &:visited {
      color: ${$.color.blue3};
    }
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 20px;
  }
  // #endregion
`;

const StyledLink = styled(Link)`
  display: inline-block;
  max-width: 350px;
  margin-top: ${$.layout().margin4}px;
  width: 100%;
  text-decoration: none;
  > * {
    width: 100%;
  }
`;

const JoinUsBanner = () => (
  <Section>
    <Container>
      Interested to join the SG Code Campus team?
      <br />
      <StyledLink to="/join-us/">
        <Button>View Current Openings</Button>
      </StyledLink>
    </Container>
  </Section>
);

export default JoinUsBanner;
