import { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import '../../utils/particles';
import $ from '../../styles/global';

const Overlay = styled.div`
  position: relative;
  width: 100vw;
  background-color: #feffff;

  & > section {
    width: 100%;
  }

  & > section > div {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    margin-left: auto;
    margin-right: auto;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    & > section > div {
      width: 970px;
    }
  }
  // #endregion
`;

const ParticlesContainer = styled.div`
  width: 100% !important;
  margin: 0 !important;
  height: 100%;
  position: absolute;
`;

const CenterColumn = styled.div`
  position: absolute;
  pointer-events: none;
  height: 100%;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 970px;
    margin-left: calc((100vw - 970px) / 2);
    margin-right: calc((100vw - 970px) / 2);
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(100vw - ${$.layout().margin3 * 2}px);
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: calc(100vw - ${$.layout().margin3 * 2}px);
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion
`;

const DottedLines = styled.div`
  ${({ position }) => `
  height: 100%;
  width: 1px;
  position: absolute;
  left: ${position}%;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    #e0e1e1 20%,
    rgba(255, 255, 255, 0) 0%
  ); 
  background-position: left;
  background-size: 1px 20px;
  background-repeat: repeat-y;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: ${position === 25 || position === 75 ? 'none' : 'block'};
  }
  // #endregion
  
`}
`;

const particlesParam = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 1000,
      },
    },
    color: {
      value: '#9e9e9e',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: '#9e9e9e',
      },
      polygon: {
        nb_sides: 5,
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 2,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 170,
      color: '#9e9e9e',
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 150,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};

const Background = ({ children, id }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.particlesJS) {
      window.particlesJS(id, particlesParam);
    }

    return () => {
      if (
        typeof window !== 'undefined' &&
        window.pJSDom &&
        window.pJSDom.length > 0
      ) {
        window.pJSDom[0].pJS.fn.vendors.destroypJS();
        window.pJSDom = [];
      }
    };
  }, []);

  return (
    <Overlay>
      <ParticlesContainer id={id} />
      <CenterColumn>
        <DottedLines position={0} />
        <DottedLines position={25} />
        <DottedLines position={50} />
        <DottedLines position={75} />
        <DottedLines position={100} />
      </CenterColumn>
      {children}
    </Overlay>
  );
};

Background.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
  id: PropTypes.string.isRequired,
};

export default Background;
