import Seo from '../../components/seo';
import ACADA from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/ACADA';

const ACADAPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <ACADA />
  </>
);

export default ACADAPage;
