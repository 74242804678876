import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

import $ from '../../../styles/global';
// eslint-disable-next-line
import '@splidejs/splide/dist/css/splide.min.css';
import AWSPartnerLogo from '../../../assets/images/tertiary/aws-partner-logo.svg';
import AppleConsultantsNetworkLogo from '../../../assets/images/tertiary/index/apple-consultants-network-white.png';
import RightChevronIcon from '../../../assets/icons/thin-arrow-right.svg';

const Container = styled.div`
  box-shadow: ${$.dropShadow.normal};
  background-color: #32325d;
  width: 50%;
  border-radius: 0 5px 5px 0;
  overflow: hidden;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 90%;
    border-radius: 0 0 5px 5px;
  }
  // #endregion
`;

const Title = styled.div`
  padding: ${$.layout().padding3}px;
  color: ${$.color.white};
  border-bottom: 2px solid rgba(85, 90, 191, 0.2);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-family: Lato Semibold;
  font-size: 16px;
`;

const Info = styled.div`
  & > p {
    padding: ${$.layout().padding3}px;
    line-height: 1.3em;
    text-align: center;
    margin: 0;
    color: ${$.color.white};
    &:first-child {
      padding-bottom: ${$.layout().padding4}px;
      font-size: 20px;
    }
    &:last-child {
      font-size: 16px;
      opacity: 0.6;
      padding: ${$.layout().padding5}px 80px ${$.layout().padding3}px 80px;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > p {
      &:last-child {
        padding: ${$.layout().padding3}px;
      }
    }
  }
  // #endregion
`;

const StyledLink = styled.a`
  padding: ${$.layout().padding3}px;
  background-color: #3c3c77;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;

  & > svg {
    width: 20px;
    height: 20px;
    fill: #87bbfd;
  }

  & > div {
    margin-right: ${$.layout().margin4}px;
    font-family: Lato Semibold;
    font-size: 16px;
    letter-spacing: 2px;
    color: #87bbfd;
  }
`;

const WindowAnimation = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > .top,
  > .bottom {
    height: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    background: #32325d;
    z-index: 1;
    will-change: transform, border-top, border-bottom;
  }

  & > .top {
    top: 0;
    transform: translateY(-100%);
    border-bottom: 1px solid rgb(85, 90, 191);
  }

  & > .bottom {
    bottom: 0;
    transform: translateY(100%);
    border-top: 1px solid rgb(85, 90, 191);
  }

  & > svg,
  > img {
    will-change: opacity;
  }

  & > svg {
    position: absolute;
    max-width: 130px;
    max-height: 130px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  & > img {
    position: absolute;
    max-width: 210px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &.aws {
    & > svg {
      opacity: 1;
    }
  }

  &.apple {
    & > img {
      opacity: 1;
    }
  }
`;

const Banner = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const delay = 1;
    const t1 = gsap.timeline({ repeat: -1, repeatDelay: delay, delay });
    const setContainerClassName = (className) => {
      const classNames = container.className
        .split(' ')
        .filter((cls) => ['aws', 'apple'].indexOf(cls) === -1);

      classNames.push(className);

      return classNames.join(' ');
    };

    const start = {
      top: {
        top: '50%',
        ease: 'power2.out',
        duration: 0.3,
        borderBottom: '1px solid rgb(85, 90, 191)',
      },
      bottom: {
        bottom: '50%',
        ease: 'power2.out',
        duration: 0.3,
        borderTop: '1px solid rgb(85, 90, 191)',
      },
    };
    const end = {
      top: {
        top: '0',
        ease: 'power2.out',
        duration: 0.8,
        // We want the closed windows to pause for 1.5s before opening up.
        delay: 0.5,
        borderBottom: '1px solid transparent',
      },
      bottom: {
        bottom: '0',
        ease: 'power2.out',
        duration: 0.8,
        borderTop: '1px solid transparent',
      },
    };

    // Since gsap resets the CSS to the original state before restarting the loop,
    // we need to set the CSS initial state through gsap instead of through styled-components
    // Starts off with AWS logo
    gsap.set(container, { className: () => setContainerClassName('aws') });

    // First iteration with Apple logo. Loop starts here
    t1.to(container.querySelector('.top'), start.top)
      .to(container.querySelector('.bottom'), start.bottom, '<')
      .set(container, {
        className: () => setContainerClassName('apple'),
      })
      .to(container.querySelector('.top'), end.top)
      .to(container.querySelector('.bottom'), end.bottom, '<');

    // Second iteration with AWS logo.
    t1.to(container.querySelector('.top'), { ...start.top, delay })
      .to(container.querySelector('.bottom'), start.bottom, '<')
      .set(container, {
        className: () => setContainerClassName('aws'),
      })
      .to(container.querySelector('.top'), end.top)
      .to(container.querySelector('.bottom'), end.bottom, '<');
  }, []);

  return (
    <Container>
      <Title>Official certifications</Title>
      <Info>
        <p>We are authorised trainers for Big Tech</p>
        <p>Earn your official certifications from Amazon and Apple</p>
      </Info>
      <WindowAnimation ref={containerRef}>
        <div className="top" />
        <AWSPartnerLogo />
        <img
          src={AppleConsultantsNetworkLogo}
          alt="Apple Consultants Network logo"
        />
        <div className="bottom" />
      </WindowAnimation>
      <StyledLink href="mailto:enquiries@sgcodecampus.com?subject=Tertiary Programmes Enquiry">
        <div>Contact Us</div>
        <RightChevronIcon />
      </StyledLink>
    </Container>
  );
};

export default Banner;
