import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../../styles/global';

const Container = styled.div``;

const StyledUl = styled.ul``;

const StyledList = styled.li``;

const StyledPara = styled.p``;

const CommonLinkStyles = css`
  color: ${$.color.blue3};
  font-family: Lato Bold;
  text-decoration: none;

  &::visited {
    color: ${$.color.blue3};
  }
`;

const StyledInternalLink = styled(Link)`
  ${CommonLinkStyles}
`;

const StyledOutgoingLink = styled.a`
  ${CommonLinkStyles}
`;

const items = [
  {
    title: 'COVID-19 Measures',
    details: [
      {
        question: 'What are the precautionary measures at SG Code Campus?',
        answer: (
          <Container>
            <StyledPara>
              {
                'You can read more information via our dedicated Coronavirus page, please click '
              }
              <StyledInternalLink to="/coronavirus-advisory/">
                here
              </StyledInternalLink>
              {'.'}
            </StyledPara>
          </Container>
        ),
      },
      {
        question:
          'Will SG Code Campus suspend classes if the sitation worsens?',
        answer:
          'We will continue to closely follow the guidance of MOH and MOE and act accordingly. Should the relevant Ministries give the directive to physically close the school, we will shift all classes online with immediate effect (except for Basics 1 and 2).',
      },
    ],
  },
  {
    title: 'What is the online class offering and who should join it?',
    details: [
      {
        question: 'What is an online class?',
        answer: (
          <Container>
            <StyledPara>
              Contrary to what some may think, online class is not a static
              pre-recorded video. In fact, online classes are fully interactive
              and includes the following:
            </StyledPara>
            <StyledUl>
              <StyledList>
                Live video conferencing with the instructor
              </StyledList>
              <StyledList>
                Instructors will be able to see the screens of the students and
                assist in real time
              </StyledList>
              <StyledList>
                Online quizzes and practice for real time feedback
              </StyledList>
            </StyledUl>
          </Container>
        ),
      },
      {
        question: 'Is the home-based online class optional?',
        answer: (
          <Container>
            <StyledPara>
              Yes, the home-based online class is currently optional. Students
              can choose to remote in to the classroom or they can still attend
              their classes physically at their scheduled time and location.
            </StyledPara>
            <StyledPara>
              Home based online classes are not available for Basics 1 and 2.
              All classes will still be physical classes held at our locations.
            </StyledPara>
          </Container>
        ),
      },
      {
        question: 'Why should I take online classes?',
        answer: (
          <Container>
            <StyledUl>
              <StyledList>
                Online classes help to prevent an interruption in learning
                during disruptive times.
              </StyledList>
              <StyledList>
                Students save on traveling time, giving them greater convenience
                and productivity.
              </StyledList>
              <StyledList>
                Be the pioneers in the future of classroom learning.
              </StyledList>
            </StyledUl>
          </Container>
        ),
      },
    ],
  },
  {
    title: 'What are the hardware requirements needed for online classes?',
    details: [
      {
        question: 'Will I be provided a computer to use?',
        answer: (
          <Container>
            <StyledPara>
              No, your child needs to have his/her own computer to join the
              class.
            </StyledPara>
            <StyledPara>
              We will be providing an in-class computer that your child will
              remotely log into, so that his/her code can be accessed by the
              instructors in real-time. Instructions will be sent to you, and
              our team will assist you.
            </StyledPara>
          </Container>
        ),
      },
      {
        question: 'How do I know if my home computer is good enough?',
        answer: (
          <Container>
            <StyledPara>
              Students will log into our remote classroom via Zoom, a web based
              software. Thus no download nor installation is necessary.
            </StyledPara>
            <StyledUl>
              <StyledList>
                {
                  'Stable internet connection with download speeds exceeding 5Mb/s (you can test your connection via '
                }
                <StyledOutgoingLink
                  href="https://speedtest.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  speedtest.net
                </StyledOutgoingLink>
                .
              </StyledList>
              <StyledList>
                Most computers bought in the last 5 years should have enough
                memory and prcessor power to run Zoom.com
              </StyledList>
            </StyledUl>
          </Container>
        ),
      },
      {
        question: 'How do I log in for the online class?',
        answer:
          'Students will get a web link to a walkthrough video online on how to set up the home computers to attend our online classes.',
      },
      {
        question: 'Do I need an Android device for my AppInventor class?',
        answer:
          'Students do not need to have an Android phone. We will be using an emulator (virtual Android device that can run on any platforms), which will be available when the student remotely logs into our classroom.',
      },
    ],
  },
];

export default items;
