import styled from 'styled-components';
import PropTypes from 'prop-types';

import MainBanner from '../../components/partners/appleConsultantsNetwork/MainBanner';
import Logo from '../../components/partners/appleConsultantsNetwork/Logo';
import MainWriteUp from '../../components/partners/appleConsultantsNetwork/MainWriteUp';
import TeamProfiles from '../../components/whyus/team/TeamProfiles';
import SEO from '../../components/seo';

import $ from '../../styles/global';

const Section = styled.section``;

const Title = styled.h2`
  font-family: Aileron Heavy;
  font-size: 50px;
  color: ${$.color.blue4};

  // #region
  @media ${$.device.mobile} {
    font-size: 42px;
  }
  // #endregion
`;

const InstructorsContainer = styled.div``;

const AppleConsultantsNetwork = ({ location }) => (
  <>
    <SEO
      keywords={[
        'iOS coding classes',
        'Swift coding classes',
        'Apple Consultants Network',
        'Apple certified instructors',
        'Apple Professional Learning Provider',
        'Apple partner',
        'MOE Schools',
        'Infocomm club',
      ]}
      location={location}
      description="Check what we offer as an official Apple Consultants Network Partner here"
      title="Joining the Apple Consultants Network"
    />
    <Logo />
    <MainWriteUp />
    <MainBanner />
    <Section>
      <InstructorsContainer>
        <Title>Our Apple Certified Instructors</Title>
      </InstructorsContainer>
    </Section>
    <TeamProfiles onlyIncludes={['natasha']} expandProfileFirst="natasha" />
  </>
);

AppleConsultantsNetwork.defaultProps = {
  location: {},
};

AppleConsultantsNetwork.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default AppleConsultantsNetwork;
