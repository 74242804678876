import { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../styles/global';
import data from '../items.json';
import BarActions from '../BarActions';
import SingleDropdown from './SingleDropdown';
import CalendarDropdown from './CalendarDropdown';
import DoublePills from './DoublePills';
import Util from '../../../../utils';
import { Context } from '../context';

const Section = styled.section``;

const Container = styled.div`
  padding-top: ${$.layout().padding3}px;
  padding-bottom: ${$.layout().padding3}px;
`;

// To prevent the keys from regenerating again, we do it outside the render function
// And simply use map function to pass it in.
Object.keys(data).forEach((className) => {
  data[className] = Util.addKeys(data[className]);
});

const Mobile = ({ className }) => {
  const [picker, setPicker] = useState('undefined');
  const searchBarRef = useRef(null);
  const {
    searchBarUIData,
    searchBarDataUpdate,
    searchBarUIUpdate,
    searchBarClear,
  } = useContext(Context);

  BarActions.initPikaday({
    searchBarRef,
    setPicker,
    searchBarDataUpdate,
    searchBarUIUpdate,
    searchBarUIData,
  });

  BarActions.clearReduxData({ searchBarClear });

  if (typeof window !== 'undefined') {
    BarActions.parseUrlParamsToRedux(
      window.location.search,
      searchBarDataUpdate
    );
  }

  return (
    <Section ref={searchBarRef} className={className}>
      <Container>
        <SingleDropdown
          text="Course"
          name="allCourses"
          picker={picker}
          choices={data.allCourses}
        />
        <DoublePills
          name="allLocations"
          text="Location"
          pills={Util.addKeys(
            data.allLocations.slice(1, data.allLocations.length)
          )}
        />
        <DoublePills
          name="allFormats"
          text="Format"
          pills={Util.addKeys(data.allFormats.slice(1, data.allFormats.length))}
        />
        <CalendarDropdown text="Date" name="allDates" picker={picker} />
        <SingleDropdown
          text="Child's Age"
          name="allAges"
          picker={picker}
          choices={data.allAges}
        />
      </Container>
    </Section>
  );
};

Mobile.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Mobile;
