import { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import context from './context';
import BannerImage from '../../assets/images/schedule/banner.svg';

const Container = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  & svg {
    width: 400px;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding1}px;
    border-radius: ${$.border().radius1}px;
    box-shadow: ${$.dropShadow.normal};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding1}px;
    border-radius: ${$.border().radius1}px;
    box-shadow: ${$.dropShadow.normal};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
    text-align: center;
    display: ${({ show }) => (show ? 'block' : 'none')};

    div {
      text-align: initial;
    }

    & svg {
      width: 100%;
      max-width: 400px;
    }
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.orange3};
  font-size: 22px;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  color: ${$.color.orange2};
  line-height: 1.4em;
  font-size: 18px;

  & > a {
    color: ${$.color.blue5};
    text-decoration: none;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    & > a {
      font-family: Lato Bold;
      cursor: pointer;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    & > a {
      font-family: Lato Bold;
      cursor: pointer;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-family: Aileron Heavy;
  }
  // #endregion
`;

const Details = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: 400px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    width: 400px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const NoCourseBanner = ({ show }) => {
  const { noCourseBannerTitle, noCourseBannerSubtitle } = useContext(context);

  return (
    <Container show={show}>
      <Details>
        <Title>{noCourseBannerTitle}</Title>
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: noCourseBannerSubtitle,
          }}
        />
      </Details>
      <BannerImage />
    </Container>
  );
};

NoCourseBanner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default NoCourseBanner;
