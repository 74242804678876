import styled from 'styled-components';

import $ from '../../../styles/global';
import ImageContainer from '../../Level2Banner/ImageContainer';
import LeftImage from '../../../assets/images/blog/left-image.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    left: -30px;
    top: -130px;

    & > svg {
      width: 180px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    position: absolute;
    left: -10px;
    top: -120px;

    & > svg {
      width: 150px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const LeftImageContainer = () => <Container image={LeftImage} />;

export default LeftImageContainer;
