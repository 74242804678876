import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section``;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${$.layout().margin3}px 0;

  & > * {
    width: 250px;
  }
`;

const Logo = () => (
  <Section>
    <LogoContainer>
      <StaticImage
        src="../../../assets/images/landing/partners/apple_consultants_network.png"
        alt="Apple Consultants Network logo"
      />
    </LogoContainer>
  </Section>
);

export default Logo;
