import { memo, useContext } from 'react';
import styled from 'styled-components';

import $ from '../../../../styles/global';
import context from '../context';
import Util from '../../../../utils';

const Container = styled.div`
  width: 100%;
  padding-top: ${$.layout().padding4}px;
  border-top: 1px solid ${$.color.gray1};
`;

const datesToStringParser = (dates) =>
  `${dates
    .slice(0, -1)
    .map((ele) => Util.parseTimeToDayMonth(ele))
    .join(', ')}${dates[1] ? ' and ' : ''}${Util.parseTimeToDayMonth(
    dates.slice(-1)[0]
  )}`;

const NoClassText = memo(() => {
  const {
    event: {
      dates: { skipped: courseDatesSkipped },
    },
  } = useContext(context);

  return (
    <Container>
      <span>{`Note: No ${
        courseDatesSkipped.length === 1 ? 'class' : 'classes'
      } on `}</span>
      <span>{datesToStringParser(courseDatesSkipped)}</span>
    </Container>
  );
});

export default NoClassText;
