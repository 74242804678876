import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';

const Section = styled.section`
  background-color: ${$.color.orange3};
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  padding: ${$.layout().padding2}px 0;
`;

const Title = styled.h1`
  font-family: Aileron Heavy;
  color: ${$.color.white};
  margin-bottom: ${$.layout().margin5}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 48px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 38px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    text-align: center;
  }
  // #endregion
`;

const Subtitle = styled.h2`
  font-family: Lato Regular;
  color: ${$.color.white};
  line-height: 1.4em;

  a {
    text-decoration: none;
    color: ${$.color.blue3};
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 18px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 19px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 16px;
    text-align: center;
  }
  // #endregion
`;

const MiniBanner = ({ title, subtitle, ...props }) => (
  <Section {...props}>
    <Container>
      <Title>{title}</Title>
      <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
    </Container>
  </Section>
);

MiniBanner.defaultProps = {
  title: '',
  subtitle: '',
};

MiniBanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default MiniBanner;
