import { useContext } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Context } from './context';
import { Title } from './common';
import CheckIcon from '../../assets/icons/check.svg';

const Container = styled.div`
  grid-area: learnWithPartner;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ theme }) => theme.layout().padding2}px;
  margin-bottom: ${({ theme }) => theme.layout().margin3}px;

  & > * {
    width: 70%;
  }

  & > .aws {
    width: 30%;
  }
`;

const LearningGoals = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${({ theme }) => theme.layout().padding2}px;
  padding-left: 0;

  & > li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${({ theme }) => theme.layout().padding2}px;
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-family: ${({ theme }) => theme.fontFamily.body.regular};
    color: ${({ theme }) => theme.color.blue4};
  }
`;

const IconContainer = styled.div`
  flex: 0 0 24px;

  & svg {
    fill: ${({ theme }) => theme.color.blue4};
  }
`;

const LearnWithPartner = () => {
  const { learningGoals, trainingPartners } = useContext(Context);

  return (
    <Container>
      <Title>
        Learn with{' '}
        {trainingPartners.some(({ id }) => id === 'smu')
          ? 'SMU Academy'
          : trainingPartners[0].name}
      </Title>
      <LogoContainer>
        {trainingPartners.map(({ id, image, name }) => {
          return (
            <GatsbyImage key={id} className={id} image={image} alt={name} />
          );
        })}
      </LogoContainer>
      <LearningGoals>
        {learningGoals.map((goal) => (
          <li key={goal}>
            <IconContainer>
              <CheckIcon />
            </IconContainer>
            {goal}
          </li>
        ))}
      </LearningGoals>
    </Container>
  );
};

export default LearnWithPartner;
