import styled from 'styled-components';

import $ from '../../../styles/global';
import FAQPills from './FAQPills';

const Section = styled.section``;

const Container = styled.div`
  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding1}px 0;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding1}px 0;
  }
  // #endregion
`;

const Title = styled.h2`
  color: ${$.color.blue4};
  font-family: Aileron Heavy;
  font-size: 48px;
  margin-bottom: ${$.layout().margin3}px;
`;

const FAQ = () => (
  <Section>
    <Container>
      <Title>Frequently Asked Questions</Title>
      <FAQPills />
    </Container>
  </Section>
);

export default FAQ;
