import { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { RemoteLink, Margin20 } from '../Misc';
import TrainerProfile from '../TrainerProfile';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import InfoTable from '../SMUReusableComponents/InfoTable';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';
import QnADropdown from '../QnADropdown';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Topics',
  },
  {
    text: 'Who Should Attend',
    threshold: 0.1,
  },
  {
    text: 'Fees And Funding',
  },
  {
    text: 'Schedule',
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Demonstrate coding proficiency using Swift programming language',
  },
  {
    text: 'Understand the industry best practices for iOS apps design',
  },
  {
    text: 'Demonstrate the usage of diverse User Interface (UI) elements and layout techniques, and navigational interfaces for app building',
  },
  {
    text: 'Implement web Application Programming Interfaces (APIs) for data exchanges between apps and data sources',
  },
  {
    text: 'Implement existing and new event-driven methods concurrently',
  },
  {
    text: 'Develop an iOS App using app design, testing, and deployment methodologies',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'Aspiring iOS developers',
  },
  {
    text: 'Software engineers',
  },
  {
    text: 'Technical project managers',
  },
  {
    text: 'Programme/ business managers looking for a deeply practical introduction to iOS app development',
  },
  {
    text: 'Learners looking to prepare for the Apple Swift Certified User certification',
  },
]);

const topics = Utils.addKeys([
  {
    title: 'Swift Programming Fundamentals and Xcode IDE',
    overview: (
      <>
        <p>
          In this module, learners will be introduced to the Swift programming
          language and the Xcode IDE which is used to develop, run and test iOS
          apps.
        </p>
        <p>
          They will acquire foundational programming concepts using the Swift
          programming language in Xcode Playgrounds, in addition to navigating
          iOS development tools and building iOS apps with Xcode.{' '}
        </p>
        <p>
          At the end of this module, learners will be able to write short
          programmes in Swift and create simple iOS apps in Xcode.
        </p>
      </>
    ),
    learningObjectives: Utils.addKeys([
      {
        text: 'Understand and use Xcode Playgrounds to test and run Swift code',
      },
      {
        text: 'Learn and apply the concepts of native Swift data types, variables, constants, type safety, type inference, basic mathematical operators, numeric type conversion, comparison and logical operators, conditions and control flow statements in writing clean and safe Swift code',
      },
    ]),
  },
  {
    title: 'Building Interfaces with UIKit Frameworks',
    overview: (
      <>
        <p>
          Building on the Xcode development skills acquired in the previous
          module, learners will now learn to create app interfaces using
          components from the UIKit framework. In addition, they will learn how
          to present information to users and respond to their interactions
          using UIKit&apos;s views and controls. Participants will learn how to
          leverage the different views and controls in UIKit to better display
          information to users and respond to user interactions.
        </p>
        <p>
          Additionally, they will learn the intricacies of Xcode&apos;s dynamic
          auto layout feature and gain hands-on practice building an iOS app.
        </p>
      </>
    ),
    learningObjectives: Utils.addKeys([
      {
        text: 'Understand and use the UIKit framework to design and build iOS interfaces',
      },
      {
        text: 'Learn and apply the concepts of views, view controllers, and layout constraints',
      },
      {
        text: 'Understand and use common UI elements such as buttons, labels, text fields, and images',
      },
      {
        text: 'Learn and apply techniques to manage user input and navigation',
      },
      {
        text: 'Learn and apply techniques to implement responsive and adaptive interfaces for different devices and orientations',
      },
    ]),
  },
  {
    title: 'Navigation and Workflows ',
    overview: (
      <>
        <p>
          This module introduces learners to new navigation view controllers in
          UIKit for building apps with multiple views. They will learn how to
          connect various views to their app and design more complex workflows
          in their user interface, as well as pass data from one view to
          another.
        </p>
        <p>
          Learners will also learn how to organise their code using the
          Model-View-Controller design pattern and how to use Swift protocols in
          their code.
        </p>
      </>
    ),
    learningObjectives: Utils.addKeys([
      {
        text: 'Understand and apply the concepts of iOS navigation, including navigation controllers, tab bar controllers, and split view controllers',
      },
      {
        text: 'Learn and apply techniques to implement navigation in iOS apps using segues, storyboards, and programmatic navigation',
      },
      {
        text: 'Understand and use common iOS workflows such as modal presentations and modal segues',
      },
      {
        text: 'Learn and apply techniques to manage data flow and communication between different parts of an iOS app',
      },
      {
        text: 'Understand and use techniques for handling user input and providing feedback to the user in an iOS app',
      },
    ]),
  },
  {
    title: 'Working with Table Views',
    overview: (
      <p>
        This module covers both table and collection views, enabling users to
        create more interactive user interfaces and display larger data sets.
        Learners will use the concepts they have learned in Swift and UIKit to
        create a to-do list app, which involves project planning and designing
        the model before app implementation.{' '}
      </p>
    ),
    learningObjectives: Utils.addKeys([
      {
        text: 'Learn and apply techniques for customising table views and collection views using prototype cells, custom cells, custom headers and footers',
      },
      {
        text: 'Learn and apply techniques for managing data and updates in table views and collection views',
      },
      {
        text: 'Understand and use the iOS notifications framework to schedule and deliver notifications to the user, and learn how to handle user interactions with notifications',
      },
    ]),
  },
  {
    title: 'Using Web APIs and JSON',
    overview: (
      <>
        <p>
          Participants will learn about Swift&apos;s powerful closure concept
          and how it can be used to store code that can be executed at a later
          stage. They will be introduced to the concept of extensions and how
          they are used to add functionality to current types by creating new
          properties or methods to extend these types while keeping the original
          source code. This module also covers the concepts of serialising data
          to a format that can be saved and then writing and reading data from
          files on disk.
        </p>
        <p>
          Participants will also learn how Automatic Reference Counting (ARC)
          manages memory usage in Swift. They will be introduced to system view
          controllers such as the activity controller, image picker and alert as
          a way of extending app functionality. They will also learn how to
          create custom input forms for gathering data from users.
        </p>
        <p>
          This module goes into detail about the built-in iOS tools for sending
          and receiving data from the web, a common feature in apps, as well as
          utilising the Codable protocol to convert between JSON and Swift
          objects.
        </p>
      </>
    ),
    learningObjectives: Utils.addKeys([
      {
        text: 'Apply Swift advanced programming techniques including closures, type extensions and collection functions to create clean, reusable code',
      },
      {
        text: 'Describe how ARC manages memory usage in iOS apps',
      },
      {
        text: 'Learn and apply techniques for data collection and persistence in iOS apps',
      },
      {
        text: 'Understand and use web APIs and JSON for networking and data transfer in iOS apps',
      },
      {
        text: 'Understand and use techniques for managing and storing data locally and remotely using various Apple and third party APIs',
      },
    ]),
  },
  {
    title: 'Concurrency with Swift',
    overview: (
      <>
        <p>
          In this module, participants will discover how to handle concurrency
          issues in Swift. They will gain an understanding of concurrent
          programming as the main tool in building fast, responsive iOS
          applications that can access remote APIs via the network, process
          data, and update and respond to user input from the app interface all
          at once.
        </p>
        <p>
          They will learn how to implement concurrent programmes with the
          ReactiveSwift library and the new asynchronous programming features
          available from Swift 5.5 onwards.{' '}
        </p>
      </>
    ),
    learningObjectives: Utils.addKeys([
      {
        text: 'Understand and use techniques for implementing concurrency in iOS apps',
      },
      {
        text: 'Learn and apply the fundamental concepts of functional programming in Swift',
      },
      {
        text: 'Understand and use Functional Reactive Programming (FRP) using ReactiveSwift',
      },
      {
        text: 'Learn and apply techniques for implementing async, non-blocking and event-driven code',
      },
      {
        text: 'Understand and use techniques for handling state, data flow and error handling in functional reactive programming',
      },
    ]),
  },
  {
    title: 'Design Cycle, Prototyping, Testing & Release',
    overview: (
      <>
        <p>
          This module covers various aspects of practical app development, such
          as planning, designing, prototyping, automated software testing, and
          release. Learners will learn design thinking, prototyping and using
          Agile software development methodologies in the iOS mobile development
          lifecycle. In addition, they will learn how to capture app
          requirements and map them to app features. Then, they will build
          prototypes and wireframes based on the user stories gathered to
          practice UI Design.
        </p>
        <p>
          Automated software testing is an essential element of the software
          development cycle which learners will review along with the multiple
          layers of automated software testing: unit testing, integration
          testing and end-to-end (e2e) testing. Students will then learn how to
          write automated unit tests for specific app functionality using the
          XCTest module by engaging in a practical Swift coding exercise.
        </p>
      </>
    ),
    learningObjectives: Utils.addKeys([
      {
        text: 'Understand the process of defining the scope and goals of an iOS app, including identifying target users and key features',
      },
      {
        text: 'Learn how to create a working prototype of an iOS app, including wireframing and mockups',
      },
      {
        text: 'Understand the importance of user testing and validation in the app development process, and learn how to conduct effective user testing',
      },
      {
        text: "Learn to use Xcode's Test Navigator and the XCTest framework to test iOS apps",
      },
      {
        text: 'Gain experience in iterating on the design and functionality of an iOS app based on user feedback and testing results',
      },
    ]),
  },
  {
    title: 'Capstone Project',
    overview: (
      <>
        <p>
          Having learned Swift programming and building iOS apps using UIKit,
          participants will now apply their skills to an individual project to
          build an iOS app that they planned and designed in the previous
          module.
        </p>
        <p>
          This course concludes with individual capstone project presentations
          and submission of final reports.
        </p>
      </>
    ),
  },
]);

const trainers = Utils.addKeys([Profiles.natasha, Profiles.germaine]);

const applyNowLink =
  '/professional-training/apply-now/?c=(SCTP) Advanced Certificate in iOS Application Development with Swift Programming';

const enquiryLink =
  'https://academy.smu.edu.sg/programme-enquiry?course=%28SCTP%29%20Advanced%20Certificate%20in%20iOS%20Application%20Development%20with%20Swift%20Programming&nid=8621';

const SCTPSwift = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="(SCTP) Advanced Certificate in iOS Application Development with Swift Programming"
      />
      <MobileNavigationMenu
        items={navigationLinks}
        applyNowLink={applyNowLink}
        enquiryLink={enquiryLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              Offered under the SkillsFuture Career Transition Programme (SCTP)
              to support mid-career individuals in developing essential emerging
              and industry-relevant skills, this programme aims to enhance
              participants&apos; employability and facilitate career transitions
              into job roles within the iOS app development field.
            </p>
            <p>
              As the demand for Apple devices rises, the iOS development market
              is simultaneously booming. With Apple&apos;s modern Swift
              programming language and the Xcode IDE, building and publishing
              apps directly to the app store has never been easier.
            </p>
            <p>
              From learning the best practices for iOS app design to
              navigational interfaces for building apps, and web APIs, students
              will be taught how to navigate through the app design process.
              Classes are conducted by Certified Apple Professional Learning
              Providers and iOS Developers working in the industry, through a
              combination of lectures, hands-on coding projects, and technical
              labs.
            </p>
            <p>
              Whether you&apos;re a software developer, project manager, or a
              complete beginner to programming, come learn with us and join the
              growing pool of iOS developers to create the next stellar iOS app.
              By the end of the programme, students will gain a solid
              understanding of Swift programming and iOS app development using
              Xcode and be prepared to take on the Apple App Development with
              Swift Certified User certification exam.
            </p>
            <Margin20 />
            <InfoTable
              venue="On-campus"
              starts="22 Aug 2023 (Tue)"
              fees="SGD5832.00* (as low as SGD1332.00* after maximum funding)"
              duration={
                <>
                  120 Course Hours (F2F)
                  <br />
                  Weekdays (7pm - 10.30pm) & Saturday (9am - 6pm)
                  <br />
                </>
              }
              level="Basic"
            />
            <h3>Learning Objectives</h3>
            <p>At the end of this programme, participants will be able to:</p>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            {topics.map(
              (
                { key, title, overview, learningObjectives: learnObj },
                index
              ) => (
                <Fragment key={key}>
                  <h3>{`Module ${index + 1}: ${title}`}</h3>
                  {overview}
                  {learnObj && (
                    <>
                      <p>
                        <strong>Learning Objectives</strong>
                      </p>
                      {learnObj.map(({ text, key: subKey }, subIndex) => (
                        <ListItem
                          number={subIndex + 1}
                          pointerColor={$.proTraining.blue}
                          key={subKey}
                          text={text}
                        />
                      ))}
                    </>
                  )}
                  {index < topics.length - 1 && (
                    <>
                      <Margin20 />
                      <Margin20 />
                      <Margin20 />
                    </>
                  )}
                </Fragment>
              )
            )}
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
            <Margin20 />
            <Margin20 />
            <h3>Prerequisites</h3>
            <p>
              No prior experience in programming or software engineering is
              required or assumed
            </p>
            <Margin20 />
            <Margin20 />
            <h3>Hardware Requirements</h3>
            <p>
              An M2 Macbook Air or similar Apple computer will be provided to
              each participant. Students are welcome to use their own machines
              (at least M1 equipped Mac required)
            </p>
          </TextSection>
          <TextSection position={3}>
            <h2>Fees And Funding</h2>
            <Table>
              <thead>
                <tr>
                  <td className="highlight1">Participant Profile</td>
                  <td className="highlight1">Self Sponsored</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight2">
                    Singapore Citizen &lt; 40 years old
                    <br />
                    Permanent Resident
                    <br />
                    LTVP+
                    <br />
                    (Individuals must be at least 21 years old)
                  </td>
                  <td>
                    <strong>
                      $5,832<sup>1</sup>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight2">
                    Singapore Citizen ≥ 40 years old
                  </td>
                  <td>
                    <strong>
                      $2,232<sup>2</sup>
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight2">
                    Singapore Citizens who qualify for AFS
                  </td>
                  <td>
                    <strong>
                      $1,332<sup>3</sup>
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
            <p>
              <em>All prices include 8% GST</em>
            </p>
            <p>
              <sup>1</sup>
              Up to 70% course fee funding
            </p>
            <p>
              <sup>2</sup>Up to 90% course fee funding under{' '}
              <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                SkillsFuture Mid-Career Enhanced Subsidy
              </RemoteLink>{' '}
              (&quot;MCES&quot;)
            </p>
            <p>
              <sup>3</sup>Up to 95% course fee funding under{' '}
              <strong>Additional Funding Support (&quot;AFS&quot;)</strong>{' '}
              where participants must be:
            </p>
            <ul>
              <li>Singapore Citizens; and</li>
              <li>Long-term unemployed individuals (6 months or more); or</li>
              <li>
                Individuals in need of financial assistance [ComCare
                Short-to-Medium Term Assistance (SMTA) recipients or Workfare
                Income Supplement (WIS) recipients]; or
              </li>
              <li>Persons with disabilities </li>
            </ul>
            <QnADropdown ques="SCTP Additional Course Fee Funding Support (AFS)">
              <p>
                The Additional Course Fee Funding Support (AFS) will be rolled
                out with the SkillsFuture Career Transition Programme (SCTP)
                from 1 April 2022
              </p>
              <ol>
                <li>
                  AFS will be{' '}
                  <u>
                    provided on application basis, prior to course enrolment.
                  </u>
                </li>
                <li>
                  Eligibility criteria:
                  <ol type="a">
                    <li>
                      <strong>Singapore Citizens (SCs) only</strong>, and those
                      who fall into either one of the categories below:
                      <ol type="i">
                        <li>
                          ComCare Short-to-Medium-Term Assistance (ComCare SMTA)
                          or
                        </li>
                        <li>
                          Workfare Income Supplement (WIS) recipients); or
                        </li>
                        <li>Persons with Disabilities (PwDs); or</li>
                        <li>
                          Long-term unemployed individuals (defined as{' '}
                          <u>
                            zero CPF contributions for the past six consecutive
                            months
                          </u>
                          ); or
                        </li>
                        <li>
                          Referrals from WSG Careers Connect, who are assessed
                          to be vulnerably employed jobseekers and require
                          additional training support{' '}
                          <em>[closed criterion]</em>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  The eligibility for the additional funding is based on the
                  status of the needy individual at the point of course start
                  date*
                </li>
                <li>
                  Level of funding support:{' '}
                  <strong>Up to 95% of course fees</strong>
                </li>
                <li>
                  The eligibility for AFS will be applied throughout the course
                  regardless whether there is a change in the individual&apos;s
                  status during training.
                </li>
              </ol>
              <p>
                <em>
                  *Applies to all categories of needy trainees, except referrals
                  from WSG, whose eligibility will be determined at the point of
                  course application
                </em>
              </p>
              <p>
                Additionally, individuals can use their{' '}
                <RemoteLink href="https://www.skillsfuture.gov.sg/credit">
                  SkillsFuture Credit
                </RemoteLink>{' '}
                (opening and top-up) and Additional SkillsFuture Credit
                (Mid-Career Support) to offset the out-of-pocket course fees for
                SCTP.
              </p>
              <p>
                For more information, visit{' '}
                <RemoteLink href="https://www.skillsfuture.gov.sg/sctp">
                  SkillsFuture SCTP&apos;s website
                </RemoteLink>
                .
              </p>
            </QnADropdown>
            <Margin20 />
            <Margin20 />
            <p>
              <strong>Post Secondary Education Account (PSEA)</strong>
              <br />
              PSEA can be utilised for subsidised programmes eligible for
              SkillsFuture Credit support. Click{' '}
              <RemoteLink href="https://www.myskillsfuture.gov.sg/content/portal/en/career-resources/career-resources/how-to-guides/all-you-need-to-know-about-psea-and-how-you-can-use-it-for-your-upskilling-journey.html">
                here
              </RemoteLink>{' '}
              to find out more.
            </p>
            <Margin20 />
            <p>
              <strong>Self Sponsored</strong>
            </p>
            <QnADropdown ques="SkillsFuture Credit">
              Singapore Citizens aged 25 and above may use their SkillsFuture
              Credits to pay for the course fees. The credits may be used on top
              of existing course fee funding.
              <br />
              This is only applicable to <strong>self-sponsored</strong>{' '}
              participants. Applications via{' '}
              <RemoteLink href="https://www.myskillsfuture.gov.sg/content/portal/en/index.html">
                MySkillsFuture.sg
              </RemoteLink>{' '}
              must be made within 60 days before course commencement.
              <br />
              SMU Academy has to be informed on the Claim ID no. and claim
              amount in order for the correct payable course fee amount to be
              reflected on the invoice. Please click{' '}
              <RemoteLink href="https://www.myskillsfuture.gov.sg/content/portal/en/header/faqs/skillsfuture-credit.html">
                here
              </RemoteLink>
              for more details on SkillsFuture Credit.
            </QnADropdown>
            <QnADropdown ques="Workfare Skills Support Scheme">
              Workfare Skills Support (WSS) Scheme in replacement of Workfare
              Training Support (WTS) Scheme:
              <br />
              With effect from <strong>1 July 2020</strong>, applicants who wish
              to apply for the WTS Scheme may refer to the WSS Scheme instead.
              Please click{' '}
              <RemoteLink href="https://www.mom.gov.sg/-/media/mom/documents/budget2020/factsheet-workfare-skills-support-scheme.pdf?la=en&hash=EC176C8C553ECE399239FFD63CACB13B">
                here
              </RemoteLink>{' '}
              for more details.
            </QnADropdown>
          </TextSection>
          <TextSection position={4}>
            <h2>Schedule</h2>
            <p>
              <strong>Start Date(s)</strong>
              <br />
              22 Aug 2023 (Tue)
            </p>
            <p>
              <strong>Intake Information</strong>
            </p>
            <p>
              <strong>
                Intake 1: 22 Aug 2023 - 27 Jan 2024 [Open For Registration]
              </strong>
            </p>
            <Table>
              <thead>
                <tr>
                  <td className="highlight2">Modules</td>
                  <td className="highlight2">Dates</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight1">
                    MODULE 1: SWIFT PROGRAMMING FUNDAMENTALS AND XCODE IDE
                  </td>
                  <td>22, 24 & 26 AUG 2023</td>
                </tr>
                <tr>
                  <td className="highlight1">
                    MODULE 2: BUILDING INTERFACES WITH UIKITS FRAMEWORKS
                  </td>
                  <td>05, 07 & 09 SEPT 2023</td>
                </tr>
                <tr>
                  <td className="highlight1">
                    MODULE 3: NAVIGATION AND WORKFLOWS
                  </td>
                  <td>19, 21 & 23 SEPT 2023</td>
                </tr>
                <tr>
                  <td className="highlight1">
                    MODULE 4: WORKING WITH TABLE VIEWS
                  </td>
                  <td>03, 05 & 07 OCT 2023</td>
                </tr>
                <tr>
                  <td className="highlight1">
                    MODULE 5: USING WEB APIS AND JSON
                  </td>
                  <td>17, 19 & 21 OCT 2023</td>
                </tr>
                <tr>
                  <td className="highlight1">
                    MODULE 6: CONCURRENCY WITH SWIFT
                  </td>
                  <td>07, 09 & 11 NOV 2023</td>
                </tr>
                <tr>
                  <td className="highlight1">
                    MODULE 7: DESIGN CYCLE, PROTOTYPING, TESTING & RELEASE
                  </td>
                  <td>21, 23 & 25 NOV 2023</td>
                </tr>
                <tr>
                  <td className="highlight1">MODULE 8: CAPSTONE PROJECT</td>
                  <td>6 & 27 JAN 2024</td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection>
            <h2>Policies</h2>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                View programme policies.
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={5}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default SCTPSwift;
