import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../styles/global';

const Section = styled.section`
  margin-bottom: 70px !important;
`;

const Container = styled.div``;

const Title = styled.h2`
  font-family: Lato Bold;
  font-size: ${$.fontSizes.xlarge};
  line-height: 1.2em;
  color: ${$.proTraining.bluegray};
  margin-bottom: 1em;
  text-align: center;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: ${$.fontSizes.xlarge};
    line-height: 43px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: ${$.fontSizes.xlarge};
    line-height: 43px;
  }
  // #endregion
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 30px;

  & > div {
    margin: auto;
  }

  ${() => {
    const modifier = 0.8;

    return `
    & .lli {
      width: ${120 * modifier}px;
    }
    & .getgo {
      width: ${80 * modifier}px;
    }
    & .cpf {
      width: ${60 * modifier}px;
    }
    & .dsta {
      width: ${80 * modifier}px;
    }
    & .infineon {
      width: ${100 * modifier}px;
    }
    & .nusrisk {
      width: ${100 * modifier}px;
    }
    & .changi {
      width: ${100 * modifier}px;
    }
    & .ocbc {
      width: ${100 * modifier}px;
    }
    & .nuslaw {
      width: ${100 * modifier}px;
    }
    & .nuslaw {
      width: ${100 * modifier}px;
    }
    & .shopee {
      width: ${100 * modifier}px;
    }
    & .blackrock {
      width: ${100 * modifier}px;
    }
    & .paypal {
      width: ${100 * modifier}px;
    }
    `;
  }}

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    grid-template-columns: repeat(6, 1fr);
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 0px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 70px;
      /* &:nth-child(8) {
        grid-column: initial;
      }
      &:nth-child(10) {
        grid-column: 2 / 3;
      } */
    }

    ${() => {
      const modifier = 0.8;
      return `
      
      & .lli {
        width: ${120 * modifier}px;
      }
      & .getgo {
        width: ${80 * modifier}px;
      }
      & .cpf {
        width: ${60 * modifier}px;
      }
      & .dsta {
        width: ${80 * modifier}px;
      }
      & .infineon {
        width: ${100 * modifier}px;
      }
      & .nusrisk {
        width: ${100 * modifier}px;
      }
      & .changi {
        width: ${100 * modifier}px;
      }
      & .ocbc {
        width: ${100 * modifier}px;
      }
      & .nuslaw {
        width: ${100 * modifier}px;
      }
      & .nuslaw {
        width: ${100 * modifier}px;
      }
      & .shopee {
        width: ${100 * modifier}px;
      }
      & .blackrock {
        width: ${100 * modifier}px;
      }
      & .paypal {
        width: ${100 * modifier}px;
      }

      `;
    }}
  }
  // #endregion
`;

const ClientsCollaborations = () => (
  <Section>
    <Container>
      <Title>Clients & Collaborations</Title>
      <Logos>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/lli.jpeg"
            className="lli"
            alt="LLI Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/get-go-logo.png"
            className="getgo"
            alt="GetGo Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/cpf.png"
            className="cpf"
            alt="CPF Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/dsta.png"
            className="dsta"
            alt="Defence Science and Technology Agency Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/infineon.png"
            className="infineon"
            alt="Infineon Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/nusrisk.png"
            className="nusrisk"
            alt="NUS Risk Management Institute Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/changi_airport_group.png"
            className="changi"
            alt="Changi Airport Group Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/ocbc.png"
            className="ocbc"
            alt="OCBC Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/nus-law.png"
            className="nuslaw"
            alt="NUS Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/shopee.png"
            className="shopee"
            alt="Shopee Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/blackrock.png"
            className="blackrock"
            alt="BlackRock Logo"
          />
        </div>
        <div>
          <StaticImage
            src="../../assets/images/landing/partners/paypal.png"
            className="paypal"
            alt="PayPal Logo"
          />
        </div>
      </Logos>
    </Container>
  </Section>
);

export default ClientsCollaborations;
