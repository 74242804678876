import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../styles/global';

const Section = styled.section`
  background-color: ${$.color.white};
  border-bottom: 1px solid ${$.color.gray1};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${$.layout().margin3}px 0;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    flex-direction: column;
    justify-content: initial;

    > * {
      margin-bottom: ${$.layout().margin4}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    justify-content: initial;

    > * {
      margin-bottom: ${$.layout().margin4}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // #endregion
`;

const Navlink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:visited {
    color: ${$.color.black2};
  }

  > span {
    color: ${$.color.black2};
    text-transform: uppercase;
    font-size: 14px;
  }

  > div {
    background-color: ${$.color.black2};
    height: 1px;
    width: 0;
    transition: all 0.35s ${$.easingFn.standard};
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      > div {
        width: 100%;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    > span {
      font-size: 16px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    text-align: center;
    > span {
      font-size: 16px;
    }
  }
  // #endregion
`;

const Navbar = () => (
  <Section>
    <Container>
      <Navlink
        to="/datascience-bootcamp-accelerator-2021/"
        title="Check our our Data Science Bootcamp & Accelerator Programme!"
      >
        <span>Data Science Bootcamp & Accelerator</span>
        <div />
      </Navlink>
      <Navlink
        to="/datascience-bootcamp-accelerator-2021/programme-outline/"
        title="Read up on Data Science Bootcamp Accelerator Programme Outline here."
      >
        <span>Programme Outline</span>
        <div />
      </Navlink>
      <Navlink
        to="/datascience-bootcamp-accelerator-2021/join-2021-programme/"
        title="Click to join our Data Science Bootcamp Accelerator 2021 Programme!"
      >
        <span>Join the 2021 Programme</span>
        <div />
      </Navlink>
      <Navlink
        to="/imda-grad-2021/"
        title="Watch our 2021's class graduation videos here!"
      >
        <span>Student Projects</span>
        <div />
      </Navlink>
    </Container>
  </Section>
);

export default Navbar;
