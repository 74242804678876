import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import FbIcon from '../../../assets/icons/fb.svg';
import TwIcon from '../../../assets/icons/tw.svg';
import LeftChevron from '../../../assets/icons/left-chevron.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: 40px;

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    padding: 0px ${$.layout().margin4}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    padding: 0px;
  }
  // #endregion
`;

const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const SocialLink = styled.a`
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ${$.easingFn.standard};

  &:hover {
    opacity: 0.5;
  }

  & > svg {
    fill: ${$.color.gray2};
  }
`;

const BackToBlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Lato Regular;
`;

const StyledBackToBlogLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  text-decoration: none;
  font-size: 15px;
  color: ${$.color.blue4};

  & > svg {
    height: 20px;
    margin-right: ${$.layout().margin5}px;
    fill: ${$.color.blue4};
  }

  &:visited {
    color: ${$.color.blue4};
  }
`;

const PostSocialIcons = ({ slug }) => {
  const { page } = useSelector(({ previousBlogPage }) => previousBlogPage);

  return (
    <Container>
      <BackToBlogContainer>
        <StyledBackToBlogLink
          to={page === 1 ? '/blog/' : `/blog/?page=${page}`}
        >
          <LeftChevron />
          <span>Back to blog</span>
        </StyledBackToBlogLink>
      </BackToBlogContainer>
      <SocialLinksContainer>
        <SocialLink
          href={`https://www.facebook.com/sharer.php?u=https://www.sgcodecampus.com/blog/${slug}/`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <FbIcon />
        </SocialLink>
        <SocialLink
          href={`https://twitter.com/intent/tweet?url=https://www.sgcodecampus.com/blog/${slug}/`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <TwIcon />
        </SocialLink>
      </SocialLinksContainer>
    </Container>
  );
};

PostSocialIcons.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default PostSocialIcons;
