import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DownChevron from '../../assets/icons/down-chevron.svg';

/**
 * This part is to fix hidden box shadows due to overflow clipping the container
 */
const Container = styled.div`
  // overflow-y: hidden;
  padding: 0px ${({ theme }) => theme.layout().padding5}px
    ${({ theme }) => theme.layout().padding5}px;
  margin: -${({ theme }) => theme.layout().margin3}px -${({ theme }) =>
      theme.layout().padding5}px;
  position: relative;
  user-select: none;
`;

const DownChevronContainer = styled.div`
  width: 20px;
  height: 20px;
  transform: rotate(${({ rotationCounter }) => rotationCounter * 180}deg);
  transition: transform 0.5s ${({ theme }) => theme.easingFn.standard};

  & > svg {
    fill: ${({ theme }) => theme.color.white};
  }
`;

const QuestionContainer = styled.div`
  width: calc(100% - ${({ theme }) => theme.layout().padding3 * 2}px);
  border-radius: ${({ theme }) => theme.border().radius1}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.layout().padding3}px;
  background-color: ${({ dropdownState, theme }) =>
    dropdownState ? theme.color.orange3 : theme.color.blue3};
  color: ${({ theme }) => theme.color.white};
  margin-bottom: ${({ dropdownState, theme }) =>
    dropdownState ? theme.layout().margin3 : 0}px;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  transition: background-color 0.5s ${({ theme }) => theme.easingFn.standard},
    margin-bottom 0.5s ${({ theme }) => theme.easingFn.standard};
  will-change: background-color, margin-bottom;

  &:hover {
    box-shadow: ${({ theme }) => theme.dropShadow.repressed};
  }
`;

const Question = styled.div`
  flex: 1;
`;

const ContentContainer = styled.div`
  overflow-y: hidden;
  padding: 0px ${({ theme }) => theme.layout().padding5}px
    ${({ theme }) => theme.layout().padding5}px;
  margin: 0px -${({ theme }) => theme.layout().padding5}px -${({ theme }) =>
      theme.layout().padding5}px;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ${({ theme }) => theme.easingFn.standard},
    opacity 0.5s ${({ theme }) => theme.easingFn.standard};
  will-change: max-height, opacity;

  &.show {
    max-height: ${({ contentLength, theme }) =>
      theme.layout().padding2 * contentLength}px;
    opacity: 1;
  }

  // NOTE: Mobile and below
  // #region
  @media ${({ theme }) => theme.device.mobile} {
    &.show {
      max-height: ${({ contentLength, theme }) =>
        theme.layout().padding1 * contentLength}px;
      opacity: 1;
    }
  }
  // #endregion
`;

const Content = styled.div`
  word-break: normal;
  hyphens: auto;
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border().radius1}px;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  padding: ${({ theme }) => theme.layout().padding3}px;
  width: calc(100% - ${({ theme }) => theme.layout().padding3 * 2}px);

  a {
    font-family: Lato Bold;
    text-decoration: none;
    color: ${({ theme }) => theme.color.blue5};
    &:visited {
      color: ${({ theme }) => theme.color.blue5};
    }
  }

  // NOTE: Tablets
  // #region
  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ theme }) => theme.layout().padding4}px;
    width: calc(100% - ${({ theme }) => theme.layout().padding4 * 2}px);
  }
  // #endregion
`;

const Dropdown = ({ title, children, contentLength }) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [rotationCounter, setRotationCounter] = useState(0);

  return (
    <Container>
      <QuestionContainer
        onClick={() => {
          setDropdownState((prev) => !prev);
          setRotationCounter((prev) => prev + 1);
        }}
        dropdownState={dropdownState}
      >
        <Question>{title}</Question>
        <DownChevronContainer
          dropdownState={dropdownState}
          rotationCounter={rotationCounter}
        >
          <DownChevron />
        </DownChevronContainer>
      </QuestionContainer>
      <ContentContainer
        className={dropdownState ? 'show' : ''}
        contentLength={contentLength}
      >
        <Content>{children}</Content>
      </ContentContainer>
    </Container>
  );
};

Dropdown.defaultProps = {
  title: '',
  children: <></>,
  contentLength: 1,
};

Dropdown.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  contentLength: PropTypes.number,
};

export default Dropdown;
