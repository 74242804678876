import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import Column from './Column';
// import Row from './Row';
import data from './items.json';
import Util from '../../utils';

const ColumnContainer = styled.div`
  ${({ length }) => `
    // NOTE: Desktops and above
    // #region
    @media ${$.device.desktop} {
      width: 100%;
      height: 400px;
      padding: ${$.layout().padding4}px 0px;
      background-color: ${$.color.white};
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: ${$.border().radius1}px;
      box-shadow: ${$.dropShadow.normal};
      margin-bottom: ${$.layout().margin4}px;
      > * {
        width: calc((100% - 2px) / ${length});
      }
    }
    // #endregion

    // NOTE: Tablets
    // #region
    @media ${$.device.tablet} {
      width: 100%;
      height: 400px;
      padding: ${$.layout().padding4}px 0px;
      background-color: ${$.color.white};
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: ${$.border().radius1}px;
      box-shadow: ${$.dropShadow.normal};
      margin-bottom: ${$.layout().margin4}px;
      > * {
        width: calc((100% - 2px) / ${length});
      }
    }
    // #endregion

    // NOTE: Mobile and below
    // #region
    @media ${$.device.mobile} {
      // Added !important to prevent being overridden by the Layout
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100vw !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > * {
        width: calc(100% - ${$.layout().padding1 * 2}px);
      }
    }
    // #endregion
  `}
`;

// const RowContainer = styled.div`
//   // NOTE: Desktop
//   // #region
//   @media ${$.device.desktop} {
//     background-color: ${$.color.white};
//     border-radius: ${$.border().radius1}px;
//     box-shadow: ${$.dropShadow.normal};
//   }
//   // #endregion

//   // NOTE: Tablets
//   // #region
//   @media ${$.device.tablet} {
//     background-color: ${$.color.white};
//     border-radius: ${$.border().radius1}px;
//     box-shadow: ${$.dropShadow.normal};
//   }
//   // #endregion

//   // NOTE: Mobile
//   // #region
//   @media ${$.device.mobile} {
//     margin-left: 0 !important;
//     margin-right: 0 !important;
//     width: 100vw !important;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: center;
//   }
//   // #endregion
// `;

const columnCards = Util.addKeys(data.columns).map(
  ({ image, color, subColor, ...details }) => ({
    ...details,
    image: Util.importOneSVG(image),
    color: $.color[color],
    subColor: $.color[subColor],
  })
);

// const rowCards = Util.addKeys(data.rows).map(
//   ({ image, color, subColor, ...details }) => ({
//     ...details,
//     image: Util.importOneSVG(image),
//     color: $.color[color],
//     subColor: $.color[subColor],
//   })
// );

const ProgrammeCards = ({ className }) => (
  <>
    <ColumnContainer className={className} length={columnCards.length}>
      {columnCards.map((columnCard, index) => {
        const corners = {
          topLeft: false,
          topRight: false,
          bottomRight: false,
          bottom: false,
        };

        switch (index) {
          case 0:
            corners.topLeft = true;
            corners.bottomLeft = true;
            break;
          case columnCards.length - 1:
            corners.topRight = true;
            corners.bottomRight = true;
            break;
          default:
            break;
        }

        return (
          <Column
            {...columnCard}
            showDivider={index !== columnCards.length - 1}
            corners={corners}
          />
        );
      })}
    </ColumnContainer>
    {/* <RowContainer className={className}>
      {rowCards.map((rowCard) => (
        <Row {...rowCard} />
      ))}
    </RowContainer> */}
  </>
);

ProgrammeCards.defaultProps = {
  className: '',
};

ProgrammeCards.propTypes = {
  className: PropTypes.string,
};

export default ProgrammeCards;
