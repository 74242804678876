import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  width: 100vw !important;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    height: 100%;
  }
  // #endregion
`;

const Info = styled.div`
  width: calc(0.4 * 970px);
  margin-left: calc((100vw - 970px) / 2);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: calc(40% - ${$.layout().margin3}px);
    margin-left: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: calc(40% - ${$.layout().margin3}px);
    margin-left: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding-top: 100px;
    padding-bottom: 50px;
    width: calc(100% - ${$.layout().margin3 * 2}px);
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.black};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Description = styled.div`
  font-family: Lato Bold;
  font-size: 18px;
  line-height: 1.3em;
`;

const ImageContainer = styled.div`
  width: calc(0.7 * 970px);
  height: 800px;
  clip-path: polygon(22% 0%, 100% 0%, 100% 100%, 22% 100%, 10% 50%);

  & > * {
    width: 100%;
    height: 100%;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    clip-path: polygon(19.5% 0%, 100% 0%, 100% 100%, 19.5% 100%, 10% 50%);
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 26% 100%, 10% 50%);
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    height: 300px;
    clip-path: none;
  }
  // #endregion
`;

const WhatIsCloud = () => (
  <Section>
    <Container>
      <Info>
        <Title>What is the Cloud?</Title>
        <Description>
          <p>
            The Cloud is where one can rent and use computing resources owned by
            a company like Amazon over a network (ie. the Internet).
          </p>
          <p>
            Did you know that companies as diverse as Grab, DBS, Samsung,
            Netflix, Playstation all live on the AWS Cloud?
          </p>
        </Description>
      </Info>
      <ImageContainer>
        <StaticImage
          src="../../../assets/images/tertiary/aws/icon-background.png"
          alt="icon background"
        />
      </ImageContainer>
    </Container>
  </Section>
);

export default WhatIsCloud;
