import styled from 'styled-components';

import $ from '../../../styles/global';
import Button from '../../Button';

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 35px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;
  text-align: center;

  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
  }
  // #endregion
`;

const Cards = styled.div`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    margin-bottom: ${$.layout().margin2}px;
    justify-content: space-between;

    > * {
      width: calc(31% - ${$.layout().padding3 * 2}px);
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    display: flex;
    flex-direction: column;
    margin-bottom: ${$.layout().margin2}px;

    > * {
      width: calc(100% - ${$.layout().padding3 * 2}px);
      margin-bottom: ${$.layout().margin3}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletB} {
    display: flex;
    flex-direction: row;
    margin-bottom: ${$.layout().margin2}px;
    justify-content: space-between;

    > * {
      width: calc(31% - ${$.layout().padding3 * 2}px);
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column;
    margin-bottom: ${$.layout().margin2}px;

    > * {
      width: calc(100% - ${$.layout().padding3 * 2}px);
      margin-bottom: ${$.layout().margin3}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // #endregion
`;

const Card = styled.div`
  border-radius: 10px;
  padding: ${$.layout().padding3}px;
  box-shadow: ${$.dropShadow.normal};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardTitle = styled.div`
  font-family: Lato Bold;
  text-align: center;
  font-size: 23px;
  color: ${$.color.blue3};
  margin-bottom: ${$.layout().margin3}px;
  height: calc(23px * 3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    height: auto;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletB} {
    font-size: 20px;
    height: calc(20px * 4);
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    height: auto;
  }
  // #endregion
`;

const CardContent = styled.div`
  font-size: 17px;
  line-height: 1.3em;
  color: ${$.color.black2};
  text-align: center;
  flex-grow: 2;
  margin-bottom: ${$.layout().margin4}px;
`;

const Paragraph = styled.p`
  color: ${$.color.black2};
  font-family: Lato Regular;
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin2}px;
  text-align: center;
`;

const StyledLink = styled(({ children, className, href }) => (
  <a
    href={href}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
))`
  display: inline-block;
  text-decoration: none;
  width: 100%;
  > button {
    width: 100%;
  }
`;

const Professional = () => (
  <Section>
    <Container>
      <Title>Bite-sized introduction for the casual beginner</Title>
      <Paragraph>
        Learn about data analytics, cloud computing, or artificial intelligence
        through short, week-long courses featuring 15 hours of instruction. No
        prior technical experience needed.
      </Paragraph>
      <Cards>
        <Card>
          <CardTitle>
            <div>Cloud Computing</div>
          </CardTitle>
          <CardContent>
            Learn how companies of all sizes can save on IT costs and increase
            reliability by moving their infrastructure to the cloud. See what
            the Cloud can do for you through fun hands-on sessions and in-class
            experiments on the AWS platform.
          </CardContent>
          <StyledLink href="https://academy.smu.edu.sg/advanced-technology-stack-series-establishing-foundation-cloud-services-and-applications-4551">
            <Button>Apply here</Button>
          </StyledLink>
        </Card>
        <Card>
          <CardTitle>
            Databases Fundamentals, Data Analytics and Big Data
          </CardTitle>
          <CardContent>
            Big Data is recognised as an important and relevant skill set in any
            industry. This course will enable students to interpret data, draw
            logical deductions, and make actionable business decisions.
          </CardContent>
          <StyledLink href="https://academy.smu.edu.sg/advanced-technology-stack-series-unearthing-potential-databases-big-data-and-data-analytics-4556">
            <Button>Apply here</Button>
          </StyledLink>
        </Card>
        <Card>
          <CardTitle>
            Artificial Intelligence, Machine Learning and Deep Learning
          </CardTitle>
          <CardContent>
            Artificial Intelligence, Machine Learning and Deep Learning are
            transforming systems, experiences, processes and entire industries.
            Learn how to independently solve business problems using these
            emerging technologies.
          </CardContent>
          <StyledLink href="https://academy.smu.edu.sg/advanced-technology-stack-series-jump-start-artificial-intelligence-machine-learning-and-deep-4546">
            <Button>Apply here</Button>
          </StyledLink>
        </Card>
      </Cards>
    </Container>
  </Section>
);

export default Professional;
