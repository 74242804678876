import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

import Footer from './Footer';
import Navbar from './Navbar';
import BackToTop from './BackToTop';
import PromoBanner from './landing/PromoBanner';
import HoverSignUpButton from './DatascienceAccelerator2022/HoverSignUpButton';
import Modal from './DatascienceAccelerator2022/Testimonials/Modal';
import TertiaryHoverSignUpButton from './Tertiary/AWS/TertiaryHoverSignUpButton';
import TertiaryAppleSignUpButton from './Tertiary/Apple/TertiaryAppleSignUpButton';

import $ from '../styles/global';

const Body = styled.div`
  width: 100%;
  font-family: Lato Regular;
  font-size: 18px;
`;

const MainContainer = styled.div`
  width: 100%;

  & > section {
    width: 100%;
    ${({ location }) =>
      location === '/' ? `margin-bottom: ${$.layout().margin1}px` : ''};
  }

  & > section > div {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    margin-left: auto;
    margin-right: auto;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    & > section > div {
      width: 970px;
    }
  }
  // #endregion
`;

/**
 * Added classNames to respective components starting with layout-* for
 * CSS selector when doing mobile sidebar.
 */
const LayoutComponent = ({ location, hostname, children, showBackToTop }) => (
  <>
    <ThemeProvider theme={$}>
      <Body className="layout-body">
        <Helmet
          script={
            hostname === 'localhost'
              ? [
                  // reddit
                  {
                    type: 'text/javascript',
                    innerHTML: `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_5mg30r65');rdt('track', 'PageVisit');`,
                  },
                  // tiktok pixel
                  {
                    type: 'text/javascript',
                    innerHTML: `!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};ttq.load('CCLTRKBC77UE3OMO3L8G');ttq.page();}(window, document, 'ttq');`,
                  },
                ]
              : []
          }
        >
          {location === '/' && (
            <meta
              name="facebook-domain-verification"
              content="2jrr7m6j9tac7bc2d75m0gh3l8eeyk"
            />
          )}
        </Helmet>
        {location === '/' && <PromoBanner className="layout-promo" />}
        <Navbar location={location} />
        <MainContainer className="layout-container" location={location}>
          {children}
        </MainContainer>
        <Modal className="layout-deepracer-modal" />
        <Footer className="layout-footer" />
        {showBackToTop && <BackToTop className="layout-backtotop" />}
        {typeof window !== 'undefined' &&
          window.location.pathname === '/datascience-accelerator/' && (
            <HoverSignUpButton className="layout-hover-sign-up" />
          )}
        {typeof window !== 'undefined' &&
          window.location.pathname === '/tertiary/aws/' && (
            <TertiaryHoverSignUpButton className="layout-hover-sign-up" />
          )}
        {typeof window !== 'undefined' &&
          window.location.pathname === '/tertiary/apple/' && (
            <TertiaryAppleSignUpButton className="layout-hover-sign-up" />
          )}
      </Body>
    </ThemeProvider>
  </>
);

LayoutComponent.defaultProps = {
  location: '',
  hostname: '',
  showBackToTop: false,
};

LayoutComponent.propTypes = {
  hostname: PropTypes.string,
  children: PropTypes.node.isRequired,
  location: PropTypes.string,
  showBackToTop: PropTypes.bool,
};

export default LayoutComponent;
