import styled from 'styled-components';

import $ from '../../../styles/global';
import Utils from '../../../utils';

const Section = styled.section`
  overflow: hidden;
  background-color: ${$.color.black};
`;

const Container = styled.div`
  width: 100vw !important;
  margin: 0 !important;
  position: relative;
`;

const ImageContainer = styled.div`
  ${({ img }) => `
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("${img}");
  filter: blur(3px) brightness(0.6);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  `}
`;

const Info = styled.div`
  position: relative;
  padding: 100px 0;
  margin: 0 calc((100vw - 970px) / 2);

  p {
    font-family: Lato Regular;
    font-size: 18px;
    color: ${$.color.white};
    line-height: 1.3em;
    text-shadow: 2px 0 ${$.color.black}, 0 2px ${$.color.black},
      2px 0 ${$.color.black}, 0 2px ${$.color.black};
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;
  text-shadow: 2px 0 ${$.color.black}, 0 2px ${$.color.black},
    2px 0 ${$.color.black}, 0 2px ${$.color.black};

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Attend = () => (
  <Section>
    <Container>
      <ImageContainer
        img={Utils.importOneImage('tertiary/aws/who-should-attend.png')}
      />
      <Info>
        <Title>Who should attend</Title>
        <p>
          Open to all post O-Level, post A-Level individuals and NS men
          preparing to enter Polytechnic or University. Students are not
          expected to have any prior experience in programming, Cloud Computing
          or AI.
        </p>
        <p>
          (Current Poly and University students, particularly in non-technical
          programmes who are interested in tech are also welcome)
        </p>
      </Info>
    </Container>
  </Section>
);

export default Attend;
