import { memo, useContext } from 'react';
import styled from 'styled-components';

import $ from '../../../../styles/global';
import context from '../context';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(65px, 1fr));
  gap: ${$.layout().margin5}px;
  place-items: stretch;
  margin-bottom: ${$.layout().margin4}px;
`;

const IndividualDate = styled.div`
  padding: ${$.layout().padding5}px;
  background-color: ${$.color.blue6};
  border-radius: ${$.border().radius2}px;
  font-family: Lato Regular;
  text-align: center;
  box-shadow: ${$.dropShadow.normal};
  white-space: nowrap;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    color: ${$.color.white};
  }
  // #endregion
`;

const CourseDays = memo(() => {
  const {
    event: {
      dates: { full: courseDatesFull },
    },
  } = useContext(context);

  return (
    <Container>
      {courseDatesFull.map((ele, index) => {
        const date = new Date(ele);
        const day = date.getDate();
        const month = date.toLocaleString('en-us', { month: 'short' });
        return <IndividualDate key={index}>{`${day} ${month}`}</IndividualDate>;
      })}
    </Container>
  );
});

export default CourseDays;
