import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../../styles/global';
import data from './items.json';
import Util from '../../../utils';

const Section = styled.section`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-top: ${$.layout().margin2}px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-top: ${$.layout().margin2}px;
    margin-bottom: ${$.layout().margin2}px;
    > * {
      margin-left: 0 !important;
      width: 100vw !important;
      margin-right: 0 !important;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    > * {
      margin-left: 0 !important;
      width: 100vw !important;
      margin-right: 0 !important;
    }
  }
  // #endregion
`;

const Container = styled.div`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: ${$.layout().margin3}px;
    grid-row-gap: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: column;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column;
  }
  // #endregion
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  // NOTE: Desktops
  // #region
  @media ${$.device.desktop} {
    border-radius: ${$.border().radius1}px;
    box-shadow: ${$.dropShadow.normal};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    align-items: center;
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion

  // NOTE: mobile
  // #region
  @media ${$.device.mobile} {
    align-items: center;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Logo = styled.a`
  margin-top: ${$.layout().margin4}px;
  margin-bottom: ${$.layout().margin5}px;
  display: flex;
  text-decoration: none;
  width: 100%;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
  }

  > svg {
    height: 100%;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    height: 100px;
    &.SMU {
      > img {
        width: 55%;
      }
    }

    &.Shopee {
      > img {
        width: 40%;
      }
    }

    &.Paypal {
      > img {
        width: 40%;
      }
    }

    &.BlackRock {
      > img {
        width: 50%;
      }
    }

    &.OCBC {
      > img {
        width: 51%;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    height: 120px;
    &.SMU {
      > img {
        width: 35%;
      }
    }

    &.Shopee {
      > img {
        width: 25%;
      }
    }

    &.Paypal {
      > img {
        width: 20%;
      }
    }

    &.BlackRock {
      > img {
        width: 30%;
      }
    }

    &.OCBC {
      > img {
        width: 30%;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    height: 120px;
    &.SMU {
      > img {
        width: 50%;
      }
    }

    &.Shopee {
      > img {
        width: 45%;
      }
    }

    &.Paypal {
      > img {
        width: 45%;
      }
    }

    &.BlackRock {
      > img {
        width: 55%;
      }
    }

    &.OCBC {
      > img {
        width: 55%;
      }
    }
  }
  // #endregion
`;

const ImageContainer = styled.div`
  width: 100%;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    height: 265px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(100% - ${$.layout().padding2}px * 2);
    margin-bottom: ${$.layout().margin4}px;
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const VideoContainer = styled.div`
  width: 100%;
  > iframe {
    width: 100%;
    height: 100%;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    height: 265px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding-bottom: 56.25%;
    width: calc(100% - ${$.layout().margin3}px * 2);
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
    height: 0;
    margin-bottom: ${$.layout().margin4}px;
    position: relative;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
    padding-top: 56.25%;
    position: relative;
    height: 0;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  // #endregion
`;

const Description = styled.div`
  color: ${$.color.blue5};
  line-height: 1.4em;
  font-size: 17px;

  strong {
    font-family: Lato Bold;
  }

  a {
    font-family: Lato Bold;
    text-decoration: none;
    color: ${$.color.blue3};
  }

  ${$.br}

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
  }
  // #endregion
`;

const QuoteCard = styled.div`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    grid-column-start: -1;
    grid-column-end: 1;
    > *:first-child {
      z-index: 2;
      width: 50%;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin2}px;
    > *:first-child {
      margin-bottom: ${$.layout().margin3}px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin2}px;
    > *:first-child {
      margin-bottom: ${$.layout().margin3}px;
    }
  }
  // #endregion
`;

const QuoteContainer = styled.div`
  padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  background-color: ${$.color.blue6};
  width: calc(100% - ${$.layout().padding3}px * 2);

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().padding4}px);
    height: 55%;
    display: inline-block;
    margin: 10% 0;
    box-shadow: ${$.dropShadow.normal};
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-right-radius: ${$.border().radius1}px;
    border-bottom-right-radius: ${$.border().radius1}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-top: ${$.layout().margin4}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-top: ${$.layout().margin4}px;
  }
  // #endregion
`;

const QuoteTitle = styled.h2`
  margin-top: 0;
  color: ${$.color.white};
  font-family: Lato Bold;
  font-size: 24px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 27px;
  }
  // #endregion
`;

const QuoteBy = styled.div`
  font-family: Aileron Bold;
  color: ${$.color.blue3};
  font-size: 17px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 18px;
  }
  // #endregion
`;

const items = Util.addKeys(data);

const Content = () => (
  <Section>
    <Fade bottom distance="100px">
      <Container>
        {items.map(
          ({
            key,
            anchor,
            className,
            logo,
            imageAlt,
            video,
            imageTitle,
            img,
            text,
            quote,
          }) => {
            const card = (
              <Card key={key}>
                <Logo className={`${className} ${anchor}`}>
                  {/.svg$/.test(logo) ? (
                    Util.importOneSVG(logo)
                  ) : (
                    <img
                      src={Util.importOneImage(logo)}
                      alt={imageAlt}
                      title={className}
                    />
                  )}
                </Logo>
                {video && (
                  <VideoContainer>
                    <iframe
                      title={`${className}_video`}
                      src={video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </VideoContainer>
                )}
                {!!img && (
                  <ImageContainer>
                    <img
                      src={Util.importOneImage(img)}
                      alt={imageAlt}
                      title={imageTitle}
                    />
                  </ImageContainer>
                )}
                <Description dangerouslySetInnerHTML={{ __html: text }} />
              </Card>
            );

            if (typeof quote !== 'undefined') {
              return (
                <QuoteCard key={key}>
                  {card}
                  <QuoteContainer>
                    <QuoteTitle>{quote.text}</QuoteTitle>
                    <QuoteBy dangerouslySetInnerHTML={{ __html: quote.by }} />
                  </QuoteContainer>
                </QuoteCard>
              );
            }

            return card;
          }
        )}
      </Container>
    </Fade>
  </Section>
);

export default Content;
