import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: Lato Regular;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Name = styled.div`
  font-size: 14px;
  color: ${$.color.blue4};
`;

const Image = styled.div`
  height: ${$.layout().margin3}px;
  width: ${$.layout().margin3}px;
  position: relative;
  overflow: hidden;
  border-radius: 999px;
  margin-right: ${$.layout().margin4}px;

  &:before {
    content: '';
    position: absolute;
    background: url(${({ profileImage }) => profileImage});
    background-size: cover;
    background-position: center;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
`;

const DetailsContainer = styled.div``;

const Details = styled.div`
  color: ${$.color.gray2};
  font-size: 14px;
`;

const PostDetails = ({
  primaryAuthor: { name, profileImage },
  timeToRead,
  publishedAt,
}) => (
  <Container>
    <Image profileImage={profileImage} />
    <DetailsContainer>
      <Name>{`Published by ${name}`}</Name>
      <Details>
        {new Date(publishedAt).toLocaleDateString('en-GB', {
          year: 'numeric',
          day: 'numeric',
          month: 'short',
        })}
        {` · ${timeToRead} min read`}
      </Details>
    </DetailsContainer>
  </Container>
);

PostDetails.propTypes = {
  primaryAuthor: PropTypes.shape({
    name: PropTypes.string,
    profileImage: PropTypes.string,
  }).isRequired,
  publishedAt: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
};

export default PostDetails;
