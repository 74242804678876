import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Container = styled.div`
  width: 100%;
  overflow: scroll;
  margin-bottom: 30px;
`;

const InnerContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${$.color.gray1};
  overflow: scroll;

  tr {
    border-bottom: 1px solid ${$.color.gray1};
    td {
      padding: 10px;
      font-family: Lato Light;
      font-size: 15px;
      vertical-align: top;
      &:not(:last-child) {
        border-right: 1px solid ${$.color.gray1};
      }
      &.highlight1 {
        font-family: Lato Bold;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        color: ${$.color.white};
        background-color: ${$.proTraining.blue};
      }
      &.highlight2 {
        font-family: Lato Bold;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        color: ${$.color.white};
        background-color: ${$.proTraining.bluegray};
      }
    }
  }

  thead {
    tr {
      td {
        font-family: Lato Bold;
      }
    }
  }
`;

const Table = ({ children }) => (
  <Container>
    <InnerContainer>{children}</InnerContainer>
  </Container>
);

Table.defaultProps = {
  children: <></>,
};

Table.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default Table;
