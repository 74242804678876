import styled from 'styled-components';

import $ from '../../styles/global';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout().padding2}px 0;
`;

const IFrameContainer = styled.div`
  background-color: ${$.color.black};
  margin-bottom: ${$.layout().margin3}px;

  & > iframe {
    width: 100%;
    height: 100%;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 970px;
    height: 546px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 100%;
    height: 480px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    height: 350px;
  }
  // #endregion
`;

const Tagline = styled.h2`
  font-size: 25px !important;
  color: ${$.color.black2};
  font-family: Lato Italic;
  margin-bottom: ${$.layout().margin3}px;
  text-align: center;
`;

const Subtitle = styled.h3`
  font-size: 26px !important;
  font-family: Aileron Heavy !important;
  color: ${$.color.black2};
  margin-bottom: ${$.layout().margin3}px;
`;

const Content = styled.div`
  font-family: Lato Regular;
  font-size: 16px;
  line-height: 1.4em;
  color: ${$.color.black2};
  margin-bottom: ${$.layout().margin2}px;

  ${$.br}

  strong {
    font-family: Lato Bold;
    font-size: 15.5px;
  }

  a {
    text-decoration: none;
    color: ${$.color.blue5};
    font-family: Lato Bold;
    font-size: 15.5px;

    &:visited {
      color: ${$.color.blue5};
    }
  }

  & ol {
    padding-left: 20px;
    & > li {
      margin-bottom: 5px;
    }
  }
`;

const Details = () => (
  <Section>
    <Container>
      <Subtitle>Competition Results</Subtitle>
      <Content>
        After going through the intense scoring of 7 SGCC judges, we are excited
        to announce the winners of the first ever SGCC Summer Scratch
        Competition!
      </Content>
      <Subtitle>Lower Primary:</Subtitle>
      <Content>
        <ol>
          <li>
            <p>Mugdha Akkinapragada (Playdate)</p>
            <IFrameContainer>
              <iframe
                src="https://scratch.mit.edu/projects/560739238/embed"
                allowtransparency="true"
                width="485"
                height="402"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                title="mugdha akkinapragada project"
              />
            </IFrameContainer>
          </li>
          <li>
            <p>Hebe Lim (Holiday Letter)</p>
            <IFrameContainer>
              <iframe
                src="https://scratch.mit.edu/projects/560739420/embed"
                allowtransparency="true"
                width="485"
                height="402"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                title="hebe lim project"
              />
            </IFrameContainer>
          </li>
          <li>
            <p>Choong Jun Rong (Holidays At Home)</p>
            <IFrameContainer>
              <iframe
                src="https://scratch.mit.edu/projects/560739556/embed"
                allowtransparency="true"
                width="485"
                height="402"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                title="choong jun rong project"
              />
            </IFrameContainer>
          </li>
        </ol>
      </Content>
      <Subtitle>Upper Primary:</Subtitle>
      <Content>
        <ol>
          <li>
            <p>Tan You Ren (Holidays At Home)</p>
            <IFrameContainer>
              <iframe
                src="https://scratch.mit.edu/projects/560739817/embed"
                allowtransparency="true"
                width="485"
                height="402"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                title="tan you ren project"
              />
            </IFrameContainer>
          </li>
          <li>
            <p>Keith Tham (Holidays Learning At Home)</p>
            <IFrameContainer>
              <iframe
                src="https://player.vimeo.com/video/587675830"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="keith tham project"
              />
            </IFrameContainer>
          </li>
          <li>
            <p>Song Wen Xuan (Holidays At Home)</p>
            <IFrameContainer>
              <iframe
                src="https://player.vimeo.com/video/587676139"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="song wen xuan project"
              />
            </IFrameContainer>
          </li>
        </ol>
      </Content>
      <Tagline>Find out more about the competition in the video below!</Tagline>
      <IFrameContainer>
        <iframe
          title="Sample snippet of our online lesson"
          src={`https://player.vimeo.com/video/547847344`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </IFrameContainer>
      <Subtitle>Competition Details:</Subtitle>
      <Content>
        {`Please find the competition details in the `}
        <a href="/sgcc-junior-scratch-competition-2021-details.pdf">
          pdf file here
        </a>
        {` or see below for the nitty gritty.`}
        <br />
        <ol>
          <li>
            Eligibility: Students studying in a school in Singapore, P6/Grade 6
            and below.
          </li>
          <li>
            There will be two categories: Lower Primary (P1-3 / Grade 1-3) and
            Upper Primary (P4-6 / Grade 4-6)
          </li>
          <li>
            Prizes: Top 3 positions for each category will win:
            <div>#1 Resorts World vouchers ($100)</div>
            <div>#2 Swensens vouchers ($50)</div>
            <div>#3 Apple App Store / Google Play Store vouchers ($20)</div>
          </li>
          <li>
            {`Theme: `}
            <strong>Holidays at Home</strong>
          </li>
          <li>
            Task: Create a STORY / ANIMATION addressing the theme using Scratch
          </li>
          <li>
            {`Submissions to be made to `}
            <a
              href="mailto:summerscratch2021@sgcodecampus.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              summerscratch2021@sgcodecampus.com
            </a>
            {`. Submissions should include:`}
            <br />
            <ol type="a">
              <li>
                Name of participant, School, Birth Year, Parent&apos;s name,
                handphone and email, coding background
              </li>
              <li>
                The scratch project as a .sb3 file, named in the following
                format: CATEGORY_NAME_PROJECTNAME.sb3
              </li>
              <li>
                A presentation of the project through a recorded video of not
                more than 3 minutes (preferably .mp4 file), named in the
                following format: CATEGORY_NAME_PROJECTNAME.extension (Note:
                total size of attachments needs to be &lt; 25 MB to be sent
                through email)
              </li>
            </ol>
          </li>
          <li>
            {`Entries will close `}
            <strong>31 July 2021, 2359h</strong>
          </li>
        </ol>
      </Content>
      <Subtitle>Judging Criteria:</Subtitle>
      <Content>
        <ol type="1">
          <li>Relevance - 10%</li>
          <li>Code - 30%</li>
          <li>Design - 10%</li>
          <li>Creativity - 20%</li>
          <li>Presentation - 30%</li>
        </ol>
      </Content>
      <Subtitle>Presentation Guidelines:</Subtitle>
      <Content>
        The presentation video should show the participant clearly at all times
        and cover the following points:
        <ol type="1">
          <li>How participants came up with their idea</li>
          <li>Explain what their code does</li>
          <li>
            Explain any difficulties encountered along the way and how they were
            solved
          </li>
        </ol>
      </Content>
      <Subtitle>Rules:</Subtitle>
      <Content>
        <ol type="1">
          <li>
            Entries must be original. Participants may look at other projects to
            get ideas, but the should NOT copy code or remix projects.
          </li>
          <li>
            Projects should not require an additional device (e.g. Makey Makey,
            micro:bit, LEGO)
          </li>
          <li>Each participant is only allowed ONE entry.</li>
          <li>
            {`The deadline for entries is `}
            <strong>31 July 2021, 2359h</strong>
          </li>
          <li>
            Projects may be showcased by SGCC (and credited to the creators)
          </li>
          <li>Decisions made by SGCC will be final</li>
        </ol>
      </Content>
    </Container>
  </Section>
);

export default Details;
