import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section``;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.black};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  font-family: Lato Bold;
  font-size: 18px;
  color: ${$.color.black};
  margin-bottom: ${$.layout().margin3}px;
`;

const ImageContainer = styled.div`
  margin-bottom: ${$.layout().margin2}px;
`;

const Quote = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > * {
    color: #3fa2ee;
    font-size: 24px;
    line-height: 1.3em;
    font-family: Lato Italic;
    &:first-child,
    &:last-child {
      font-size: 50px;
      font-family: Lato Bold;
      line-height: 1em;
    }
    &:not(:last-child) {
      margin-right: ${$.layout().margin4}px;
    }
  }

  strong {
    font-family: Lato Bold;
  }
`;

const SGCCLogoContainer = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  & > svg {
    width: 80px;
  }
`;

const AWSPartnerLogoContainer = styled.div`
  margin-top: ${$.layout().margin2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    width: 100px;
  }
`;

const WhyAWS = () => (
  <Section>
    <Container>
      <Title>Why AWS</Title>
      <Subtitle>
        AWS is by far the world&apos;s largest cloud provider (source: Statista
        2022)
      </Subtitle>
      <ImageContainer>
        <StaticImage
          src="../../../assets/images/tertiary/aws/aws-graph.png"
          alt="AWS graph"
        />
      </ImageContainer>
      <Quote>
        <div>&quot;</div>
        <div>
          The
          <em>
            <strong> AWS Cloud Practitioner </strong>
          </em>{' '}
          credential is #8 on Forbes&apos; Top Paying IT Certifications 2020
          list and consistently ranked as a top IT Certification year after year
          on CIO.com, PC Mag, Global Knowledge, US News
        </div>
        <div>&quot;</div>
      </Quote>
    </Container>
  </Section>
);

export default WhyAWS;
