import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import items from '../items.json';

const Container = styled.div`
  pointer-events: none;
  background-color: ${$.color.white};
  height: 0;
  opacity: 0;
  width: 100vw;
  border-bottom: 1px solid transparent;
  position: absolute;
  top: 88px;
  left: 0;
  will-change: height, opacity;
  transition: opacity 0.3s ${$.easingFn.standard},
    height 0.3s ${$.easingFn.standard};

  > * {
    transition: opacity 0.3s ${$.easingFn.standard};
    opacity: 0;
  }

  &.show {
    opacity: 1;
    height: 280px;
    border-bottom: 1px solid ${$.color.gray1};
    pointer-events: initial;
    > * {
      opacity: 1;
    }
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const InnerContainer = styled.div`
  width: 1100px;
  position: relative;
  padding: ${$.layout().padding2}px;
  height: calc(100% - ${$.layout().padding2 * 2}px);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: repeat(2, minmax(52px, auto));
  grid-column-gap: 30px;
  grid-row-gap: 20px;
`;

const NavbarTitle = styled.div`
  font-family: Lato Regular;
  font-size: 15px;
  margin-bottom: ${$.layout().margin5}px;
  color: ${$.color.black};
`;

const NavbarSubtitle = styled.div`
  font-family: Lato Light;
  hyphens: auto;
  font-size: 13.5px;
  color: ${$.color.black};
`;

const StyledLink = styled(({ link, className, onClick, children }) => {
  if (link.indexOf('http') > -1) {
    return (
      <a
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={link} className={className} onClick={onClick}>
      {children}
    </Link>
  );
})`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${$.color.black};
  }
`;

const links = {};

items.forEach(({ subLinks, footerItem }) => {
  links[footerItem] = Utils.addKeys(subLinks);
});

const HeroBanner = ({ tab, setTab, setHeroActive }) => {
  const [show, setShow] = useState(false);

  /**
   * Delays the showing of the herobanner.
   * This is to prevent the herobanner content from flashing/snapping into.
   * 1. Update the herobanner content.
   * 2. 100ms later, show the herobanner.
   */
  useEffect(() => {
    let timeout = '';

    if (tab) {
      timeout = setTimeout(() => {
        setShow(tab !== '');
      }, 100);
    } else {
      setShow(false);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [tab]);

  return (
    <Container className={show ? 'show' : ''}>
      <InnerContainer
        total={(links[tab] || []).length}
        onMouseLeave={() => {
          setHeroActive(false);
        }}
      >
        {(links[tab] || []).map(
          ({ navbarSubText, navbarSubtitle, subLink, key }) => (
            <StyledLink
              onClick={() => {
                setTab('');
                setHeroActive(false);
              }}
              link={subLink}
              key={key}
            >
              <NavbarTitle>{navbarSubText}</NavbarTitle>
              {navbarSubtitle && (
                <NavbarSubtitle>{navbarSubtitle}</NavbarSubtitle>
              )}
            </StyledLink>
          )
        )}
      </InnerContainer>
    </Container>
  );
};

HeroBanner.defaultProps = {
  tab: '',
  setTab: () => {},
  setHeroActive: () => {},
};

HeroBanner.propTypes = {
  tab: PropTypes.string,
  setTab: PropTypes.func,
  setHeroActive: PropTypes.func,
};

export default HeroBanner;
