import Seo from '../../components/seo';
import BigData from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/BigData';

const BigDataPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <BigData />
  </>
);

export default BigDataPage;
