import { StaticImage } from 'gatsby-plugin-image';
import Utils from '../../../utils';

const sunChang = {
  name: 'Sun Chang',
  image: (
    <StaticImage
      alt="sun chang trainer image"
      src="../../../assets/images/professional-training/trainers/sunchang.jpg"
    />
  ),
  qualification: Utils.addKeys([{ text: 'Senior Data Scientist, Grab' }]),
  story: (
    <>
      <p>
        Sun Chang holds a PhD in Computational Engineering from Singapore-MIT
        Alliance at the National University of Singapore (NUS) and a
        Bachelor&apos;s degree in Applied Mathematics, where he was awarded
        First Class Honours by the NUS Faculty of Science.
      </p>
      <p>
        A full-time Senior Data Scientist in Grab, Sun Chang deals extensively
        in the areas of Database management, Data Analytics, Data Visualization,
        Model Training, Big Data and Cloud Computing. He has also previously
        worked as a technical consultant providing AI solutions to hospitals and
        manufacturing industries. Sun Chang believes that the rare combination
        of his scholastic background, paired with a wealth of practical
        experience across a spectrum of diverse industries empowers him to
        provide his students with the ideal balance of theory and practicum in
        the classroom.
      </p>
    </>
  ),
};

const philipBeh = {
  name: 'Philip Beh',
  image: (
    <StaticImage
      alt="philip beh trainer image"
      src="../../../assets/images/professional-training/trainers/philipbeh.jpg"
    />
  ),
  qualification: Utils.addKeys([{ text: 'Software Developer, TEKsystems' }]),
  story: (
    <>
      <p>
        Philip holds a Master of Science in Computing from the National
        University of Singapore and a Bachelor of Computer Science from the
        University of Malaya. He has worked as a software engineer in Singapore
        and Malaysia and was involved in in a range of projects including
        backend applications used by telecommunications providers (eg. Maxis,
        Digi, Celcom), web applications used in the Malaysian banking sector and
        embedded software running on network recorders collecting traffic video
        data used by the Land Transport Authority of Singapore (LTA). As a
        contracted developer at TEKsystems, he is currently deployed at
        Facebook, where he works on product systems automation.
      </p>
      <p>
        Philip believes that as technology becomes increasingly entrenched in
        our everyday lives, a practical education in programming and data
        science will become more important to every individual regardless their
        age or stage in career. He seeks to tap into his software engineering
        experience and academic background in computer science to bring this
        learning to all his students.
      </p>
    </>
  ),
};

const huangBin = {
  name: 'Huang Bin',
  image: (
    <StaticImage
      alt="huang bin trainer image"
      src="../../../assets/images/professional-training/trainers/huangbin.jpg"
    />
  ),
  qualification: Utils.addKeys([{ text: 'Instructor, SG Code Campus' }]),
  story: (
    <>
      <p>
        Huang Bin holds a Master of Science in Computation for Design and
        Optimization from the Massachusetts Institute of Technology. On
        completion of his Bachelor&apos;s degree in Computer Engineering, he was
        awarded a First Class Honors by NUS School of Computing and the IEEE
        Gold Medal (Top student in Computer Engineering), and was honoured to be
        picked as the reserve Valedictorian for his 2009 cohort. Huang Bin has
        extensive experience in teaching and tutoring which enables him to
        communicate technical details to a wide audience.
      </p>
      <p>
        During his time at Barclays, he was a C++ programmer with previous
        experience of C#, Java and C. Using his expertise, he designed and
        implemented IT solutions covering trade flow, data analytics and risk
        control of the award-winning trading platform.
      </p>
    </>
  ),
};

const germaine = {
  name: 'Germaine',
  image: (
    <StaticImage
      alt="germaine-trainer-image"
      src="../../../assets/images/professional-training/trainers/germaine.jpg"
    />
  ),
  qualification: Utils.addKeys([{ text: 'Head, Curriculum, SG Code Campus' }]),
  story: (
    <>
      <p>
        Germaine holds a Ph.D. in Bioengineering from the University of
        California, Berkeley, and was a recipient of the prestigious A*STAR and
        EDB academic scholarships. She was a senior research fellow at A*STAR,
        where she applied her technical skills to practical use in
        bioinformatics, biostatistics, data analysis and robotics, with
        publications in a variety of fields. She has since returned to teaching
        and education with SG Code Campus where she serves as the Head of
        Curriculum development, overseeing the structural and curriculum
        development for the school&apos;s entire course roadmap including python
        courses, as well as instructional training and quality.
      </p>
      <p>
        Germaine has taught in various contexts from individual tutoring to full
        classrooms, and fellow scientists. She believes that the key to
        engineering solutions to the world&apos;s most complex problems is a
        multi-faceted approach where hard engineering skills are paired with an
        awareness and empathy for the people whose lives we seek to impact. It
        is this ethos that Germaine seeks to instill in every student.
      </p>
    </>
  ),
};

const ian = {
  name: 'Ian Choo',
  image: (
    <StaticImage
      alt="ian trainer image"
      src="../../../assets/images/professional-training/trainers/ian.jpg"
    />
  ),
  qualification: Utils.addKeys([{ text: 'Founder, SG Code Campus' }]),
  story: (
    <>
      <p>
        Ian holds a Master of Science in Management Science & Engineering from
        Stanford University and a Bachelor of Arts in Applied Mathematics &
        Economics from the University of California, Berkeley. His love for
        computing began when he dived into the study of Computational
        Engineering and Machine Learning over three years as a graduate student
        at Stanford. After graduating, he took on a role as a quant risk manager
        at Barclays Investment Bank, where he got to further sharpen his craft
        data-munging reams of financial data, programming risk engines and
        coding up financial models in Python, R, VB.NET and the NAG numerical
        library (FORTRAN).
      </p>
      <p>
        As founder of SG Code Campus, Ian has designed and taught curricula in
        Web and Mobile programming, Cloud Computing, Data Science and Machine
        Learning to a variety of audiences including kids, youths, Polytechnic,
        University students, as well as working professionals. He often spends
        his days working to improve his teaching and better engage learners
        through a different teaching approaches and course formats.
      </p>
    </>
  ),
};

const huiLiang = {
  name: 'Lui Huiliang',
  image: (
    <StaticImage
      alt="huiliang trainer image"
      src="../../../assets/images/professional-training/trainers/huiliang.png"
    />
  ),
  qualification: Utils.addKeys([
    { text: 'Chief Product Officer, SG Code Campus' },
  ]),
  story: (
    <>
      <p>
        Huiliang holds a Masters of Science and Bachelor of Science (Honors)
        from the University of California, Berkeley, in Mechanical Engineering
        (with a Minor in Industrial Engineering and Operations Research) and was
        a DSTA (Defence Science and Technology Agency) Overseas Scholarship
        holder. Upon graduation, he joined the DSTA, where he was a project
        manager overseeing the acquisition of modelling and simulation systems
        for training the SAF.
      </p>
      <p>
        Huiliang currently helms the technology department at Indigo Education
        Group, where his team uses Python and modern data analytics to extract
        actionable insights from student enrolment trends and demographic data.
        He also manages Indigo&apos;s online learning platform where he
        spearheads the design, development and integration of ERP and CRM
        applications for the smooth running of operations. As an educator with
        an infectious passion for math, science and engineering, Huiliang
        believes that programming aptitude is born of interest, and seeks to
        inspire his students through interesting real-life applications of
        computer science.
      </p>
    </>
  ),
};

const natasha = {
  name: 'Natasha Waliany',
  image: (
    <StaticImage
      alt="natasha trainer image"
      src="../../../assets/images/professional-training/trainers/natasha.png"
    />
  ),
  qualification: Utils.addKeys([
    { text: 'Instructor, SG Code Campus' },
    { text: 'Certified Apple Professional Learning Provider' },
  ]),
  story: (
    <>
      <p>
        Natasha is a certified Apple Professional Learning Provider at SG Code
        Campus, an official Apple Consultants Network Partner organisation. She
        graduated with a Master of Science (Distinction) in Computer Science
        from the University of Hong Kong with a 4.0 GPA, and a Bachelors of Arts
        in Mathematics and Science (Great Distinction) from McGill university.
        Her experience as a software engineer ranges from working with startups
        and multinationals in Singapore and USA including IBM where she worked
        on data quality management solutions that helped deliver quality data
        for big data, business intelligence, data warehousing, application
        migration and master data management projects.
      </p>
      <p>
        She has experience leading software teams at ST Electronics&apos;
        i-Learning division and founded 2 startups including an iPhone app
        development company, where she developed the MakanSutra iOS app.
      </p>
    </>
  ),
};

const terence = {
  name: 'Terence Tan',
  image: (
    <StaticImage
      alt="natasha trainer image"
      src="../../../assets/images/professional-training/trainers/terence.jpg"
    />
  ),
  qualification: Utils.addKeys([{ text: 'Instructor, SG Code Campus' }]),
  story: (
    <>
      <p>
        Terence has a wealth of experience amassed in various sectors including
        banking and finance at OCBC and Standard Chartered banks, logistics, and
        IT. He has had roles in both Infrastructure Support as well as
        Application Development, and was Director of IT at vCargo Cloud. His
        past programming experience includes C and Java. During his career, he
        helmed the design and development of one of the first push-enabled
        Internet Trading platforms used by multiple Online Equities Trading
        providers.
      </p>
      <p>
        He also led the design and development of a Tradenet-equivalent product
        using C and Open Systems, used internationally in multiple countries as
        their Customs Single Window. He currently teaches and also provides
        consultancy on IT governance and security. He holds a Master of Science
        in Advanced Methods in Computer Science from the University of London,
        QMW. Terence passionately believes in life-long learning and has been
        keeping himself up to date with new developments in fields including
        Data Science, Artificial Intelligence and Cloud Computing.
      </p>
    </>
  ),
};

const yongYang = {
  name: 'Yong Yang Chung',
  image: (
    <StaticImage
      alt="yong yang trainer image"
      src="../../../assets/images/professional-training/trainers/yongyang.jpg"
    />
  ),
  qualification: Utils.addKeys([
    {
      text: 'Technical Programme Manager, Instructor & Curriculum Developer, SG Code Campus',
    },
  ]),
  story: (
    <>
      <p>
        Yong Yang holds a Bachelor&apos;s in Mechanical Engineering (2nd Class
        Honours, Upper Division) from Nanyang Technological University. As a
        Senior Instructor with SG Code Campus, he is experienced in technical
        education, having taught a wide range of topics including Python
        programming, web development, cloud computing, and data science, to a
        variety of audiences including adult learners from the finance industry
        and government organizations. He has also created teaching curricula,
        managed technical project streams, architected the company&apos;s CRM
        and backend sales system on the Salesforce cloud, and worked on UI/UX
        website design.
      </p>
      <p>
        Yong Yang believes in keeping up to date with technical developments and
        holds many professional certifications including the WSQ Advanced
        Certificate in Learning and Performance (ACLP) as well as certifications
        from AWS and Google—the AWS Cloud Practitioner, AWS Solutions Architect
        - Associate, AWS SysOps administrator - Associate; Google Project
        Management, GCP Associate Cloud Engineer and GCP Professional Cloud
        Architect.
      </p>
    </>
  ),
};

export {
  sunChang,
  huangBin,
  germaine,
  ian,
  huiLiang,
  natasha,
  yongYang,
  philipBeh,
  terence,
};
