import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import Util from '../../utils';
import DownChevron from '../../assets/icons/down-chevron.svg';

const Container = styled.div`
  ${({ index }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-area: ${`bubble_${index}`};
  cursor: pointer;
  user-select: none;
  position: relative;
`}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 9999px;
  font-family: Aileron Heavy;
  font-size: 23px;
  background-color: rgba(117, 117, 117, 0.5);
  color: ${$.color.white};
  transition: opacity 0.5s ${$.easingFn.standard},
    background-color 0.5s ${$.easingFn.standard};

  > span {
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 999px;
  overflow: hidden;
  display: block;
  text-decoration: none;
  margin-bottom: ${$.layout().margin3}px;
  position: relative;
  box-shadow: ${$.dropShadow.normal};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 999px;
    mask-image: radial-gradient(#fff, #000);
    transition: filter 0.5s ${$.easingFn.standard};
  }

  &:hover ${Overlay} {
    opacity: 1;
  }

  &:hover img {
    filter: blur(10px);
  }
`;

const StyledChevron = styled(DownChevron)`
  position: absolute;
  fill: ${$.color.blue4};
  width: 20px;
  height: 20px;
  top: 190px;
`;

const Bubble = ({
  index,
  currentBubble,
  setCurrentBubble,
  dropdownState,
  setDropdownState,
  bubble: { overlayText, avatarURL, children, anchor },
}) => {
  Util.useAnchor(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const hasAnchor = searchParams.get('anchor');
    if (anchor === hasAnchor) {
      setTimeout(() => {
        setCurrentBubble(index);
        setDropdownState(true);
      }, 800);
    }
  });

  return (
    <Fade bottom fraction={0.3} distance="100px">
      <Container
        className={`bubble-gridview ${anchor}`}
        index={index}
        onClick={() => {
          // Switched bubble while dropdown is open
          if (currentBubble !== index && dropdownState === true) {
            setDropdownState(false);
            setTimeout(() => {
              setCurrentBubble(index);
              setDropdownState(true);
            }, 499);
            return;
          }

          // Switch bubbles if clicked on other bubbles
          if (currentBubble !== index) {
            setCurrentBubble(index);
          }

          // Toggle the dropdown
          setDropdownState((prev) => !prev);
        }}
      >
        <ImageContainer>
          <img src={avatarURL} alt={`${anchor} profile img`} />
          <Overlay>
            <span>{overlayText}</span>
          </Overlay>
        </ImageContainer>
        <StyledChevron />
        {children}
      </Container>
    </Fade>
  );
};

Bubble.defaultProps = {
  index: 0,
  bubble: {
    avatarURL: '',
    overlayText: '',
    children: <></>,
    anchor: '',
  },
  currentBubble: null,
  setCurrentBubble: () => {},
  dropdownState: false,
  setDropdownState: () => {},
};

Bubble.propTypes = {
  bubble: PropTypes.shape({
    overlayText: PropTypes.string,
    avatarURL: PropTypes.string,
    children: PropTypes.element,
    anchor: PropTypes.string,
  }),
  index: PropTypes.number,
  currentBubble: PropTypes.number,
  setCurrentBubble: PropTypes.func,
  dropdownState: PropTypes.bool,
  setDropdownState: PropTypes.func,
};

export default Bubble;
