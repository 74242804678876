import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { RemoteLink, Margin20 } from '../Misc';
import TrainerProfile from '../TrainerProfile';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import FundingTable from '../SMUReusableComponents/FundingTable';
import InfoTable from '../SMUReusableComponents/InfoTable';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';
import Testimonial from '../Testimonial';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Topics',
  },
  {
    text: 'Who Should Attend',
    threshold: 0.1,
  },
  {
    text: 'Fees And Funding',
  },
  {
    text: 'Schedule',
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Fundamental concepts in programming',
  },
  {
    text: 'Use of functions and data structures in Python',
  },
  {
    text: 'Object-oriented programming paradigm',
  },
  {
    text: 'Basic data wrangling in Python',
  },
  {
    text: 'Applied data analysis using Python',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'Aspiring data science professionals seeking to apply Python to real-world data problems e.g. business intelligence analysts, data engineers',
  },
  {
    text: 'Anyone with an interest in learning about the fundamentals of data science programming',
  },
  {
    text: 'Professionals looking for a deeper understanding and hands-on experience with professional educators who are also industry experts',
  },
]);

const trainers = Utils.addKeys([
  Profiles.germaine,
  Profiles.ian,
  Profiles.huangBin,
  Profiles.natasha,
  Profiles.sunChang,
  Profiles.huiLiang,
  Profiles.yongYang,
]);

const applyNowLink =
  '/professional-training/apply-now/?c=Professional Certificate in Python Programming';

const enquiryLink =
  'https://academy.smu.edu.sg/programme-enquiry?course=Professional%20Certificate%20in%20Python%20Programming&nid=3266';

const PCPP = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="Professional Certificate in Python Programming"
      />
      <MobileNavigationMenu
        items={navigationLinks}
        applyNowLink={applyNowLink}
        enquiryLink={enquiryLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              Explore one of the most versatile, popular and free-to-use
              open-source programming languages and learn how Python can
              optimise your digital solution development cycle and workflow with
              SMU&apos;s Professional Certificate in Python Programming.
            </p>
            <p>
              Python is a first-class programming language for modern web and
              mobile development and is used by companies like Instagram,
              Spotify, Amazon and more. Python is also ideal for data analysis
              as the majority of libraries involved in machine learning and data
              science have Python interfaces.
            </p>
            <Margin20 />
            <InfoTable
              venue="Online"
              starts="11 Jul 2023 (Tue)"
              fees="SGD10386.00* (as low as SGD1190.40* after maximum funding)"
              duration={
                <>
                  Please refer to respective modules for dates.
                  <br />
                  Weeknights (7pm - 10:30pm)
                  <br />
                  Saturday (9am - 6:00pm)
                </>
              }
              level="Basic"
            />
            <h3>Learning Objectives</h3>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            <p>
              To achieve the Professional Certificate in Python Programming,
              participants will need to complete the following modules offered
              by SMU Academy in sequential order:
            </p>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-python-programming-basics-python-programming-3271">
                Basics of Python Programming
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-python-programming-data-structures-python-3276">
                Data Structures in Python
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-python-programming-advanced-functions-and-data-structures-3281">
                Advanced Functions and Data Structures in Python
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-python-programming-object-oriented-programming-3286">
                Object-Oriented Programming in Python
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-python-programming-basic-data-wrangling-python-3291">
                Basic Data Wrangling in Python
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-python-programming-capstone-project-data-analytics-3296">
                Capstone Project in Data Analytics
              </RemoteLink>
            </p>
            <p>
              This course is also a part of the{' '}
              <RemoteLink href="https://academy.smu.edu.sg/advanced-diploma-python-programming-and-applied-data-analytics-4731">
                Advanced Diploma in Python Programming and Applied Data
                Analytics
              </RemoteLink>{' '}
              and{' '}
              <RemoteLink href="https://academy.smu.edu.sg/advanced-diploma-python-programming-and-machine-learning-3331">
                Advanced Diploma in Python Programming and Machine Learning
              </RemoteLink>
              .
            </p>
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
            <Margin20 />
            <Margin20 />
            <h3>System Requirements</h3>
            <p>
              Functional Laptop: <br />
              (1) CPU must be of at least Intel Core I3
              <br />
              (2) GPU must have an integrated graphics card
              <br />
              (3) RAM must be of at least 4GB
            </p>
          </TextSection>
          <TextSection>
            <h2>Assessment</h2>
            <p>An assessment will be conducted at the end of the course.</p>
            <Margin20 />
            <Margin20 />
            <h3>Certification</h3>
            <p>
              Upon completion of all 6 modules within a maximum duration of 3
              years, participants will be awarded a digital Certificate in
              Professional Certificate in Python Programming.
            </p>
          </TextSection>
          <TextSection position={3}>
            <h2>Fees And Funding</h2>
            <FundingTable
              firstRow={[
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="http://www.ssg.gov.sg/programmes-and-initiatives/funding/enhanced-training-support-for-smes1.html">
                    ETSS Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
              ]}
              secondRow={[
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
              ]}
              thirdRow={[
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
              ]}
            />
          </TextSection>
          <TextSection position={4}>
            <h2>Schedule</h2>
            <p>
              <strong>Start Date(s)</strong>
              <br />
              11 Jul 2023 (Tue)
            </p>
            <p>
              <strong>Intake Information</strong>
            </p>
            <Table>
              <thead>
                <tr>
                  <td className="highlight2">Modules</td>
                  <td className="highlight2">Intake 32</td>
                  <td className="highlight2">Intake 33</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight1">Basics of Python Programming</td>
                  <td>
                    6, 8 & 10 Jun 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      11, 13 & 15 Jul 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">Data Structures in Python</td>
                  <td>
                    4, 6 & 8 Jul 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      1, 3 & 5 Aug 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    Advanced Functions And Data Structures
                  </td>
                  <td>
                    18, 20 & 22 Jul 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      22, 24 & 26 Aug 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">Object-Oriented Programming</td>
                  <td>
                    8, 10 & 12 Aug 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      12, 14 & 16 Sep 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">BASIC DATA WRANGLING IN PYTHON</td>
                  <td>
                    29, 31 Aug & 2 Sep 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      3, 5 & 7 Oct 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    CAPSTONE PROJECT IN DATA ANALYTICS
                  </td>
                  <td>
                    19, 21 & 23 Sep 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      24, 26 & 28 Oct 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection>
            <h2>Policies</h2>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                View programme policies.
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={5}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
          <TextSection>
            <h2>Testimonial</h2>
            <Testimonial
              name="Weilun Liang, William"
              title="Senior Technical Services Engineer, Singapore Airlines"
            >
              <h3>
                I appreciate how detailed and dedicated the lecturers, Dr Chang
                Sun, Bin Huang and Natasha Waliany are, and how
                beginner-friendly they have made the course to be.
              </h3>
              <p>
                It all started with my curiosity about the current data boom and
                wanting to keep up with the industry trends. Today, I am
                delighted to share that I have successfully achieved the
                Advanced Diploma in Applied Data Analytics (Python) from SMU
                Academy!
              </p>

              <p>
                Along with my Capstone Project mates, I enjoyed the process of
                using data analytics to analyse crime trends in Washington DC
                and the opportunity to explore different ensemble learning
                methods for classification, regression, and prediction during
                our training!
              </p>
            </Testimonial>
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default PCPP;
