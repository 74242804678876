import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { Margin20, RemoteLink, ContentImage } from '../Misc';
import TrainerProfile from '../TrainerProfile';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import InfoTable from '../SMUReusableComponents/InfoTable';
import FundingTable from '../SMUReusableComponents/FundingTable';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Topics',
  },
  {
    text: 'Who Should Attend',
    threshold: 0.1,
  },
  {
    text: 'Fees And Funding',
  },
  {
    text: 'Schedule',
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Introduction to design thinking, prototyping and cloud architecting in the cloud development lifecycle',
  },
  {
    text: 'To learn with lab hands-on architecting cloud applications with core AWS services',
  },
  {
    text: 'Introduced to the Test-Driven Development (TDD) approach to software design and development using AWS cloud services',
  },
  {
    text: 'Guided to utilise Continuous Integration, Delivery and Deployment (CI/ CD) on AWS',
  },
  {
    text: 'Trained using a roadmap implementing DevOps through the AWS Cloud',
  },
  {
    text: 'To apply knowledge gained from prior modules in a group-based capstone project in designing, architecting and implementing a DevOps workflow using AWS Tools and Services.',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: (
      <>
        Participants are expected to have software programming skills (web or
        mobile) equivalent to that covered in the{' '}
        <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-7041">
          Advanced Certificate in AWS Cloud Services and Technicals
        </RemoteLink>
        ,{' '}
        <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-web-application-development-4126">
          Professional Certificate in Web Application Development
        </RemoteLink>{' '}
        or{' '}
        <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-mobile-application-development-4081">
          Professional Certificate in Mobile Application Development
        </RemoteLink>
        {` `}programmes.
      </>
    ),
  },
  {
    text: 'Some basic familiarity with the AWS Cloud is preferred but not required.',
  },
  {
    text: 'The course also prepares learners looking to prepare for the AWS Certified Developer and AWS SysOps Administrator Associate Certification exams.',
  },
]);

const trainers = Utils.addKeys([
  Profiles.ian,
  Profiles.huiLiang,
  Profiles.yongYang,
]);

const applyNowLink =
  '/professional-training/apply-now/?c=Advanced Certificate in AWS Cloud Architecting and DevOps';

const enquiryLink =
  'https://academy.smu.edu.sg/programme-enquiry?course=Advanced%20Certificate%20in%20AWS%20Cloud%20Architecting%20and%20DevOps&nid=7236';

const ACACAD = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="Advanced Certificate in AWS Cloud Architecting and DevOps"
      />
      <MobileNavigationMenu
        items={navigationLinks}
        applyNowLink={applyNowLink}
        enquiryLink={enquiryLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              Want to gain the skills required to capitalise on global
              opportunities in the brisk job market for AWS Cloud talent? Look
              no further as this programme will teach you how to design and
              utilise functionality on Amazon Web Services, the world&apos;s
              largest provider of Cloud Services (AWS).
            </p>
            <p>
              Whether you are a Business Analyst, Technical Project Manager or
              Developer, the experience gained in this course will supercharge
              your career through a thorough, practical and immersive learning
              journey with highly qualified technology professionals and
              AWS-certified technical trainers from top universities, companies
              and organisations.
            </p>
            <p>
              It serves as an introduction to architecting, developing and
              maintaining software applications on the AWS Cloud.
            </p>
            <p>
              This programme is designed to follow on from the{' '}
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-7041">
                Advanced Certificate in AWS Cloud Services and Technicals
                programme
              </RemoteLink>
              .
            </p>
            <Margin20 />
            <InfoTable
              venue="On-campus"
              starts="13 Jun 2023 (Tue)"
              fees="SGD10368.00* (as low as SGD1190.40* after maximum funding)"
              duration={
                <>
                  {' '}
                  Please refer to respective modules for dates. <br />
                  Weeknights (7pm - 10:30pm)
                  <br />
                  Saturday (9am - 1:30pm)
                </>
              }
              level="Basic"
            />
            <h3>Programme Partner</h3>
            <p>
              <strong>Supported by:</strong>
              <Margin20 />
              <ContentImage
                width={225}
                height={100}
                path="landing/aws_academy_member_institution.png"
              />
              <Margin20 />
            </p>
            <h3>Learning Objectives</h3>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            <p>
              To achieve the Advanced Certificate in AWS Cloud Architecting and
              DevOps, participants will need to complete the following modules
              offered by SMU Academy:
            </p>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-architecting-and-devops-module-1-designing-and-prototyping-cloud">
                Module 1: Designing and Prototyping Cloud Apps
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-architecting-and-devops-module-2-architecting-aws-cloud-7251">
                Module 2: Architecting the AWS Cloud Module
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-architecting-and-devops-module-3-test-driven-development-7256">
                Module 3: Test Driven Development
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-architecting-and-devops-module-4-continuous-integration-delivery-and">
                Module 4: Continuous Integration, Delivery and Deployment on AWS
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-architecting-and-devops-module-5-devops-aws-7266">
                Module 5: DevOps on AWS
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-architecting-and-devops-module-6-practical-lab-developing-7271">
                Module 6: Practical Lab in Developing Applications on AWS
              </RemoteLink>
            </p>
            <p>This course is also a part of the following programmes:</p>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/advanced-diploma-cloud-management-and-applications-7756">
                Advanced Diploma in Cloud Management and Applications
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-diploma-multiplatform-mobile-app-development-and-cloud-management-7886">
                Advanced Diploma in Multiplatform Mobile App Development and
                Cloud Management
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-diploma-full-stack-web-app-development-and-cloud-management-7961">
                Advanced Diploma in Full Stack Web App Development and Cloud
                Management
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
            <Margin20 />
            <Margin20 />
            <h3>Targeted Jobs:</h3>
            <p>
              AWS Cloud Architect, Solutions Architect, SysOps Administrator,
              Cloud DevOps Engineer, Systems Engineer, Business Analyst, and
              Cloud Consultant, Cloud Developer, Cloud Sales & Purchase Manager,
              Key Account Manager (Cloud), AWS Networking Specialist, Cloud
              Software Engineer, AWS Big Data Specialist
            </p>
            <h3>Prerequisites</h3>
            <ul>
              <li>Participants with at least a diploma qualification</li>
              <li>
                Ability to code in modern object-oriented programming like
                Python, JavaScript, Java or C++ (above 50 hours of prior
                programming experience)
              </li>
            </ul>
          </TextSection>
          <TextSection>
            <h2>Assessment</h2>
            <ul>
              <li>Multiple-Choice Questions</li>
              <li>Individual/group assignments</li>
              <li>Project presentations</li>
              <li>Individual assessments</li>
            </ul>
            <Margin20 />
            <Margin20 />
            <h3>Certification</h3>
            <p>
              Upon completion of all 6 modules within a maximum duration of 3
              years, participants will be awarded a digital Advanced Certificate
              in AWS Cloud Architecting and DevOps.
            </p>
          </TextSection>
          <TextSection position={3}>
            <h2>Fees And Funding</h2>
            <FundingTable
              firstRow={[
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="http://www.ssg.gov.sg/programmes-and-initiatives/funding/enhanced-training-support-for-smes1.html">
                    ETSS Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
              ]}
              secondRow={[
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
              ]}
              thirdRow={[
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
              ]}
            />
          </TextSection>
          <TextSection position={4}>
            <h2>Schedule</h2>
            <p>
              <strong>Start Date(s)</strong>
              <br />
              13 Jun 2023 (Tue)
            </p>
            <p>
              <strong>Intake Information</strong>
            </p>
            <Table>
              <thead>
                <tr>
                  <td className="highlight2">Modules</td>
                  <td className="highlight2">Intake 1</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight1">
                    DESIGNING AND PROTOTYPING CLOUD APPS
                  </td>
                  <td>
                    <strong>
                      13, 14, 15 & 17 Jun 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">ARCHITECTING THE AWS CLOUD</td>
                  <td>
                    <strong>
                      20, 21, 22 & 24 Jun 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">TEST DRIVEN DEVELOPMENT</td>
                  <td>
                    <strong>
                      04, 05, 06 & 08 Jul 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    CONTINUOUS INTEGRATION, DELIVERY AND DEPLOYMENT ON AWS
                  </td>
                  <td>
                    <strong>
                      18, 19, 20 & 22 Jul 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">DEVOPS ON AWS</td>
                  <td>
                    <strong>
                      01, 02, 03 & 05 Aug 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    PRACTICAL LAB IN DEVELOPING APPLICATIONS ON AWS
                  </td>
                  <td>
                    <strong>
                      15, 16, 17 & 19 Aug 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection>
            <h2>Policies</h2>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                View programme policies.
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={5}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default ACACAD;
