import { combineReducers } from 'redux';

import modalData from './modalReducer';
import scheduleData from './scheduleDataReducer';
import recommenderData from './recommenderDataReducer';
import searchBarUIData from './searchBarUIReducer';
import recommenderQ1Selection from './recommenderQ1SelectionReducer';
import recommenderQ2Selection from './recommenderQ2SelectionReducer';
import searchBarData from './searchBarReducer';
import previousBlogPage from './previousBlogPageReducer';
import videoLoginStatus from './videoLoginStatusReducer';
import acadaPopUpData from './acadaPopUpReducer';

export default combineReducers({
  modalData,
  scheduleData,
  recommenderData,
  searchBarUIData,
  acadaPopUpData,
  recommenderQ1Selection,
  recommenderQ2Selection,
  searchBarData,
  previousBlogPage,
  videoLoginStatus,
});
