import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import Utils from '../../utils';
import AWSPartnerLogo from '../../assets/images/tertiary/aws-cloud-practitioner-logo.png';
import AppleConsultantsNetworkLogo from '../../assets/images/tertiary/app-dev-with-swift-logo.png';
import Tertiary1Img from '../../assets/images/tertiary/index/tertiary-1.jpg';
import Tertiary3Img from '../../assets/images/tertiary/index/tertiary-3.jpg';
import RightArrowIcon from '../../assets/icons/thin-arrow-right.svg';

gsap.registerPlugin(ScrollTrigger);

const Section = styled.section``;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-family: Lato Bold;
  font-size: 40px;
  position: sticky;
  top: 110px;
  text-align: center;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
  }
  // #endregion
`;

const Content = styled.div`
  & > * {
    position: sticky;
    &:nth-child(1) {
      top: 230px;
      height: calc(530px - ${$.layout().padding4 * 2}px);
    }
    &:nth-child(2) {
      top: 285px;
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    & > * {
      &:nth-child(1) {
        height: auto;
      }
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    & > * {
      &:nth-child(1) {
        height: auto;
        top: 250px;
      }
      &:nth-child(2) {
        top: 305px;
      }
    }
  }
  // #endregion
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: ${$.layout().padding4}px;
  height: calc(500px - ${$.layout().padding4 * 2}px);
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: ${$.color.white};
  margin-bottom: ${$.layout().margin4}px;
  will-change: transform;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    height: auto;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    height: auto;
  }
  // #endregion
`;

const CardTopTitle = styled.div`
  font-family: Lato Bold;
  font-size: 22px;
  position: absolute;
  top: 0;
  left: 0;
  color: ${$.color.blue5};

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 20px;
  }
  // #endregion
`;

const Info = styled.div`
  width: 30%;
  height: calc(100% - 160px);
  padding: 80px 10%;
  position: relative;
  display: flex;
  flex-direction: column;

  & > svg {
    width: 100px;
    margin-bottom: ${$.layout().margin3}px;

    &.apple {
      width: 160px;
    }
  }

  & > img {
    width: 130px;
    margin-bottom: ${$.layout().margin3}px;

    &.apple {
      width: 120px;
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 80%;
    padding: 80px 10% 50px 10%;
    height: auto;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 88%;
    height: auto;
    padding: 60px 8% 30px 6%;

    & > svg {
      margin: ${$.layout().margin3}px 0;
    }
  }
  // #endregion
`;

const CardTitle = styled.div`
  margin-bottom: 5px;
  font-family: Lato Bold;
  font-size: 28px;
  color: ${$.color.black};

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 24px;
  }
  // #endregion
`;

const CardDescription = styled.div`
  line-height: 1.3em;
  font-size: 16px;
  opacity: 0.8;
  flex-grow: 1;
  margin-bottom: ${$.layout().margin4}px;

  & span {
    font-family: Lato Bold;
    color: ${$.color.blue5};
  }
`;

const ViewButton = styled(Link)`
  text-decoration: none;
  font-size: 15px;
  font-family: Lato Bold;
  color: ${$.color.blue5};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:visited {
    color: ${$.color.blue5};
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${$.color.blue5};
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-size: 15px;
  font-family: Lato Bold;
  color: ${$.color.blue5};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:visited {
    color: ${$.color.blue5};
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${$.color.blue5};
  }
`;

const ImgContainer = styled.div`
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-radius: 0 10px 10px 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
    border-radius: 0 0 10px 10px;
    height: 350px;
    flex: initial;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    border-radius: 0 0 10px 10px;
    height: 350px;
    flex: initial;
  }
  // #endregion
`;

const items = Utils.addKeys([
  {
    title: 'Amazon Web Services (AWS)',
    desc: (
      <div>
        Join the AWS Cloud & AI Bootcamp and earn the official{' '}
        <span>AWS Cloud Practitioner</span> Certification.
      </div>
    ),
    Img: Tertiary1Img,
    scale: 0.96,
    logo: <img src={AWSPartnerLogo} alt="dsadas" />,
    color: $.color.blue4,
    link: '/tertiary/aws/',
    linkText: 'View',
  },
  {
    title: 'Apple',
    desc: (
      <div>
        Join the Apple iOS Mobile Development Bootcamp and earn the official
        Apple <span>App Development with Swift Certified User</span>{' '}
        Certification.
      </div>
    ),
    Img: Tertiary3Img,
    scale: 1,
    logo: (
      <img src={AppleConsultantsNetworkLogo} alt="dasd" className="apple" />
    ),
    color: $.color.blue5,
    link: '/tertiary/apple/',
    linkText: 'View',
  },
]);

const CodingForEveryone = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const { childNodes } = containerRef.current;
    const t1 = gsap.timeline();

    childNodes.forEach((node, index) => {
      t1.to(node, {
        scaleX: items[index].scale,
        scrollTrigger: {
          trigger: node,
          start: '-=100px center',
          end: 'bottom center',
          scrub: 1,
        },
      });
    });
  }, []);

  return (
    <Section>
      <Container>
        <Title>Begin your journey today</Title>
        <Content ref={containerRef}>
          {items.map(
            ({
              title,
              key,
              logo,
              link,
              desc,
              color,
              linkText,
              mailLink,
              Img,
            }) => (
              <Card key={key}>
                <Info>
                  <CardTopTitle>{title}</CardTopTitle>
                  {logo}
                  <CardTitle color={color}>{title}</CardTitle>
                  {/* <CardSubtitle>{subtitle}</CardSubtitle> */}
                  <CardDescription>{desc}</CardDescription>
                  {mailLink ? (
                    <StyledLink href={mailLink}>
                      <span>{linkText}</span>
                      <RightArrowIcon />
                    </StyledLink>
                  ) : (
                    <ViewButton to={link}>
                      <span>{linkText}</span>
                      <RightArrowIcon />
                    </ViewButton>
                  )}
                </Info>
                <ImgContainer>
                  <img src={Img} alt="Why Choose SG Code Campus" />
                </ImgContainer>
              </Card>
            )
          )}
        </Content>
      </Container>
    </Section>
  );
};

export default CodingForEveryone;
