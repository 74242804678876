import SEO from '../../components/seo';
import Banner from '../../components/DatascienceAccelerator2022/Schedule/Banner';
import ScheduleTable from '../../components/DatascienceAccelerator2022/Schedule/ScheduleTable';

const DatascienceAccelerator = () => (
  <>
    <SEO title="Data Science Accelerator 2022" />
    <Banner />
    <ScheduleTable />
  </>
);

export default DatascienceAccelerator;
