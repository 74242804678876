import PropTypes from 'prop-types';

import Banner from '../components/MiniBanner';
import MiniBanner from '../components/schedule/MiniBanner';
import SearchBar from '../components/schedule/SearchBar';
import Schedules from '../components/schedule';
import SEO from '../components/seo';

const Schedule = ({ location }) => (
  <>
    <SEO
      location={location}
      title="Course Schedule"
      description="Interested to learn coding from us? Check our timings here."
      keywords={[
        'coding class schedule',
        'coding course schedule',
        'coding bootcamp schedule',
        'holiday camp schedule',
        'holiday bootcamp schedule',
      ]}
    />
    <Banner
      title="Course Schedule"
      subtitle="Email us at <a href='mailto:enquiries@sgcodecampus.com'>enquiries@sgcodecampus.com</a> if you have any questions"
    />
    <MiniBanner />
    <SearchBar />
    <Schedules />
  </>
);

Schedule.defaultProps = {
  location: {},
};

Schedule.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default Schedule;
