import React, { useContext } from 'react';
import styled from 'styled-components';

import { Context as EventsContext } from '../context';
import ArrowForward from '../../../assets/icons/arrow-forward.svg';

const Section = styled.section`
  margin-top: ${({ theme }) => theme.layout().margin2}px;
  margin-bottom: ${({ theme }) => theme.layout().margin2}px;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white1};
  border-radius: ${({ theme }) => theme.border().radius2}px;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.layout().padding3}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${({ theme }) => theme.layout().padding2}px;

  // #region
  @media ${({ theme }) => theme.device.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ${({ theme }) => theme.layout().padding5}px auto max-content 50px ${({
        theme,
      }) => theme.layout().padding5}px;
    grid-template-areas:
      'image topgap'
      'image title'
      'image details'
      'image button'
      'image bottomgap';
    grid-gap: ${({ theme }) => theme.layout().padding2}px
      ${({ theme }) => theme.layout().padding5}px;
    padding-bottom: 0px;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  height: 280px;
  overflow: hidden;
  grid-area: image;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // #region
  @media ${({ theme }) => theme.device.tablet} {
    height: 350px;
  }
  // #endregion

  // #region
  @media ${({ theme }) => theme.device.desktop} {
    height: auto;
  }
  // #endregion
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${({ theme }) => {
      return theme.layout().padding5;
    }}px
    ${({ theme }) => theme.layout().padding5}px;
  padding-left: ${({ theme }) => theme.layout().padding3}px;
  padding-right: ${({ theme }) => theme.layout().padding3}px;
  grid-area: details;

  font-size: ${({ theme }) => theme.fontSizes.normal};

  // #region
  @media ${({ theme }) => theme.device.desktop} {
    grid-auto-rows: max-content;
  }
  // #endregion
`;

const DetailsTitle = styled.div`
  font-family: Cooper Hewitt Semibold;
  color: ${({ theme }) => theme.color.blue3};
`;

const DetailsData = styled.div`
  color: ${({ theme }) => theme.color.black2};
`;

const Title = styled.h1`
  padding: 0px ${({ theme }) => theme.layout().padding3}px;
  grid-area: title;
`;

const RegistrationButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.layout().padding4}px;
  padding-top: ${({ theme }) => theme.layout().padding4}px;
  padding-bottom: ${({ theme }) => theme.layout().padding4}px;
  background-color: ${({ theme }) => theme.color.blue3};
  color: ${({ theme }) => theme.color.white};
  border-radius: 999px;
  margin-left: ${({ theme }) => theme.layout().margin4}px;
  margin-right: ${({ theme }) => theme.layout().margin4}px;
  text-decoration: none;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  transition: all 0.3s ${({ theme }) => theme.easingFn.standard};
  border: 2px solid ${({ theme }) => theme.color.blue3};
  grid-area: button;

  & svg {
    stroke: ${({ theme }) => theme.color.white};
    fill: ${({ theme }) => theme.color.white};
    transition: all 0.3s ${({ theme }) => theme.easingFn.standard};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blue3};
    box-shadow: ${({ theme }) => theme.dropShadow.repressed};

    & svg {
      stroke: ${({ theme }) => theme.color.blue3};
      fill: ${({ theme }) => theme.color.blue3};
    }
  }

  // #region
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 250px;
    align-self: flex-end;
  }
  // #endregion
`;

const Banner = () => {
  const {
    id,
    title,
    excerpt,
    html,
    featureImage,
    tags,
    eventExternalUrl,
    admissionFee,
    date,
    dateObject,
    time,
    venue,
  } = useContext(EventsContext);

  return (
    <Section>
      <Container>
        <ImageContainer>
          <img src={featureImage} alt="" />
        </ImageContainer>
        <Title>{title}</Title>
        <DetailsContainer>
          {[
            {
              title: 'Date',
              value: date,
            },
            {
              title: 'Time',
              value: time,
            },
            {
              title: 'Admission Fee',
              value: admissionFee,
            },
            {
              title: 'Venue',
              value: venue,
            },
            {
              title: 'Categories',
              value: tags.map((tag) => tag.name).join(', '),
            },
          ].map(({ title, value }, index) => {
            return (
              <React.Fragment key={`${title}_${index}`}>
                <DetailsTitle>{title}</DetailsTitle>
                <DetailsData>{value}</DetailsData>
              </React.Fragment>
            );
          })}
        </DetailsContainer>
        <RegistrationButton
          target="_blank"
          rel="noopener"
          href={eventExternalUrl}
        >
          Register <ArrowForward />
        </RegistrationButton>
      </Container>
    </Section>
  );
};

export default Banner;
