import styled from 'styled-components';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import $ from '../../../styles/global';
import Utils from '../../../utils';
// eslint-disable-next-line
import '@splidejs/splide/dist/css/splide.min.css';

const Section = styled.section`
  background-color: ${$.tertiary.orange};
`;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Description = styled.div`
  margin-bottom: ${$.layout().margin2}px;
  p {
    font-family: Lato Regular;
    font-size: 18px;
    color: ${$.color.white};
    opacity: 0.8;
    line-height: 1.3em;
  }
`;

const Pill = styled.div`
  border-radius: 10px;
  border: 1px solid ${$.color.white};
  padding: 12px;
  opacity: 0.6;
  color: ${$.color.white};
  font-family: Lato Regular;
  font-size: 20px;
`;

const SplideTrackContainer = styled.div`
  margin-bottom: ${$.layout().margin5}px;
  width: 100vw;
  position: relative;
  z-index: 1;
  left: -${$.layout().margin3}px;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 1200px;
    left: -115px;
  }
  // #endregion
`;

const splideItems = [
  {
    pills: Utils.addKeys([
      {
        name: 'Data Scientist',
      },
      {
        name: 'Project Manager',
      },
      {
        name: 'Network Engineer',
      },
      {
        name: 'Software Designer',
      },
      {
        name: 'Business Analyst',
      },
      {
        name: 'Machine Learning Engineer',
      },
      {
        name: 'Game Developer',
      },
      {
        name: 'App Developer',
      },
    ]),
  },
  {
    pills: Utils.addKeys([
      {
        name: 'Project Manager',
      },
      {
        name: 'Game Developer',
      },
      {
        name: 'App Developer',
      },
      {
        name: 'Network Engineer',
      },
      {
        name: 'Software Designer',
      },
      {
        name: 'Data Scientist',
      },
      {
        name: 'Business Analyst',
      },
      {
        name: 'Machine Learning Engineer',
      },
    ]),
  },
  {
    pills: Utils.addKeys([
      {
        name: 'Business Analyst',
      },
      {
        name: 'Game Developer',
      },
      {
        name: 'Software Designer',
      },
      {
        name: 'Project Manager',
      },
      {
        name: 'Data Scientist',
      },
      {
        name: 'App Developer',
      },
      {
        name: 'Network Engineer',
      },
      {
        name: 'Machine Learning Engineer',
      },
    ]),
  },
];

const Career = () => {
  const splideOptions = {
    type: 'loop',
    gap: '10px',
    drag: true,
    arrows: false,
    pagination: false,
    perPage: 3,
    autoWidth: true,
  };

  return (
    <Section>
      <Container>
        <Title>Career Path</Title>
        <Description>
          <p>
            Every modern technical role requires you to know something about how
            the cloud works - simply put any App, AI, Deep Learning model,
            crypto exchange or e-commerce marketplace lives on some cloud
            somewhere in the world. What better place to begin your cloud
            journey than at the world largest cloud provider AWS?
          </p>
        </Description>
        <SplideTrackContainer>
          <Splide options={splideOptions}>
            {splideItems[0].pills.map(({ name, key }) => (
              <SplideSlide key={key}>
                <Pill>{name}</Pill>
              </SplideSlide>
            ))}
          </Splide>
        </SplideTrackContainer>
        <SplideTrackContainer>
          <Splide options={splideOptions}>
            {splideItems[1].pills.map(({ name, key }) => (
              <SplideSlide key={key}>
                <Pill>{name}</Pill>
              </SplideSlide>
            ))}
          </Splide>
        </SplideTrackContainer>
        <SplideTrackContainer>
          <Splide options={splideOptions}>
            {splideItems[2].pills.map(({ name, key }) => (
              <SplideSlide key={key}>
                <Pill>{name}</Pill>
              </SplideSlide>
            ))}
          </Splide>
        </SplideTrackContainer>
      </Container>
    </Section>
  );
};

export default Career;
