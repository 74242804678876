import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';

import $ from '../../../styles/global';
import Button from '../../Button';

const Section = styled.section`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding1}px 0px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding1}px 0px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-top: -${$.layout().margin2}px;
    padding-bottom: ${$.layout().padding1}px;
    background-color: ${$.color.blue1};
  }
  // #endregion
`;

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 450px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 400px;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().margin2}px / 2);
    height: 450px;
    margin-right: ${$.layout().margin2}px;
    border-radius: ${$.border().radius1}px;
    order: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    mask-image: radial-gradient(#fff, #000);

    & > div {
      border-radius: inherit;
      box-shadow: ${$.dropShadow.normal};
      height: 100%;
      width: 100%;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(50% - ${$.layout().margin3}px);
    height: 400px;
    margin-right: ${$.layout().margin3}px;
    border-radius: ${$.border().radius1}px;
    order: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    mask-image: radial-gradient(#fff, #000);

    & > div {
      border-radius: inherit;

      box-shadow: ${$.dropShadow.normal};
      height: 100%;
      width: 100%;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-left: -${$.layout().margin3}px;
    margin-bottom: ${$.layout().margin3}px;
    width: 100vw;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue3};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().margin2}px / 2);
    order: 2;
    font-size: 40px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 50%;
    order: 2;
    font-size: 28px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  font-family: Cooper Hewitt Semibold;
  text-transform: uppercase;
  color: ${$.color.orange3};
  letter-spacing: 2px;
  line-height: 1.4em;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().margin2}px / 2);
    order: 4;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 50%;
    order: 4;
    font-size: 18px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    text-align: center;
    font-size: 18px;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const Details = styled.div`
  font-family: Lato Regular;
  color: ${$.color.blue5};
  line-height: 1.4em;
  p {
    margin-top: 0;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().margin2}px / 2);
    order: 3;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 50%;
    order: 3;
    font-size: 17px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const StyledButton = styled(({ children, className, to }) => (
  <Link to={to} className={className}>
    <Button
      normalColor={$.color.orange3}
      clickedColor={$.color.blue3}
      normalText={$.color.white}
      clickedText={$.color.white}
      className={className}
    >
      {children}
    </Button>
  </Link>
))`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    order: 5;
    min-width: 150px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    order: 5;
    min-width: 150px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const BuildBetterFuture = () => (
  <Section>
    <Fade bottom fraction={0.1} distance="100px">
      <Container>
        <ImageContainer>
          <StaticImage
            src="../../../assets/images/whyus/philosophy/better_future_through_code.jpg"
            alt="Co-Founder Stephanie Law presenting at Today at Apple"
            title="Today at Apple event"
          />
        </ImageContainer>
        <Title>
          By Learning to Code Together, We will Build a Better Future
        </Title>
        <Details>
          <p>
            Our Campuses and tech educators are here to provide Code Campers
            with a supportive community and positive learning environment so
            that they can dream big and build boldly.
          </p>
          <p>
            Don&apos;t just prepare for the future - build a better future,
            together.
          </p>
        </Details>
        <Subtitle>SEE WHAT CODE CAMPERS CAN DO WITH CODE</Subtitle>
        <StyledButton to="/blog/what-primary-kids-can-do-with-code/">
          Learn More
        </StyledButton>
      </Container>
    </Fade>
  </Section>
);

export default BuildBetterFuture;
