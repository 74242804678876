import Seo from '../../components/seo';
import ArchitectingOnAWS from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/ArchitectingOnAWS';

const AOAPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <ArchitectingOnAWS />
  </>
);

export default AOAPage;
