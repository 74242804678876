import PropTypes from 'prop-types';

import SEO from '../components/seo';
import CoronaAdvisory from '../components/CoronaAdvisory';
import Banner from '../components/MiniBanner';

const CoronavirusAdvisory = ({ location }) => (
  <>
    <SEO
      keywords={[
        'DORSCON Orange',
        'safety precautions',
        'terms and policies',
        'temperature taking',
        'hand sanitising',
        'equipment cleaning',
        'travel declaration',
        'leave of absence',
      ]}
      location={location}
    />
    <Banner title="Coronavirus (COVID-19) Advisory" />
    <CoronaAdvisory />
  </>
);

CoronavirusAdvisory.defaultProps = {
  location: {},
};

CoronavirusAdvisory.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default CoronavirusAdvisory;
