import { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import context from './context';
import Schedule from './Schedule';
import NoCourseBanner from './NoCourseBanner';
import PriorExpBanner from './PriorExpBanner';

const Section = styled.section`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Container = styled.div`
  transform-style: flat;
`;

const Schedules = ({ q2Answer }) => {
  const { data, filteredData } = useContext(context);

  return (
    <Section>
      <Container>
        <PriorExpBanner show={q2Answer === 'Yes'} />
        {data.map(({ key, ...ele }, index) => (
          <Schedule
            key={key}
            data={{ key, ...ele }}
            index={index}
            length={data.length}
          />
        ))}
        <NoCourseBanner
          show={
            ['None', 'No'].indexOf(q2Answer) > -1 && filteredData.length === 0
          }
        />
      </Container>
    </Section>
  );
};

Schedules.propTypes = {
  q2Answer: PropTypes.string.isRequired,
};

export default Schedules;
