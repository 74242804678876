import SEO from '../../components/seo';
import Background from '../../components/Tertiary/AWS/Background';
import Banner from '../../components/Tertiary/AWS/Banner';
import Why from '../../components/Tertiary/AWS/Why';
import About from '../../components/Tertiary/AWS/About';
import Attend from '../../components/Tertiary/AWS/Attend';
import Programme from '../../components/Tertiary/AWS/Programme';
// import Connected from '../../components/Tertiary/AWS/Connected';
import Trainers from '../../components/Tertiary/AWS/Trainers';
import Schedule from '../../components/Tertiary/AWS/Schedule';
import WhyAWS from '../../components/Tertiary/AWS/WhyAWS';
import Career from '../../components/Tertiary/AWS/Career';
import WhatIsCloud from '../../components/Tertiary/AWS/WhatIsCloud';

const TertiaryAWSPage = () => (
  <>
    <SEO title="Tertiary | AWS" />
    <Background id="particles-1">
      <Banner />
    </Background>
    <WhatIsCloud />
    <Why />
    <Career />
    <WhyAWS />
    <About />
    <Attend />
    <Programme />
    {/* <Background id="particles-2">
      <Connected />
    </Background> */}
    <Background id="particles-2">
      <Trainers />
    </Background>
    <Schedule />
  </>
);

export default TertiaryAWSPage;
