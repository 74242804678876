import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import NavBar from '../blog/NavBar';

const Section = styled.section`
  ${({ backgroundColor }) => `
  overflow: hidden;
  background-color: ${backgroundColor};
`}
`;

const Container = styled.div`
  width: 100%;
  position: relative;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 100vw;
    height: 400px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    height: 350px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: flex;
    height: 330px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: ${$.layout().padding2}px 0px ${$.layout().padding3}px;
  }
  // #endregion
`;

const TitleContainer = styled.div`
  ${({ path }) => `
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 2;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-bottom: 40px;
    width: 45%;
    height: ${path ? '60%' : '80%'};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-bottom: 35px;
    width: 450px;
    height: ${path ? '60%' : '68%'};
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    justify-content: flex-end;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`}
`;

const Title = styled.h1`
  ${({ color }) => `
  font-family: Aileron Heavy;
  color: ${color};
  font-size: 55px;
  text-align: left;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 38px;
    margin-bottom: ${$.layout().margin5 / 2}px;
  }
  // #endregion
`}
`;

const Subtitle = styled.div`
  ${({ color }) => `
  color: ${color};
  font-size: 18px;
  line-height: 1.3em;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 16px;
  }
  // #endregion
`}
`;

const Level2Banner = ({
  title,
  subtitle,
  postsWithTag,
  path,
  color,
  backgroundColor,
  className,
  children,
}) => (
  <Section backgroundColor={backgroundColor} className={className}>
    <Container>
      <TitleContainer path={path} className="level2-title-container">
        <Title color={color}>{title}</Title>
        <Subtitle color={color} className="level2-subtitle">
          {postsWithTag
            ? `A collection of ${postsWithTag} post${
                postsWithTag > 1 ? 's' : ''
              }`
            : subtitle}
        </Subtitle>
      </TitleContainer>
      {path ? <NavBar path={path} /> : ''}
      {children}
    </Container>
  </Section>
);

Level2Banner.defaultProps = {
  color: $.color.blue4,
  backgroundColor: $.color.white,
  children: [],
  className: '',
  title: '',
  subtitle: '',
  postsWithTag: 0,
  path: '',
};

Level2Banner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  postsWithTag: PropTypes.number,
  path: PropTypes.string,
};

export default Level2Banner;
