import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import { Title, Body } from './common';

const Container = styled.div`
  grid-area: courseDuration;
`;

const CourseDuration = () => {
  const {
    courseCard: { modulesInformation },
  } = useContext(Context);

  return (
    <Container>
      <Title>Course Duration</Title>
      <Body
        dangerouslySetInnerHTML={{
          __html: modulesInformation,
        }}
      />
    </Container>
  );
};

export default CourseDuration;
