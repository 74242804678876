import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';
import { Link } from 'gatsby';

import Arrow from '../../assets/icons/thin-arrow-right.svg';
import CarAnimated from '../../assets/images/awsaccelerator2021/car-animated.json';
import $ from '../../styles/global';

const Section = styled.section`
  padding: 80px 0;
`;

const Container = styled.div``;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${$.layout().padding2}px;
  border-radius: 20px;
  background-color: #feffff;
  box-shadow: ${$.dropShadow.normal};
  z-index: 0;
  width: calc(700px - ${$.layout().padding2 * 2}px);
  margin: 0 auto;
  position: relative;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(600px - ${$.layout().padding2 * 2}px);
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column-reverse;
    width: calc(100% - ${$.layout().padding2 * 2}px);
  }
  // #endregion
`;

const CardInfo = styled.div`
  width: 60%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Title = styled.div`
  font-size: 40px;
  line-height: 1.1em;
  color: ${$.color.black2};
  font-family: Aileron Heavy;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 35px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    word-break: break-all;
  }
  // #endregion
`;

const JoinButton = styled(Link)`
  text-decoration: none;
  display: inline-block;

  > button {
    color: ${$.color.white};
    border: none;
    background-color: ${$.color.orange3};
    padding: 20px 30px;
    border-radius: 35px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.35s ${$.easingFn.standard};
    > svg {
      margin-left: ${$.layout().margin4}px;
      fill: ${$.color.white};
    }
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      > button {
        background-color: ${$.color.orange2};
        box-shadow: ${$.dropShadow.repressed};
        cursor: pointer;
      }
    }

    > button {
      font-size: 20px;
      > svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    > button {
      font-size: 18px;
      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: 15px 25px 15px 0;
    width: 100%;
    justify-content: center;
    > div {
      font-size: 17px;
    }
  }
  // #endregion
`;

const AnimationContainer = styled.div`
  width: 40%;
  height: 190px;
  align-self: stretch;
  position: relative;
  overflow: hidden;

  > * {
    position: absolute;
    width: 300%;
    height: 300%;
    top: -100%;
    left: -100%;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 80%;
    margin: auto;
  }
  // #endregion
`;

const JoinCard = () => {
  const animateRef = useRef(null);

  useEffect(() => {
    const animateCar = lottie.loadAnimation({
      container: animateRef.current, // current instance of our container!
      animationData: CarAnimated, // animation file!
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });

    return () => {
      animateCar.destroy();
    };
  }, []);

  return (
    <Section>
      <Container>
        <Card>
          <CardInfo>
            <Title>Developers, start your engines</Title>
            <JoinButton to="/datascience-bootcamp-accelerator-2021/join-2021-programme/">
              <button type="button">
                Join us for class 2021!
                <Arrow />
              </button>
            </JoinButton>
          </CardInfo>
          <AnimationContainer>
            <div ref={animateRef} />
          </AnimationContainer>
        </Card>
      </Container>
    </Section>
  );
};

export default JoinCard;
