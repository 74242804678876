import PropTypes from 'prop-types';

import SEO from '../components/seo';
import {
  IndividualEventPageBanner,
  IndividualEventPageBody,
  Provider as EventsProvider,
} from '../components/EventsPage';

const IndividualEventPage = ({
  pageContext: {
    id,
    title,
    html,
    excerpt,
    featureImage,
    tags,
    eventExternalUrl,
    admissionFee,
    date,
    dateObject,
    time,
    venue,
  },
}) => {
  return (
    <>
      <EventsProvider
        value={{
          id,
          title,
          html,
          excerpt,
          featureImage,
          tags,
          eventExternalUrl,
          admissionFee,
          date,
          dateObject,
          time,
          venue,
        }}
      >
        <SEO
          title="Individual Event"
          description="Individual Event"
          keywords={['SG Code Campus', 'Events']}
        />
        <IndividualEventPageBanner />
        <IndividualEventPageBody />
      </EventsProvider>
    </>
  );
};

IndividualEventPage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    html: PropTypes.string,
    excerpt: PropTypes.string,
    featureImage: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    eventExternalUrl: PropTypes.string,
    admissionFee: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    venue: PropTypes.string,
    dateObject: PropTypes.string,
  }).isRequired,
};

export default IndividualEventPage;
