import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { Margin20, RemoteLink } from '../Misc';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import QnADropdown from '../QnADropdown';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Course Objectives',
  },
  {
    text: 'Course Outline',
  },
  {
    text: 'Certification',
  },
  {
    text: 'Price',
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Summarize the working definition of AWS',
  },
  {
    text: 'Differentiate between on-premises, hybrid-cloud, and all-in cloud',
  },
  {
    text: 'Describe the basic global infrastructure of the AWS Cloud',
  },
  {
    text: 'Explain the six benefits of the AWS Cloud',
  },
  {
    text: 'Describe and provide an example of the core AWS services, including compute, network, databases, and storage',
  },
  {
    text: 'Identify an appropriate solution using AWS Cloud services with various use cases',
  },
  {
    text: 'Describe the AWS Well-Architected Framework',
  },
  {
    text: 'Explain the shared responsibility model',
  },
  {
    text: 'Describe the core security services within the AWS Cloud',
  },
  {
    text: 'Describe the basics of AWS Cloud migration.',
  },
  {
    text: "Articulate the financial benefits of the AWS Cloud for an organization's cost management",
  },
  {
    text: 'Define the core billing, account management, and pricing models',
  },
  {
    text: 'Explain how to use pricing tools to make cost-effective choices for AWS services',
  },
]);

const courseOutline = Utils.addKeys([
  {
    text: 'Introduction to Amazon Web Services',
    items: [
      'Summarize the benefits of AWS',
      'Describe differences between on-demand delivery and cloud deployments',
      'Summarize the pay-as-you-go pricing model',
    ],
  },
  {
    text: 'Compute in the Cloud',
    items: [
      'Describe the benefits of Amazon Elastic Compute Cloud (Amazon EC2) at a basic level',
      'Identify the different Amazon EC2 instance types',
      'Differentiate between the various billing options for Amazon EC2',
      'Describe the benefits of Amazon EC2 Auto Scaling',
      'Summarize the benefits of Elastic Load Balancing',
      'Give an example of the uses for Elastic Load Balancing',
      'Summarize the differences between Amazon Simple Notification Service (Amazon SNS) and Amazon Simple Queue Services (Amazon SQS)',
      'Summarize additional AWS compute options',
    ],
  },
  {
    text: 'Global Infrastructure and Reliability',
    items: [
      'Summarize the benefits of the AWS Global Infrastructure',
      'Describe the basic concept of Availability Zones',
      'Describe the benefits of Amazon CloudFront and Edge locations',
      'Compare different methods for provisioning AWS services',
    ],
  },
  {
    text: 'Networking',
    items: [
      'Describe the basic concepts of networking',
      'Describe the difference between public and private networking resources',
      'Explain a virtual private gateway using a real life scenario',
      'Explain a virtual private network (VPN) using a real life scenario',
      'Describe the benefit of AWS Direct Connect',
      'Describe the benefit of hybrid deployments',
      'Describe the layers of security used in an IT strategy',
      'Describe which services are used to interact with the AWS global network',
    ],
  },
  {
    text: 'Storage and Databases',
    items: [
      'Summarize the basic concept of storage and databases',
      'Describe benefits of Amazon Elastic Block Store (Amazon EBS)',
      'Describe benefits of Amazon Simple Storage Service (Amazon S3)',
      'Describe the benefits of Amazon Elastic File System (Amazon EFS)',
      'Summarize various storage solutions',
      'Describe the benefits of Amazon Relational Database Service (Amazon RDS)',
      'Describe the benefits of Amazon DynamoDB',
      'Summarize various database services',
    ],
  },
  {
    text: 'Security',
    items: [
      'Explain the benefits of the shared responsibility model',
      'Describe multi-factor authentication (MFA)',
      'Differentiate between the AWS Identity and Access Management (IAM) security levels',
      'Describe security policies at a basic level',
      'Explain the benefits of AWS Organizations',
      'Summarize the benefits of compliance with AWS',
      'Explain primary AWS security services at a basic level',
    ],
  },
  {
    text: 'Pricing and Support',
    items: [
      'Understand AWS pricing and support models',
      'Describe the AWS Free Tier',
      'Describe key benefits of AWS Organizations and consolidated billing',
      'Explain the benefits of AWS Budgets',
      'Explain the benefits of AWS Cost Explorer',
      'Explain the primary benefits of the AWS Pricing Calculator',
      'Distinguish between the various AWS Support Plans',
      'Describe the benefits of AWS Marketplace',
    ],
  },
  {
    text: 'Migration and Innovation',
    items: [
      'Understand migration and innovation in the AWS Cloud',
      'Summarize the AWS Cloud Adoption Framework (AWS CAF)',
      'Summarize six key factors of a cloud migration strategy',
      'Describe the benefits of various AWS data migration solutions, such as AWS Snowcone, AWS Snowball, and AWS Snowmobile',
      'Summarize the broad scope of innovative solutions that AWS offers',
    ],
  },
  {
    text: 'The Cloud Journey',
    items: [
      'Summarize the five pillars of the AWS Well-Architected Framework',
      'Explain the six benefits of cloud computing',
    ],
  },
  {
    text: 'AWS Certified Cloud Practitioner Basics',
    items: [
      'Determine resources for preparing for the AWS Certified Cloud Practitioner examination',
      'Describe benefits of becoming AWS Certified',
    ],
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'IT professionals who are new to AWS',
  },
  {
    text: 'Business managers and executives who want to understand the benefits and potential of the AWS Cloud',
  },
  {
    text: 'Marketing and sales professionals who need to understand the impact of AWS on their business',
  },
  {
    text: 'Finance professionals who want to understand the cost benefits of using the AWS Cloud',
  },
  {
    text: 'Anyone who is interested in learning about cloud computing and AWS.',
  },
]);

const applyNowLink =
  '/professional-training/apply-now/?c=AWS Cloud Practitioner Essentials';

const enquiryLink =
  'https://www.ntuclearninghub.com/enquiry-form?course_name=AWS%20CLOUD%20PRACTITIONER%20ESSENTIALS&course_code=NICF161&course_type=ITT';

const AWSCPE = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner image={bgImage} title="AWS Cloud Practitioner Essentials" />
      <MobileNavigationMenu
        items={navigationLinks}
        enquiryLink={enquiryLink}
        applyNowLink={applyNowLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              The AWS Cloud Practitioner Essentials course is an introductory
              level course designed to provide participants with a foundational
              understanding of the AWS Cloud. The course covers the core AWS
              services, including computing, storage, databases, and networking.
            </p>
            <p>
              The course is delivered through a combination of instructor-led
              lectures, interactive discussions, and hands-on lab exercises.
              Participants will learn about the benefits of using the AWS Cloud,
              the different services offered, and how to use them to solve
              real-world business problems.
            </p>
            <p>
              By the end of the course, participants will have a good
              understanding of the AWS Cloud and how to use it to solve
              real-world business problems. They will also be prepared to take
              the AWS Certified Cloud Practitioner exam.
            </p>
            <Table>
              <tbody>
                <tr>
                  <td className="highlight1">Course Code</td>
                  <td>NICF161</td>
                </tr>
                <tr>
                  <td className="highlight1">Course Duration</td>
                  <td>8 hours</td>
                </tr>
                <tr>
                  <td className="highlight1">Medium of Instruction</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td className="highlight1">Trainer:Trainee Ratio</td>
                  <td>1:20</td>
                </tr>
                <tr>
                  <td className="highlight1">Mode of Delivery</td>
                  <td>Online Instructor-Led Training via VLC</td>
                </tr>
                <tr>
                  <td className="highlight1">Courseware</td>
                  <td>AWS</td>
                </tr>
                <tr>
                  <td className="highlight1">Lab</td>
                  <td>Qwiklabs</td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection position={1}>
            <h2>Course Objectives</h2>
            <p>Upon completing this course, participants will be able to:</p>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
            <Margin20 />
            <p>
              <strong>Prerequisites</strong>
            </p>
            <p>
              Be able to speak, listen, read and write English at a proficiency
              level equivalent to the Employability Skills Workforce Skills
              Qualification (ES WSQ) Workplace Literacy (WPL) Level 6
            </p>
          </TextSection>
          <TextSection position={2}>
            <h2>Course Outline</h2>
            {courseOutline.map(({ text, key, items }, index) => (
              <>
                <ListItem
                  key={key}
                  number={index + 1}
                  pointerColor={$.proTraining.bluegray}
                  text={<strong>{`Module ${index + 1}: ${text}`}</strong>}
                />
                <ul>
                  {items.map((txt, subIndex) => (
                    <li key={`${key}_${subIndex}`}>{txt}</li>
                  ))}
                </ul>
                {courseOutline.length - 1 > index && (
                  <>
                    <Margin20 />
                    <Margin20 />
                  </>
                )}
              </>
            ))}
            <Margin20 />
            <Margin20 />
            <p>
              <strong>Who Should Attend</strong>
            </p>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem key={key} number={index + 1} text={text} />
            ))}
          </TextSection>
          <TextSection position={3}>
            <h2>Certification</h2>
            <p>
              <strong>
                e-Statement of Attainment (SOA) from SkillsFuture Singapore
                (SSG)
              </strong>
            </p>
            <p>
              Upon meeting attendance requirements and passing the
              assessment(s), participants will receive an e-Statement of
              Attainment (SOA) from SkillsFuture Singapore (SSG).
            </p>
            <p>
              <strong>ICT-DES-3003-1.1 Infrastructure Design</strong>
            </p>
            <p>
              <strong>
                Certificate of Completion from NTUC LearningHub & Amazon Web
                Services
              </strong>
            </p>
            <p>
              Upon meeting at least 75% attendance and passing the
              assessment(s), participants will receive a Certificate of
              Completion from NTUC LearningHub & Amazon Web Services
            </p>
            <p>
              <strong>External Certification Exam</strong>
            </p>
            <p>
              This course will prepare participants to sit for{' '}
              <RemoteLink href="https://aws.amazon.com/certification/certified-cloud-practitioner/?trk=8e4f8862-b963-4ec1-bad5-91e77ca9a0bb&sc_channel=ps&s_kwcid=AL!4422!3!508672714204!e!!g!!aws%20cloud%20practitioner%20certification&ef_id=CjwKCAiA0JKfBhBIEiwAPhZXD_2KnGQ_EA2DYhnPhd2NWJxPevfnSpfYvgfqLE-D5TJEzuLt92WDSRoCMxEQAvD_BwE:G:s&s_kwcid=AL!4422!3!508672714204!e!!g!!aws%20cloud%20practitioner%20certification">
                AWS Certified Cloud Practitioner exam
              </RemoteLink>
            </p>
            <p>
              <strong>Exam Overview</strong>
            </p>
            <Table>
              <tbody>
                <tr>
                  <td className="highlight1">Level</td>
                  <td>Foundational</td>
                </tr>
                <tr>
                  <td className="highlight1">Length</td>
                  <td>90 minutes to complete the exam</td>
                </tr>
                <tr>
                  <td className="highlight1">Cost</td>
                  <td>USD100</td>
                </tr>
                <tr>
                  <td className="highlight1">Format</td>
                  <td>
                    65 questions, either multiple choice or multiple responses
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">Delivery Method</td>
                  <td>
                    Pearson VUE testing center or online proctored exam.{' '}
                    <RemoteLink href="https://aws.amazon.com/certification/certified-cloud-practitioner/?trk=8e4f8862-b963-4ec1-bad5-91e77ca9a0bb&sc_channel=ps&s_kwcid=AL!4422!3!508672714204!e!!g!!aws%20cloud%20practitioner%20certification&ef_id=CjwKCAiA0JKfBhBIEiwAPhZXD_2KnGQ_EA2DYhnPhd2NWJxPevfnSpfYvgfqLE-D5TJEzuLt92WDSRoCMxEQAvD_BwE:G:s&s_kwcid=AL!4422!3!508672714204!e!!g!!aws%20cloud%20practitioner%20certification">
                      Exam vouchers
                    </RemoteLink>{' '}
                    can be purchased. *Please note that LHub will not be issuing
                    any exam vouchers.
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection position={4}>
            <h2>Price</h2>
            <Table>
              <thead>
                <tr>
                  <td className="highlight1" />
                  <td className="highlight1">Individual Sponsored</td>
                  <td className="highlight1">Non-SME (Company Sponsored)</td>
                  <td className="highlight1">SME (Company Sponsored)</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight2">
                    For International Participants (Full Course Fee)
                  </td>
                  <td>
                    <strong>SGD810.00 w/ GST</strong> (SGD750.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD810.00 w/ GST</strong> (SGD750.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD810.00 w/ GST</strong> (SGD750.00 w/o GST)
                  </td>
                </tr>
                <tr>
                  <td className="highlight2">
                    For Singapore Citizens and PRs aged 21 years and above
                  </td>
                  <td>
                    <strong>SGD405.00 w/ GST</strong> (SGD375.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD405.00 w/ GST</strong> (SGD375.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD243.00 w/ GST</strong> (SGD225.00 w/o GST)
                  </td>
                </tr>
                <tr>
                  <td className="highlight2">
                    For Singapore Citizens aged 40 years and above
                  </td>
                  <td>
                    <strong>SGD243.00 w/ GST</strong> (SGD225.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD243.00 w/ GST</strong> (SGD225.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD243.00 w/ GST</strong> (SGD225.00 w/o GST)
                  </td>
                </tr>
              </tbody>
            </Table>
            <p>
              *After 70% funding for SME-sponsored Singaporeans and PRs under
              Enhanced Training Support for SMEs (ETSS) scheme
            </p>
            <Margin20 />
            <Margin20 />
            <h3>Funding Eligibility Criteria</h3>
            <p>
              <strong>Individual Sponsored Trainee</strong>
            </p>
            <ul>
              <li>Singapore Citizens or Singapore Permanent Residents</li>
              <li>
                Trainee must pass all prescribed tests/assessments, and attain
                100% competency
              </li>
              <li>Trainee must achieve at least 75% attendance</li>
              <li>
                NTUC LearningHub reserves the right to claw back the funded
                amount from trainee if he/she did not meet the eligibility
                criteria
              </li>
            </ul>
            <QnADropdown ques="SkillsFuture Credit">
              Eligible Singapore Citizens can use their SkillsFuture Credit to
              offset course fee payable after funding
            </QnADropdown>
            <QnADropdown ques="UTAP">
              NTUC Members can enjoy up to 50% funding (capped at $250 per year)
              under Union Training Assistance Programme (UTAP)
            </QnADropdown>
            <Margin20 />
            <Margin20 />
            <Margin20 />
            <p>
              <strong>Company Sponsored Trainee</strong>
            </p>
            <ul>
              <li>Singapore Citizens or Singapore Permanent Residents</li>
              <li>
                Trainee must pass all prescribed tests/assessments, and attain
                100% competency
              </li>
              <li>Trainee must achieve at least 75% attendance</li>
              <li>
                NTUC LearningHub reserves the right to claw back the funded
                amount from trainee if he/she did not meet the eligibility
                criteria
              </li>
            </ul>
            <QnADropdown ques="Absentee Payroll">
              <ul>
                <li>
                  SGD4.50 per hour, capped at SGD100,000 per enterprise per
                  calendar year
                </li>
                <li>
                  AP funding will be computed based on the actual number of
                  training hours attended by the trainee
                </li>
                <li>
                  Note: Courses/Modules under Professional Conversion Programme
                  (PCP) will not be eligible for AP funding claim.
                </li>
              </ul>
            </QnADropdown>
            <Margin20 />
            <Margin20 />
            <p>
              Terms & Conditions apply. NTUC LearningHub reserve the right to
              make changes or improvements to any of the products described in
              this document without prior notice.
            </p>
            <p>
              Prices are subject to other{' '}
              <RemoteLink href="https://www.ntuclearninghub.com/contact-us-2/faq">
                LHUB miscellaneous fees
              </RemoteLink>
              .
            </p>
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default AWSCPE;
