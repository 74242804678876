import PropTypes from 'prop-types';

import SEO from '../components/seo';
import Policies from '../components/learnmore/Policies';
import Banner from '../components/learnmore/Banner';
import BannerImage from '../assets/images/learnmore/policies/banner.svg';
import $ from '../styles/global';

const PoliciesPage = ({ location }) => (
  <>
    <SEO
      keywords={['school policies', 'campus policies', 'house rules']}
      location={location}
      title="Campus Policies"
      description="Check out our campus policies here"
    />
    <Banner
      title="Campus Policies"
      subtitle="We can't wait to welcome you on Campus! Before you arrive, here are some of our Campus policies that you need to be aware of."
      textColor={$.color.white}
      titleColor={$.color.white}
      backgroundColor={$.color.orange3}
      image={BannerImage}
    />
    <Policies />
  </>
);

PoliciesPage.defaultProps = {
  location: {},
};

PoliciesPage.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default PoliciesPage;
