import PropTypes from 'prop-types';

import SEO from '../components/seo';
import Banner from '../components/DatascienceBootcampAccelerator2021/Graduation/Banner';
import Videos from '../components/DatascienceBootcampAccelerator2021/Graduation/Videos';
import Navbar from '../components/DatascienceBootcampAccelerator2021/Navbar';

const ImdaGrad = ({ location }) => (
  <>
    <SEO
      title="AWS Accelerator Class of 2021 Graduation [LIVE STREAM]"
      location={location}
      description="Watch class graduation videos and live stream of our AWS Accelerator Class of 2021 here"
    />
    <Navbar />
    <Banner />
    <Videos />
  </>
);

ImdaGrad.defaultProps = {
  location: {},
};

ImdaGrad.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default ImdaGrad;
