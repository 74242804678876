import { graphql } from 'gatsby';

import Seo from '../../components/seo';
import { Provider } from '../../components/courses';
import { Banner, Cards } from '../../components/courses/coursesPage';

export const query = graphql`
  query {
    allCoursesJson(
      filter: { trainingPartners: { elemMatch: { id: { eq: "google" } } } }
    ) {
      edges {
        node {
          id
          trainingPartners {
            id
            name
          }
          title
          slug
          description
          learningGoals
          coursePartnerLink
          skillPills {
            id
            name
          }
          courseCard {
            image
            nextStartDate {
              upcomingModule
              date
              day
            }
            modulesInformation
            courseLevel
            feesInformation
          }
          overview
          courseOutline {
            id
            description
            link
            date
          }
          whoShouldAttend {
            id
            name
          }
          instructors {
            id
            name
            credentials
            profileLink
            image
            bio
          }
        }
      }
    }
    allFile(
      filter: {
        relativePath: { eq: "partners/google/google-cloud-partner.png" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const SMUCoursesIndexPage = ({ data }) => {
  return (
    <>
      <Provider
        value={{
          trainingPartner: 'google',
          courses: data.allCoursesJson.edges.map(({ node }) => node),
          banner: {
            title: 'Google Training',
            className: 'google',
            description:
              'Get professional IT training and certifications from the SMU Academy at the Singapore Management University. Learn in-demand skills in computer programming, data science and cloud computing from our highly qualified instructors teaching at SMU Academy.',
            image: data.allFile.edges[0].node.childImageSharp.gatsbyImageData,
          },
        }}
      >
        <Seo title="Google Training | SG Code Campus" />
        <Banner />
        <Cards />
      </Provider>
    </>
  );
};

export default SMUCoursesIndexPage;
