import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import { Title, Body } from './common';

const Container = styled.div`
  grid-area: overview;

  ${({ theme }) => theme.br}

  & > ${Body} {
    & a {
      color: ${({ theme }) => theme.color.blue3};
      text-decoration: none;

      &:visited {
        color: ${({ theme }) => theme.color.blue3};
      }
    }
  }
`;

const Overview = () => {
  const { overview } = useContext(Context);

  return (
    <Container id="overview">
      <Title>Course Overview</Title>
      <Body dangerouslySetInnerHTML={{ __html: overview }} />
    </Container>
  );
};

export default Overview;
