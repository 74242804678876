import { useState } from 'react';
import styled from 'styled-components';

import Logo from './Logo';
import $ from '../../styles/global';
import BurgerIcon from './Mobile/BurgerIcon';
import HeroBanner from './Desktop/HeroBanner';
import Menu from './Desktop/Menu';
import Sidebar from './Mobile/Sidebar';

const Container = styled.div`
  padding: ${$.layout().padding4}px;
  background-color: ${$.color.white};
  position: sticky;
  top: 0;
  z-index: 9999;
  height: 100%;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${$.layout().padding3}px 0;
  }
  // #endregion
`;

const NavbarContainer = styled.div`
  background-color: ${$.color.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: 1100px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    align-items: center;
  }
  // #endregion
`;

const Navbar = () => {
  const [counters, setCounters] = useState({});
  const [prevTab, setPrevTab] = useState('');
  const [heroActive, setHeroActive] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <Container>
      <NavbarContainer>
        <Logo setHeroActive={setHeroActive} />
        <BurgerIcon setOpenSidebar={setOpenSidebar} openSidebar={openSidebar} />
        <Menu
          counters={counters}
          setCounters={setCounters}
          prevTab={prevTab}
          setPrevTab={setPrevTab}
          heroActive={heroActive}
          setHeroActive={setHeroActive}
        />
      </NavbarContainer>
      <HeroBanner
        tab={prevTab}
        setTab={setPrevTab}
        setHeroActive={setHeroActive}
      />
      <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
    </Container>
  );
};

export default Navbar;
