import Seo from '../../components/seo';
import DeepLearning from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/DeepLearning';

const DeepLearningPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <DeepLearning />
  </>
);

export default DeepLearningPage;
