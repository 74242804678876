import Seo from '../../components/seo';
import SCTPAWS from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/SCTPAWS';

const SCTPAWSPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <SCTPAWS />
  </>
);

export default SCTPAWSPage;
