import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import switchBubble from './switchBubble';
import { Context } from './Context';

const Container = styled.div`
  margin-bottom: ${$.layout().margin3}px;
  overflow: auto;
  position: relative;
  z-index: 1;
`;

const Slider = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    appearance: none;
    height: 10px;
    border-radius: 4px;
    background-color: rgba(5, 83, 153, 0.45);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${$.color.blue3};
  }

  &.active {
    padding: ${$.layout().padding4}px 0;
  }
`;

const BubbleContainer = styled.div`
  margin-left: ${$.layout().margin4}px;
  margin-right: ${$.layout().margin4}px;

  & > img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 999px;
    box-shadow: ${$.dropShadow.normal};
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    & > img {
      width: 160px;
      height: 160px;
    }
  }
  // #endregion
`;

const BottomSlider = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <Context.Consumer>
      {({ setCurrent, setDesc, data }) => (
        <Fade bottom distance="100px">
          <Container>
            <Slider className={show === true ? 'active' : ''}>
              {data.map(({ avatarURL, key }, index) => (
                <BubbleContainer
                  key={`${key}_lower`}
                  onClick={() => {
                    switchBubble({
                      newIndex: index,
                      setCurrent,
                      setDesc,
                      desc: data[index].description,
                    });
                  }}
                >
                  <img
                    src={avatarURL}
                    alt={`${data[index].bubble.anchor} profile img`}
                  />
                </BubbleContainer>
              ))}
            </Slider>
          </Container>
        </Fade>
      )}
    </Context.Consumer>
  );
};

export default BottomSlider;
