import PropTypes from 'prop-types';

import Banner from '../../components/MiniBanner';
import Content from '../../components/whyus/partners/Content';
import SEO from '../../components/seo';

const Partners = ({ location }) => (
  <>
    <SEO
      keywords={['Coding Partners', 'Corporate Coding Events']}
      location={location}
      title="Our Clients"
      description="Read up on our satisfied corporate clients"
    />
    <Banner
      title="Our Clients"
      subtitle="It has been our privilege working with these corporate clients to spread the power of code."
    />
    <Content />
  </>
);

Partners.defaultProps = {
  location: {},
};

Partners.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default Partners;
