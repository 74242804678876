const initialState = {
  page: 1,
};

export default (state = initialState, { payload, type }) => {
  const newState = { ...state };
  switch (type) {
    case 'UPDATE_PREVIOUS_BLOG_PAGE':
      newState.page = payload.page;
      return newState;
    default:
      return state;
  }
};
