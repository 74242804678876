import { graphql, useStaticQuery } from 'gatsby';

import $ from '../../styles/global';
import Level2Banner from '../../components/Level2Banner';
import LeftImageContainer from '../../components/blog/banner/LeftImageContainer';
import RightImageContainer from '../../components/blog/banner/RightImageContainer';
import MobileImageContainer from '../../components/blog/banner/MobileImageContainer';
import TagsContainer from '../../components/blog/tags/TagsContainer';

const Tags = () => {
  const {
    allGhostTag: { edges },
  } = useStaticQuery(graphql`
    {
      allGhostTag(
        sort: { fields: name }
        filter: { slug: { nin: "data-schema" } }
      ) {
        edges {
          node {
            id
            name
            slug
            postCount
          }
        }
      }
    }
  `);

  return (
    <>
      <Level2Banner
        title="Categories"
        subtitle="Our blog articles are organised by topic into categories for ease of exploration. Pick one and go wherever it takes you!"
        path="/blog/tags/"
        color={$.color.white}
        backgroundColor={$.color.blue4}
      >
        <LeftImageContainer />
        <RightImageContainer />
        <MobileImageContainer />
      </Level2Banner>
      <TagsContainer data={edges} />
    </>
  );
};

export default Tags;
