import { memo, useRef, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../styles/global';
import context from '../context';
import Utils from '../../../../utils';
import CourseDays from './CourseDays';
import NoClassText from './NoClassText';
import UpChevron from '../../../../assets/icons/up-chevron.svg';

const Container = styled.div.attrs(({ visible }) => ({
  style: {
    display: visible ? 'initial' : 'none',
  },
}))`
  font-family: Lato Regular;
  font-size: 14px;
  position: absolute;
  box-shadow: ${$.dropShadow.repressed};
  background-color: ${$.color.blue3};
  border-radius: ${$.border().radius2}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: 360px;
    padding: ${$.layout().padding4}px;
    color: ${$.color.white};
    top: -${$.layout().padding4}px;
    left: -${$.layout().padding4}px;
    cursor: default;
    z-index: 9999;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(
      ${({ filteredDataLength, filteredData: { eventIndex } }) =>
        filteredDataLength % 2 && eventIndex === filteredDataLength - 1
          ? `50% - ${$.layout().padding4 * 2}px - ${$.layout().margin3 * 2}px`
          : `100% - ${$.layout().padding4 * 2}px - ${$.layout().margin3 * 2}px`}
    );
    max-width: 550px;
    padding: ${$.layout().padding4}px;
    color: ${$.color.white};
    top: 90px;
    right: ${$.layout().margin3}px;
    right: calc(
      ${({ filteredDataLength, filteredData: { eventIndex } }) =>
        filteredDataLength % 2 && eventIndex === filteredDataLength - 1
          ? `(100vw - ${$.layout().margin3 * 2}px) / 2 + ${
              $.layout().margin3 * 2
            }px`
          : `${$.layout().margin3}px`}
    );
    cursor: default;
    z-index: 9999;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    left: 0;
    width: calc(100vw - ${$.layout().padding2 * 2}px);
    padding: ${$.layout().padding4}px ${$.layout().padding2}px;
    /* transform: translateY(-24px); */
  }
  // #endregion
`;

const HideScheduleLink = styled.div`
  display: inline-block;
  cursor: pointer;
  border-bottom: 2px solid ${$.color.blue6};
  margin-bottom: ${$.layout().margin4}px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;
    vertical-align: middle;
    color: ${$.color.blue6};
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;
    vertical-align: middle;
    color: ${$.color.blue6};
  }
  // #endregion
`;

const StyledUpChevron = styled(UpChevron)`
  height: 6px;
`;

const PopUp = memo(({ popUpVisibility, setPopUpVisibility }) => {
  const popUpRef = useRef(null);
  const {
    filteredData,
    filteredDataLength,
    event: { dates },
  } = useContext(context);

  Utils.useOutsideClick(popUpRef, () => {
    setPopUpVisibility(false);
  });

  return (
    <Container
      visible={popUpVisibility}
      ref={popUpRef}
      filteredData={filteredData}
      filteredDataLength={filteredDataLength}
    >
      <HideScheduleLink
        onClick={() => {
          setPopUpVisibility(false);
        }}
      >
        <div>
          {`Hide Schedule`}
          <StyledUpChevron />
        </div>
      </HideScheduleLink>
      <CourseDays />
      {dates.skipped && !!dates.skipped ? <NoClassText /> : ''}
    </Container>
  );
});

PopUp.defaultProps = {
  setPopUpVisibility: () => {},
};

PopUp.propTypes = {
  popUpVisibility: PropTypes.bool.isRequired,
  setPopUpVisibility: PropTypes.func,
};

export default PopUp;
