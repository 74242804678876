import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import Utils from '../../utils';

const Link = styled.a`
  font-family: Lato Bold;
  font-size: 15px;
  text-decoration: none;
  color: ${$.proTraining.orange2};
`;

const RemoteLink = ({ href, children }) => (
  <Link href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </Link>
);

RemoteLink.defaultProps = {
  href: '',
  children: '',
};

RemoteLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]),
};

const Margin20 = styled.div`
  margin-top: 20px;
  margin-bottom: 0;
  width: 100%;
  height: 1px;
`;

const Margin10 = styled.div`
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
  height: 1px;
`;

const ImageContainer = styled.div`
  ${({ width = 100, height = 100 }) => `
  width: ${width}px;
  height: ${height}px;

  & > * {
    width: 100%;
    height: 100%;
  }
`}
`;

const ContentImage = ({ width, height, path }) => (
  <ImageContainer width={width} height={height}>
    <img src={Utils.importOneImage(path)} alt="" />
  </ImageContainer>
);

ContentImage.defaultProps = {
  width: 100,
  height: 100,
};

ContentImage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  path: PropTypes.string.isRequired,
};

export { RemoteLink, Margin20, Margin10, ContentImage };
