import { useEffect } from 'react';
import Pikaday from 'pikaday';

import Util from '../../../utils';
import '../../../styles/pikaday.css';

// All actions related to the search bar can be found here.
// Moved here because we have different layouts of searchBar,
// but we keep reusing these functions.

/**
 * Updates the state on which dropdown was clicked.
 * Used to determine which dropdown to show/hide.
 * For the datepicker, we need to manually trigger the hide/show for it.
 * @param {string} key - Classname of the clicked dropdown text.
 * @param {string} clicked - The classname of the clicked dropdown text.
 * @param {Pikaday} picker - Instance of the Pikaday datepicker.
 * @param {func} searchBarUIUpdate - Redux action emitter.
 */
const handleOnClick = ({ clicked, key, picker, searchBarUIUpdate }) => {
  const newState = clicked === key ? '' : key;

  switch (key) {
    case 'allDates':
      if (newState === '') {
        picker.hide();
        searchBarUIUpdate({ clicked: '' });
      } else {
        picker.show();
        searchBarUIUpdate({ clicked: key });
      }
      break;
    default:
      searchBarUIUpdate({ clicked: newState });
      break;
  }
};

/**
 * Updates the state everytime the user clicks on a checkbox.
 * Also does the following:
 * 1. User clicks on 'All X'. Every other checkbox is unchecked.
 * 2. User clicks on anything but 'All X'. 'All X' checkbox is unchecked.
 * @param {string} key - The className of the dropdown.
 * @param {string} value - What choice did the user clicked on?
 * @param {bool} checked - Is the choice checked or unchecked?
 * @param {object} searchBarData - All previous info of the searchbar items that we clicked on.
 * @param {func} searchBarDataUpdate - Redux action emitter.
 */
const handleOnChange = ({
  searchBarData,
  searchBarDataUpdate,
  key,
  checkedItems,
}) => {
  const newCheckedItems = { ...checkedItems };
  const currCheckedItems = searchBarData[key];
  const givenCheckedItems = Object.keys(checkedItems);
  const allCheckedItems = [...currCheckedItems, ...givenCheckedItems];
  const uncheckedAllItems = currCheckedItems.filter(
    (currItem) => newCheckedItems[currItem] === false
  );
  const update = {};

  if (checkedItems.all === true) {
    allCheckedItems.forEach((item) => {
      newCheckedItems[item] = false;
    });
    newCheckedItems.all = true;
    update[key] = newCheckedItems;
    searchBarDataUpdate(update);
    return;
  }

  if (
    currCheckedItems.indexOf('all') > -1 &&
    typeof checkedItems.all === 'undefined'
  ) {
    newCheckedItems.all = false;
    update[key] = newCheckedItems;
    searchBarDataUpdate(update);
    return;
  }

  if (uncheckedAllItems.length === currCheckedItems.length) {
    newCheckedItems.all = true;
  }

  update[key] = newCheckedItems;
  searchBarDataUpdate(update);
};

/**
 * Init items that is needed by the searchBar.
 * E.g. listen to outside clicks, handles init of the pikaDay.
 * @param {HTML element} searchBarRef - HTML node of the searchBar section.
 * @param {func} searchBarUpdate - Redux action emitter.
 * @param {func} setPicker - useState's given function to set state.
 */
const initPikaday = ({
  searchBarRef,
  searchBarDataUpdate,
  searchBarUIUpdate,
  searchBarUIData,
  setPicker,
}) => {
  // Inits pikaday and remove it once we unmount.
  useEffect(() => {
    let firstInit = 'undefined';

    if (searchBarRef !== null && searchBarRef.current) {
      firstInit = new Pikaday({
        trigger: searchBarRef.current.querySelector('.all-dates'),
        field: searchBarRef.current.querySelector('.calendar'),
        onSelect: (dateString) => {
          const update = {};
          update.allDates = `${new Date(dateString).getTime()}`;
          searchBarUIUpdate({ clicked: '' });
          searchBarDataUpdate(update);
        },
        onClose: () => {
          // Only close the mobile calendar dropdown if the dropdown is still active!
          // On desktop, close the mobile calendar whenever another dropdown is active.
          const closeWhenMobile =
            Util.isMobile() &&
            searchBarRef.current.querySelector('.active.all-dates');
          const closeWhenDesktop =
            Util.isDesktop() && searchBarUIData.clicked !== '';

          if (closeWhenDesktop || closeWhenMobile) {
            searchBarUIUpdate({ clicked: '' });
          }
        },
      });

      setPicker(firstInit);
    }

    return () => {
      if (firstInit) {
        firstInit.destroy();
        firstInit.el.remove();
      }
    };
  }, []);
};

/**
 * Returns a list of choices user has chosen from the dropdown,
 * and stringify them into a readable text.
 * @param {[Object]} availableChoices - An array of dropdown item.
 *         {string} availableChoices[].value - Value of the dropdown item. Used as ID.
 *         {string} availableChoices[].text - The readable text to show to user.
 * @param {[string]} chosenChoices - An array of dropdown item value.
 * @return {string}
 */
const getStringifiedChoices = ({ availableChoices, chosenChoices }) => {
  const list = [];
  availableChoices.forEach((item) => {
    if (chosenChoices.indexOf(item.value) > -1) {
      list.push(item.text);
    }
  });
  return list.join(', ');
};

/**
 * Converts unix time to a string-based date.
 * @param {string} unixTime
 * @returns {string}
 */
const getStringDate = (unixTime) => {
  const date = new Date(parseInt(unixTime, 10));
  const day = date.getDate();
  const month = date.toLocaleDateString('en-US', { month: 'long' });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

/**
 * Reverts the reducer data back to default values if we switched to another page
 * @param {function} searchBarClear - Fires a redux action that clears the reducer values.
 */
const clearReduxData = ({ searchBarClear }) => {
  useEffect(() => {
    const location = window.location.pathname;

    return () => {
      if (location !== window.location.pathname) {
        searchBarClear();
      }
    };
  }, []);
};

/**
 * Reads the url query string and populates the reducer with the given values.
 * Inits once when first load the schedule page.
 * @param {string} urlParamsString - The full query string
 * @param {function} searchBarDataUpdate - the redux action to update the reducer values
 */
const parseUrlParamsToRedux = (urlParamsString, searchBarDataUpdate) => {
  const allArrayKeys = ['allCourses', 'allLocations', 'allFormats', 'allAges'];
  const allStringKeys = ['allDates'];
  const splitValues = ({ searchParams, key }) => {
    const obj = {};

    if (!searchParams.has(key)) {
      obj.all = true;
    } else {
      obj.all = false;
      searchParams
        .get(key)
        .split(',')
        .forEach((item) => {
          obj[item] = true;
        });
    }

    return obj;
  };

  useEffect(() => {
    if (typeof URLSearchParams !== 'undefined') {
      const queryObject = {};
      const searchParams = new URLSearchParams(urlParamsString);
      const hiddenParams = ['allSubCourses', 'allEventIds'];

      Array.from(searchParams.keys()).forEach((key) => {
        if (allStringKeys.indexOf(key) > -1) {
          allStringKeys.forEach((allKey) => {
            queryObject[allKey] = searchParams.get(allKey);
          });
        }

        if (hiddenParams.indexOf(key) > -1 && searchParams.has(key) === true) {
          queryObject[key] = [];
          searchParams
            .get(key)
            .split(',')
            .forEach((item) => {
              queryObject[key].push(item);
            });
        }

        if (allArrayKeys.indexOf(key) > -1) {
          allArrayKeys.forEach((allKey) => {
            queryObject[allKey] = splitValues({
              searchParams,
              key: allKey,
            });
          });
        }
      });

      searchBarDataUpdate(queryObject);
    }
  }, []);
};

export default {
  clearReduxData,
  initPikaday,
  handleOnChange,
  handleOnClick,
  getStringifiedChoices,
  getStringDate,
  parseUrlParamsToRedux,
};
