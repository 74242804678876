import { useEffect } from 'react';

/**
 * Informs if a click outside the given ref is done.
 * @param {React.reference} ref - Reference to the component
 * @param {func} callback - A callback function to run if user clicked outside the component
 */
const useOutsideClick = (ref, callback) => {
  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback(event);
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    };
  }, []);
};

export default useOutsideClick;
