import PropTypes from 'prop-types';

import TeamProfiles from '../components/whyus/team/TeamProfiles';
import JoinUsBanner from '../components/whyus/team/JoinUsBanner';
import SEO from '../components/seo';
import Banner from '../components/whyus/team/Banner';

const Team = ({ location }) => (
  <>
    <SEO
      keywords={['Coding Instructors for kids', 'best coding instructors']}
      location={location}
      title="Our Team"
      description="Meet the instructors and developers of SG Code Campus"
    />
    <Banner />
    <TeamProfiles expandProfileFirst="ian" />
    <JoinUsBanner />
  </>
);

Team.defaultProps = {
  location: {},
};

Team.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default Team;
