import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../styles/global';
import Checkbox from './Checkbox';

const Dropdown = styled.div.attrs({ className: 'menu' })`
  max-height: 200px;
  box-shadow: ${$.dropShadow.normal};
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  position: absolute;
  transition: all 0.2s ease-in-out;
  transform: translateY(-20px);
  background-color: ${$.color.white};
  padding: ${$.layout().padding5}px ${$.layout().padding5}px
    ${$.layout().padding5}px ${$.layout().padding4}px;
  width: calc(180px - (${$.layout().padding4}px + ${$.layout().padding5}px));

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    top: 53px;
    left: -3px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    top: 40px;
    left: 1px;
    width: calc(100% - (${$.layout().padding3}px * 2));
    padding: ${$.layout().padding3}px;
  }
  // #endregion
`;

const DropdownContent = styled.div`
  overflow: auto;
  max-height: inherit;
  padding-right: ${$.layout().padding5}px;

  // To style a scrollbar, it needs to be a webkit.
  // Works fine in all modern browsers except Firefox Quantum.
  // Scrollbar-track is the scrollable area.
  // Scrollbar-thumb is the long pill that you click to scroll.
  // To init the webkit scrollbar, ::-webkit-scrollbar needs to be there.
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    //$.color.blue3. We need to use RGBA to use opacity.
    background-color: rgba(5, 83, 153, 0.45);
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${$.color.blue3};
    border-radius: 6px;
  }
`;

const CheckboxText = styled.label`
  ${({ checked }) => `
  display: inline-block;
  width: calc(100% - 16px - 5px);
  opacity: ${checked ? 1 : 0.45};
`}
`;

const DropdownItem = styled.div`
  padding: ${$.layout().padding5}px 0;
  color: ${$.color.blue3};
  font-family: Aileron Heavy;
  font-size: 12.5px;
  line-height: 1.3em;
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 15px;
  }
  // #endregion
`;

// Create a div to store the text for the dropdown. Once clicked,
// show a dropdown with checkboxes.
const Menu = ({ list, onChange }) => (
  <Dropdown>
    <DropdownContent>
      {list.map(({ text, value, checked, key }) => (
        <DropdownItem key={key}>
          <CheckboxText checked={checked}>{text}</CheckboxText>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              onChange(value, e.target.checked);
            }}
          />
        </DropdownItem>
      ))}
    </DropdownContent>
  </Dropdown>
);

Menu.defaultProps = {
  list: [],
  onChange: () => {},
};

Menu.propTypes = {
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  onChange: PropTypes.func,
};

export default Menu;
