import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import shortid from 'shortid';

import $ from '../../../styles/global';
import Card from './Card';
import cardCopies from './items.json';

const Section = styled.section`
  margin-bottom: ${$.layout().margin3}px;
`;

const Container = styled.div``;

const Title = styled.h2`
  color: ${$.color.blue4};
  font-family: Aileron Heavy;
  font-size: 48px;
`;

const KeyFeatures = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "onlineclasses/keyfeatures" } }
      ) {
        edges {
          node {
            childImageSharp {
              resize {
                originalName
              }
              gatsbyImageData(
                width: 600
                height: 600
                quality: 100
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Section>
        <Container>
          <Title>Key Features</Title>
        </Container>
      </Section>
      {cardCopies.map(({ imageName, message, title, alt }, index) => (
        <Card
          key={shortid.generate()}
          twos={(index + 1) % 2 === 0}
          alt={alt}
          img={
            edges.find(
              ({
                node: {
                  childImageSharp: {
                    resize: { originalName },
                  },
                },
              }) => originalName === imageName
            ).node.childImageSharp.gatsbyImageData
          }
          last={index + 1 === cardCopies.length}
          message={message}
          title={title}
        />
      ))}
    </>
  );
};

export default KeyFeatures;
