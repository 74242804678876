import styled from 'styled-components';
import PropTypes from 'prop-types';

import RightChevron from '../../assets/icons/right-chevron.svg';

const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  font-family: ${({ theme }) => theme.fontFamily.title.bold};
  color: ${({ theme }) => theme.color.blue4};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.layout().margin3}px;
`;

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.subtitle.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  color: ${({ theme }) => theme.color.blue4};
`;

const Body = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body.regular};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.color.blue4};
  line-height: 1.3;

  & b,
  strong {
    font-family: ${({ theme }) => theme.fontFamily.body.bold};
  }

  & i {
    font-family: ${({ theme }) => theme.fontFamily.body.boldItalic};
  }
`;

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.body.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.color.blue3};
`;

const LinkContainer = styled.a`
  --color: ${({ color, theme }) => {
    return color || theme.color.white;
  }};
  --background: ${({ background, theme }) => {
    return background || 'none';
  }};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => {
    return theme.layout().margin4;
  }}px;
  background-color: var(--background);
  padding: 12px 10px;
  color: var(--color);
  font-family: Lato Bold;
  font-size: 16px;
  text-decoration: none;
  border: 2px solid var(--color);
  min-width: 200px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: all 0.3s
    ${({ theme }) => {
      return theme.easingFn.standard;
    }};

  &:hover {
    color: ${({ background, theme }) => background || theme.color.blue3};
    cursor: pointer;
    background-color: ${({ color, theme }) => color || theme.color.white};

    & svg {
      fill: ${({ background, theme }) => background || theme.color.blue3};
    }
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: black;
    background-color: blue;
  }

  svg {
    width: 15px;
    height: 15px;
    fill: ${({ color, theme }) => color || theme.color.white};
    transition: all 0.3s
      ${({ theme }) => {
        return theme.easingFn.standard;
      }};

    @media ${({ theme }) => {
        return theme.device.desktop + ', ' + theme.device.tablet;
      }} {
      position: relative;
      bottom: 1px;
    }
  }
`;

const OutboundLink = ({ href, children, color, background }) => {
  return (
    <LinkContainer
      color={color}
      background={background}
      href={href}
      target="_blank"
      rel="nofollow noreferrer"
    >
      {children}
      <RightChevron />
    </LinkContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: Lato Regular;
  line-height: 18px;
  position: relative;

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.color.white};
    margin-left: 8px;
    transition: all 0.5s;
    right: 0;
    position: relative;
  }
`;

const Button = styled.div`
  background: radial-gradient(
    50% 628.51% at 50% 50%,
    ${({ theme }) => theme.proTraining.orange2} 0%,
    #ef7d05 100%
  );
  color: ${({ theme }) => theme.color.white};
  border-radius: 54px;
  padding: 12px 30px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  letter-spacing: 0;
  transition: left 0.3s, transform 0.3s, color 0.5s, letter-spacing 0.3s;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  user-select: none;

  & > span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.white};
    transform: translateY(200%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 20%);
    transition: 0.5s;
    top: 0;
    transition-delay: calc((var(--n) - 1) * 0.05s);
    z-index: -1;

    &:nth-child(2) {
      --n: 1;
    }

    &:nth-child(3) {
      --n: 2;
    }

    &:nth-child(4) {
      --n: 3;
    }

    &:nth-child(5) {
      --n: 4;
    }

    &:nth-child(6) {
      --n: 5;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.proTraining.orange2};
    svg {
      right: -10px;
      fill: ${({ theme }) => theme.proTraining.orange2};
    }

    & span {
      transform: translateY(0) scale(2);
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${({ theme }) => theme.device.tablet} {
    padding: 9px 27px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 20px;
  }
  // #endregion
`;

const Pill = ({ text, className }) => (
  <Button className={className}>
    <Container>{text}</Container>
    <span />
    <span />
    <span />
    <span />
    <span />
  </Button>
);

Pill.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};
Pill.defaultProps = {
  text: 'Learn More',
  className: '',
};

export default Pill;

export { Title, Subtitle, Body, OutboundLink, Label, Pill };
