import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../styles/global';

const DetailsContainer = styled.div`
  width: calc(970px - 200px - ${$.layout().margin2}px);
  margin: ${$.layout().margin3}px 0px;
`;

const DetailName = styled.div`
  font-family: Aileron Heavy;
  font-size: 48px;
  color: ${$.color.blue1};
  margin-bottom: ${$.layout().margin5}px;
`;

const CategoryTitle = styled.div`
  font-family: Cooper Hewitt Semibold;
  font-size: 18px;
  color: ${$.color.blue1};
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Details = styled.div`
  font-size: 16px;
  color: ${$.color.blue1};
  line-height: 1.4em;
  margin-bottom: ${({ theme }) => theme.layout().margin3}px;

  a {
    color: ${$.color.blue2};
    font-family: Lato Bold;
    text-decoration: none;
    &:visited {
      color: ${$.color.blue2};
    }
  }

  strong {
    font-family: Lato Bold;
  }

  ${$.br}
`;

const Description = ({
  name,
  desc,
  title,
  qualifications,
  education,
  workExperience,
}) => (
  <DetailsContainer>
    <DetailName>{name}</DetailName>
    {qualifications.length > 0 && (
      <>
        <CategoryTitle>
          Professional Qualifications & Certifications
        </CategoryTitle>
        <Details>
          {qualifications.map((qualification) => (
            <div key={qualification}>{qualification}</div>
          ))}
        </Details>
      </>
    )}
    {education.length > 0 && (
      <>
        <CategoryTitle>Education</CategoryTitle>
        <Details>
          {education.map((edu) => (
            <div key={edu}>{edu}</div>
          ))}
        </Details>
      </>
    )}
    {workExperience.length > 0 && (
      <>
        <CategoryTitle>Work Experience</CategoryTitle>
        <Details>
          {workExperience.map((experience) => (
            <div key={experience}>{experience}</div>
          ))}
        </Details>
      </>
    )}
    <Details dangerouslySetInnerHTML={{ __html: desc }} />
  </DetailsContainer>
);

Description.defaultProps = {
  name: '',
  desc: '',
  title: '',
  qualifications: [],
  workExperience: [],
  education: [],
};

Description.propTypes = {
  name: PropTypes.string,
  desc: PropTypes.string,
  title: PropTypes.string,
  qualifications: PropTypes.arrayOf(PropTypes.string),
  workExperience: PropTypes.arrayOf(PropTypes.string),
  education: PropTypes.arrayOf(PropTypes.string),
};

export default Description;
