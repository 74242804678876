import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import OrangeButton from '../ProfessionalTraining/OrangeButton';

const Section = styled.section`
  position: sticky;
  z-index: 999;
  top: 70px;
  background-color: ${$.color.white};
`;

const Container = styled.div`
  display: none;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    display: block;
    padding: 15px 0 10px 0;
  }
  // #endregion
`;

const NavDropdown = styled.select`
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  outline: none;
  border: 1px solid ${$.color.gray1};
  font-size: 15px;
  font-family: Lato Light;
  margin-bottom: 10px;
  color: ${$.color.black};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > a {
    text-align: center;
    &:first-child {
      text-transform: uppercase;
      width: calc(50% - 60px);
      margin-bottom: 0;
      margin-right: 5%;

      & > div {
        display: block;
      }
    }
    &:last-child {
      width: 45%;
      display: block;
      color: ${$.proTraining.orange2};
      font-family: Lato Bold;
      font-size: 16px;
      &:visited {
        color: ${$.proTraining.orange2};
      }
    }
  }
`;

const MobileNavigationMenu = ({
  items,
  applyNowLink,
  enquiryLink,
  disableApplyNowButton,
}) => (
  <Section>
    <Container>
      <NavDropdown
        onChange={(e) => {
          const val = e.target.value;

          if (val && typeof window !== 'undefined') {
            const element = document.getElementById(e.target.value);
            const headerOffset = 150;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition =
              elementPosition + window.scrollY - headerOffset;

            window.scrollTo({
              top: offsetPosition,
            });
          }
        }}
      >
        <option value="">--Jump to section--</option>
        {items.map(({ text, key }, index) => (
          <option key={`${key}_mobile`} value={index}>
            {text}
          </option>
        ))}
      </NavDropdown>
      <ButtonContainer>
        <OrangeButton
          text={disableApplyNowButton ? 'TBA' : 'Apply Now'}
          link={applyNowLink}
          disabled={disableApplyNowButton}
        />
        <a href={enquiryLink} target="_blank" rel="noopener noreferrer">
          Send Enquiry
        </a>
      </ButtonContainer>
    </Container>
  </Section>
);

MobileNavigationMenu.defaultProps = {
  items: [],
  applyNowLink: '',
  enquiryLink: '',
  disableApplyNowButton: false,
};

MobileNavigationMenu.propTypes = {
  disableApplyNowButton: PropTypes.bool,
  applyNowLink: PropTypes.string,
  enquiryLink: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default MobileNavigationMenu;
