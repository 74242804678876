import PropTypes from 'prop-types';

import SEO from '../../../components/seo';
import SMU from '../../../components/partners/SMU';

const SMUPage = ({ location }) => (
  <>
    <SEO
      location={location}
      keywords={[
        'coding classes for adults',
        'coding classes for adults singapore',
        'skills future programming course',
        'skills future coding course',
      ]}
      description="Professional training and certification at SMU Academy"
      title="Skills Future Coding & Programming Courses For Adults In Singapore"
    />
    <SMU />
  </>
);

SMUPage.defaultProps = {
  location: {},
};

SMUPage.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default SMUPage;
