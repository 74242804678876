import styled from 'styled-components';

import $ from '../../../styles/global';
import ImageContainer from '../../Level2Banner/ImageContainer';
import Banner3 from '../../../assets/images/holidaycamps/banner3.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: 0px;
    right: -50px;

    & > svg {
      width: 550px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tabletA} {
    position: absolute;
    top: 0;
    right: -200px;
    width: 70%;

    & > svg {
      width: 100%;
    }
  }

  @media ${$.device.tabletB} {
    position: absolute;
    top: 0;
    right: -140px;
    width: 70%;
    max-width: 600px;

    & > svg {
      width: 100%;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Image1Container = () => <Container image={Banner3} />;

export default Image1Container;
