import styled from 'styled-components';

import $ from '../../../styles/global';
import Utils from '../../../utils';

const Section = styled.section``;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.black};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const HorizontalCard = styled.div`
  border-radius: 10px;
  width: calc(100% - ${$.layout().padding3 * 2}px);
  overflow: hidden;
  transition: all 0.35s ease;
  text-decoration: none;
  box-shadow: ${$.dropShadow.normal};
  margin-bottom: ${$.layout().margin3}px;
  padding: ${$.layout().padding3}px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const HorizontalCardItem = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const HorizontalCardDateTitle = styled.div`
  font-size: 20px;
  font-family: Lato Bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${$.color.blue5};
  margin-bottom: 4px;
`;

const HorizontalCardTopTitle = styled.div`
  font-size: 24px;
  font-family: Lato Bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${$.color.blue5};
  margin-bottom: 4px;
`;

const HorizontalCardAddress = styled.div`
  font-size: 16px;
  line-height: 1.3em;
  color: ${$.color.black2};
`;

const PriceSection = styled.div`
  & > div {
    &:first-child {
      font-size: 28px;
      color: ${$.color.orange3};
      font-family: Lato Light;
      margin-bottom: 4px;
    }
    &:nth-child(2) {
      font-size: 16px;
      color: ${$.color.orange3};
      margin-bottom: ${$.layout().margin4}px;
    }
  }
`;

const Button = styled.a`
  background-color: ${$.color.blue3};
  padding: 20px;
  text-decoration: none;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: Lato Bold;
  letter-spacing: 1px;
  text-align: center;
  display: inline-block;
  color: ${$.color.white};
  min-width: 150px;

  &:hover {
    cursor: pointer;
  }
`;

const items = Utils.addKeys([
  // {
  //   link: 'https://www.eventbrite.sg/e/amazon-web-services-cloud-ai-bootcamp-22-26-may-10-5pm-m-f-tickets-601045422037',
  //   discount: '$1180 if purchased before 21 Apr 2023',
  //   card: [
  //     {
  //       date: '3 July 2023 - 7 July 2023 (Mon - Fri)',
  //       time: '10AM - 5PM (1 hour lunch break)',
  //       address: Utils.addKeys([
  //         {
  //           value: '10am - 5pm @ SG Code Campus (Tanjong Pagar)',
  //         },
  //       ]),
  //     },
  //   ],
  // },
]);

const Schedule = () => (
  <Section id="tertiary-sign-up">
    <Container>
      <Title>Schedule</Title>
      <Cards>
        {items.length === 0 && (
          <HorizontalCard>
            <HorizontalCardDateTitle>Coming Soon</HorizontalCardDateTitle>
          </HorizontalCard>
        )}
        {items.map(({ card, link, topTitle, discount, key }) => (
          <HorizontalCard key={key}>
            <div>
              {topTitle && (
                <HorizontalCardTopTitle>{topTitle}</HorizontalCardTopTitle>
              )}
              {card.map(({ date, address }, index) => (
                <HorizontalCardItem key={`item-${key}-${index}`}>
                  <HorizontalCardDateTitle>{date}</HorizontalCardDateTitle>
                  {address.map(({ key: aKey, value }) => (
                    <HorizontalCardAddress key={aKey}>
                      {value}
                    </HorizontalCardAddress>
                  ))}
                </HorizontalCardItem>
              ))}
            </div>
            <PriceSection>
              <div>$1280 w/o GST</div>
              <div>{discount}</div>
              <Button href={link} target="_blank" rel="noopener noreferrer">
                Sign up now
              </Button>
            </PriceSection>
          </HorizontalCard>
        ))}
      </Cards>
    </Container>
  </Section>
);

export default Schedule;
