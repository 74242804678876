import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/global';
import Right from '../../assets/icons/right-chevron.svg';

const Button = styled.div`
  padding: ${$.layout().padding4}px;
  border-radius: 999px;
  box-shadow: ${$.dropShadow.normal};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ clicked, normalBgColor, clickedBgColor }) => {
    if (clicked === true) {
      return clickedBgColor || $.color.blue3;
    }

    return normalBgColor || $.color.blue6;
  }};
  z-index: 10;

  & > svg {
    width: 20px;
    height: 20px;
    position: relative;
    left: 2px;
    fill: ${$.color.white};
  }
`;

const RightChevron = ({ normalBgColor, clickedBgColor }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <Button
      normalBgColor={normalBgColor}
      clickedBgColor={clickedBgColor}
      onClick={() => {
        setClicked(true);
      }}
      clicked={clicked}
    >
      <Right />
    </Button>
  );
};

RightChevron.defaultProps = {
  normalBgColor: '',
  clickedBgColor: '',
};

RightChevron.propTypes = {
  normalBgColor: PropTypes.string,
  clickedBgColor: PropTypes.string,
};

export default RightChevron;
