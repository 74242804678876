import shortid from 'shortid';

/**
 * Takes in 1 array of objects, and add keys to them.
 * @param {array} items - 1 array of objects.
 * @returns {array}
 */
const addKeys = items =>
  items.map(item => ({ ...item, key: shortid.generate() }));

export default addKeys;
