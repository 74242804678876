import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import { Title, Pill } from './common';

const Container = styled.div`
  grid-area: skillsYoullGet;
`;

const PillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.layout().padding4}px;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.device.mobile} {
    gap: 0 ${({ theme }) => theme.layout().padding5}px;
  }
`;

const FlexPill = styled(Pill)`
  flex: 1 0 auto;
`;

const SkillsYoullGet = () => {
  const { skillPills } = useContext(Context);

  return (
    <Container>
      <Title>Skills You'll Get</Title>
      <PillsContainer>
        {skillPills.map(({ id, name }) => (
          <FlexPill key={id} text={name} />
        ))}
      </PillsContainer>
    </Container>
  );
};

export default SkillsYoullGet;
