import shortid from 'shortid';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../../styles/global';
import data from '../items.json';
import Profile from './Profile';
import Util from '../../../../../utils';
import SliderView from '../../../../SliderView';

const Container = styled.div`
  display: block;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: none;
  }
  // #endregion
`;

const CategoryTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.title.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-bottom: ${({ theme }) => theme.layout().margin5}px;
`;

const CategoryBody = styled.div`
  margin-bottom: ${({ theme }) => theme.layout().margin3}px;
`;

const Desc = styled.div`
  ${$.br}
  a {
    font-family: Lato Bold;
    color: ${$.color.blue4};
    text-decoration: none;
    &:visited {
      color: ${$.color.blue4};
    }
  }
`;

const Mobile = ({ onlyIncludes, expandProfileFirst }) => (
  <Container>
    <SliderView
      data={data
        .filter(({ anchor }) => {
          if (onlyIncludes.length > 0 && onlyIncludes.includes(anchor)) {
            return true;
          }
          if (onlyIncludes.length === 0) {
            return true;
          }
          return false;
        })
        .map(
          ({
            name,
            avatarURL,
            qualifications,
            education,
            workExperience,
            description,
            title,
            anchor,
          }) => ({
            description: (
              <>
                {qualifications.length > 0 && (
                  <>
                    <CategoryTitle>Qualifications</CategoryTitle>
                    <CategoryBody>
                      {qualifications.map((qualification) => (
                        <div key={qualification}>{qualification}</div>
                      ))}
                    </CategoryBody>
                  </>
                )}
                {workExperience.length > 0 && (
                  <>
                    <CategoryTitle>Work Experience</CategoryTitle>
                    <CategoryBody>
                      {workExperience.map((experience) => (
                        <div key={experience}>{experience}</div>
                      ))}
                    </CategoryBody>
                  </>
                )}
                {education.length > 0 && (
                  <>
                    <CategoryTitle>Education</CategoryTitle>
                    <CategoryBody>
                      {education.map((edu) => (
                        <div key={edu}>{edu}</div>
                      ))}
                    </CategoryBody>
                  </>
                )}
                <Desc dangerouslySetInnerHTML={{ __html: description }} />
              </>
            ),
            avatarURL: Util.importOneImage(avatarURL),
            bubble: {
              anchor,
              content: (
                <Profile
                  title={title}
                  name={name}
                  qualifications={qualifications}
                  workExperience={workExperience}
                  education={education}
                />
              ),
            },
            key: shortid.generate(),
          })
        )}
      expandProfileFirst={expandProfileFirst}
    />
  </Container>
);

Mobile.propTypes = {
  onlyIncludes: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandProfileFirst: PropTypes.string.isRequired,
};

export default Mobile;
