import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import IMDALogo from '../../assets/images/landing/partners/imda.png';
import DunmanLogo from '../../assets/images/awsaccelerator2024/dunman-logo.png';
import FarrerParkLogo from '../../assets/images/awsaccelerator2024/farrer-park-hospital.png';
import SeoulGardenLogo from '../../assets/images/awsaccelerator2024/seoul-garden.png';
import NparksLogo from '../../assets/images/awsaccelerator2024/nparks.png';
import LaptopIcon from '../../assets/images/awsaccelerator2024/laptop.svg';
import PythonIcon from '../../assets/images/awsaccelerator2024/python.svg';
import CalendarIcon from '../../assets/images/awsaccelerator2024/calendar.svg';
import ArrowRightIcon from '../../assets/images/awsaccelerator2024/arrow-right.svg';
import AWSCloudPractitionerLogo from '../../assets/images/awsaccelerator2024/aws-certified-cloud-practitioner-logo.png';

const Section = styled.section`
  background-color: ${$.accelerator.grey};
`;

const Container = styled.div`
  padding: 100px 0;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 50px 0;
  }
  // #endregion
`;

const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const SupportingOrganisationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${$.layout().margin3}px;
  margin-bottom: ${$.layout().margin3}px;

  & > h2 {
    color: ${$.color.black};
    font-family: Lato Black;
    font-size: 40px;
    margin-bottom: ${$.layout().margin3}px;
  }
`;

const LogosContainer = styled.div`
  // NOTE: Desktop
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${$.layout().margin1}px;
  }

  // NOTE: Tablets
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${$.layout().margin1}px;
  }

  // NOTE: Mobiles
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: ${$.layout().margin2}px;
  }
`;

const LogoImage = styled.div`
  & > img {
    width: 100%;
  }

  &.farrer {
    flex: 1.1;
  }

  &.seoul {
    flex: 0.55;
  }

  &.nparks {
    flex: 0.55;
  }

  // NOTE: Mobile
  @media ${$.device.mobile} {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.farrer {
      flex: 1;

      & > img {
        width: 80%;
      }
    }

    &.seoul {
      flex: 1;

      & > img {
        width: 40%;
      }
    }

    &.nparks {
      flex: 1;

      & > img {
        width: 40%;
      }
    }
  }
`;

const DescriptionImage = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${$.layout().margin3}px;

  & > img {
    width: 230px;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin2}px;
    & > div {
      display: none;
    }
    & > img {
      width: 260px;
    }
  }
  // #endregion
`;

const Info = styled.div`
  width: 60%;
  h2 {
    color: ${$.color.black};
    font-family: Lato Black;
    font-size: 40px;
    margin-bottom: ${$.layout().margin3}px;
  }

  p {
    font-family: Lato Regular;
    font-size: 18px;
    color: ${$.color.black2};
    opacity: 0.8;
    line-height: 1.4em;
  }

  strong {
    font-family: Lato Bold;
    color: ${$.color.blue5};
  }

  a {
    color: ${$.color.blue3};
    font-family: Lato Bold;
    &:visited {
      color: ${$.color.blue3};
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;

    h2 {
      font-size: 34px;
    }

    p {
      font-size: 16px;
    }
  }
  // #endregion
`;

const Points = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: ${$.layout().padding3}px;

  & > div {
    width: 30%;
    & > h3 {
      font-family: Lato Black;
      font-size: 24px;
      color: ${$.color.black2};
      margin-bottom: ${$.layout().margin4}px;
      margin-top: 0px;
    }

    & > div:not(:last-child) {
      margin-bottom: ${$.layout().margin3}px;
    }

    & > p {
      font-size: 16px;
      font-family: Lato Regular;
      line-height: 1.3em;
      color: ${$.color.black2};
      opacity: 0.8;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: initial;

    & > div {
      width: 100%;
      margin-bottom: ${$.layout().margin2}px;

      & > h3 {
        margin-bottom: 20px;
      }

      & > div:not(:last-child) {
        margin-bottom: ${$.layout().margin4}px;
      }
    }
  }
  // #endregion
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.aws-partner-logo {
    & > img {
      width: 100px;
      height: auto;
      margin-right: ${$.layout().margin4}px;
    }

    & > div {
      width: calc(100% - 100px - ${$.layout().margin4}px);
      color: ${$.color.black2};
      opacity: 1;
      font-size: 18px;
      margin-bottom: ${$.layout().margin4}px;
    }
  }

  & > svg {
    width: 30px;
    height: 30px;
    fill: ${$.accelerator.blue};
    margin-right: ${$.layout().margin4}px;
  }

  & > div {
    width: calc(100% - 30px - ${$.layout().margin4}px);
    font-family: Lato Bold;
    font-size: 16px;
    color: ${$.color.black2};
    opacity: 0.8;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
  }
  // #endregion
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:visited {
    color: ${$.accelerator.blue};
  }

  & > div,
  & > span {
    color: ${$.accelerator.blue};
    font-size: 16px;
    font-family: Lato Bold;
    width: calc(100% - 20px);
  }

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${$.accelerator.blue};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    justify-content: initial;

    & > div,
    & > span {
      margin-right: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const StyledExternalLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;

  &:visited {
    color: ${$.accelerator.blue};
  }

  & > div {
    color: ${$.accelerator.blue};
    font-size: 16px;
    font-family: Lato Bold;
  }

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${$.accelerator.blue};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    justify-content: initial;

    & > div {
      margin-right: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const DunmanStyledLink = styled(StyledLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${$.layout().margin3}px;
  text-decoration: none;
`;

const DunmanImageContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    width: 60%;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const About = () => (
  <Section>
    <Container>
      <Description>
        <DescriptionImage>
          <img src={IMDALogo} alt="IMDA logo" />
        </DescriptionImage>
        <Info>
          <h2>About the Accelerator</h2>
          <p>
            The AWS Accelerator Programme 2024 is the 3rd year of an exciting
            initiative aimed at introducing students to cutting edge
            Machine/Deep Learning capabilities on the AWS Cloud. (Check out the
            2021 Accelerator website{' '}
            <Link to="/datascience-bootcamp-accelerator-2021/">here</Link>.)
          </p>
          <p>
            This fully-sponsored programme is supported by the{' '}
            <strong>Infocomm Media Development Authority of Singapore</strong>,
            and open to students enrolled in local Secondary Schools and Junior
            Colleges. Each participant will receive <strong>USD100</strong>{' '}
            worth of AWS cloud computing credits and also receive a Certificate
            of Completion issued by AWS, for the official Cloud Practitioner
            Essentials course (incorporated into the Accelerator programme).
          </p>
        </Info>
      </Description>
      <Description>
        <DunmanImageContainer>
          <img className="dunman" src={DunmanLogo} alt="Dunman logo" />
        </DunmanImageContainer>
        <Info>
          <h2>School Partner</h2>
          <p>
            Dunman Secondary School, as an AWS Academy Member Institution, is
            the official school partner for the 2024 AWS Accelerator.
            <DunmanStyledLink to="/datascience-accelerator/dunman/">
              <span>Learn how Dunman supports the AWS DeepRacer League</span>
              <ArrowRightIcon />
            </DunmanStyledLink>
          </p>
        </Info>
      </Description>
      <SupportingOrganisationContainer>
        <h2>Supporting Organisations</h2>
        <LogosContainer>
          <LogoImage className="farrer">
            <img src={FarrerParkLogo} alt="Farrer Park Hospital logo" />
          </LogoImage>
          <LogoImage className="seoul">
            <img src={SeoulGardenLogo} alt="Seoul Garden logo" />
          </LogoImage>
          <LogoImage className="nparks">
            <img src={NparksLogo} alt="NParks logo" />
          </LogoImage>
        </LogosContainer>
      </SupportingOrganisationContainer>
      <Points>
        <div>
          <h3>Requirements</h3>
          <Row>
            <LaptopIcon />
            <div>Laptop (Windows/Mac) and Internet connectivity</div>
          </Row>
          <Row>
            <PythonIcon />
            <div>
              Recommended at least 20 hours of Python programming experience
              attained elsewhere.
            </div>
          </Row>
        </div>
        <div>
          <h3>Schedule</h3>
          <Row>
            <CalendarIcon />
            <div>
              Mar 2024 - Dec 2024
              <br />
              (Graduation ceremony in Jan 2025)
            </div>
          </Row>
          <Row>
            <div>
              Check out the{' '}
              <StyledLink href="#schedule-table">full schedule</StyledLink>
            </div>
          </Row>
        </div>
        <div>
          <Row className="aws-partner-logo">
            <img
              src={AWSCloudPractitionerLogo}
              alt="AWS Cloud Practitioner logo"
            />
            <div>AWS Cloud Practitioner Essentials</div>
          </Row>
          <p>
            Take the foundational AWS Cloud Practitioner Essentials course as
            part of the AWS Accelerator and gain an overall understanding of AWS
            services, architectural principles and real-world applications.
          </p>
          <Row>
            <div>
              This programme does not include the certification exam fees.
            </div>
          </Row>
          <StyledExternalLink
            href="https://aws.amazon.com/training/classroom/aws-cloud-practitioner-essentials/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>Read more on official AWS course website</div>
            <ArrowRightIcon />
          </StyledExternalLink>
        </div>
      </Points>
    </Container>
  </Section>
);

export default About;
