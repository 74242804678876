import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../../styles/global';
import { Context } from '../Context';
import { Title, Prize, Students, Arrow } from '../GridStyles';

const Content = styled.div`
  ${({ scroll }) => `
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-right: ${$.layout().margin5}px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: ${
      typeof scroll.tab !== 'undefined' ? scroll.tab : 'rgba(5, 83, 153, 0.45)'
    };
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${
      typeof scroll.bg !== 'undefined' ? scroll.bg : $.color.blue3
    };
    border-radius: 6px;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    justify-self: flex-start;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    justify-self: flex-start;
  }
  // #endregion
`}
`;

const Info = styled.div`
  ${({ position }) => `
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    opacity: 1;
    top: 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    ${
      position === 'left'
        ? 'left: 0;'
        : 'right: 0; align-items: flex-end; text-align: right;'
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    position: absolute;
    opacity: 1;
    top: 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    ${
      position === 'left'
        ? 'left: 0;'
        : 'right: 0; align-items: flex-end; text-align: right;'
    }
  }
  // #endregion
`}
`;

const InnerContent = styled.div`
  ${({ linkColor }) => `
  line-height: 1.4em;
  opacity: 0;
  font-size: 17px;

  a {
    color: ${linkColor};
    font-family: Lato Bold;
    text-decoration: none;
    &:visited {
      color: ${linkColor};
    }
  }

  ${$.br}

  > iframe {
    display: block;
    margin-top: ${$.layout().margin4}px;
    width: 100%;
    max-width: 403.2px;
    height: 100vh;
    max-height: 250px;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 16px;
    margin-right: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-right: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    max-height: 0;
    width: calc(100% - ${$.layout().padding4}px);
  }
  // #endregion
`}
`;

const Container = styled.div`
  ${({ textColor, position, bgColor }) => `
  color: ${textColor};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 65%;
    height: auto;
    position: relative;
    overflow: hidden;

    ${Info} {
      padding: ${$.layout().padding3}px ${$.layout().padding4}px;
      height: calc(100% - ${$.layout().padding3 * 2}px);
      width: calc(100% - ${$.layout().padding4 * 2}px);
      transition: ${
        position === 'left'
          ? 'left 0.4s ease, opacity 0.3s ease'
          : 'right 0.4s ease, opacity 0.3s ease'
      };
    }

    ${Content} {
      margin: ${$.layout().padding3}px ${$.layout().margin4}px;
      height: calc(100% - ${$.layout().padding3 * 2}px);
      width: calc(100% - ${$.layout().margin4 * 2}px);
      transition: top 0.6s ease, opacity 0.5s ease;
    }

    ${InnerContent} {
      transition: all 0.4s ${$.easingFn.standard};
    }

    &.clicked {
      ${Info} {
        pointer-events: none;
        opacity: 0;
        ${position === 'left' ? 'left: -50%;' : 'right: -50%;'}
      }

      ${Content} {
        opacity: 1;
        top: 0;
      }

      ${InnerContent} {
        opacity: 1;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 70%;
    height: auto;
    position: relative;
    overflow: hidden;

    ${Info} {
      padding: ${$.layout().padding3}px ${$.layout().padding4}px;
      height: calc(100% - ${$.layout().padding3 * 2}px);
      width: calc(100% - ${$.layout().padding4 * 2}px);
      transition: ${
        position === 'left'
          ? 'left 0.4s ease, opacity 0.4s ease'
          : 'right 0.4s ease, opacity 0.4s ease'
      };
    }

    ${Content} {
      margin: ${$.layout().padding3}px ${$.layout().margin4}px;
      height: calc(100% - ${$.layout().padding3 * 2}px);
      width: calc(100% - ${$.layout().margin4 * 2}px);
      transition: top 0.4s ease, opacity 0.3s ease;
    }

    ${InnerContent} {
      transition: all 0.4s ${$.easingFn.standard};
    }

    &.clicked {
      ${Info} {
        pointer-events: none;
        opacity: 0;
        ${position === 'left' ? 'left: -100%;' : 'right: -100%;'}
      }

      ${Content} {
        opacity: 1;
        top: 0;
      }

      ${InnerContent} {
        opacity: 1;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    background-color: ${bgColor};
    padding: 100px ${$.layout().padding3}px ${$.layout().padding3}px
      ${$.layout().padding3}px;
    float: left;
    margin-top: 150px;
    width: calc(100vw - ${$.layout().padding3 * 2}px);
    text-align: ${position};

    ${InnerContent} {
      transition: all 0.4s ${$.easingFn.standard};
    }

    &.clicked {
      ${InnerContent} {
        opacity: 1;
        max-height: 290px;
      }
    }
  }
  // #endregion
`}
`;

const RectBox = ({ index, className }) => (
  <Context.Consumer>
    {({ items, allColors, clicked }) => {
      const { linkColor, scroll = {}, bgColor, textColor } = allColors[index];
      const {
        position,
        title,
        prize,
        students,
        story,
        dropdownVideo = '',
      } = items[index];
      const video =
        dropdownVideo !== ''
          ? `<iframe title="${title} video" src="${dropdownVideo}" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
          : '';

      return (
        <Container
          position={position}
          className={`${className}${clicked === index ? ' clicked' : ''}`}
          textColor={textColor}
          bgColor={bgColor}
        >
          <Info position={position}>
            {story && <Arrow textColor={textColor} position={position} />}
            <Title>{title}</Title>
            <Prize>{prize}</Prize>
            <Students>
              {students.map(({ name, age }) => `${name}, ${age}`).join(' | ')}
            </Students>
          </Info>
          {story && (
            <Content scroll={scroll}>
              <InnerContent
                dangerouslySetInnerHTML={{ __html: `${story} ${video}` }}
                linkColor={linkColor}
              />
            </Content>
          )}
        </Container>
      );
    }}
  </Context.Consumer>
);

RectBox.defaultProps = {
  index: 0,
  className: '',
};

RectBox.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
};

export default RectBox;
