import { useState, useRef } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';

import $ from '../../../../../styles/global';
import Util from '../../../../../utils';
import data from './items.json';
import Rectangle from './Rectangle';
import Box from './Box';
import { Provider } from './Context';

const items = Util.addKeys(data).map(({ students, ...rest }, index) => {
  const position = index % 2 === 0 ? 'left' : 'right';

  if (typeof students !== 'undefined') {
    return {
      students: Util.addKeys(students),
      position,
      ...rest,
    };
  }

  return {
    position,
    ...rest,
  };
});

const allColors = (() => {
  const all = [
    {
      bgColor: $.color.orange5,
      textColor: $.color.white,
      linkColor: $.color.blue2,
      scroll: { bg: $.color.orange1, tab: $.color.orange3 },
    },
    {
      bgColor: $.color.white,
      textColor: $.color.blue4,
      linkColor: $.color.blue5,
    },
    {
      bgColor: $.color.blue4,
      textColor: $.color.white,
      linkColor: $.color.blue2,
      scroll: { bg: $.color.blue5 },
    },
    {
      bgColor: $.color.blue5,
      textColor: $.color.white,
      linkColor: $.color.blue2,
    },
    {
      bgColor: $.color.blue1,
      textColor: $.color.blue4,
      linkColor: $.color.blue5,
    },
  ];
  const total = Math.ceil(items.length / all.length);
  let repeats = [];

  for (let i = 0; i < total; i += 1) {
    repeats = [...repeats, ...all];
  }

  return repeats;
})();

const Container = styled.div`
  overflow: hidden;
`;

const Grid = () => {
  const images = useStaticQuery(graphql`
    {
      allFile(filter: { dir: { regex: "/whyus/students/achievements/" } }) {
        edges {
          node {
            childImageSharp {
              resize {
                originalName
              }
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                transformOptions: { fit: COVER }
              )
            }
          }
        }
      }
    }
  `);
  const [clicked, setClicked] = useState(-1);
  const containerRef = useRef(null);

  Util.useOutsideClick(containerRef, () => {
    setClicked(-1);
  });

  Util.useAnchor();

  return (
    <Container ref={containerRef}>
      <Fade bottom distance="100px">
        <Provider
          value={{
            clicked,
            setClicked,
            allColors,
            items,
            images: [...images.allFile.edges],
          }}
        >
          {items.map(({ key }, index) => {
            const everyFour = index % 3 === 0;

            return everyFour === true && index !== 0 ? (
              <Box index={index} key={key} />
            ) : (
              <Rectangle index={index} key={key} />
            );
          })}
        </Provider>
      </Fade>
    </Container>
  );
};

export default Grid;
