import PropTypes from 'prop-types';

import Schedules from './Schedules';
import { Provider } from './context';

const CoursesScheduleViewer = ({
  data,
  filteredData,
  noCourseBannerTitle,
  noCourseBannerSubtitle,
  priorExpBannerSubtitle,
  q2Answer,
}) => {
  const location =
    typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <Provider
      value={{
        data,
        filteredData,
        noCourseBannerTitle,
        noCourseBannerSubtitle,
        priorExpBannerSubtitle,
      }}
    >
      <Schedules
        data={data}
        filteredData={filteredData}
        q2Answer={location.indexOf('schedule') > -1 ? 'None' : q2Answer}
      />
    </Provider>
  );
};

CoursesScheduleViewer.defaultProps = {
  data: [],
  filteredData: [],
  q2Answer: '',
  priorExpBannerSubtitle:
    'Students with prior coding experience, including students currently studying Computing for the iGCSE, IB or \'O\' Levels, are required to take a placement test before being placed in our advanced classes. To enquire about the placement test, please click <a href="https://docs.google.com/forms/d/e/1FAIpQLSeDjtfkjdlQKxtJH8ws9zyQAtpM9KTyc3ptcXNo5-s78d56AQ/viewform?embedded=true" target="_blank" rel="noreferrer noopener">here.</a>',
  noCourseBannerTitle: 'No classes are being run for Nov-Dec holidays.',
  noCourseBannerSubtitle:
    'For Principles 8 enquiries, email us at <a href="mailto:enquiries@sgcodecampus.com">enquiries@sgcodecampus.com</a>.',
};

CoursesScheduleViewer.propTypes = {
  q2Answer: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array]),
  filteredData: PropTypes.oneOfType([PropTypes.array]),
  noCourseBannerTitle: PropTypes.string,
  noCourseBannerSubtitle: PropTypes.string,
  priorExpBannerSubtitle: PropTypes.string,
};

export default CoursesScheduleViewer;
