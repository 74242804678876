import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../styles/global';
import Overlay from './Overlay';
import { Age, Title, Subtitle, MobileButton } from '../CardStyles';

const Card = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    height: calc(100% - ${$.layout().padding4}px * 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px ${$.layout().padding4}px;
    position: relative;
    & > svg {
      width: 100%;
      margin-bottom: ${$.layout().margin5}px;
    }
    & > a:last-child {
      width: calc(100% - (${$.layout().padding3}px * 2) + 2px);
      position: absolute;
      top: -${$.layout().padding2}px;
      left: 0;
      text-decoration: none;
    }
    & :hover {
      .card-overlay {
        opacity: 1;
        transition-delay: 0s, 0s, 0.4s;
        transform: translateY(0%);
      }
    }
  }
  // #endregion

  // NOTE: Tablets and above
  // #region
  @media ${$.device.tablet} {
    height: calc(100% - ${$.layout().padding4}px * 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px ${$.layout().padding4}px;
    position: relative;
    & > svg {
      width: 100%;
      margin-bottom: ${$.layout().margin5}px;
    }
    & > a:last-child {
      width: calc(100% - (${$.layout().padding3}px * 2) + 2px);
      position: absolute;
      top: -${$.layout().padding2}px;
      left: 0;
      text-decoration: none;
    }
    & :hover {
      .card-overlay {
        opacity: 1;
        transition-delay: 0s, 0s, 0.4s;
        transform: translateY(0%);
      }
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding3}px ${$.layout().padding1}px;
    background-color: ${({ bgColor }) => bgColor};
    & > svg {
      display: none;
    }
  }
  // #endregion
`;

const Divider = styled.div`
  width: 1px !important;
  background-color: ${$.color.gray1};
  opacity: 0.35;
  filter: blur(0.6px);
  height: calc(100% - ${$.layout().padding4}px * 2);

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Column = ({
  className,
  color,
  age,
  title,
  subtitle,
  link,
  button,
  subColor,
  description,
  corners,
  showDivider,
  image,
}) => (
  <>
    <Card bgColor={color} className={className}>
      <Age textColor={color}>{age}</Age>
      {image}
      <Title textColor={color}>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Link to={link}>
        <MobileButton
          normalColor={$.color.white}
          clickedColor={$.color.white}
          normalText={color}
        >
          {button}
        </MobileButton>
      </Link>
      <Link to={link}>
        <Overlay
          color={color}
          corners={corners}
          age={age}
          title={title}
          subColor={subColor}
          subtitle={subtitle}
          button={button}
          description={description}
        />
      </Link>
    </Card>
    {showDivider ? <Divider /> : ''}
  </>
);

Column.defaultProps = {
  className: '',
  color: '',
  age: '',
  title: '',
  subtitle: '',
  link: '',
  button: '',
  showDivider: false,
  corners: {
    topLeft: false,
    topRight: false,
    bottomLeft: false,
    bottomRight: false,
  },
  subColor: '',
  description: '',
  image: <></>,
};

Column.propTypes = {
  showDivider: PropTypes.bool,
  image: PropTypes.element,
  className: PropTypes.string,
  color: PropTypes.string,
  age: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
  button: PropTypes.string,
  corners: PropTypes.shape({
    topLeft: PropTypes.bool,
    topRight: PropTypes.bool,
    bottomLeft: PropTypes.bool,
    bottomRight: PropTypes.bool,
  }),
  subColor: PropTypes.string,
  description: PropTypes.string,
};

export default Column;
