import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../../styles/global';
import Util from '../../../../../../utils';
import BoxImage from './BoxImage';
import BoxInfo from './BoxInfo';
import { Context } from '../Context';

const LeftBoxImage = styled(BoxImage)``;

const RightBoxImage = styled(BoxImage)``;

const StyledBoxInfo = styled(BoxInfo)``;

const Container = styled.div`
  ${({ bgColor, position, story }) => `
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    height: 300px;
    width: 100%;
    position: relative;
    background-color: ${bgColor};
    ${
      position === 'left'
        ? `left: 30%; transition: left 0.4s ${$.easingFn.standard};`
        : `right: 30%; transition: right 0.4s ${$.easingFn.standard};`
    }

    ${LeftBoxImage} {
      transition: opacity 0.4s ease;
      order: 0;
      opacity: 1;
      ${position === 'right' ? 'right: 0; position: absolute; top: 0;' : ''};
    }

    ${RightBoxImage} {
      transition: opacity 0.4s ease;
      opacity: 0;
      order: 2;
      ${
        position === 'left'
          ? 'left: 0; position: absolute; top: 0;'
          : 'right: 0; position: absolute; top: 0;'
      };
    }

    ${StyledBoxInfo} {
      order: 1;
    }

    &.clicked {
      ${position === 'left' ? 'left: 0;' : 'right: 0;'}
      ${LeftBoxImage} {
        opacity: 0;
      }

      ${RightBoxImage} {
        opacity: 1;
      }
    }

    ${LeftBoxImage}, ${RightBoxImage} {
        > * {
          transform: scale(1, 1);
          transition: transform 0.3s ${$.easingFn.standard};
        }
      }

    &:hover {
      ${story ? 'cursor: pointer;' : ''}
      ${LeftBoxImage}, ${RightBoxImage} {
        > * {
          ${story ? 'transform: scale(1.1, 1.1);' : ''}
        }
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    height: 260px;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: ${bgColor};
    ${
      position === 'left'
        ? `left: 40%; transition: left 0.5s ${$.easingFn.standard};`
        : `right: 40%; transition: right 0.5s ${$.easingFn.standard};`
    }

    ${LeftBoxImage} {
      order: 0;
      opacity: 1;
      ${position === 'right' ? 'right: 0; position: absolute; top: 0;' : ''};
    }

    ${RightBoxImage} {
      order: 2;
      opacity: 0;
      ${position === 'left' ? 'left: 0; position: absolute; top: 0;' : ''};
    }

    ${StyledBoxInfo} {
      order: 1;
    }

    &.clicked {
      ${position === 'left' ? 'left: 0;' : 'right: 0;'}
      ${LeftBoxImage} {
        opacity: 0;
      }

      ${RightBoxImage} {
        opacity: 1;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    min-height: 400px;
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
    position: relative;

    ${LeftBoxImage} {
      position: absolute;
      top: 0;
      opacity: 1;
      z-index: 2;
    }

    ${RightBoxImage} {
      position: absolute;
      top: 0;
      opacity: 0;
      z-index: 2;
    }

    ${LeftBoxImage}, ${RightBoxImage}, ${StyledBoxInfo} {
      transition: all 0.5s ease-in-out;
      text-align: center;
    }

    &.clicked {
      ${LeftBoxImage} {
        opacity: 0;
      }

      ${RightBoxImage} {
        opacity: 1;
      }
    }
  }
  // #endregion
`}
`;

const Box = ({ index }) => (
  <Context.Consumer>
    {({ items, allColors, clicked, setClicked }) => {
      const { bgColor } = allColors[index];
      const { firstImg, secondImg, position = 'left', story } = items[index];
      const anchor = items.length - index;

      return (
        <Container
          bgColor={bgColor}
          position={position}
          story={story}
          title={`anchor: ${anchor}`}
          className={clicked === index ? `clicked ${anchor}` : anchor}
          onClick={() => {
            if (!story) {
              return;
            }

            // Allow user to tap on the container in desktop and tablet only.
            if (typeof window !== 'undefined' && Util.isMobile() === false) {
              setClicked((prev) => (prev === index ? -1 : index));
            }
          }}
        >
          <LeftBoxImage img={firstImg} position={position} index={index} />
          {story && (
            <RightBoxImage img={secondImg} position={position} index={index} />
          )}
          <StyledBoxInfo index={index} />
        </Container>
      );
    }}
  </Context.Consumer>
);

Box.defaultProps = {
  index: 0,
};

Box.propTypes = {
  index: PropTypes.number,
};

export default Box;
