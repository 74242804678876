import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import Header from './Header';
import Button from '../Button';

const Section = styled.section`
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion
`;

const Container = styled.div``;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  padding: ${$.layout().padding4}px;
  font-family: Aileron Heavy;
  font-size: 14px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding4}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    border-radius: 999px;
  }
  // #endregion
`;

const Step2 = () => (
  <Section>
    <Fade bottom distance="100px">
      <Container>
        <Header
          title="Step 2: Choose your preferred camp dates and times"
          subtitle="View our schedule to see available holiday coding bootcamp dates and times, pick one that fits your needs, and make your booking!"
        />
        <LinkContainer>
          <Link to="/schedule/?allFormats=holiday_camp">
            <StyledButton>See Our Full Schedule</StyledButton>
          </Link>
        </LinkContainer>
      </Container>
    </Fade>
  </Section>
);

export default Step2;
