import { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import Menu from '../Menu';
import BarActions from '../BarActions';
import Util from '../../../../utils';
import { Context } from '../context';
import {
  OptionContainer,
  LabelText,
  PillText,
  Pill,
  Dropdown,
  StyledRotatingDownChevron,
} from './DropdownStyles';

const SingleDropdown = ({ text, choices, name, picker }) => {
  const menuRef = useRef(null);
  const {
    searchBarData,
    searchBarDataUpdate,
    searchBarUIUpdate,
    searchBarUIData,
  } = useContext(Context);
  const getClassName = () => {
    const [firstClick, setFirstClick] = useState(false);
    let klass = '';

    if (searchBarData[name].indexOf('all') === -1 && firstClick === false) {
      setFirstClick(true);
    }
    if (searchBarData[name].indexOf('all') > -1 && firstClick === true) {
      setFirstClick(false);
    }
    if (searchBarUIData.clicked === name) {
      klass += ' active ';
    }
    if (firstClick) {
      klass += ' clicked ';
    }
    return klass;
  };

  Util.useOutsideClick(menuRef, () => {
    const isActive = menuRef.current.className.indexOf('active') > -1;
    const isVisible = menuRef.current.offsetParent !== null;

    if (isActive && isVisible) {
      searchBarUIUpdate({ clicked: '' });
    }
  });

  return (
    <OptionContainer>
      <LabelText>{text}</LabelText>
      <Dropdown className={getClassName()} ref={menuRef}>
        <Pill
          onClick={() => {
            BarActions.handleOnClick({
              key: name,
              clicked: searchBarUIData.clicked,
              searchBarUIUpdate,
              picker,
            });
          }}
        >
          <PillText>
            {BarActions.getStringifiedChoices({
              availableChoices: choices,
              chosenChoices: searchBarData[name],
            })}
          </PillText>
          <StyledRotatingDownChevron
            counter={searchBarUIData.rotatingCounter[name]}
          />
        </Pill>
        <Menu
          list={choices.map((item) => {
            const newItem = { ...item };

            newItem.checked = searchBarData[name].indexOf(newItem.value) > -1;

            return newItem;
          })}
          onChange={(value, checked) => {
            const checkedItems = {};

            checkedItems[value] = checked;

            BarActions.handleOnChange({
              key: name,
              checkedItems,
              searchBarData,
              searchBarDataUpdate,
            });
          }}
        />
      </Dropdown>
    </OptionContainer>
  );
};

SingleDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  choices: PropTypes.oneOfType([PropTypes.array]).isRequired,
  name: PropTypes.string.isRequired,
  picker: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default SingleDropdown;
