import SEO from '../components/seo';
import NotFound from '../components/NotFound';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found | SG Code Campus" />
    <NotFound />
  </>
);

export default NotFoundPage;
