import styled from 'styled-components';

import $ from '../../styles/global';
import { Title } from './FooterStyles';
import SocialIcons from './SocialIcons';

const Container = styled.div`
  & input::placeholder {
    color: ${$.color.blue6};
    opacity: 0.8;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    grid-area: footerConnected;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & input::placeholder {
      font-size: 15px;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    grid-area: footerConnected;
    display: flex;
    flex-direction: column;

    & input::placeholder {
      font-size: 15px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 1;
    width: 100%;

    & input::placeholder {
      font-size: 16px;
    }
  }
  // #endregion
`;

const TitleContainer = styled.div``;

const StayConnected = () => (
  <Container>
    <TitleContainer>
      <Title>Stay Connected</Title>
    </TitleContainer>
    <SocialIcons></SocialIcons>
  </Container>
);

export default StayConnected;
