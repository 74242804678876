import { useState, useContext, useRef } from 'react';
import styled from 'styled-components';

import { Context } from '../../Context';
import $ from '../../../../../styles/global';
import RotatingDownChevron from '../../../../Chevron/RotatingDownChevron';
import DropdownList from './DropdownList';
import Util from '../../../../../utils';

const Container = styled.div`
  width: calc(50% - ${$.layout().margin3}px);

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > div:first-child {
      width: calc((100% - ${$.layout().margin5}px) * 3 / 5);
    }

    & > div:last-child {
      width: calc((100% - ${$.layout().margin5}px) * 2 / 5);
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > div:first-child {
      width: calc((100% - ${$.layout().margin5}px) * 3 / 5);
    }

    & > div:last-child {
      width: calc((100% - ${$.layout().margin5}px) * 2 / 5);
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;

    & > div:first-child {
      margin-bottom: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const Question = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue5};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 16px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 16px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 20px;
  }
  // #endregion
`;

const DropdownContainer = styled.div`
  position: relative;
  user-select: none;
`;

const Pill = styled.div`
  font-family: Aileron Heavy;
  text-align: center;
  position: relative;
  border-radius: 500px;
  box-shadow: ${$.dropShadow.normal};
  cursor: pointer;
  width: calc(100% - ${$.layout().padding5 * 2}px);
  padding: ${$.layout().padding5}px;
  background-color: ${$.color.white};
  color: ${$.color.blue3};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 14px;
    &:hover {
      box-shadow: ${$.dropShadow.repressed};
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 14px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 17px;
  }
  // #endregion
`;

const ChevronContainer = styled.div`
  position: absolute;
  right: ${$.layout().margin4}px;
  top: ${$.layout().padding5}px;
  height: calc(100% - ${$.layout().padding5 * 2}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > svg {
    stroke: ${$.color.blue3};
    fill: ${$.color.blue3};
  }
`;

const Question1 = () => {
  const { recommenderQ1Selection } = useContext(Context);
  const [displayState, setDisplayState] = useState(false);
  const [counter, setCounter] = useState(0);
  const containerRef = useRef(null);

  const handleMainButtonMouseDown = () => {
    setDisplayState(!displayState);
    setCounter((prev) => prev + 1);
  };

  Util.useOutsideClick(containerRef, () => {
    setDisplayState(false);
    setCounter((prev) => prev + 1);
  });

  return (
    <Container>
      <Question>My child&apos;s age this year is:</Question>
      <DropdownContainer ref={containerRef}>
        <Pill
          onMouseDown={handleMainButtonMouseDown}
          dropdownState={displayState}
        >
          <span>{recommenderQ1Selection || 'Age'}</span>
          <ChevronContainer>
            <RotatingDownChevron counter={counter} />
          </ChevronContainer>
        </Pill>
        {displayState ? (
          <DropdownList
            displayState={displayState}
            setDisplayState={setDisplayState}
            setCounter={setCounter}
          />
        ) : (
          ''
        )}
      </DropdownContainer>
    </Container>
  );
};

export default Question1;
