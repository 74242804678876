import PropTypes from 'prop-types';

import Seo from '../components/seo';
import Banner from '../components/learnmore/PrivacyPolicy/Banner';
import Content from '../components/learnmore/PrivacyPolicy/Content';

const PrivacyPolicy = ({ location }) => (
  <>
    <Seo
      keywords={['privacy policy']}
      location={location}
      title="Privacy Policy"
      description="Read our privacy policy here"
    />
    <Banner />
    <Content />
  </>
);

PrivacyPolicy.defaultProps = {
  location: {},
};

PrivacyPolicy.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default PrivacyPolicy;
