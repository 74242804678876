import SEO from '../../components/seo';
import Banner from '../../components/DatascienceAccelerator2022/Dunman/Banner';
import Description from '../../components/DatascienceAccelerator2022/Dunman/Description';

const SchedulePage = () => (
  <>
    <SEO title="About Dunman Secondary" />
    <Banner />
    <Description />
  </>
);

export default SchedulePage;
