import styled from 'styled-components';

import $ from '../../styles/global';
import ArrowRightIcon from '../../assets/images/awsaccelerator2022/arrow-right.svg';

const Section = styled.section`
  background-color: ${$.accelerator.grey};
`;

const Container = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 50px 0;
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
  }
  // #endregion
`;

const Segment1 = styled.div`
  width: 30%;

  & > h2 {
    font-family: Lato Black;
    margin-bottom: ${$.layout().margin3}px;
    font-size: 40px;
  }

  & > p {
    font-family: Lato Regular;
    font-size: 16px;
    line-height: 1.3em;
    color: ${$.color.black2};
    opacity: 0.8;
    margin-bottom: ${$.layout().margin3}px;
  }

  & > div {
    font-family: Lato Bold;
    font-size: 16px;
  }

  & > div > div:not(:last-child) {
    margin-bottom: ${$.layout().margin3}px;
  }

  & > a {
    color: ${$.color.blue3};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin2}px;

    & > h2 {
      font-size: 34px;
    }
  }
  // #endregion
`;

const Number = styled.div`
  font-family: Lato Bold;
  font-size: 28px;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-right: ${$.layout().margin4}px;
    font-size: 24px;
  }
  // #endregion
`;

const Info = styled.div`
  > h3 {
    font-size: 24px;
    font-family: Lato Black;
    margin-bottom: ${$.layout().margin4}px;
    margin-top: 0;
  }

  > p {
    font-family: Lato Regular;
    font-size: 16px;
    line-height: 1.3em;
    color: ${$.color.black2};
  }
`;

const Segment2 = styled.div`
  width: 30%;

  & > ${Number} {
    color: ${$.accelerator.blue};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  // #endregion
`;

const Segment3 = styled.div`
  width: 30%;

  & > ${Number} {
    color: ${$.accelerator.orange};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  // #endregion
`;

const SubmitButton = styled.a`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-shadow: ${$.dropShadow.normal};
  padding: 10px 20px;
  min-width: 120px;
  background-color: ${$.accelerator.orange};
  border-radius: 5px;
  border: none;
  transition: box-shadow 0.3s ${$.easingFn.standard};
  text-decoration: none;

  & > span {
    font-family: Lato Bold;
    font-size: 16px;
    color: ${$.color.white};
  }

  & > svg {
    fill: ${$.color.white};
    width: 20px;
    height: 20px;
    padding: 0;
    margin-left: ${$.layout().margin4}px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: ${$.dropShadow.repressed};
  }
`;

const SignUp = () => (
  <Section>
    <Container id="sign-up-process">
      <Segment1>
        <h2>How to sign up?</h2>
        <p>
          Follow the instructions in this section. Applicants do not need their
          teacher&apos;s permission to attend the Accelerator. However, as we
          would like to keep your schools updated on your progress, schools will
          be emailed once at the start and at the end of the Accelerator. In the
          online registration form on this webpage, there will be a section for
          you to fill in the contact details of your CCA or form teacher (please
          get permission from them to fill in the form with their contact).
        </p>
        <div>
          <div>
            Email us at{' '}
            <a href="mailto:datascience@sgcodecampus.com">
              datascience@sgcodecampus.com
            </a>{' '}
            if you have any questions.
          </div>
          <div>23 Feb 2024 End of Registration for AWS Accelerator</div>
          <div>1 Mar 2024 Students notified of application status</div>
        </div>
      </Segment1>
      <Segment2>
        <Number>1</Number>
        <Info>
          <h3>Record a video introduction</h3>
          <p>
            Prepare and upload a 30- to 45-second video introducing yourself and
            why you want to join the AWS Accelerator 2024. If applicable,
            mention any prior programming experiences or any relevant background
            in AI or Deep Learning.
          </p>
        </Info>
      </Segment2>
      <Segment3>
        <Number>2</Number>
        <Info>
          <h3>Submit your video and application</h3>
          <p>
            Fill in your application details and upload your video from the
            previous step using the online application form linked below.
          </p>
          <SubmitButton
            onClick={() => {
              if (typeof window !== 'undefined') {
                // Tiktok tracking as requested by Trevor.
                window?.ttq?.track('ClickButton');
                window.open('https://forms.gle/svhGL4QgTFNYS22v5', '_blank');
              }
            }}
          >
            <span>Apply here</span>
            <ArrowRightIcon />
          </SubmitButton>
        </Info>
      </Segment3>
    </Container>
  </Section>
);

export default SignUp;
