import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Context } from '../../../Context';
import Checkbox from './Checkbox';
import $ from '../../../../../../styles/global';

const Container = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Aileron Heavy;
  font-size: 13px;
  padding: 0px ${$.layout().padding5}px 0px 0px;
  margin: 0px 0px ${$.layout().margin4}px;
  opacity: ${({ checked }) => (checked ? '1' : '0.45')};
`;

const Text = styled.div`
  width: 70%;
`;

const ListItem = ({ children }) => {
  const {
    recommenderQ1Selection: currentSelection,
    recommenderQ1UpdateSelection: updateSelection,
    answered,
    setAnswered,
  } = useContext(Context);

  const handleCheckboxChange = () => {
    /*
     * Checks for the currently clicked item with the currentSelection.
     * If the current selection matches the one that is currently clicked, current selection
     * will reset back to empty string.
     * If user has selected something different from the current selection, update the
     * currentSelection to reflect the new selection.
     */
    updateSelection(children === currentSelection ? '' : children);

    if (!answered) {
      setAnswered(true);
    }
  };

  return (
    <Container checked={currentSelection === children}>
      <Text>{children}</Text>
      <Checkbox
        checked={currentSelection === children}
        onChange={handleCheckboxChange}
      />
    </Container>
  );
};

ListItem.defaultProps = {
  children: '',
};

ListItem.propTypes = {
  children: PropTypes.string,
};

export default ListItem;
