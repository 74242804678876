import styled from 'styled-components';

import $ from '../../../../styles/global';

const Container = styled.div`
  width: 100%;
  overflow: scroll;
  z-index: 1;
  position: relative;
`;

const Table = styled.table`
  border: 1px solid ${$.color.black};
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;

  & td {
    min-width: 80px;
    padding: ${$.layout().padding5}px;
    border: 1px solid ${$.color.black};
  }

  & tr.highlight {
    background-color: ${$.color.gray1};
    font-family: Lato Bold;
  }

  & tbody {
    tr {
      &:first-child {
        font-family: Lato Bold;
      }
    }
  }
`;

const BootcampTable = () => (
  <Container>
    <Table>
      <thead>
        <tr className="highlight">
          <td colSpan="7">Bootcamp: Python Programming and Cloud Computing</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Session</td>
          <td>Term Week</td>
          <td>Day</td>
          <td>Date</td>
          <td>Time</td>
          <td>Hours</td>
          <td>Comment</td>
        </tr>
        <tr>
          <td>1</td>
          <td>June Holidays</td>
          <td>Mon</td>
          <td>31st May</td>
          <td>9:00AM - 12:00PM & 1:00PM - 4:00PM</td>
          <td>6</td>
          <td />
        </tr>
        <tr>
          <td>2</td>
          <td>June Holidays</td>
          <td>Tue</td>
          <td>1st June</td>
          <td>9:00AM - 12:00PM & 1:00PM - 4:00PM</td>
          <td>6</td>
          <td />
        </tr>
        <tr>
          <td>3</td>
          <td>June Holidays</td>
          <td>Wed</td>
          <td>2nd June</td>
          <td>9:00AM - 12:00PM & 1:00PM - 4:00PM</td>
          <td>6</td>
          <td />
        </tr>
        <tr className="highlight">
          <td colSpan="7">Bootcamp: DeepRacer League Qualifiers</td>
        </tr>
        <tr>
          <td rowSpan="8">4</td>
          <td>June Holidays</td>
          <td>Thurs</td>
          <td>3rd June</td>
          <td>9:00AM - 11:30AM</td>
          <td rowSpan="8">2.5</td>
          <td rowSpan="8">
            Each Participant will be allocated to, and will only attend one of
            these time slots (2 hour 30 minutes)
          </td>
        </tr>
        <tr>
          <td>June Holidays</td>
          <td>Thurs</td>
          <td>3rd June</td>
          <td>12:30PM - 3:00PM</td>
        </tr>
        <tr>
          <td>June Holidays</td>
          <td>Thurs</td>
          <td>3rd June</td>
          <td>4:00PM - 6:30PM</td>
        </tr>
        <tr>
          <td>June Holidays</td>
          <td>Fri</td>
          <td>4th June</td>
          <td>9:00AM - 11:30AM</td>
        </tr>
        <tr>
          <td>June Holidays</td>
          <td>Fri</td>
          <td>4th June</td>
          <td>12:30PM - 3:00PM</td>
        </tr>
        <tr>
          <td>June Holidays</td>
          <td>Fri</td>
          <td>4th June</td>
          <td>4:00PM - 6:30PM</td>
        </tr>
        <tr>
          <td>June Holidays</td>
          <td>Sat</td>
          <td>5th June</td>
          <td>9:00AM - 11:30AM</td>
        </tr>
        <tr>
          <td>June Holidays</td>
          <td>Sat</td>
          <td>5th June</td>
          <td>12:30PM - 3:00PM</td>
        </tr>
        <tr className="highlight">
          <td colSpan="7">
            Bootcamp: DeepRacer League Finals and Closing Ceremony
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>June Holidays</td>
          <td>Sat</td>
          <td>5th June</td>
          <td>4:00PM - 5:30PM</td>
          <td>1.5</td>
          <td />
        </tr>
      </tbody>
    </Table>
  </Container>
);

export default BootcampTable;
