import PhaseCard from './PhaseCard';
import addKeys from '../../../utils/addKeys';

const cardContent = addKeys([
  {
    title: 'Introduction to Python Fundamentals',
    text: 'Students will learn concepts via pre-prepared Jupyter Notebooks which serve as interactive textbooks, where they compose their Python code alongside conceptual notes. Concepts covered are documented in sections of the Jupyter Notebook alongside sections which allow the execution of Python code. Students will be introduced to variables and basic data types in Python, conditions and conditional statements, and loops.',
  },
  {
    title: 'Programming with Functions',
    text: 'Students will appreciate functions as tools which allow programmers to enhance the readability and reusability of their code. In the process, they will develop their computational thinking skills of decomposition and abstraction.',
  },
  {
    title: 'Checkpoint Assessment',
    text: 'A comprehensive Multiple Choice Questionnaire will be conducted for students to gauge their progress status.',
  },
  {
    title: 'Learning to Program the AWS DeepRacer',
    text: 'The highlight of the BootCamps is learning how to code a virtual DeepRacer car. All students will be provided with an AWS starter account which they can use to access the DeepRacer console, a graphical interface for training their reinforcement learning models.',
  },
  {
    title: 'DeepRacer Talk',
    text: 'A DeepRacer league champion engaged by AWS will share tips and practical strategies for programming the DeepRacer.',
  },
  {
    title: 'DeepRacer League',
    text: 'After successfully training and evaluating models in simulations, students will get a chance to run their code on a physical DeepRacer. The race is run as a time-tap where each team will get several runs on teh track thus being able to tweak their code after each run. We will take the best time from each team for selection of the finalists!.',
  },
  {
    title: 'DeepRacer Finals',
    text: 'Conducted in a similar fashion to the Singapore Grand Prix, the 8 fastest teams from the league will get additional sessions to better their times and vie for the top spot. The entire DeepRacer League and Finals will be live streamed.',
  },
]);

const Phase1 = () => (
  <PhaseCard
    number={1}
    title={`BootCamp (31 May - 5 June 2021)`}
    content={cardContent}
  />
);

export default Phase1;
