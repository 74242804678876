import SEO from '../../components/seo';
import Background from '../../components/Tertiary/Apple/Background';
import Banner from '../../components/Tertiary/Apple/Banner';
import Why from '../../components/Tertiary/Apple/Why';
import About from '../../components/Tertiary/Apple/About';
import Attend from '../../components/Tertiary/Apple/Attend';
import Trainer from '../../components/Tertiary/Apple/Trainer';
import Schedule from '../../components/Tertiary/Apple/Schedule';

const TertiaryApplePage = () => (
  <>
    <SEO title="Tertiary | Apple" />
    <Background id="particles-1">
      <Banner />
    </Background>
    <Background id="particles-2">
      <About />
    </Background>
    <Why />
    <Attend />
    <Background id="particles-3">
      <Trainer />
    </Background>
    <Schedule />
  </>
);

export default TertiaryApplePage;
