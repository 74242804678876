import styled from 'styled-components';

import $ from '../../styles/global';
import Utils from '../../utils';
import ForumIcon from '../../assets/images/tertiary/index/forum.svg';
import VerifiedIcon from '../../assets/images/tertiary/index/verified.svg';
import HomeworkIcon from '../../assets/images/tertiary/index/homework.svg';

const Section = styled.section``;

const Container = styled.div`
  padding: 200px 0 100px 0;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    padding: 300px 0 100px 0;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    padding: 400px 0 100px 0;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 200px 0 100px 0;
  }
  // #endregion
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 80px;
  font-size: 40px;
  font-family: Lato Bold;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    justify-content: initial;

    & > *:not(:last-child) {
      margin-bottom: 70px;
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    justify-content: initial;

    & > *:not(:last-child) {
      margin-bottom: 70px;
    }
  }
  // #endregion
`;

const Card = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-self: stretch;

  & > svg {
    width: 50px;
    height: 50px;
    fill: ${$.color.blue5};
    margin-bottom: ${$.layout().margin3}px;
  }

  & > h3 {
    margin: 0 0 ${$.layout().margin3}px 0;
    font-family: Lato Bold;
    font-size: 22px;
    flex-grow: 2;
  }

  & > p {
    margin: 0;
    line-height: 1.3em;
    font-size: 17px;
    color: ${$.color.black2};
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const items = Utils.addKeys([
  {
    title: 'Boost your resumes',
    desc: 'Gain recognition with top IT certifications to prepare for Polytechnic, University, internship and job applications',
    icon: <HomeworkIcon />,
  },
  {
    title: 'Official certifications',
    desc: 'Earn your certification and display your verified digital badge on any professional networking site like LinkedIn',
    icon: <VerifiedIcon />,
  },
  {
    title: 'Build your industry network',
    desc: 'Interact with industry experts and technical peers from top universities in talks and fireside chats built into the programme',
    icon: <ForumIcon />,
  },
]);

const IconCards = () => (
  <Section>
    <Container>
      <Title>Key programme features</Title>
      <Cards>
        {items.map(({ key, title, icon, desc }) => (
          <Card key={key}>
            {icon}
            <h3>{title}</h3>
            <p>{desc}</p>
          </Card>
        ))}
      </Cards>
    </Container>
  </Section>
);

export default IconCards;
