import { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Context } from '../context';
import $ from '../../../../styles/global';
import {
  OptionContainer,
  LabelText,
  PillText,
  Dropdown,
} from './DropdownStyles';

const LeftPill = styled(Dropdown)`
  display: inline-block;
  width: calc(50% - ${$.layout().margin4}px * 3);
  margin-right: ${$.layout().margin3}px;
  padding: ${$.layout().padding5}px ${$.layout().padding4}px;
  margin-bottom: ${({ showMargin }) =>
    showMargin === true ? `${$.layout().margin4}px` : 0};
  ${({ clicked }) =>
    clicked === true
      ? `background-color: ${$.color.blue3}; > div { color: ${$.color.white}; }`
      : ''}
`;

const RightPill = styled(Dropdown)`
  display: inline-block;
  width: calc(50% - ${$.layout().margin4}px * 3);
  padding: ${$.layout().padding5}px ${$.layout().padding4}px;
  margin-bottom: ${({ showMargin }) =>
    showMargin === true ? `${$.layout().margin4}px` : 0};
  ${({ clicked }) =>
    clicked === true
      ? `background-color: ${$.color.blue3}; > div { color: ${$.color.white}; }`
      : ''}
`;

const StyledPillText = styled(PillText)`
  white-space: initial;
  overflow: initial;
  text-overflow: initial;
  word-wrap: break-word;
  font-size: 13px;
`;

const DoublePills = ({ text, pills, name }) => {
  const optionRef = useRef(null);
  const { searchBarDataUpdate, searchBarData, searchBarUIUpdate } =
    useContext(Context);
  const [clicked, setClicked] = useState([]);
  const [first, setFirst] = useState(true);

  const handleOnClick = (value) => {
    setClicked((prev) => {
      const newPrev = [...prev];
      const update = {};

      if (newPrev.indexOf(value) > -1) {
        newPrev.splice(newPrev.indexOf(value), 1);
        update[name] = { [value]: false };
      } else {
        newPrev.push(value);
        update[name] = { [value]: true };
      }

      update[name].all =
        newPrev.length === pills.length || newPrev.length === 0;

      // An edge case: When we first loads up the component, we disregard searchBarData
      // and set it up to highlight all pills by their value if searchBarData has the value 'all'.
      // To sync this edge case with the redux state, we forcibly set the redux state
      // to match up with our local state to prevent mismatched state.
      if (first === true) {
        setFirst(false);

        // If 'all' is in the redux state, run this. Otherwise, do nothing because the redux state
        // matches our current local state.
        if (
          searchBarData[name].length === 1 &&
          searchBarData[name][0] === 'all'
        ) {
          pills.forEach(({ value: pillValue }) => {
            update[name][pillValue] =
              typeof update[name][pillValue] === 'undefined'
                ? true
                : update[name][pillValue];
          });
        }
      }

      searchBarUIUpdate({ clicked: value });
      searchBarDataUpdate(update);

      return newPrev;
    });
  };

  /**
   * Since both mobilebar and desktopbar is rendered together, the components
   * only receives the state the first time it was rendered. Subsequent updates
   * are reliant on updates to mobilebar alone. Thus, we need to make sure the components in here
   * listen to changes whenever the desktopbar is updated too.
   */
  useEffect(
    () => () => {
      // Only update the state if the OptionContainer is hidden in DOM
      // (Meaning when the mobilebar is hidden)
      if (optionRef.current && optionRef.current.offsetParent === null) {
        setClicked(
          searchBarData[name][0] === 'all'
            ? pills.map(({ value }) => value)
            : searchBarData[name]
        );
      }
    },
    [searchBarData]
  );

  // Force all pills to be highlighted if the given searchBarData is 'all'.
  // Otherwise do nothing.
  useEffect(() => {
    const highlightAllPills =
      searchBarData[name].length === 1 && searchBarData[name][0] === 'all'
        ? pills.map(({ value }) => value)
        : searchBarData[name];

    setClicked(highlightAllPills);
  }, []);

  return (
    <OptionContainer ref={optionRef}>
      <LabelText>{text}</LabelText>
      {pills.map(({ text: pillText, value, key }, index) => {
        const isLeftPill = index % 2 === 0;
        const showMargin =
          pills.length > 2 &&
          [index.length - 1, index.length - 2].indexOf(index) === -1;
        const isClicked = clicked.indexOf(value) > -1;

        if (isLeftPill === true) {
          return (
            <LeftPill
              clicked={isClicked}
              showMargin={showMargin}
              key={key}
              onClick={() => {
                handleOnClick(value);
              }}
            >
              <StyledPillText>{pillText}</StyledPillText>
            </LeftPill>
          );
        }

        return (
          <RightPill
            clicked={isClicked}
            showMargin={showMargin}
            key={key}
            onClick={() => {
              handleOnClick(value);
            }}
          >
            <StyledPillText>{pillText}</StyledPillText>
          </RightPill>
        );
      })}
    </OptionContainer>
  );
};

DoublePills.propTypes = {
  text: PropTypes.string.isRequired,
  pills: PropTypes.oneOfType([PropTypes.array]).isRequired,
  name: PropTypes.string.isRequired,
};

export default DoublePills;
