import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Professional from './Professional';
import Flexible from './Flexible';
import Micro from './Micro';

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.h1`
  font-family: Aileron Heavy;
  font-size: 50px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;

  // #region
  @media ${$.device.mobile} {
    font-size: 42px;
  }
  // #endregion
`;

const Logo = styled.div`
  display: block;
  margin: ${$.layout().margin2}px auto;
  width: 300px;
`;

const Paragraph = styled.p`
  color: ${$.color.black2};
  font-family: Lato Regular;
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin3}px;

  strong {
    font-family: Lato Bold;
  }

  a {
    text-decoration: none;
    color: ${$.color.blue5};
    font-family: Lato Bold;
    &:visited {
      color: ${$.color.blue5};
    }
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 400px;
  margin-bottom: ${$.layout().margin2}px;

  > * {
    width: 100%;
    height: 100%;
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    height: 400px;
    width: 100vw;
    position: relative;
    left: -${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    height: 250px;
    width: 100vw;
    position: relative;
    left: -${$.layout().margin3}px;
  }
  // #endregion
`;

const SMU = () => (
  <>
    <Section>
      <Container>
        <Logo>
          <StaticImage
            src="../../../assets/images/partners/smu/smu.png"
            alt="SMU logo"
          />
        </Logo>
        <Title>Professional Training and Certification at SMU Academy</Title>
        <Paragraph>
          Get professional IT training and certifications from the SMU Academy
          at the Singapore Management University. Learn in-demand skills in
          computer programming, data science and cloud computing from our highly
          qualified instructors teaching at SMU Academy.
        </Paragraph>
        <Paragraph>
          Whether you are a pure beginner looking for a bite-sized introduction
          to some of the most cutting edge technologies shaping the business
          world today, a serious learner looking to develop programming skills,
          or an experienced engineer/analyst looking to add modern data
          analytics skills to your toolset, SMU Academy has the course for you.
        </Paragraph>
        <ImgContainer>
          <StaticImage
            src="../../../assets/images/partners/smu/smu_1.jpg"
            alt="Professional Training and Certification at SMU Academy image banner"
          />
        </ImgContainer>
      </Container>
    </Section>
    <Professional />
    <Flexible />
    <Micro />
  </>
);

export default SMU;
