import Seo from '../../components/seo';
import PCPP from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/PCPP';

const PCPPPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <PCPP />
  </>
);

export default PCPPPage;
