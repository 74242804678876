import styled from 'styled-components';

import $ from '../../../styles/global';
import ImageContainer from '../../Level2Banner/ImageContainer';
import Banner2 from '../../../assets/images/holidaycamps/banner2.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: -120px;
    left: 300px;

    & > svg {
      width: 140px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tabletA} {
    position: absolute;
    top: -120px;
    left: 290px;

    & > svg {
      width: 120px;
    }
  }

  @media ${$.device.tabletB} {
    position: absolute;
    top: -130px;
    left: 280px;

    & > svg {
      width: 110px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Image1Container = () => <Container image={Banner2} />;

export default Image1Container;
