import styled from 'styled-components';

const StripeBackground1 = styled.div`
  left: 0;
  right: 0;
  position: absolute;
  transform: skewY(-12deg);
  top: calc(50% + 275px);
  pointer-events: none;

  & > * {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    height: 155px;
  }

  & .s0 {
    height: 3000px;
    bottom: 0;
    background: linear-gradient(90deg, #ff933b, #ff7708);
  }

  & .s2 {
    bottom: calc(155px * 2);
    right: calc(50% + 260px);
    left: auto;
    width: 30%;
    overflow: hidden;
    min-width: 450px;
    background: linear-gradient(90deg, #d85700, #ff6e0c);
    opacity: 0.75;

    &:after {
      content: '';
      background: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1000px;
      top: -1000px;
      transform: skewY(0, 12deg);
      opacity: 0.25;
    }
  }
`;

const StripeBackground2 = styled.div`
  right: 0;
  width: 100vw;
  position: absolute;
  transform: skewY(-12deg);
  top: calc(50% + 275px);
  pointer-events: none;

  & > * {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    height: 155px;
  }

  & .s1 {
    bottom: calc(155px * 3);
    right: 80%;
    background: linear-gradient(90deg, #f76f12, #ffaf6e);
    opacity: 0.5;
  }

  & .s3 {
    bottom: calc(155px * 1);
    left: 75%;
    background: linear-gradient(90deg, #ff933b, #ff9f50);
  }

  & .s4 {
    bottom: 0;
    background: linear-gradient(90deg, #f98d43, #f76f12);
  }
`;

const SlantedBackground = () => (
  <>
    <StripeBackground1>
      <div className="s0" />
      <div className="s2" />
    </StripeBackground1>
    <StripeBackground2>
      <div className="s1" />
      <div className="s3" />
      <div className="s4" />
    </StripeBackground2>
  </>
);

export default SlantedBackground;
