import styled from 'styled-components';

import $ from '../../../../styles/global';
import OrangeSchool from '../../../../assets/images/awsaccelerator2021/orange-school.svg';

const Container = styled.div`
  width: 100%;
  box-shadow: ${$.dropShadow.repressed};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  background-color: ${$.color.white};

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column-reverse;
  }
  // #endregion
`;

const Message = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  > * {
    &:first-child {
      width: 10px;
      background-color: ${$.color.blue5};
    }
    &:last-child {
      width: calc(100% - 10px);
      word-break: break-word;
      font-family: Aileron Heavy;
      font-size: 30px;
      padding: ${$.layout().padding3}px;
      color: ${$.color.black2};
      > span {
        color: ${$.color.orange3};
      }
    }
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    > * {
      &:last-child {
        font-size: 25px;
      }
    }
  }
  // #endregion
`;

const Asset = styled.div`
  width: 30%;
  position: relative;
  right: -15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > svg {
    width: 100%;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Banner = () => (
  <Container>
    <Message>
      <div />
      <div>
        {`Applications for AWS Bootcamp close on `}
        <span>15th May 2021</span>
      </div>
    </Message>
    <Asset>
      <OrangeSchool />
    </Asset>
  </Container>
);

export default Banner;
