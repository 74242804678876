import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Card from './Card';

const Section = styled.section`
  background-color: ${$.accelerator.grey};
`;

const Container = styled.div`
  padding: 100px 0;

  & > h2 {
    font-family: Lato Black;
    margin-bottom: ${$.layout().margin3}px;
    font-size: 40px;
  }

  & > p {
    font-family: Lato Bold;
    font-size: 24px;
    line-height: 1.3em;
    color: ${$.color.orange3};
    margin-top: ${$.layout().margin2}px;

    & > a {
      color: ${$.color.blue3};
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 50px 0;

    & > h2 {
      font-size: 34px;
    }
  }
  // #endregion
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 ${$.layout().padding4}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    padding: 0;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 0;
    flex-direction: column;
    justify-content: initial;
  }
  // #endregion
`;

const items = Utils.addKeys([
  {
    name: "Isabella Tan, 14, Methodist Girl's School",
    desc: 'AWS Accelerator class of 2021',
    image: (
      <StaticImage
        src="../../../assets/images/awsaccelerator2022/testimonial-isabella.png"
        alt="AWS Deepracer League 2022"
      />
    ),
    video: 'https://player.vimeo.com/video/753777590?h=bcb10c442b',
  },
  {
    name: 'Ashley Khng, 17, Hwa Chong Institution',
    desc: 'AWS Accelerator class of 2021',
    image: (
      <StaticImage
        src="../../../assets/images/awsaccelerator2022/testimonial-ashley.png"
        alt="AWS Deepracer League 2022"
      />
    ),
    video: 'https://player.vimeo.com/video/753778855?h=4b882ff33d',
  },
  {
    name: 'Ved Kalanke, 16, Ngee Ann Secondary',
    desc: 'AWS Accelerator class of 2021',
    image: (
      <StaticImage
        src="../../../assets/images/awsaccelerator2022/testimonial-ved.png"
        alt="AWS Deepracer League 2022"
      />
    ),
    video: 'https://player.vimeo.com/video/753776233?h=c2a1cc3f54',
  },
]);

const Testimonials = () => (
  <Section>
    <Container>
      <h2>Student Testimonials</h2>
      <Cards>
        {items.map(({ name, image, key, desc, video }) => (
          <Card key={key} image={image} name={name} desc={desc} video={video} />
        ))}
      </Cards>
      <p>
        View the 2021 AWS Accelerator Student Projects{' '}
        <Link to="/imda-grad-2021/">here</Link>.
      </p>
    </Container>
  </Section>
);

export default Testimonials;
