import styled from 'styled-components';
import shortid from 'shortid';

import $ from '../../../../styles/global';
import faqData from './items';
import Util from '../../../../utils';
import Pill from './Pill';

const data = Util.addKeys(faqData);

const Container = styled.div`
  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-top: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Cooper Hewitt Bold;
  font-size: 25px;
  text-transform: uppercase;
  color: ${$.color.orange3};
  margin-bottom: ${$.layout().margin3}px;
`;

const CategoryContainer = styled.div`
  margin-bottom: ${$.layout().margin2}px;
`;

const FAQPills = () => (
  <Container>
    {data.map((category) => (
      <CategoryContainer key={shortid.generate()}>
        <Title>{category.title}</Title>
        {category.details.map((qas) => (
          <Pill key={shortid.generate()} qas={qas} />
        ))}
      </CategoryContainer>
    ))}
  </Container>
);

export default FAQPills;
