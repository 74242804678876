import styled from 'styled-components';
import { navigate } from 'gatsby';

import $ from '../../../styles/global';

const Container = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 9999;
`;

const Button = styled.div`
  background-color: ${$.accelerator.blue};
  color: ${$.color.white};
  font-family: Lato Bold;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px 16px;
  min-width: 120px;
  position: absolute;
  bottom: 30px;
  right: 120px;

  &:hover {
    cursor: pointer;
  }
`;

const TertiaryHoverSignUpButton = () => (
  <Container>
    <Button
      onClick={() => {
        navigate('/tertiary/aws/#tertiary-sign-up');
      }}
    >
      Learn how to sign up
    </Button>
  </Container>
);

export default TertiaryHoverSignUpButton;
