import { useContext } from 'react';
import styled from 'styled-components';

import { Context as EventsContext } from '../context';

const Section = styled.section``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  // NOTE: Desktops and above
  // #region
  @media ${({ theme }) => theme.device.desktop} {
    line-height: 1.6em;
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-family: Lato Light;
    color: ${({ theme }) => theme.color.black2};

    & p.excerpt {
      font-family: Lato Light Italic;
    }

    & h1 {
      font-family: Lato Bold;
      font-size: 34px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & h2 {
      font-family: Aileron Heavy;
      font-size: 27px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & h3 {
      font-family: Aileron Heavy;
      font-size: 22px;
      color: ${({ theme }) => theme.color.blue6};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & img {
      max-width: 100%;
    }

    & img + figcaption {
      text-align: center;
      font-size: 0.9em;
      line-height: 1.4em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & strong {
      font-family: Lato Bold;
    }

    & p {
      margin: 0px 0px ${({ theme }) => theme.layout().margin3}px;
      word-break: break;
      hyphens: auto;
    }

    & p + ol,
    & p + ul {
      margin-top: -${({ theme }) => theme.layout().margin4}px;
      li {
        line-height: 1.7em;
      }
    }

    & ol,
    & ul {
      margin-bottom: ${({ theme }) => theme.layout().margin3}px;
      li {
        line-height: 1.7em;
      }
    }

    & table {
      width: 100%;
      box-shadow: ${({ theme }) => theme.dropShadow.normal};
      margin-bottom: ${({ theme }) => theme.layout().margin3}px;
      border-spacing: 10px;
      border-collapse: collapse;
      border-radius: ${({ theme }) => theme.border().radius3}px;
      overflow: hidden;

      > thead {
        background-color: ${({ theme }) => theme.color.blue5};
        color: ${({ theme }) => theme.color.white};
        font-family: Lato Bold;

        th {
          text-align: center;
          padding: ${({ theme }) => theme.layout().padding5}px
            ${({ theme }) => theme.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
        }
      }

      & tbody {
        td {
          padding: ${({ theme }) => theme.layout().padding5}px
            ${({ theme }) => theme.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;

          :first-child {
            border-left: none;
          }
          :last-child {
            border-right: none;
          }
        }
      }
    }

    & figure.kg-embed-card {
      padding-top: 56.25%;
      position: relative;
    }

    & figure.kg-image-card {
      text-align: center;
    }

    & figure > iframe {
      width: 100%;
      height: calc(
        100% - 1.4em - ${({ theme }) => theme.layout().padding5}px * 2
      );
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    & iframe + figcaption {
      line-height: 1.4em;
      padding: ${({ theme }) => theme.layout().padding5}px 0;
      text-align: center;
      font-size: 0.9em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & p:first-child {
      font-size: 27px;
      font-weight: 100;
      line-height: 1.4em;
    }

    & a {
      text-decoration: none;
      font-family: Lato Bold;
      color: ${({ theme }) => theme.color.blue5};

      &:visited {
        color: ${({ theme }) => theme.color.blue5};
      }
    }

    & blockquote {
      margin: 0 0 1.5em;
      padding: 0 1.2em;
      font-size: 23px;
      border-left: 3px solid ${({ theme }) => theme.color.blue5};
      quotes: none;
      font-style: italic;
    }

    & pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    & pre > code {
      width: calc(100% - 10px);
    }

    & code {
      padding: 2px 5px 2px;
      font-size: 0.8em;
      background: ${({ theme }) => theme.color.blue1};
      border-radius: ${({ theme }) => theme.border().radius4}px;
      background-color: ${({ theme }) => theme.color.blue1};
      display: inline-block;
      max-width: 100%;
    }

    & hr {
      margin: ${({ theme }) => theme.layout().margin3}px 0px
        ${({ theme }) => theme.layout().margin2}px;
      border: 1px solid ${({ theme }) => theme.color.blue5};
      opacity: 0.3;
      width: 85%;
      align-self: center;
    }

    & > iframe {
      max-width: 100%;
      align-self: center;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${({ theme }) => theme.device.tablet} {
    line-height: 1.7em;
    font-size: 19px;
    font-family: Lato Light;

    & p.excerpt {
      font-family: Lato Light Italic;
    }

    & h1 {
      font-family: Lato Bold;
      font-size: 34px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & h2 {
      font-family: Aileron Heavy;
      font-size: 27px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & h3 {
      font-family: Aileron Heavy;
      font-size: 24px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & img {
      max-width: 100%;
    }

    & img + figcaption {
      text-align: center;
      font-size: 0.9em;
      line-height: 1.4em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & figure.kg-image-card {
      text-align: center;
    }

    & strong {
      font-family: Lato Bold;
    }

    & p {
      margin: 0px 0px ${({ theme }) => theme.layout().margin3}px;
      word-break: break;
      hyphens: auto;
    }

    & p + ol,
    & p + ul {
      margin-top: -${({ theme }) => theme.layout().margin4}px;
      li {
        line-height: 1.7em;
      }
    }

    & ol,
    & ul {
      margin-bottom: ${({ theme }) => theme.layout().margin3}px;
      li {
        line-height: 1.7em;
      }
    }

    & .table {
      width: 100%;
      overflow-x: auto;
      box-shadow: ${({ theme }) => theme.dropShadow.normal};
      margin-bottom: ${({ theme }) => theme.layout().margin3}px;
      border-radius: ${({ theme }) => theme.border().radius3}px;
    }

    & table {
      border-spacing: 10px;
      border-collapse: collapse;
      min-width: 100%;
      overflow: hidden;

      > thead {
        background-color: ${({ theme }) => theme.color.blue5};
        color: ${({ theme }) => theme.color.white};
        font-family: Lato Bold;

        th {
          text-align: center;
          padding: ${({ theme }) => theme.layout().padding5}px
            ${({ theme }) => theme.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
        }
      }

      & tbody {
        td {
          padding: ${({ theme }) => theme.layout().padding5}px
            ${({ theme }) => theme.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;

          :first-child {
            border-left: none;
          }
          :last-child {
            border-right: none;
          }
        }
      }
    }

    & figure.kg-embed-card {
      padding-top: 56.25%;
      position: relative;
      margin-bottom: ${({ theme }) => theme.layout().margin3}px;
    }

    & figure > iframe {
      width: 100%;
      height: calc(
        100% - 1.4em - ${({ theme }) => theme.layout().padding5}px * 2
      );
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    & iframe + figcaption {
      line-height: 1.4em;
      padding: ${({ theme }) => theme.layout().padding5}px 0;
      text-align: center;
      font-size: 0.9em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & p:first-child {
      font-size: 27px;
      font-weight: 100;
      line-height: 1.4em;
    }

    & a {
      text-decoration: none;
      font-family: Lato Bold;
      color: ${({ theme }) => theme.color.blue5};

      &:visited {
        color: ${({ theme }) => theme.color.blue5};
      }
    }

    & blockquote {
      margin: 0 0 1.5em;
      padding: 0 1.5em;
      border-left: 3px solid ${({ theme }) => theme.color.blue5};
      quotes: none;
      font-style: italic;
    }

    & pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    & pre > code {
      width: calc(100% - 10px);
    }

    & code {
      padding: 2px 5px 2px;
      font-size: 0.8em;
      background: ${({ theme }) => theme.color.blue1};
      border-radius: ${({ theme }) => theme.border().radius4}px;
      background-color: ${({ theme }) => theme.color.blue1};
      display: inline-block;
    }

    & hr {
      margin: ${({ theme }) => theme.layout().margin4}px 0px
        ${({ theme }) => theme.layout().margin3}px;
      border: 1px solid ${({ theme }) => theme.color.blue5};
      opacity: 0.3;
      width: 90%;
      align-self: center;
    }

    & > iframe {
      max-width: 100%;
      align-self: center;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${({ theme }) => theme.device.mobile} {
    line-height: 1.7em;
    font-family: Lato Light;
    font-size: 19px;

    & p.excerpt {
      font-family: Lato Light Italic;
    }

    & h1 {
      font-family: Lato Bold;
      font-size: 34px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & h2 {
      font-family: Aileron Heavy;
      font-size: 27px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & h3 {
      font-family: Aileron Heavy;
      font-size: 24px;
      color: ${({ theme }) => theme.color.blue5};
      margin-bottom: ${({ theme }) => theme.layout().margin4}px;
    }

    & img {
      max-width: 100%;
    }

    & img + figcaption {
      text-align: center;
      font-size: 0.9em;
      line-height: 1.4em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & strong {
      font-family: Lato Bold;
    }

    & p {
      margin: 0px 0px ${({ theme }) => theme.layout().margin3}px;
      word-break: break;
      hyphens: auto;
    }

    & p + ol,
    & p + ul {
      margin-top: -${({ theme }) => theme.layout().margin4}px;
      li {
        line-height: 1.7em;
      }
    }

    & ol,
    & ul {
      margin-bottom: ${({ theme }) => theme.layout().margin3}px;
      li {
        line-height: 1.7em;
      }
    }

    & .table {
      width: 100%;
      overflow-x: auto;
      box-shadow: ${({ theme }) => theme.dropShadow.normal};
      margin-bottom: ${({ theme }) => theme.layout().margin3}px;
      border-radius: ${({ theme }) => theme.border().radius3}px;
    }

    & table {
      border-spacing: 10px;
      border-collapse: collapse;
      min-width: 100%;
      overflow: hidden;

      > thead {
        background-color: ${({ theme }) => theme.color.blue5};
        color: ${({ theme }) => theme.color.white};
        font-family: Lato Bold;

        th {
          text-align: center;
          padding: ${({ theme }) => theme.layout().padding5}px
            ${({ theme }) => theme.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
        }
      }

      & tbody {
        td {
          padding: ${({ theme }) => theme.layout().padding5}px
            ${({ theme }) => theme.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;

          :first-child {
            border-left: none;
          }
          :last-child {
            border-right: none;
          }
        }
      }
    }

    & figure.kg-embed-card {
      padding-top: 56.25%;
      position: relative;
      margin: 0px 0px ${({ theme }) => theme.layout().margin3}px;
    }

    & figure.kg-image-card {
      text-align: center;
      margin: ${({ theme }) => theme.layout().margin3}px 0;
    }

    & figure > iframe {
      width: 100%;
      height: calc(
        100% - 1.4em - ${({ theme }) => theme.layout().padding5}px * 2
      );
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    & iframe + figcaption {
      line-height: 1.4em;
      padding: ${({ theme }) => theme.layout().padding5}px 0;
      text-align: center;
      font-size: 0.9em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & p:first-child {
      font-size: 23px;
      font-weight: 100;
      line-height: 1.4em;
    }

    & a {
      text-decoration: none;
      font-family: Lato Bold;
      color: ${({ theme }) => theme.color.blue5};

      &:visited {
        color: ${({ theme }) => theme.color.blue5};
      }
    }

    & blockquote {
      margin: 0 0 1.5em;
      padding: 0 1.5em;
      border-left: 3px solid ${({ theme }) => theme.color.blue5};
      quotes: none;
      font-style: italic;
    }

    & pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    & pre > code {
      width: calc(100% - 10px);
    }

    & code {
      padding: 2px 5px 2px;
      font-size: 0.8em;
      background: ${({ theme }) => theme.color.blue1};
      border-radius: ${({ theme }) => theme.border().radius4}px;
      background-color: ${({ theme }) => theme.color.blue1};
      display: inline-block;
    }

    & hr {
      margin: ${({ theme }) => theme.layout().margin4}px 0px
        ${({ theme }) => theme.layout().margin3}px;
      border: 1px solid ${({ theme }) => theme.color.blue5};
      opacity: 0.3;
      width: 100%;
    }

    & > iframe {
      width: 100%;
    }
  }
  // #endregion
`;

const Body = () => {
  const { html } = useContext(EventsContext);

  return (
    <Section>
      <Container dangerouslySetInnerHTML={{ __html: html }}></Container>
    </Section>
  );
};

export default Body;
