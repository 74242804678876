import styled from 'styled-components';

import $ from '../../../styles/global';

const Section = styled.section`
  background-color: ${$.color.orange3};
`;

const Container = styled.div`
  padding: 50px 0;
`;

const Title = styled.h1`
  color: ${$.color.white};
  font-family: Aileron Heavy;
  font-size: 30px;
`;

const Banner = () => (
  <Section>
    <Container>
      <Title>AWS Accelerator Class of 2021 Graduation [LIVE STREAM]</Title>
    </Container>
  </Section>
);

export default Banner;
