import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../../styles/global';

const ProfileName = styled.div`
  font-family: Aileron Heavy;
  font-size: 30px;
  text-transform: capitalize;
  color: ${$.color.blue3};
  margin-bottom: ${$.layout().margin5}px;
`;

const ProfileTitle = styled.div`
  font-family: Cooper Hewitt Semibold;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.4em;
  text-align: center;
  width: 70%;
  margin-bottom: ${$.layout().margin5}px;
  color: ${$.color.blue6};
`;

const Profile = ({ name, title }) => (
  <>
    <ProfileName>
      <span>{name}</span>
    </ProfileName>
    <ProfileTitle>{title}</ProfileTitle>
  </>
);

Profile.defaultProps = {
  name: '',
  title: '',
};

Profile.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
};

export default Profile;
