import styled from 'styled-components';

import Level2Banner from '../../Level2Banner';
import $ from '../../../styles/global';
import Particles from './Particles';

const StyledLevel2Banner = styled(Level2Banner)`
  position: relative;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    .level2-title-container {
      height: 90%;
      > div {
        width: 150%;
      }
    }
  }
  // #endregion
`;

const BackgroundContainer = styled.div`
  background-color: ${({ theme }) => theme.color.blue3};
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.color.blue4} 0%,
    ${({ theme }) => theme.color.blue4} 40%,
    ${({ theme }) => theme.color.blue4} 60%,
    ${({ theme }) => theme.color.orange3} 100%
  );
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 40% 100%);
  outline: 1px solid black;
  position: absolute;
  width: 80%;
  top: 0;
  right: 0;
  bottom: 0;
`;

const Banner = () => (
  <StyledLevel2Banner
    title="Our Team"
    subtitle="You deserve to learn from the best. Get to know Singapore’s largest and most qualified team of coding instructors, and see who is shaping the Campus student experience both inside and out of the classroom."
    color={$.color.white}
    backgroundColor={$.color.orange3}
  >
    <BackgroundContainer>
      <Particles id="team-page-banner" />
    </BackgroundContainer>
  </StyledLevel2Banner>
);

export default Banner;
