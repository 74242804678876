import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import RightChevron from '../../assets/icons/thin-arrow-right.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: Lato Regular;
  line-height: 16.8px;

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${$.color.white};
    margin-left: 8px;
    transition: fill 0.5s;
  }
`;

const Button = styled(({ to, children, className }) =>
  to.indexOf('http') > -1 ? (
    <a
      href={to}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link to={to} className={className}>
      {children}
    </Link>
  )
)`
  text-decoration: none;
  background: ${({ disabled }) =>
    disabled
      ? $.color.gray1
      : `radial-gradient(
    50% 628.51% at 50% 50%,
    ${$.proTraining.orange2} 0%,
    #ef7d05 100%
  )`};
  color: ${$.color.white};
  border-radius: 54px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  padding: 12px 30px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  transition: left 0.3s, transform 0.3s, color 0.5s;
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}

  &:hover {
    color: ${$.proTraining.orange2};
    & > span {
      transform: translateY(0) scale(2);
    }
    & > ${Container} > svg {
      fill: ${$.proTraining.orange2};
    }
    &:visited {
      color: ${$.proTraining.orange2};
    }
  }

  &:visited {
    color: ${$.color.white};
  }

  & > span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: ${$.color.white};
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.5s;
    top: 0;
    transition-delay: calc((var(--n) - 1) * 0.05s);
    z-index: -1;

    &:nth-child(2) {
      --n: 1;
    }

    &:nth-child(3) {
      --n: 2;
    }

    &:nth-child(4) {
      --n: 3;
    }

    &:nth-child(5) {
      --n: 4;
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    padding: 9px 27px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 20px;
  }
  // #endregion
`;

const OrangeButton = ({ text, link, chevron, disabled }) => (
  <Button to={link} disabled={disabled}>
    <Container>
      {text}
      {chevron && <RightChevron />}
    </Container>
    <span />
    <span />
    <span />
    <span />
  </Button>
);

OrangeButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  chevron: PropTypes.bool,
  disabled: PropTypes.bool,
};
OrangeButton.defaultProps = {
  text: 'Explore More',
  link: '/',
  chevron: false,
  disabled: false,
};

export default OrangeButton;
