import { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';

import $ from '../../../styles/global';
import Util from '../../../utils';
import promoItems from './items.json';

const data = Util.addKeys(promoItems);

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 15px;
  padding: 0 ${$.layout().padding5}px;
`;

const Subtitle = styled.div`
  font-family: Aileron Regular;
  font-size: 14px;
  padding: 0 ${$.layout().padding5}px;

  & a {
    font-family: Aileron Bold;
    color: ${$.color.blue2};
    text-decoration: none;
  }
`;

const Container = styled.div`
  overflow: hidden;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    flex-grow: 10;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    width: 100%;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Slider = styled.div`
  ${({ counter, length }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ${$.easingFn.standard};
    width: ${length * 100}%;
    transform: translateX(
      ${(counter % length) * -1 * (100 / length)}%
    );
  `}
`;

const PromoItemContainer = styled.div`
  ${({ counter, index, length }) => `
    transition: opacity 0.5s ${$.easingFn.standard};

    // NOTE: Desktops and above
    // #region
    @media ${$.device.desktop} {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px ${$.layout().padding4}px;
      justify-content: center;
      width: 100%;
      opacity: ${index === counter % length ? '1' : '0'};
    }
    // #endregion

    // NOTE: Tablets
    // #region
    @media ${$.device.tablet} {
      width: calc(${100 / length}%);
      text-align: center;
      opacity: ${index === counter % length ? '1' : '0'};
    }
    // #endregion

    // NOTE: Mobile and below
    // #region
    @media ${$.device.mobile} {
      width: calc(${100 / length}%);
      text-align: center;
      opacity: ${index === counter % length ? '1' : '0'};
    }
    // #endregion
  `}
`;

const PromoDetails = () => {
  const [counter, setCounter] = useState(0);
  let setCounterInterval;

  const handleSetCounterInterval = () => {
    setCounter((prev) => prev + 1);
  };

  useEffect(() => {
    setCounterInterval = setInterval(handleSetCounterInterval, 5000);

    return () => {
      clearInterval(setCounterInterval);
    };
  }, []);

  return (
    <Container>
      <Slider length={data.length} counter={counter}>
        {data.map(({ title, subtitle, key }, index) => (
          <Fragment key={key}>
            <PromoItemContainer
              counter={counter}
              index={index}
              length={data.length}
            >
              {title ? <Title>{`${title}: `}</Title> : ''}
              <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            </PromoItemContainer>
          </Fragment>
        ))}
      </Slider>
    </Container>
  );
};

export default PromoDetails;
