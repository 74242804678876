import styled from 'styled-components';

import $ from '../../../../styles/global';
import Question1 from './Question1';
import Question2 from './Question2';

const Container = styled.div`
  display: flex;
  margin-bottom: ${$.layout().margin2}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  // #endregion
`;

const Questions = () => (
  <Container>
    <Question1 />
    <Question2 />
  </Container>
);

export default Questions;
