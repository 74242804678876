import styled from 'styled-components';

import $ from '../../../styles/global';
import ImageContainer from '../../Level2Banner/ImageContainer';
import MobileImage from '../../../assets/images/blog/mobile-image.svg';

const Container = styled(ImageContainer)`
  display: none;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    position: absolute;
    top: -60px;
    left: -${$.layout().padding3}px;
    width: calc(100% + ${$.layout().padding3 * 2}px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    & > svg {
      max-height: 225px;
    }
  }
  // #endregion
`;

const MobileImageContainer = () => <Container image={MobileImage} />;

export default MobileImageContainer;
