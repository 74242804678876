import PropTypes from 'prop-types';
import styled from 'styled-components';
import SEO from '../components/seo';

import $ from '../styles/global';

const Section = styled.section``;

const Content = styled.div`
  color: ${$.color.black2};
  font-size: 17px;
  padding: ${$.layout().padding2}px 0;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Aileron Heavy !important;
    margin: ${$.layout().margin2}px 0 ${$.layout().margin4}px 0;
    &:first-child {
      margin: 0 0 ${$.layout().margin4}px 0;
    }
  }

  p,
  li {
    line-height: 1.4em;
    margin-bottom: ${$.layout().margin4}px;
  }

  iframe {
    display: block;
    margin: ${$.layout().margin3}px 0;
    width: 100%;
    max-width: 640px;
    background-color: ${$.color.black};
  }

  a {
    font-family: Lato Bold;
    text-decoration: none;
    color: ${$.color.blue5};
    &:visited {
      color: ${$.color.blue5};
    }
  }

  code.language-text {
    padding: 3px 5px;
    font-size: 16px;
    border-radius: 3px;
    background-color: #e2e1e1;
    font-family: 'Courier New', Courier, monospace;
    color: ${$.color.black2};
  }

  pre {
    margin-bottom: ${$.layout().margin3}px;
  }

  .gatsby-resp-image-wrapper {
    margin-bottom: ${$.layout().margin3}px;
    max-width: 100% !important;
  }

  table {
    border: 1px solid ${$.color.gray2};
    border-collapse: collapse;
    td,
    th {
      text-align: left;
      padding: ${$.layout().padding5}px;
      border: 1px solid ${$.color.gray2};
      line-height: 1.4em;
    }
    th {
      font-family: Lato Bold;
    }
  }
`;

const IndividualMDPage = ({ pageContext, location }) => {
  const { html, title, description, keywords } = pageContext;

  return (
    <>
      <SEO
        location={location}
        title={title}
        description={description}
        keywords={keywords}
      />
      <Section>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Section>
    </>
  );
};

IndividualMDPage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
    .isRequired,
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ).isRequired,
};

export default IndividualMDPage;
