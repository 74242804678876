import styled from 'styled-components';

import $ from '../../../styles/global';
import Button from '../../Button';

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 35px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin2}px;
  text-align: center;

  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
  }
  // #endregion
`;

const Cards = styled.div`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    margin-bottom: ${$.layout().margin2}px;

    > * {
      width: calc(48% - ${$.layout().padding3 * 2}px);
      &:first-child {
        margin-right: 4%;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    display: flex;
    flex-direction: column;
    margin-bottom: ${$.layout().margin2}px;

    > * {
      width: calc(100% - ${$.layout().padding3 * 2}px);
      &:first-child {
        margin-bottom: ${$.layout().margin3}px;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletB} {
    display: flex;
    flex-direction: row;
    margin-bottom: ${$.layout().margin2}px;

    > * {
      width: calc(48% - ${$.layout().padding3 * 2}px);
      &:first-child {
        margin-right: 4%;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column;
    margin-bottom: ${$.layout().margin2}px;

    > * {
      width: calc(100% - ${$.layout().padding3 * 2}px);
      &:first-child {
        margin-bottom: ${$.layout().margin3}px;
      }
    }
  }
  // #endregion
`;

const Card = styled.div`
  border-radius: 10px;
  padding: ${$.layout().padding3}px;
  box-shadow: ${$.dropShadow.normal};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardTitle = styled.div`
  font-family: Lato Bold;
  text-align: center;
  font-size: 23px;
  color: ${$.color.blue3};
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    flex-grow: 1;
  }
  // #endregion
`;

const CardContent = styled.div`
  font-size: 17px;
  line-height: 1.3em;
  color: ${$.color.black2};
  text-align: center;
  flex-grow: 2;

  em {
    font-family: Lato Italic;
  }

  a {
    color: ${$.color.blue5};
    text-decoration: none;
    font-family: Lato Bold;
  }
`;

const StyledLink = styled.a`
  display: inline-block;
  text-decoration: none;
  max-width: 250px;
  width: 100%;
  > button {
    width: 100%;
  }
`;

const Professional = () => (
  <Section>
    <Container>
      <Title>Professional certifications for the serious learner</Title>
      <Cards>
        <Card>
          <CardTitle>
            Professional Certificate in Python Programming (PCPP)
          </CardTitle>
          <CardContent>
            <p>
              Python is one of the most heavily used programming languages in
              industry and can be found in a broad range of applications
              including the Google Cloud Platform, JP Morgan&apos;s Athena
              trading system and Facebook&apos;s Ads API. Its versatility,
              simplicity and elegance ensure that Python programming remains as
              one of the most sought-after skills from employers today.
            </p>
            <p>
              PCPP is a comprehensive 6-module introduction to Python
              Programming featuring 90 hours of instruction, designed for
              motivated beginners who are looking to develop a serious
              programming background.
            </p>
            <p>No prior experience in programming is necessary.</p>
          </CardContent>
          <StyledLink
            href="https://academy.smu.edu.sg/professional-certificate-python-programming-3266"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Apply here</Button>
          </StyledLink>
        </Card>
        <Card>
          <CardTitle>
            Advanced Certificate in Applied Data Analytics (ACADA)
          </CardTitle>
          <CardContent>
            <p>
              This course is designed to equip users with a variety of
              techniques drawn from data science, machine learning and cloud
              computing in how to process, analyse and utilise data in an
              applied business setting.
            </p>
            <p>
              This 6 module course is for individuals interested in formulating
              data-driven recommendations to drive business decisions and is
              recommended for professionals who want to strengthen their
              analytical and technical skills to get an edge in their careers.
              Students should have prior experience in programming equivalent to
              the PCPP.
            </p>
          </CardContent>
          <StyledLink
            href="https://academy.smu.edu.sg/advanced-certificate-applied-data-analytics-4541"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Apply here</Button>
          </StyledLink>
        </Card>
      </Cards>
    </Container>
  </Section>
);

export default Professional;
