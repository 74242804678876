import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import data from '../../masterCourseEventListWithKeys';
import CoursesScheduleViewer from '../CoursesScheduleViewer';

const memoizedFilteringLogic = () => {
  const cache = {};

  return (arr, searchBarData) => {
    if (JSON.stringify(searchBarData) in cache) {
      return cache[JSON.stringify(searchBarData)];
    }

    const result = arr
      .map((ele) => {
        if (searchBarData.allEventIds[0] === '') {
          return ele;
        }

        return {
          ...ele,
          events: ele.events.filter(({ url }) => {
            let eventId = url.split('-');
            eventId = eventId[eventId.length - 1];

            if (searchBarData.allEventIds.indexOf(eventId) > -1) {
              return true;
            }

            return false;
          }),
        };
      })
      .filter((ele) => {
        if (searchBarData.allSubCourses[0] === '') {
          return ele;
        }

        if (searchBarData.allSubCourses.indexOf(ele.subCourseName) > -1) {
          return true;
        }

        return false;
      })
      .map((ele) => ({
        // Location filter
        // #region
        ...ele,
        events: ele.events.filter((event) => {
          if (searchBarData.allLocations.indexOf('all') !== -1) {
            return true;
          }
          if (
            searchBarData.allLocations.indexOf(
              event.location.replace(' ', '_').toLowerCase()
            ) !== -1
          ) {
            return true;
          }
          return false;
        }),
        // #endregion
      }))
      .filter((ele) => {
        // Course name filter
        // #region
        if (searchBarData.allCourses.indexOf('all') !== -1) {
          return true;
        }
        if (searchBarData.allCourses.indexOf(ele.courseName) !== -1) {
          return true;
        }
        return false;
        // #endregion
      })
      .map((ele) => ({
        // Course type filter
        // #region
        ...ele,
        events: ele.events.filter((event) => {
          if (searchBarData.allFormats.indexOf('all') !== -1) {
            return true;
          }
          if (
            searchBarData.allFormats.indexOf(
              event.type.replace(' ', '_').toLowerCase()
            ) !== -1
          ) {
            return true;
          }
          return false;
        }),
        // #endregion
      }))
      .map((ele) => ({
        ...ele,
        events: ele.events.filter((event) => {
          if (
            searchBarData.allDates !== '' &&
            parseInt(searchBarData.allDates, 10) <= event.dates.startDate
          ) {
            return true;
          }
          if (searchBarData.allDates === '') {
            return true;
          }
          return false;
        }),
      }))
      .filter((ele) => {
        const { courseName } = ele;

        if (searchBarData.allAges.indexOf('all') !== -1) {
          return true;
        }

        if (
          searchBarData.allAges.indexOf('7or8') !== -1 &&
          ['Basics1', 'Basics2'].indexOf(courseName) !== -1
        ) {
          return true;
        }

        if (
          searchBarData.allAges.indexOf('9or10') !== -1 &&
          ['Basics3', 'Basics4'].indexOf(courseName) !== -1
        ) {
          return true;
        }

        if (
          searchBarData.allAges.indexOf('11or12') !== -1 &&
          [
            'BasicsX',
            'JrPython1',
            'JrPython2',
            'JrPython3',
            'JrPython4',
          ].indexOf(courseName) !== -1
        ) {
          return true;
        }

        if (
          searchBarData.allAges.indexOf('13to16') !== -1 &&
          courseName.includes('Principles')
        ) {
          return true;
        }

        if (
          searchBarData.allAges.indexOf('17toOlder') !== -1 &&
          ['Java', 'holiday'].indexOf(courseName) > -1
        ) {
          return true;
        }

        return false;
      })
      // Default filter to clean up courses without any events
      .filter((ele) => !!ele.events.length);

    cache[JSON.stringify(searchBarData)] = result;
    return result;
  };
};

const filteringLogic = memoizedFilteringLogic();

const FilteredSchedule = () => {
  const searchBarData = useSelector(
    ({ searchBarData: searchData }) => searchData
  );
  useEffect(
    () => () => {
      if (typeof window !== 'undefined') {
        window.history.replaceState(
          '',
          '',
          `${window.location.origin}${window.location.pathname}`
        );
      }
    },
    []
  );

  return (
    <CoursesScheduleViewer
      data={data}
      filteredData={filteringLogic(data, searchBarData)}
    />
  );
};

export default FilteredSchedule;
