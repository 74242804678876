import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';

const Container = styled.div`
  width: 24px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  div {
    position: absolute;
    transition: all 0.25s ${$.easingFn.standard};
    background-color: ${$.proTraining.blue};
    &:nth-child(1) {
      top: 0;
      left: 0;
      height: 4px;
      width: 100%;
      opacity: 1;
      transform: rotate(0deg);
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 8px;
      left: 0;
      height: 4px;
      width: 100%;
      opacity: 1;
      transform: rotate(0deg);
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 16px;
      left: 0;
      height: 4px;
      width: 100%;
      opacity: 1;
      transform: rotate(0deg);
      transform-origin: left center;
    }
  }

  &.open {
    div {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 0;
        left: 4px;
      }
      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 16.5px;
        left: 4px;
      }
    }
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: none;
  }
  // #endregion
`;

const BurgerMenu = ({ setOpenSidebar, openSidebar }) => (
  <Container
    className={openSidebar ? 'open' : ''}
    onClick={() => {
      setOpenSidebar((prev) => !prev);
    }}
  >
    <div />
    <div />
    <div />
  </Container>
);

BurgerMenu.defaultProps = {
  setOpenSidebar: () => {},
  openSidebar: false,
};

BurgerMenu.propTypes = {
  openSidebar: PropTypes.bool,
  setOpenSidebar: PropTypes.func,
};

export default BurgerMenu;
