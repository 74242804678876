import { useState } from 'react';

import styled from 'styled-components';
import Tabs from './Tabs';
import Courses from './Courses';
import Items from './Items';
import Banner from './Banner';

const Section = styled.section`
  margin-bottom: 80px !important;
`;

const ProfessionalTrainingCoursePage = () => {
  const [selected, setSelected] = useState('python');

  return (
    <Section>
      <Banner />
      <Tabs items={Items} selected={selected} setSelected={setSelected} />
      <Courses items={Items} selected={selected} />
    </Section>
  );
};

export default ProfessionalTrainingCoursePage;
