import styled from 'styled-components';

import $ from '../../../styles/global';
import NatashaImage from '../../../assets/images/tertiary/apple/natasha-trainer.png';
import SGCCLogo from '../../../assets/images/tertiary/sgcc-white-logo.svg';
import AppleConsultantsNetworkLogo from '../../../assets/images/tertiary/index/apple-consultants-network-white.png';
import AWSAuthorisedInstructor from '../../../assets/images/tertiary/aws-authorized-instructor.svg';
import AWSCertifiedSolutionsArchitect from '../../../assets/images/tertiary/aws-solutions-architect-associate.svg';

const Section = styled.section`
  background-color: ${$.color.blue5};
`;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Card = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const Info = styled.div`
  width: 30%;
  margin-right: 40px;

  & img {
    width: 200px;
    position: relative;
    z-index: 1;
    margin-bottom: ${$.layout().margin4}px;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  // #endregion
`;

const Description = styled.div`
  text-align: left;
  color: ${$.color.white};
  opacity: 0.8;
  width: calc(70% - 40px);

  p {
    margin: 0;
    margin-bottom: 15px;
    line-height: 1.3em;
    letter-spacing: 0.3px;
  }

  a {
    color: ${$.color.white};
    font-family: Lato Bold;
    font-size: 17.5px;

    &:visited {
      color: ${$.color.white};
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const TrainerName = styled.div`
  font-size: 20px;
  font-family: Lato Bold;
  color: ${$.color.white};
  margin-bottom: ${$.layout().margin4}px;
`;

const Qualification = styled.div`
  font-size: 20px;
  font-family: Lato Bold;
  color: ${$.color.white};
  opacity: 0.6;
  margin-bottom: ${$.layout().margin5}px;
`;

const AWSLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${$.layout().margin3}px;

  & > svg {
    width: 100px;
    &:first-child {
      margin-right: ${$.layout().margin5}px;
    }
    &.resize-svg {
      width: 88px;
    }
  }
`;

const SGCCLogoContainer = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  & > svg {
    width: 80px;
  }
`;

const AppleConsultantsNetworkLogoContainer = styled.div`
  margin-top: ${$.layout().margin2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > img {
    width: 200px;
  }
`;

const Trainer = () => (
  <Section>
    <Container>
      <SGCCLogoContainer>
        <SGCCLogo />
      </SGCCLogoContainer>
      <Title>Trainer</Title>
      <Card>
        <Info>
          <img src={NatashaImage} alt="Natasha Apple Trainer" />
          <TrainerName>Natasha</TrainerName>
          <Qualification>M.Sc. Computer Science, HKU</Qualification>
          <Qualification>
            B.A. Mathematics & Computer Science, McGill University
          </Qualification>
          <Qualification>IBM</Qualification>
          <Qualification>ST Electronics</Qualification>
          <Qualification>
            Certified Apple Professional Learning Provider
          </Qualification>
          {/* <AWSLogos>
            <AWSCertifiedSolutionsArchitect />
            <AWSAuthorisedInstructor />
          </AWSLogos> */}
        </Info>
        <Description>
          <p>
            Natasha holds a Master of Science (Distinction) in Computer Science
            from the University of Hong Kong where she graduated with a 4.0 GPA
            and a Bachelors of Arts in Mathematics and Science (Great
            Distinction) from McGill university.
          </p>
          <p>
            Natasha&apos;s experience as a software engineer ranges from working
            with startups and multinationals in Singapore and USA including IBM
            where she worked on data quality management solutions that helped
            deliver quality data for big data, business intelligence, data
            warehousing, application migration and master data management
            projects. She has experience leading software teams at ST
            Electronics’ i-Learning division and founded 2 startups including an
            iPhone development company. Natasha also holds the Apple
            Professional Learning Provider certification, and teaches at the
            Singapore Management University (SMU) where she delivers classes in
            introductory Python and Data Science to working professionals under
            SMU Academy&apos;s{' '}
            <a
              href="https://academy.smu.edu.sg/professional-certificate-python-programming-3266"
              target="_blank"
              rel="noopener noreferrer"
            >
              Professional Certificate in Python Programming programme
            </a>
            .
          </p>
        </Description>
      </Card>
      <AppleConsultantsNetworkLogoContainer>
        <img
          src={AppleConsultantsNetworkLogo}
          alt="Apple Consultants Network Logo"
        />
      </AppleConsultantsNetworkLogoContainer>
    </Container>
  </Section>
);

export default Trainer;
