import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import CommaIcon from '../../assets/icons/comma.svg';

const Container = styled.div`
  position: relative;
  background: radial-gradient(
    152.64% 127.74% at 25.6% 78.1%,
    #f7814d 44.99%,
    #fdb483 96.49%
  );
  box-shadow: 0px 4px 30px 0px #f8824e;
  padding: 40px;
  border-radius: 25px;
`;

const EnlargedComma = styled.div`
  position: absolute;
  width: 130px;
  height: 130px;
  top: 10px;
  right: 40px;

  & > svg {
    opacity: 0.6;
    fill: ${$.proTraining.blue};
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  p {
    font-family: Lato Regular !important;
  }
  margin-bottom: 40px;
`;

const Name = styled.div`
  font-family: Lato Bold;
  font-size: 20px;
  margin-bottom: 5px;
`;

const Title = styled.div`
  font-family: Lato Regular;
  font-size: 15px;
`;

const Testimonial = ({ children, name, title }) => (
  <Container>
    <EnlargedComma>
      <CommaIcon />
    </EnlargedComma>
    <Content>{children}</Content>
    <Name>{name}</Name>
    <Title>{title}</Title>
  </Container>
);

Testimonial.defaultProps = {
  children: <></>,
  name: '',
  title: '',
};

Testimonial.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]),
  name: PropTypes.string,
  title: PropTypes.string,
};

export default Testimonial;
