import styled from 'styled-components';

import $ from '../../../../styles/global';
import RotatingDownChevron from '../../../Chevron/RotatingDownChevron';

const OptionContainer = styled.div`
  margin-bottom: ${$.layout().margin3}px;
`;

const LabelText = styled.div`
  font-family: Cooper Hewitt Semibold;
  font-size: 16px;
  color: ${$.color.blue4};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: ${$.layout().margin5}px;
`;

const PillText = styled.div`
  flex-grow: 2;
  text-align: center;
  font-family: Aileron Heavy;
  font-size: 15px;
  color: ${$.color.blue3};
  ${$.ellipsis}
`;

const Pill = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${$.layout().padding5}px;
  padding-bottom: ${$.layout().padding5}px;
  padding-left: ${$.layout().padding4}px;
  padding-right: ${$.layout().padding4}px;
`;

const Dropdown = styled.div`
  border-radius: ${$.border().radius1}px;
  box-shadow: ${$.dropShadow.normal};
  position: relative;
  cursor: default;

  &.active {
    .menu {
      opacity: 1;
      pointer-events: initial;
      z-index: 9999;
      transform: translateY(0);
    }
  }
  &.clicked {
    background-color: ${$.color.blue3};

    > div:first-child {
      > div {
        color: ${$.color.white};
      }
    }

    svg {
      fill: ${$.color.white};
      stroke: ${$.color.white};
    }
  }
`;

const StyledRotatingDownChevron = styled(RotatingDownChevron)`
  fill: ${$.color.blue4};
  stroke: ${$.color.blue4};
  height: 6px;
`;

export {
  OptionContainer, LabelText, PillText, Pill, Dropdown, StyledRotatingDownChevron,
};
