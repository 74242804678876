import Seo from '../../components/seo';
import ApplyNowForm from '../../components/ProfessionalTrainingIndividualPage/ApplyNowForm';

const ApplyNowPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <ApplyNowForm />
  </>
);

export default ApplyNowPage;
