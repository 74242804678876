import styled from 'styled-components';
import $ from '../../../styles/global';

const Section = styled.section`
  background-color: ${$.color.orange4};
`;

const Container = styled.div`
  color: ${$.color.white};
  font-size: 30px;
  font-family: Aileron Heavy;
  padding: 50px 0;
`;

const Banner = () => (
  <Section>
    <Container>
      <h1>Privacy Policy</h1>
    </Container>
  </Section>
);

export default Banner;
