import styled from 'styled-components';
import { Link } from 'gatsby';

import PropTypes from 'prop-types';
import $ from '../../../styles/global';
import BottomInfo from './BottomInfo';
import Tags from './Tags';

const Container = styled.div`
  border-radius: ${$.border().radius4}px;
  box-shadow: ${$.dropShadow.normal};
  background-color: ${$.color.white};
  width: ${({ threes: { size } }) =>
    size === 1
      ? '100%'
      : `calc((100% - ${$.layout().margin3 * (size - 1)}px) / ${size})`};
  margin-bottom: ${$.layout().margin3}px;
  display: inline-block;
  transform: translate3d(0, 0, 0);

  &.hidden {
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    transform: translate3d(0, -20px, 0);
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-right: ${({ threes: { size, pos } }) =>
      (size === 3 && (pos === 1 || pos === 2)) || (size === 2 && pos === 1)
        ? $.layout().margin3
        : 0}px;
    height: auto;
    ${({ threes: { size } }) =>
      size === 1
        ? 'display: flex; flex-direction: row; min-height: 350px;'
        : ''}
    opacity: 1;
    transition: opacity 0.6s ${$.easingFn.standard},
      box-shadow 0.5s ${$.easingFn.standard},
      transform 0.5s ${$.easingFn.standard};

    &:hover {
      transform: translate3D(0, -8px, 8px);
      box-shadow: ${$.dropShadow.repressed};
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletB} {
    margin-right: ${({ threes: { size, pos } }) =>
      (size === 3 && (pos === 1 || pos === 2)) || (size === 2 && pos === 1)
        ? $.layout().margin3
        : 0}px;
    height: auto;
    ${({ threes: { size } }) =>
      size === 1
        ? 'display: flex; flex-direction: row; min-height: 350px;'
        : ''}
    transition: opacity 0.6s ${$.easingFn.decelerate}, transform 0.5s ${$
      .easingFn.standard};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    width: ${({ twos: { size } }) =>
      size === 2 ? `calc((100% - ${$.layout().margin3}px)/2)` : '100%'};
    margin-right: ${({ twos: { pos, size } }) =>
      pos === 1 && size === 2 ? $.layout().margin3 : 0}px;
    height: auto;
    ${({ twos: { size } }) =>
      size === 1
        ? 'display: flex; flex-direction: row; min-height: 350px;'
        : ''}
    transition: opacity 0.6s ${$.easingFn.decelerate}, transform 0.5s ${$
      .easingFn.standard};
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: opacity 0.6s ${$.easingFn.decelerate},
      transform 0.5s ${$.easingFn.standard};
  }
  // #endregion
`;

const ImgContainer = styled(Link)`
  overflow: hidden;
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  border-radius: ${({ threes: { size } }) =>
    size === 1
      ? `${$.border().radius4}px 0 0 ${$.border().radius4}px`
      : `${$.border().radius4}px ${$.border().radius4}px 0 0`};
  &:before {
    content: '';
    background-image: url(${({ img }) => img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: ${({ threes: { size } }) => (size === 1 ? '55%' : 'auto')};
    height: ${({ threes: { size } }) => (size === 1 ? 'auto' : '220px')};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletB} {
    width: ${({ threes: { size } }) => (size === 1 ? '55%' : 'auto')};
    height: ${({ threes: { size } }) => (size === 1 ? 'auto' : '220px')};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    width: ${({ twos: { size } }) => (size === 1 ? '55%' : 'auto')};
    height: ${({ twos: { size } }) => (size === 1 ? 'auto' : '220px')};
    border-radius: ${({ twos: { size } }) =>
      size === 1
        ? `${$.border().radius4}px 0 0 ${$.border().radius4}px`
        : `${$.border().radius4}px ${$.border().radius4}px 0 0`};
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    height: 220px;
    border-radius: ${$.border().radius4}px ${$.border().radius4}px 0 0;
  }
  // #endregion
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ threes: { size } }) =>
    size === 1
      ? `${$.layout().padding4}px ${$.layout().padding3}px`
      : `${$.layout().padding4}px`};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: ${({ threes: { size } }) =>
      size === 1
        ? `calc(45% - ${$.layout().padding3}px * 2)`
        : `calc(100% - ${$.layout().padding4}px * 2)`};
    height: ${({ threes: { size } }) =>
      size === 1
        ? 'auto'
        : `calc(100% - 220px - ${$.layout().padding4}px * 2)`};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletB} {
    width: ${({ threes: { size } }) =>
      size === 1
        ? `calc(45% - ${$.layout().padding3}px * 2)`
        : `calc(100% - ${$.layout().padding4}px * 2)`};
    height: ${({ threes: { size } }) =>
      size === 1
        ? 'auto'
        : `calc(100% - 220px - ${$.layout().padding4}px * 2)`};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    width: ${({ twos: { size } }) =>
      size === 1
        ? `calc(45% - ${$.layout().padding3}px * 2)`
        : `calc(100% - ${$.layout().padding4}px * 2)`};
    height: ${({ twos: { size } }) =>
      size === 1
        ? 'auto'
        : `calc(100% - 220px - ${$.layout().padding4}px * 2)`};
    padding: ${({ twos: { size } }) =>
      size === 1
        ? `${$.layout().padding4}px ${$.layout().padding3}px`
        : `${$.layout().padding4}px`};
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  }
  // #endregion
`;

const Title = styled(Link)`
  text-decoration: none;
  display: inline-block;
  font-family: Aileron Heavy;
  color: ${$.color.blue3};
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 24px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 22px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 20px;
  }
  // #endregion
`;

const Excerpt = styled(Link)`
  text-decoration: none;
  display: inline-block;
  color: ${$.color.blue4};
  font-family: Lato Light;
  flex-grow: 1;
  font-size: 18px;
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 16px;
  }
  // #endregion
`;

const Card = ({
  excerpt,
  title,
  feature_image: img,
  slug,
  tags,
  className,
  threes,
  twos,
  publishedAt,
  time_to_read: timeToRead,
  updatePreviousBlogPage,
  primary_author: author,
  hidden,
}) => {
  const handleOnClick = {
    to: `/blog/${slug}/`,
    // Update the redux so we know which blog pagination we last visited
    onClick: () => {
      const currentBlogPage =
        new URLSearchParams(
          typeof window !== 'undefined' ? window.location.search : ''
        ).get('page') || 1;

      updatePreviousBlogPage({ page: currentBlogPage });
    },
  };

  return (
    <Container
      className={`${className}${hidden === true ? ' hidden' : ''}`}
      threes={threes}
      twos={twos}
    >
      <ImgContainer img={img} threes={threes} twos={twos} {...handleOnClick} />
      <Content threes={threes} twos={twos}>
        <Tags slug={tags[0].slug} name={tags[0].name} />
        <Title {...handleOnClick}>{title}</Title>
        <Excerpt {...handleOnClick}>{excerpt}</Excerpt>
        <BottomInfo
          slug={slug}
          author={author}
          publishedAt={publishedAt}
          timeToRead={timeToRead}
        />
      </Content>
    </Container>
  );
};

Card.defaultProps = {
  excerpt: '',
  title: '',
  feature_image: '',
  className: '',
  slug: '',
  publishedAt: '',
  hidden: false,
  updatePreviousBlogPage: () => {},
  primary_author: {},
  twos: {},
  time_to_read: 0,
  threes: {},
  tags: [],
};

Card.propTypes = {
  updatePreviousBlogPage: PropTypes.func,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  primary_author: PropTypes.oneOfType([PropTypes.object]),
  threes: PropTypes.oneOfType([PropTypes.object]),
  excerpt: PropTypes.string,
  title: PropTypes.string,
  publishedAt: PropTypes.string,
  feature_image: PropTypes.string,
  time_to_read: PropTypes.number,
  slug: PropTypes.string,
  twos: PropTypes.oneOfType([PropTypes.object]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default Card;
