import Seo from '../../components/seo';
import AWSCPE from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/AWSCPE';

const AWSCPEPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <AWSCPE />
  </>
);

export default AWSCPEPage;
