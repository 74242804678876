import PropTypes from 'prop-types';

import Table from '../Table';

const InfoTable = ({ starts, fees, duration, level, venue }) => (
  <Table>
    <tbody>
      <tr>
        <td className="highlight1">Next Course Starts On</td>
        <td>{starts}</td>
      </tr>
      <tr>
        <td className="highlight1">Fees</td>
        <td>{fees}</td>
      </tr>
      <tr>
        <td className="highlight1">Duration</td>
        <td>{duration}</td>
      </tr>
      <tr>
        <td className="highlight1">Venue</td>
        <td>{venue}</td>
      </tr>
      <tr>
        <td className="highlight1">Level</td>
        <td>{level}</td>
      </tr>
    </tbody>
  </Table>
);

InfoTable.defaultProps = {
  starts: '',
  fees: '',
  venue: 'Online',
  duration: <></>,
  level: '',
};

InfoTable.propTypes = {
  venue: PropTypes.string,
  starts: PropTypes.string,
  fees: PropTypes.string,
  duration: PropTypes.element,
  level: PropTypes.string,
};

export default InfoTable;
