import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import Details from './Details';

// You can find themes in /themes. E.g. coy, dark, funky, okaidia, tomorrow, twilight
// or you can modify it yourself, it's just CSS
import 'prismjs/themes/prism-okaidia.css';

const Container = styled.div`
  color: ${$.color.blue4};
  position: relative;
  background: ${$.color.white};

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ${$.layout().margin2}px;
    z-index: -1;
    display: block;
    width: 25px;
    height: 300px;
    background: rgba(39, 44, 49, 0.15);
    filter: blur(5px);
  }

  &:before {
    transform: rotate(-5deg);
    left: 0px;
  }

  &:after {
    transform: rotate(5deg);
    right: 0px;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin: 0px -${$.layout().margin2}px 0px;
    padding: ${$.layout().margin2}px ${$.layout().margin2}px
      ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin: 0px -${$.layout().margin4}px 0px;
    padding: ${$.layout().padding3}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin: 0px -${$.layout().margin4}px;
    padding: ${$.layout().margin3}px ${$.layout().margin4}px;

    &:before,
    &:after {
      top: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const Title = styled.h1`
  margin: 0px 0px ${$.layout().margin3}px;
  font-family: Aileron Heavy;
  color: ${$.color.blue3};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 50px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    font-size: 42px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
  }
  // #endregion
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    line-height: 1.7em;
    font-size: 19px;
    font-family: Lato Light;

    & p.excerpt {
      font-family: Lato Light Italic;
    }

    & h1 {
      font-family: Lato Bold;
      font-size: 34px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & h2 {
      font-family: Aileron Heavy;
      font-size: 27px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & h3 {
      font-family: Aileron Heavy;
      font-size: 22px;
      color: ${$.color.blue6};
      margin-bottom: ${$.layout().margin4}px;
    }

    & img {
      max-width: 100%;
    }

    & img + figcaption {
      text-align: center;
      font-size: 0.9em;
      line-height: 1.4em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & strong {
      font-family: Lato Bold;
    }

    & p {
      margin: 0px 0px ${$.layout().margin3}px;
      word-break: break;
      hyphens: auto;
    }

    & p + ol,
    & p + ul {
      margin-top: -${$.layout().margin4}px;
      li {
        line-height: 1.7em;
      }
    }

    & ol,
    & ul {
      margin-bottom: ${$.layout().margin3}px;
      li {
        line-height: 1.7em;
      }
    }

    & table {
      width: 100%;
      box-shadow: ${$.dropShadow.normal};
      margin-bottom: ${$.layout().margin3}px;
      border-spacing: 10px;
      border-collapse: collapse;
      border-radius: ${$.border().radius3}px;
      overflow: hidden;

      > thead {
        background-color: ${$.color.blue5};
        color: ${$.color.white};
        font-family: Lato Bold;

        th {
          text-align: center;
          padding: ${$.layout().padding5}px ${$.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
        }
      }

      & tbody {
        td {
          padding: ${$.layout().padding5}px ${$.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;

          :first-child {
            border-left: none;
          }
          :last-child {
            border-right: none;
          }
        }
      }
    }

    & figure.kg-embed-card {
      padding-top: 56.25%;
      position: relative;
    }

    & figure.kg-image-card {
      text-align: center;
    }

    & figure > iframe {
      width: 100%;
      height: calc(100% - 1.4em - ${$.layout().padding5}px * 2);
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    & iframe + figcaption {
      line-height: 1.4em;
      padding: ${$.layout().padding5}px 0;
      text-align: center;
      font-size: 0.9em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & p:first-child {
      font-size: 27px;
      font-weight: 100;
      line-height: 1.4em;
    }

    & a {
      text-decoration: none;
      font-family: Lato Bold;
      color: ${$.color.blue5};

      &:visited {
        color: ${$.color.blue5};
      }
    }

    & blockquote {
      margin: 0 0 1.5em;
      padding: 0 1.2em;
      font-size: 23px;
      border-left: 3px solid ${$.color.blue5};
      quotes: none;
      font-style: italic;
    }

    & pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    & pre > code {
      width: calc(100% - 10px);
    }

    & code {
      padding: 2px 5px 2px;
      font-size: 0.8em;
      background: ${$.color.blue1};
      border-radius: ${$.border().radius4}px;
      background-color: ${$.color.blue1};
      display: inline-block;
      max-width: 100%;
    }

    & hr {
      margin: ${$.layout().margin3}px 0px ${$.layout().margin2}px;
      border: 1px solid ${$.color.blue5};
      opacity: 0.3;
      width: 85%;
      align-self: center;
    }

    & > iframe {
      max-width: 100%;
      align-self: center;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    line-height: 1.7em;
    font-size: 19px;
    font-family: Lato Light;

    & p.excerpt {
      font-family: Lato Light Italic;
    }

    & h1 {
      font-family: Lato Bold;
      font-size: 34px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & h2 {
      font-family: Aileron Heavy;
      font-size: 27px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & h3 {
      font-family: Aileron Heavy;
      font-size: 24px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & img {
      max-width: 100%;
    }

    & img + figcaption {
      text-align: center;
      font-size: 0.9em;
      line-height: 1.4em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & figure.kg-image-card {
      text-align: center;
    }

    & strong {
      font-family: Lato Bold;
    }

    & p {
      margin: 0px 0px ${$.layout().margin3}px;
      word-break: break;
      hyphens: auto;
    }

    & p + ol,
    & p + ul {
      margin-top: -${$.layout().margin4}px;
      li {
        line-height: 1.7em;
      }
    }

    & ol,
    & ul {
      margin-bottom: ${$.layout().margin3}px;
      li {
        line-height: 1.7em;
      }
    }

    & .table {
      width: 100%;
      overflow-x: auto;
      box-shadow: ${$.dropShadow.normal};
      margin-bottom: ${$.layout().margin3}px;
      border-radius: ${$.border().radius3}px;
    }

    & table {
      border-spacing: 10px;
      border-collapse: collapse;
      min-width: 100%;
      overflow: hidden;

      > thead {
        background-color: ${$.color.blue5};
        color: ${$.color.white};
        font-family: Lato Bold;

        th {
          text-align: center;
          padding: ${$.layout().padding5}px ${$.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
        }
      }

      & tbody {
        td {
          padding: ${$.layout().padding5}px ${$.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;

          :first-child {
            border-left: none;
          }
          :last-child {
            border-right: none;
          }
        }
      }
    }

    & figure.kg-embed-card {
      padding-top: 56.25%;
      position: relative;
      margin-bottom: ${$.layout().margin3}px;
    }

    & figure > iframe {
      width: 100%;
      height: calc(100% - 1.4em - ${$.layout().padding5}px * 2);
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    & iframe + figcaption {
      line-height: 1.4em;
      padding: ${$.layout().padding5}px 0;
      text-align: center;
      font-size: 0.9em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & p:first-child {
      font-size: 27px;
      font-weight: 100;
      line-height: 1.4em;
    }

    & a {
      text-decoration: none;
      font-family: Lato Bold;
      color: ${$.color.blue5};

      &:visited {
        color: ${$.color.blue5};
      }
    }

    & blockquote {
      margin: 0 0 1.5em;
      padding: 0 1.5em;
      border-left: 3px solid ${$.color.blue5};
      quotes: none;
      font-style: italic;
    }

    & pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    & pre > code {
      width: calc(100% - 10px);
    }

    & code {
      padding: 2px 5px 2px;
      font-size: 0.8em;
      background: ${$.color.blue1};
      border-radius: ${$.border().radius4}px;
      background-color: ${$.color.blue1};
      display: inline-block;
    }

    & hr {
      margin: ${$.layout().margin4}px 0px ${$.layout().margin3}px;
      border: 1px solid ${$.color.blue5};
      opacity: 0.3;
      width: 90%;
      align-self: center;
    }

    & > iframe {
      max-width: 100%;
      align-self: center;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    line-height: 1.7em;
    font-family: Lato Light;
    font-size: 19px;

    & p.excerpt {
      font-family: Lato Light Italic;
    }

    & h1 {
      font-family: Lato Bold;
      font-size: 34px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & h2 {
      font-family: Aileron Heavy;
      font-size: 27px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & h3 {
      font-family: Aileron Heavy;
      font-size: 24px;
      color: ${$.color.blue5};
      margin-bottom: ${$.layout().margin4}px;
    }

    & img {
      max-width: 100%;
    }

    & img + figcaption {
      text-align: center;
      font-size: 0.9em;
      line-height: 1.4em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & strong {
      font-family: Lato Bold;
    }

    & p {
      margin: 0px 0px ${$.layout().margin3}px;
      word-break: break;
      hyphens: auto;
    }

    & p + ol,
    & p + ul {
      margin-top: -${$.layout().margin4}px;
      li {
        line-height: 1.7em;
      }
    }

    & ol,
    & ul {
      margin-bottom: ${$.layout().margin3}px;
      li {
        line-height: 1.7em;
      }
    }

    & .table {
      width: 100%;
      overflow-x: auto;
      box-shadow: ${$.dropShadow.normal};
      margin-bottom: ${$.layout().margin3}px;
      border-radius: ${$.border().radius3}px;
    }

    & table {
      border-spacing: 10px;
      border-collapse: collapse;
      min-width: 100%;
      overflow: hidden;

      > thead {
        background-color: ${$.color.blue5};
        color: ${$.color.white};
        font-family: Lato Bold;

        th {
          text-align: center;
          padding: ${$.layout().padding5}px ${$.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
        }
      }

      & tbody {
        td {
          padding: ${$.layout().padding5}px ${$.layout().padding4}px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;

          :first-child {
            border-left: none;
          }
          :last-child {
            border-right: none;
          }
        }
      }
    }

    & figure.kg-embed-card {
      padding-top: 56.25%;
      position: relative;
      margin: 0px 0px ${$.layout().margin3}px;
    }

    & figure.kg-image-card {
      text-align: center;
      margin: ${$.layout().margin3}px 0;
    }

    & figure > iframe {
      width: 100%;
      height: calc(100% - 1.4em - ${$.layout().padding5}px * 2);
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    & iframe + figcaption {
      line-height: 1.4em;
      padding: ${$.layout().padding5}px 0;
      text-align: center;
      font-size: 0.9em;
      color: #868282;
      a {
        font-family: Lato Regular;
        font-size: 0.9em;
      }
    }

    & p:first-child {
      font-size: 23px;
      font-weight: 100;
      line-height: 1.4em;
    }

    & a {
      text-decoration: none;
      font-family: Lato Bold;
      color: ${$.color.blue5};

      &:visited {
        color: ${$.color.blue5};
      }
    }

    & blockquote {
      margin: 0 0 1.5em;
      padding: 0 1.5em;
      border-left: 3px solid ${$.color.blue5};
      quotes: none;
      font-style: italic;
    }

    & pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    & pre > code {
      width: calc(100% - 10px);
    }

    & code {
      padding: 2px 5px 2px;
      font-size: 0.8em;
      background: ${$.color.blue1};
      border-radius: ${$.border().radius4}px;
      background-color: ${$.color.blue1};
      display: inline-block;
    }

    & hr {
      margin: ${$.layout().margin4}px 0px ${$.layout().margin3}px;
      border: 1px solid ${$.color.blue5};
      opacity: 0.3;
      width: 100%;
    }

    & > iframe {
      width: 100%;
    }
  }
  // #endregion
`;

const PostContentContainer = ({
  title,
  html,
  excerpt,
  timeToRead,
  primaryAuthor,
  publishedAt,
}) => (
  <Container>
    <Title>{title}</Title>
    <Details
      primaryAuthor={primaryAuthor}
      timeToRead={timeToRead}
      publishedAt={publishedAt}
    />
    <Content
      dangerouslySetInnerHTML={{
        __html: `<p class="excerpt">${excerpt}</p>${html
          .replace(
            /<a href="http/g,
            '<a target="_blank" rel="noopener noreferrer" href="http'
          )
          .replace(/<table>/g, '<div class="table"><table>')
          .replace(/<\/table>/g, '</table></div>')}`,
      }}
    />
  </Container>
);

PostContentContainer.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  primaryAuthor: PropTypes.objectOf(PropTypes.string).isRequired,
  publishedAt: PropTypes.string.isRequired,
};

export default PostContentContainer;
