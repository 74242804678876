import styled from 'styled-components';

import $ from '../../../styles/global';
import ImageContainer from '../../Level2Banner/ImageContainer';
import RightImage from '../../../assets/images/blog/right-image.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    right: -50px;
    top: -20px;

    & > svg {
      width: 550px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    position: absolute;
    right: -70px;
    top: -30px;

    & > svg {
      width: 45vw;
      max-height: 200px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const RightImageContainer = () => <Container image={RightImage} />;

export default RightImageContainer;
