import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../styles/global';
import Level2Banner from '../components/Level2Banner';
import Image1Container from '../components/whyus/students/banner/Image1Container';
import Image2Container from '../components/whyus/students/banner/Image2Container';
import Image3Container from '../components/whyus/students/banner/Image3Container';
import Image4Container from '../components/whyus/students/banner/Image4Container';
import MobileImageContainer from '../components/whyus/students/banner/MobileImageContainer';
import Achievements from '../components/whyus/students/Achievements';
import SEO from '../components/seo';

const StyledLevel2Banner = styled(Level2Banner)`
  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    .level2-title-container {
      height: 85%;
    }
  }

  @media ${$.device.tabletB} {
    .level2-title-container {
      height: 85%;
      .level2-subtitle {
        width: 90%;
      }
    }
  }
  // #endregion
`;

const Students = ({ location }) => (
  <>
    <SEO
      location={location}
      keywords={[
        'coding student achievements',
        'what can kids do with code',
        'what can teens do with code',
        'coding hackathons for kids',
        'coding competition kids',
      ]}
      title="Our Students"
      description="Check out this hefty list of what our students are capable of"
    />
    <StyledLevel2Banner
      title="Our Students"
      subtitle="We offer an unparalleled student experience on Campus. Come meet our community of passionate young coders and see what they are capable of!"
      color={$.color.white}
      backgroundColor={$.color.blue3}
    >
      <Image1Container />
      <Image2Container />
      <Image3Container />
      <Image4Container />
      <MobileImageContainer />
    </StyledLevel2Banner>
    <Achievements />
  </>
);

Students.defaultProps = {
  location: {},
};

Students.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default Students;
