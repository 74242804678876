import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import { Title, Body } from './common';

const Container = styled.div`
  grid-area: whoShouldAttend;
`;

const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.layout().margin3}px;
  padding-left: ${({ theme }) => theme.layout().padding4}px;
`;

const WhoShouldAttend = () => {
  const { whoShouldAttend } = useContext(Context);

  return (
    <Container>
      <Title>Who Should Attend</Title>
      <ListContainer>
        {whoShouldAttend.map(({ id, name }) => (
          <li key={id}>{name}</li>
        ))}
      </ListContainer>
    </Container>
  );
};

export default WhoShouldAttend;
