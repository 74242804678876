import styled from 'styled-components';

import $ from '../../../../styles/global';
import FAQ from './FAQ';
import Banner from './Banner';

const Section = styled.section`
  padding-bottom: 80px;
`;

const Container = styled.div``;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.black2};
  font-family: Aileron Heavy;
  margin-bottom: ${$.layout().margin3}px;
`;

const Blockquote = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout().margin2}px;

  > * {
    &:first-child {
      background-color: ${$.color.orange3};
      width: 10px;
      align-self: stretch;
      margin-right: ${$.layout().margin4}px;
    }
    &:last-child {
      width: calc(100% - 10px - ${$.layout().margin4}px);
      font-family: Lato Bold;
      font-size: 16px;
      line-height: 1.3em;
    }
  }
`;

const ApplicationDetails = () => (
  <Section>
    <Container>
      <Title>Application Details</Title>
      <FAQ />
      <Blockquote>
        <div />
        <div>
          This programme is fully subsidised for all successful participants.
          Participants will be accorded sufficient AWS credits to complete all
          projects.
        </div>
      </Blockquote>
      <Banner />
    </Container>
  </Section>
);

export default ApplicationDetails;
