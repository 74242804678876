const initialState = {
  loggedIn: false,
};

if (typeof window !== 'undefined') {
  initialState.loggedIn = !!window.localStorage.getItem(
    'schoolsPageLoginState'
  );
}

export default (state = initialState, { type }) => {
  const newState = { ...state };

  switch (type) {
    case 'VIDEO_LOGIN_STATUS_UPDATE':
      if (typeof window !== 'undefined') {
        newState.loggedIn = !!window.localStorage.getItem(
          'schoolsPageLoginState'
        );

        console.log(newState);
        return newState;
      }

      console.log(state);
      return state;
    default:
      return state;
  }
};
