import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  & > span {
    font-size: 15px;
    line-height: 18px;
    width: calc(100% - 30px - 15px);
    font-family: Lato Light;
    color: ${$.color.black};
  }
`;

const Pointer = styled.div`
  ${({ color }) => `
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  align-self: flex-start;

  & > span {
    display: block;
    text-align: center;
    color: ${$.color.white};
    font-size: 20px;
    font-family: Lato Light;
    line-height: 28px;
    position: relative;
    z-index: 2;
  }

  & > div {
    &:first-of-type {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${color};
      z-index: 1;
    }
    &:last-of-type {
      width: 30px;
      height: 30px;
      border-radius: 999px;
      position: absolute;
      background-color: ${color};
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
`}
`;

const ListItem = ({ pointerColor, number, text }) => (
  <Container>
    <Pointer color={pointerColor}>
      <div />
      <div />
      <span>{number}</span>
    </Pointer>
    <span>{text}</span>
  </Container>
);

ListItem.defaultProps = {
  pointerColor: $.proTraining.orange1,
  number: 1,
  text: 'Hello world!',
};

ListItem.propTypes = {
  pointerColor: PropTypes.string,
  number: PropTypes.number,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]),
};

export default ListItem;
