import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../styles/global';
import Background from './Background';
import AWSSGCCOrange from '../../assets/images/awsaccelerator2022/aws-sgcc-orange-logo.svg';

const Container = styled.div`
  padding: 100px 0;
  height: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    height: 100%;
    padding: 50px 0 0 0;
  }
  // #endregion
`;

const Description = styled.div`
  width: 50%;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100vw - ${$.layout().margin3 * 2}px);
    margin-left: ${$.layout().margin3}px;
    margin-right: ${$.layout().margin3}px;
    padding-bottom: 50px;
  }
  // #endregion
`;

const Pills = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${$.layout().margin4}px;

  & > *:not(:last-child) {
    margin-right: ${$.layout().margin5}px;
  }
`;

const Pill = styled.h1`
  display: inline-block;
  background-color: ${$.color.white};
  color: ${$.accelerator.blue};
  font-size: 14px;
  font-family: Lato Bold;
  border-radius: 20px;
  margin-bottom: ${$.layout().margin5}px;
  padding: ${$.layout().padding5}px ${$.layout().padding4}px;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Subtitle = styled.h3`
  font-family: Lato Regular;
  font-size: 16px;
  color: ${$.color.white};
  opacity: 0.6;
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin3}px;
`;

const AWSPartnerDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    color: ${$.color.white};
    font-size: 16px;
    font-family: Lato Bold;
    line-height: 1.3em;
  }

  svg {
    /* margin-right: ${$.layout().margin4}px; */
    margin-bottom: ${$.layout().margin3}px;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: flex-start;

    svg {
      width: 100%;
      margin-right: 0;
      margin-bottom: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const Image = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 700px;
  right: -10%;
  position: relative;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: calc(50% + ${$.layout().margin3}px);
    right: -${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    width: 100vw;
    left: 0;
    height: auto;

    & > :nth-child(2) {
      height: 400px;
    }
  }
  // #endregion
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 350px 0 350px 80px;
  border-color: transparent transparent transparent ${$.accelerator.blue};
  position: absolute;
  z-index: 1;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    border-width: 60px 50vw 0 50vw;
    border-color: ${$.accelerator.blue} transparent transparent transparent;
  }
  // #endregion
`;

const Banner = () => (
  <Background>
    <Container>
      <Description>
        <Pills>
          <Pill>Amazon Web Services</Pill>
          <Pill>Accelerator 2024</Pill>
          <Pill>Fully Sponsored</Pill>
        </Pills>
        <Title>
          For Secondary School/JC students interested in a <u>future</u> in
          tech.
        </Title>
        <Subtitle>
          Experience a fully-sponsored 110-hour programme in Data Science,
          explore Deep Learning with the AWS DeepRacer robotic race car and get
          certified with Amazon Web Services (AWS). Use your skills to build a
          Data Science/AI solution for a real-world business client.
        </Subtitle>
        <AWSPartnerDescription>
          <AWSSGCCOrange />
          <span>
            Jointly organised by Amazon Web Services (AWS) and SG Code Campus
            (an Authorised Training Partner in the AWS Partner Network)
          </span>
        </AWSPartnerDescription>
      </Description>

      <Image>
        <Triangle />
        <StaticImage
          src="../../assets/images/awsaccelerator2022/aws-deepracer-banner.jpg"
          alt="AWS Accelerator 2024"
        />
      </Image>
    </Container>
  </Background>
);

export default Banner;
