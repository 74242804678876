import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import item from './item.json';
import $ from '../../styles/global';

const Section = styled.section`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Container = styled.div``;

const ImgContainer = styled.div`
  width: 100%;
  border-radius: ${$.border().radius2}px;
  margin: ${$.layout().margin3}px 0;
  border: 1px solid ${$.color.gray1};

  > * {
    border-radius: inherit;
    width: 100%;
    height: 400px;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin: 0 0 ${$.layout().margin3}px 0;
    border-radius: 0;
    width: 100vw;
    position: relative;
    border: none;
    left: -${$.layout().margin3}px;
  }
  // #endregion
`;

const Message = styled.div`
  line-height: 1.5em;
  color: ${$.color.blue4};
  font-size: 18px;
  font-family: Lato Regular;
  margin-bottom: ${$.layout().margin2}px;

  em {
    font-family: Lato Italic;
  }

  strong {
    font-family: Lato Bold;
  }
`;

const StyledLink = styled(Link)`
  font-family: Lato Bold;
  text-decoration: none;
  color: ${$.color.orange3};
`;

const IFrameContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin: 0px ${$.layout().margin4}px ${$.layout().margin3}px;
    padding-top: calc(56.25%);
    position: relative;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin: ${$.layout().margin3}px ${$.layout().margin4}px;
    padding-top: calc(56.25% + ${$.layout().padding2}px);
    position: relative;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: calc(100% + ${$.layout().margin3 * 2}px);
    padding-top: calc(56.25% + ${0.5625 * $.layout().margin3 * 2}px);
    margin-left: -${$.layout().margin3}px;
    margin-right: -${$.layout().margin3}px;
    position: relative;
  }
  // #endregion
`;

const StyledIFrame = styled.iframe`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
`;

const IFrameTitle = styled.div`
  font-family: Aileron Heavy;
  font-size: 24px;
  color: ${$.color.blue4};
  text-align: center;
  margin-bottom: ${$.layout().margin3}px;
`;

const MainExcerpt = () => (
  <Section>
    <Container>
      <ImgContainer>
        <StaticImage
          src="../../assets/images/onlineclasses/main.png"
          alt="Online classes image banner"
        />
      </ImgContainer>
      <Message>
        <p>
          {
            'Starting 27 March, all SG Code Campus lessons will be conducted online, in compliance with the latest social distancing '
          }
          <StyledLink to="/coronavirus-advisory/">measures</StyledLink>
          {'.'}
        </p>
        <p>
          We have been working hard on this front, and have already had some
          students take our classes from home. You can thus be assured that your
          child will still be able to receive their quality coding education
          from home!
        </p>
      </Message>
      <IFrameTitle>
        Here&apos;s a sample of what our online class looks like
      </IFrameTitle>
      <IFrameContainer>
        <StyledIFrame
          title={item.title}
          src={item.video}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </IFrameContainer>
    </Container>
  </Section>
);

export default MainExcerpt;
