import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { RemoteLink, Margin20 } from '../Misc';
import TrainerProfile from '../TrainerProfile';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import FundingTable from '../SMUReusableComponents/FundingTable';
import InfoTable from '../SMUReusableComponents/InfoTable';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Topics',
  },
  {
    text: 'Who Should Attend',
    threshold: 0.1,
  },
  {
    text: 'Fees And Funding',
  },
  {
    text: 'Schedule',
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Understand the classification of cloud computing products into categories Infrastructure-as-a-Service (IaaS), Platform-as-a-service (PaaS) and Software-as-a-Service (SaaS)',
  },
  {
    text: 'Learn about data storage on AWS by creating their own address book on the DynamoDB service and understand this service as an example of a PaaS offering',
  },
  {
    text: 'Understand how smart applications are created through hands-on exercises and demo web applications built with the AI/Machine Learning (ML)/ Deep Learning (DL) PaaS offerings on AWS like Polly, Lex and Rekognition',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'Business Decision Makers/ Owners looking to understand how AI, ML and DL work and gain practical experience',
  },
  {
    text: 'Data Science enthusiasts',
  },
  {
    text: 'Developers looking to understand Cloud Computing and APIs available for developing and deploying smart applications and infrastructures on the AWS Cloud',
  },
]);

const topics = Utils.addKeys([
  {
    text: 'Learn HTML to create a simple website on the AWS Cloud9 Code Editor and understand Cloud9 as an example of SaaS offering',
  },
  {
    text: 'Learn about the IaaS offerings on AWS by deploying their own website to the internet using the EC2 and S3 services on AWS and understand these services as an example of IaaS offerings',
  },
  {
    text: 'Understand how SaaS offerings like AWS Chime, AWS Connect and AWS Workspaces allow for businesses to rapidly implement remote connectivity and Business Continuity Plans',
  },
]);

const trainers = Utils.addKeys([
  Profiles.ian,
  Profiles.sunChang,
  Profiles.philipBeh,
  Profiles.huangBin,
]);

const applyNowLink =
  '/professional-training/apply-now/?c=Certificate in Technology Foundations: Establishing Foundation in Cloud Services and Applications';

const enquiryLink =
  'https://academy.smu.edu.sg/programme-enquiry?course=Certificate%20in%20Technology%20Foundations%3A%20Establishing%20Foundation%20in%20Cloud%20Services%20and%20Applications&nid=4551';

const TechFoundation = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="Certificate in Technology Foundations: Establishing Foundation in Cloud Services and Applications"
      />
      <MobileNavigationMenu
        items={navigationLinks}
        applyNowLink={applyNowLink}
        enquiryLink={enquiryLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              Cloud Computing is often hailed as one of the biggest potential
              areas of growth in the success of today&apos;s business. However,
              the very definition of cloud computing and its massive potential
              remains elusive to the general public. This programme introduces
              critical technology through the world&apos;s largest provider of
              Cloud Computing - Amazon Web Services (AWS).
            </p>
            <p>
              Participants will also learn the basics of web programming, before
              understanding Cloud computing through creating web applications.
            </p>
            <Margin20 />
            <InfoTable
              venue="On-campus"
              starts="06 Jul 2023 (Thu)"
              fees="SGD1728.00* (as low as SGD198.40* after maximum funding)"
              duration={
                <>
                  3 days
                  <br />
                  Weeknights (7pm - 10:30pm)
                  <br />
                  Saturday (9am - 6:00pm)
                </>
              }
              level="Basic"
            />
            <h3>Learning Objectives</h3>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            {topics.map(({ key, text }, index) => (
              <ListItem text={text} key={key} number={index + 1} />
            ))}
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
          </TextSection>
          <TextSection>
            <h2>Assessment</h2>
            <p>Individual assessment</p>
            <Margin20 />
            <Margin20 />
            <h3>Certification</h3>
            <p>
              Upon meeting the attendance and assessment criteria, participants
              will be awarded a digital certificate for participating in each
              module. Please refer to our{' '}
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                course policies
              </RemoteLink>{' '}
              to view the attendance and assessment criteria.
            </p>
            <p>
              Upon completion of all modules required for this programme within
              a maximum duration of 3 years, participants will be awarded a
              digital certificate.
            </p>
            <Margin20 />
            <Margin20 />
            <h3>Learning Activities</h3>
            <ul>
              <li>Theory</li>
              <li>Live demos</li>
              <li>
                Hands-on exercises with the various services available on the
                Amazon Web Services cloud
              </li>
            </ul>
          </TextSection>
          <TextSection position={3}>
            <h2>Fees And Funding</h2>
            <FundingTable
              firstRow={[
                <>
                  <strong>$518.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="http://www.ssg.gov.sg/programmes-and-initiatives/funding/enhanced-training-support-for-smes1.html">
                    ETSS Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$518.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
              ]}
              secondRow={[
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
              ]}
              thirdRow={[
                <>
                  <strong>$$1,728</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$1,728</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$1,728</strong>
                  <br />
                  (No Funding)
                </>,
              ]}
            />
          </TextSection>
          <TextSection position={4}>
            <h2>Schedule</h2>
            <p>
              <strong>Start Date(s)</strong>
              <br />
              06 Jul 2023 (Thu)
            </p>
            <p>
              <strong>Intake Information</strong>
            </p>
            <Table>
              <thead>
                <tr>
                  <td className="highlight2">Intakes</td>
                  <td className="highlight2">Dates</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Intake 5</td>
                  <td>
                    16, 17 & 18 Feb 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                </tr>
                <tr>
                  <td>Intake 6</td>
                  <td>
                    <strong>
                      6, 7 & 8 Jul 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection>
            <h2>Policies</h2>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                View programme policies.
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={5}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default TechFoundation;
