import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../styles/global';
import CodieNotFound from '../assets/images/notfound/codie.svg';

const Section = styled.section`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding2}px 0;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: 0;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding3}px 0;
  }
  // #endregion
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    flex-wrap: wrap;
    height: 500px;
    // make the content smaller than 970px
    width: 900px !important;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    flex-wrap: wrap;
    justify-content: center;
    height: 500px;
  }
  // #endregion
`;

const TitleContainer = styled.div`
  position: relative;
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 47%;
    margin-right: 3%;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 50%;
    margin-right: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    order: 1;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue5};
  position: absolute;
  z-index: 2;
  top: 55px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 45px;
    width: 350px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 38px;
    top: 70px;
    width: 350px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 36px;
  }
  // #endregion
`;

const BackgroundTitle = styled.div`
  font-family: Aileron Heavy;
  opacity: 0.5;
  z-index: 1;
  color: ${$.color.orange1};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 210px;
    line-height: 190px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 200px;
    line-height: 180px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 160px;
    line-height: 150px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  color: ${$.color.blue5};
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 16px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 15px;
    order: 2;
  }
  // #endregion
`;

const Info = styled.div`
  color: ${$.color.blue5};
  line-height: 1.4em;

  a {
    font-family: Lato Bold;
    text-decoration: none;
    color: ${$.color.blue5};
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 47%;
    margin-right: 3%;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 16px;
    width: 47%;
    margin-right: 3%;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 15px;
    order: 4;
  }
  // #endregion
`;

const Message = styled.div`
  font-family: Aileron Heavy;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 20px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 18px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 15px;
  }
  // #endregion
`;

const ShowChat = styled.span`
  font-family: Lato Bold;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
    }
  }
  // #endregion
`;

const StyledCodieNotFound = styled(CodieNotFound)`
  width: 50%;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    max-width: 440px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 80%;
    max-width: 300px;
    margin: auto;
    order: 3;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const NotFound = () => (
  <Section>
    <Container>
      <TitleContainer>
        <Title>Oops, it looks like you&apos;re lost!</Title>
        <BackgroundTitle>404</BackgroundTitle>
        <Subtitle>
          Sorry, we can&apos;t find that page - it might be an old link or maybe
          it moved.
        </Subtitle>
      </TitleContainer>
      <Info>
        <Message>Let&apos;s see if we can help you get back on track!</Message>
        <div>
          {'Return to our '}
          <Link to="/">homepage</Link>
          {' , or get in touch with our team via our '}
          <ShowChat
            onClick={() => {
              if (
                typeof window !== 'undefined' &&
                typeof window.Intercom !== 'undefined'
              ) {
                window.Intercom('show');
              }
            }}
          >
            chat
          </ShowChat>
          {' or '}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeDjtfkjdlQKxtJH8ws9zyQAtpM9KTyc3ptcXNo5-s78d56AQ/viewform?embedded=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            enquiry form
          </a>{' '}
          — maybe we can help you out more quickly!
        </div>
      </Info>
      <StyledCodieNotFound />
    </Container>
  </Section>
);

export default NotFound;
