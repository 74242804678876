import styled from 'styled-components';

import $ from '../../../../styles/global';
import SplitCard from './SplitCard';

const Section = styled.section`
  background-color: transparent;
  padding-bottom: 80px;
`;

const Container = styled.div`
  position: relative;
  z-index: 0;
`;

const Content = styled.div`
  color: ${$.color.black2};
  font-size: 16px;
  margin-bottom: ${$.layout().margin3}px;
  line-height: 1.4em;

  ${$.br}

  strong {
    font-family: Lato Bold;
    font-size: 15.5px;
  }

  a {
    font-family: Lato Bold;
    font-size: 15.5px;
    color: ${$.color.blue5};
    text-decoration: none;

    &:visited {
      color: ${$.color.blue5};
    }
  }
`;

const TwoSessions = () => (
  <Section>
    <Container>
      <Content>
        <strong>
          Applications for the 2021 Bootcamp programme have closed!
        </strong>
        <br />
        <strong>
          Registration is now open for direct applicants to the 2021
          Accelerator.
        </strong>
        {` Forms need to be submitted by 8 June 2021. (Applicants attending the
          Bootcamp do need not send a new application for the Accelerator -
          registration for the Accelerator will be conducted during the
          Bootcamp.)`}
        <br />
        <strong>Direct applicants to the Accelerator</strong>
        {` (those not attending the
          Bootcamp) do not need their teacher’s permission to attend the
          Accelerator but, as we would like to keep their schools updated on
          their progress, schools will be emailed once at the start and at the
          end of the Accelerator. In the online registration form on this
          webpage, there will be a section for applicants to fill in the contact
          details of their CCA or form teacher (please get permission from them
          to fill in the form with their contact).`}
        <br />
        Applicants who apply through this route will have to take an online
        Python programming test as part of the application to ascertain that
        they have the level of coding background needed to succeed in the
        Accelerator (recommended at least 12-20 hours of programming education
        in Python or languages like Swift, Java, JavaScript, C++). They will
        also be required to prepare and upload a 30- to 45-second video
        introducing themselves and why they want to join the AWS Accelerator
        Programme 2021. If possible, applicants should mention any prior
        programming experiences or any relevant experiences in AI or Deep
        Learning.
        <br />
        {`If you have any questions, please contact us at `}
        <a
          href="mailto:datascience@sgcodecampus.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          datascience@sgcodecampus.com
        </a>
        {`.`}
      </Content>
      <SplitCard />
    </Container>
  </Section>
);

export default TwoSessions;
