import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import Header from './Header';

const Section = styled.section`
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion
`;

const Container = styled.div``;

const Step3 = () => (
  <Section>
    <Fade bottom distance="100px">
      <Container>
        <Header
          title="Step 3: Sign up and begin your coding learning journey with us!"
          subtitle="Sign up for our <a href='/'>coding courses</a> online by clicking on the sign-up link in our schedule, filling in our registration form and making payment. It's as easy as 1-2-3!<br />Whether you’re a beginner or ready to take your programming skills to the next level, our intensive coding bootcamps will immerse you into the world of coding. This will ensure that you emerge with everything you need to deepen your understanding and strengthen your ability to go further in the field of computer science. Find a suitable holiday programming bootcamp for your kid in Singapore and let them explore wonders!<br />Still need help? <a href='https://docs.google.com/forms/d/e/1FAIpQLSeDjtfkjdlQKxtJH8ws9zyQAtpM9KTyc3ptcXNo5-s78d56AQ/viewform?embedded=true'>Fill up the enquiry form</a> or <a href='/contact-us/'>contact us</a> directly, and we will be more than happy to help you get started!"
        />
      </Container>
    </Fade>
  </Section>
);

export default Step3;
