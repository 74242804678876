import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import SGCCLogo from '../../assets/icons/logo.svg';
import $ from '../../styles/global';

const Container = styled.div`
  height: 40px;
  width: 180px;

  a {
    display: inline-block;
  }

  a,
  svg {
    width: 100%;
    height: 100%;
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    a {
      width: 180px;
    }
  }
  // #endregion
`;

const Logo = ({ setHeroActive }) => (
  <Container>
    <Link
      to="/"
      onClick={() => {
        setHeroActive(false);
      }}
    >
      <SGCCLogo />
    </Link>
  </Container>
);

Logo.defaultProps = {
  setHeroActive: () => {},
};

Logo.propTypes = {
  setHeroActive: PropTypes.func,
};

export default Logo;
