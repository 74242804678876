import styled from 'styled-components';
import { useSelector } from 'react-redux';

import $ from '../../styles/global';

const Section = styled.section`
  background-color: ${$.color.orange3};
`;

const Container = styled.div`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    padding-top: ${$.layout().padding2}px;
    padding-bottom: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding-top: ${$.layout().padding2}px;
    padding-bottom: ${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding-top: ${$.layout().padding3}px;
    padding-bottom: ${$.layout().padding3}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  margin-bottom: ${$.layout().margin5}px;
  color: ${$.color.white};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 48px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    text-align: center;
  }
  // #endregion
`;

const Banner = () => {
  const videoLoginStatus = useSelector((state) => state.videoLoginStatus);

  return (
    <Section>
      {videoLoginStatus.loggedIn && (
        <Container>
          <Title>Mobile Programming with Thunkable</Title>
        </Container>
      )}
    </Section>
  );
};

export default Banner;
