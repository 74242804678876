import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import { Title, Body } from './common';

const Container = styled.div`
  grid-area: courseFeesFunding;
`;

const CourseFeesFunding = () => {
  const {
    courseCard: { feesInformation },
  } = useContext(Context);

  return (
    <Container>
      <Title>Course Fees Funding</Title>
      <Body dangerouslySetInnerHTML={{ __html: feesInformation }} />
    </Container>
  );
};

export default CourseFeesFunding;
