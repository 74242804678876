import styled from 'styled-components';

import $ from '../../../styles/global';
import DeepRacerLogo from '../../../assets/images/awsaccelerator2022/deepracer-logo.png';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
  overflow: scroll;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    justify-content: initial;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    justify-content: initial;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const InfoDescription = styled.div`
  width: 65%;
  line-height: 1.3em;
  font-size: 17px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const StyledParagraph = styled.p`
  line-height: 1.3em;
  font-size: 17px;
`;

const Subtitle = styled.p`
  font-family: Lato Bold;
  font-size: 20px;
  color: ${$.color.black};
  margin-bottom: ${$.layout().margin3}px;
`;

const ScheduleTable = () => (
  <Section>
    <Container>
      <Info>
        <ImageContainer>
          <img src={DeepRacerLogo} alt="deep racer logo" />
        </ImageContainer>
        <InfoDescription>
          Dunman Secondary School is an autonomous co-educational secondary
          school in Tampines, Singapore, founded in 1963. As a government
          school, Dunman Secondary offers three academic streams, the Express
          course and Normal Course comprising of the Normal (Academic) and
          Normal (Technical) academic tracks. Signature programmes at Dunman
          Secondary are its ELIXIR (Explore, Life, Innovations, Excel,
          Individual Responsibility) programme and school-based curriculum MEDAL
          (Mastering English, Drama, the Art of Communication and Literature),
          which integrates English, Drama and Literature. Dunman is an AWS
          Academy Member Institution with a fleet of 20 AWS DeepRacer robotic
          cars, which students can program to race against each other on a
          physical racetrack.
        </InfoDescription>
      </Info>
      <Subtitle>About the AWS DeepRacer League</Subtitle>
      <StyledParagraph>
        AWS DeepRacer is an autonomous 1/18th scale race car designed to test
        Deep Learning models by racing on a physical track. Using cameras to
        view the track and a reinforcement model to control throttle and
        steering, the car shows how a model trained in a simulated environment
        can be transferred to the real-world.
      </StyledParagraph>
    </Container>
  </Section>
);

export default ScheduleTable;
