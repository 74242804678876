import styled from 'styled-components';

import $ from '../../../../styles/global';
import ImageContainer from '../../../Level2Banner/ImageContainer';
import Banner3 from '../../../../assets/images/whyus/students/banner3.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: 10px;
    left: 440px;

    & > svg {
      width: 180px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tabletA} {
    position: absolute;
    top: -120px;
    left: 160px;

    & > svg {
      width: 120px;
    }
  }

  @media ${$.device.tabletB} {
    position: absolute;
    top: -125px;
    left: 200px;

    & > svg {
      width: 120px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Image3Container = () => <Container image={Banner3} />;

export default Image3Container;
