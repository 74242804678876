import PropTypes from 'prop-types';

import SEO from '../../../components/seo';
import MicroWorkshop from '../../../components/partners/SMU/MicroWorkshop';

const MicroWorkshopPage = ({ location }) => (
  <>
    <SEO
      location={location}
      title="Micro Workshop Materials"
      description="Materials, note and resources from SMU’s Applied Data Analytics Micro Workshop"
    />
    <MicroWorkshop />
  </>
);

MicroWorkshopPage.defaultProps = {
  location: {},
};

MicroWorkshopPage.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default MicroWorkshopPage;
