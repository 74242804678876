import styled from 'styled-components';

import $ from '../../../styles/global';
import ImageContainer from '../../Level2Banner/ImageContainer';
import MobileImage from '../../../assets/images/holidaycamps/mobile.svg';

const Container = styled(ImageContainer)`
  display: none;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 200px;

    & > svg {
      width: 100%;
    }
  }
  // #endregion
`;

const MobileImageContainer = () => <Container image={MobileImage} />;

export default MobileImageContainer;
