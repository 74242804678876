import { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../styles/global';
// import SignInButton from '../SignInButton';
// import RotatingDownChevron from '../../Chevron/RotatingDownChevron';
import Utils from '../../../utils';
import items from '../items.json';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: ${$.layout().margin3}px;
    &:last-child {
      margin-right: 0;
    }
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2px;
  padding-top: 10px;
  &:hover {
    cursor: default;
  }

  > svg {
    fill: ${$.color.blue4};
    width: 15px;
    height: 15px;
  }
`;

const Text = styled.div`
  text-align: right;
  margin-right: ${$.layout().margin5}px;
  font-size: 15px;
  line-height: 18px;
  font-family: Lato Light;
  color: ${$.color.black};

  &:hover {
    cursor: pointer;
  }
`;

const TextLink = styled(Link)`
  text-align: right;
  margin-right: ${$.layout().margin5}px;
  font-size: 15px;
  line-height: 18px;
  font-family: Lato Light;
  color: ${$.color.black};
  text-decoration: none;
  display: block;
  padding-bottom: 2px;
  padding-top: 10px;
  margin-right: ${$.layout().margin3}px;
`;

const set = (obj, name) => {
  // eslint-disable-next-line
  obj[name] = typeof obj[name] === 'undefined' ? 1 : obj[name] + 1;
};

const list = Utils.addKeys(items);

const Menu = ({
  // counters,
  setCounters,
  prevTab,
  setPrevTab,
  setHeroActive,
  heroActive,
}) => {
  useEffect(() => {
    if (prevTab && heroActive === false) {
      /**
       * Handles resetting the chevrons back to the default position if user
       * hovers away.
       */
      setCounters((prev) => {
        const newPrev = { ...prev };
        Object.keys(newPrev).forEach((name) => {
          /**
           * If odd, +1 to the counter to reset position of the down chevron.
           */
          if (newPrev[name] % 2) {
            newPrev[name] += 1;
          }
        });
        setPrevTab('');
        return newPrev;
      });
    }
  }, [prevTab, heroActive]);

  return (
    <Container>
      {list.map(({ key, dropdownText, individualLink, footerItem }) =>
        individualLink ? (
          <TextLink key={key} to={individualLink}>
            {dropdownText}
          </TextLink>
        ) : (
          <Tab
            key={key}
            onMouseEnter={() => {
              if ((heroActive && footerItem === prevTab) || individualLink) {
                return;
              }

              /**
               * Update down chevron whenever we enter a new tab.
               * Previous tab also updated if necessary to reset their position.
               */
              setCounters((prev) => {
                const newPrev = { ...prev };
                /**
                 * Sets current tab.
                 */
                set(newPrev, footerItem);
                /**
                 * Reset previous tab if necessary. The chevron
                 * needs to go back to neutral position.
                 */
                if (prevTab && prevTab !== footerItem && newPrev[prevTab] % 2) {
                  set(newPrev, prevTab);
                }
                /**
                 * Keeps track of the previous tab.
                 */
                setPrevTab(footerItem);
                setHeroActive(true);
                return newPrev;
              });
            }}
          >
            <Text>{dropdownText}</Text>
            {/* <RotatingDownChevron counter={counters[footerItem]} /> */}
          </Tab>
        )
      )}
      {/* <SignInButton /> */}
    </Container>
  );
};

Menu.defaultProps = {
  // counters: {},
  heroActive: false,
  setHeroActive: () => {},
  setCounters: () => {},
  prevTab: '',
  setPrevTab: () => {},
};

Menu.propTypes = {
  heroActive: PropTypes.bool,
  // counters: PropTypes.objectOf(PropTypes.number),
  setCounters: PropTypes.func,
  prevTab: PropTypes.string,
  setPrevTab: PropTypes.func,
  setHeroActive: PropTypes.func,
};

export default Menu;
