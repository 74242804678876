import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '../Button';
import $ from '../../styles/global';
import AWSLogo from '../../assets/icons/aws-logo.svg';

const Section = styled.section``;

const StyledSection = styled.section`
  background-color: ${$.color.blue1};
  p {
    margin-bottom: 0;
  }

  ol {
    padding-left: 20px;
    margin-bottom: 0;
    a {
      font-family: Lato Bold;
      text-decoration: none;
      color: ${$.color.blue5};
      &:visited {
        color: ${$.color.blue5};
      }
    }
    li {
      line-height: 1.3em;
      padding-bottom: ${$.layout().padding5}px;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  > div {
    padding: ${$.layout().padding1}px 0;
  }
`;

const Container = styled.div`
  ul {
    color: ${$.color.black2};
    line-height: 1.3em;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  margin-bottom: ${$.layout().margin3}px;
  height: 0px;
  padding-top: 56.25%;
  position: relative;

  > * {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 100vw;
    padding-top: 58.25%;
    position: relative;
    left: -${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100vw;
    padding-top: 65.25%;
    position: relative;
    left: -${$.layout().margin3}px;
  }
  // #endregion
`;

const Title = styled.h1`
  font-family: Aileron Heavy;
  font-size: 50px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;

  // #region
  @media ${$.device.mobile} {
    font-size: 42px;
  }
  // #endregion
`;

const Subtitle = styled.h2`
  font-family: Aileron Heavy !important;
  font-size: 40px !important;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;

  // #region
  @media ${$.device.mobile} {
    font-size: 35px !important;
  }
  // #endregion
`;

const Paragraph = styled.p`
  color: ${$.color.black2};
  font-family: Lato Regular;
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin3}px;

  strong {
    font-family: Lato Bold;
  }

  i {
    font-family: Lato Italic;
  }

  ${$.br}

  a {
    text-decoration: none;
    color: ${$.color.blue5};
    font-family: Lato Bold;
    &:visited {
      color: ${$.color.blue5};
    }
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  display: block;
  max-width: 350px;
  width: 100%;
  margin-bottom: ${$.layout().margin3}px;
  margin-left: auto;
  margin-right: auto;
`;

const Logo = styled.div`
  width: 100%;
  margin: ${$.layout().margin2}px 0;
  > * {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 150px;
  }
`;

const BuildOn = () => (
  <>
    <Section>
      <Container>
        <Logo>
          <AWSLogo />
        </Logo>
        <ImgContainer>
          <StaticImage
            src="../../assets/images/partners/buildon/AWS_Buildon_2019_2.jpg"
            alt="Build On 2019 event image"
          />
        </ImgContainer>
        <Title>Hackathon - Build On 2021</Title>
        <Paragraph>
          {'The '}
          <a
            href="https://www.buildonasean2021.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Build On
          </a>
          {
            ' competition is a pan-ASEAN coding competition organised by AWS, taking place in Malaysia, Singapore, Cambodia, Thailand, Indonesia, Philippines, Myanmar, and Vietnam. '
          }
          <strong>Build On</strong>
          {
            ' provides a platform for students to learn about cloud computing through designing and implementing tech solutions to real world challenges issued by institutions, corporates and start-ups across ASEAN. The Singapore edition of the competition has two categories:'
          }
        </Paragraph>
        <ul>
          <li>
            Junior Category (Open to International School, local Secondary
            School and Junior College students)
          </li>
          <li>Institutional Category (Polytechnics and Universities)</li>
        </ul>
        <ImgContainer>
          <StaticImage
            src="../../assets/images/partners/buildon/build_on_header.png"
            alt="Build On 2021 event poster"
          />
        </ImgContainer>
        <ImgContainer>
          <StaticImage
            src="../../assets/images/partners/buildon/build_on_instructors.png"
            alt="Build On 2021 instructors"
          />
        </ImgContainer>
        <Paragraph>
          <strong>Technical Skills</strong>
          {' are useful but are not required for this hackathon - '}
          <strong>SG Code Campus</strong>
          {'  is proud to be an '}
          <strong>official training partner</strong>
          {
            ' for this momentous event and will conduct the training sessions to ensure that all participants get the basic programming and cloud computing skills needed for the hackathon.'
          }
        </Paragraph>
        <Paragraph>
          {
            'If you are a team of 3-5 students in Secondary School, Junior College, Polytechnic or University and interested in taking part in this competition in Singapore, please use the link to the official '
          }
          <strong>Build On, Singapore 2021 registration portal</strong>
          {' from AWS below.'}
        </Paragraph>
        <StyledLink
          href="https://www.buildonasean2021.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>I want to participate in Build On 2021</Button>
        </StyledLink>
      </Container>
    </Section>
    <StyledSection>
      <Container>
        <Subtitle>Recordings of training sessions</Subtitle>
        <Paragraph>
          Participating in Build On, Singapore 2021 and missed a particular
          training session? No worries as video recordings and notes from each
          session will be posted on this page as the various sessions from 7th
          June 2021 to 17th June 2021 will be posted as they&apos;re available.
        </Paragraph>
        <Paragraph>
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-1/">
            Beginning Python 1 (7th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-2/">
            Beginning Python 2 (8th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-3/">
            Beginning Python 3 (9th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-4/">
            Beginning Python 4 (10th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-5/">
            Building websites with HTML and CSS & JavaScript 1 (11th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-6/">
            Beginning Python 5 (12th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-7/">
            Building websites with HTML and CSS & JavaScript 2 (15th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-8/">
            Deploying web applications with Amazon Simple Storage Service
            (Amazon S3) and Amazon Elastic Beanstalk (16th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-9/">
            Database programming with Amazon DynamoDB (17th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-10/">
            Introduction to Machine Learning with Amazon SageMaker (22nd June
            2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-11/">
            Deep Learning with AWS 1 (25th June 2021)
          </Link>
          <br />
          <Link to="/build-on/aws-buildon-singapore-2021-workshop-12/">
            Deep Learning with AWS 2 (26th June 2021)
          </Link>
        </Paragraph>
      </Container>
    </StyledSection>
  </>
);

export default BuildOn;
