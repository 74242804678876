import styled from 'styled-components';

import $ from '../../styles/global';
import Util from '../../utils';

const Section = styled.section``;

const Container = styled.div`
  width: 100vw !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    position: relative;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin: 0 !important;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin: 0 !important;
  }
  // #endregion
`;

const Title = styled.h1`
  font-family: Aileron Heavy;
  color: ${$.color.white};
  position: absolute;
  font-size: 75px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 100px;
  }
  // #endregion
`;

const Image = styled.div`
  display: block;
  width: 100%;
  height: 407px;
  background-repeat: repeat-x;
  background-image: url(${({ image }) => image});

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const MobileImage = styled.img`
  display: none;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: block;
    width: 100%;
  }
  // #endregion
`;

const bannerImage = Util.importOneImage('joinus/banner.png');
const mobileBannerImage = Util.importOneImage('joinus/mobilebanner.png');

const Banner = () => (
  <Section>
    <Container>
      <Title>Join Us!</Title>
      <Image image={bannerImage} />
      <MobileImage src={mobileBannerImage} />
    </Container>
  </Section>
);

export default Banner;
