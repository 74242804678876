import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../../styles/global';
import Util from '../../../utils';

const proficienciesData = [
  {
    title: 'Build',
  },
  {
    title: 'Concepts',
  },
  {
    title: 'Language',
  },
];

const data = Util.addKeys(proficienciesData);

const Section = styled.section`
  padding: ${$.layout().padding1}px 0;
  background-color: ${$.color.blue1};
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion
`;

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    height: calc((970px - ${$.layout().margin2}px) / 2);
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue3};
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().margin2 / 2}px);
    font-size: 38px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 28px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  font-family: Lato Regular;
  color: ${$.color.blue5};
  line-height: 1.4em;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 17px;
    width: calc(50% - ${$.layout().margin2 / 2}px);
    > p {
      margin-top: 0;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const ProficienciesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    height: calc((970px - ${$.layout().margin2}px) / 2);
    width: calc((50% - ${$.layout().margin2}px / 2));
    align-self: flex-end;
    flex-wrap: wrap;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    justify-content: space-evenly;
    align-items: center;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    flex-wrap: wrap;
  }
  // #endregion
`;

const Proficiency = styled.div`
  border-radius: 9999px;
  color: ${$.color.white};
  display: flex;
  flex-direction: row;
  font-family: Lato Bold;
  background-color: ${$.color.blue6};
  justify-content: center;
  align-items: center;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-family: Lato Regular;
    width: calc((100% - 30px) / 2);
    height: calc((100% - 30px) / 2);

    &:nth-child(2),
    &:last-child {
      align-self: flex-end;
    }

    &:first-child {
      margin: 0 calc((100% + 30px) / 4);
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 20vw;
    height: 20vw;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: calc((100% - 30px) / 2);
    height: calc((100vw - ${$.layout().margin3 * 2}px - 30px) / 2);

    &:first-child {
      margin: 0
        calc((100% - ((100vw - ${$.layout().margin3 * 2}px - 30px) / 2)) / 2);
    }
  }
  // #endregion
`;

const Proficiencies = () => (
  <Section>
    <Fade bottom cascade distance="100px">
      <Container>
        <Title>
          A good coder needs 3 proficiencies to solve problems well{' '}
        </Title>
        <Subtitle>
          <p>
            Having a strong conceptual understanding, fluency in programming
            languages, and solid build skills are integral to solving problems
            with code.
          </p>
          <p>
            One or two coding courses alone is not enough time to build all
            three proficiencies, let alone internalise a way of thinking.
            Therefore, our roadmap spanning up to 6 years reflects our long-term
            goal and commitment to develop Code Campers in each of these three
            proficiencies and create Campuses where successive cohorts of Code
            Campers can emerge as communities of thinkers and problem solvers.
          </p>
        </Subtitle>
        <ProficienciesContainer>
          {data.map(({ title, key }) => (
            <Proficiency key={key}>
              <div className="proficiency-circle">{title}</div>
            </Proficiency>
          ))}
        </ProficienciesContainer>
      </Container>
    </Fade>
  </Section>
);

export default Proficiencies;
