import styled from 'styled-components';

import $ from '../../../styles/global';

const Section = styled.section`
  margin-bottom: ${$.layout().margin2}px;
`;

const MainWriteUpContainer = styled.div``;

const Title = styled.h1`
  font-family: Aileron Heavy;
  font-size: 50px;
  color: ${$.color.blue4};

  // #region
  @media ${$.device.mobile} {
    font-size: 42px;
  }
  // #endregion
`;

const Paragraph = styled.p`
  color: ${$.color.black2};
  line-height: 1.3;
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-family: Lato Bold;
  color: ${$.color.blue5};
`;

const MainWriteUp = () => (
  <Section>
    <MainWriteUpContainer>
      <Title>Joining the Apple Consultants Network</Title>
      <Paragraph>
        {`As an `}
        <StyledLink
          href="https://consultants.apple.com/sg/profile/2408886"
          target="_blank"
          rel="noopener noreferrer"
        >
          official Apple Consultants Network partner
        </StyledLink>
        {`, we are at the forefront of defining coding education for youth and adult learners. `}
      </Paragraph>
      <Paragraph>
        {`Our pedagogical approach involves delivering concepts that are illuminated through
          hands-on coding sessions - reflecting Apple's mantra of `}
        <StyledLink
          href="https://www.apple.com/ca/education/docs/Apple-ACOT2Whitepaper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Challenge Based Learning
        </StyledLink>
        .
      </Paragraph>
      <Paragraph>
        {`We specialise in teaching `}
        <StyledLink
          href="https://developer.apple.com/ios/"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS
        </StyledLink>
        {` programming courses using the modern `}
        <StyledLink
          href="https://developer.apple.com/swift/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Swift
        </StyledLink>
        {` programming language to different audiences in a variety of learning environments. We live, breathe and speak Apple - each student at our centres is supplied with one of our 100+ Apple MacBooks as we firmly believe that providing the best computing environments makes learning fun, effortless and engaging.`}
      </Paragraph>
    </MainWriteUpContainer>
  </Section>
);

export default MainWriteUp;
