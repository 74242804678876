import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Section = styled.section`
  width: 100%;
  margin-bottom: 0 !important;
  position: relative;
  background-color: ${$.color.gray1};
`;

const Container = styled.div`
  width: 100vw !important;
  margin: 0 !important;
  overflow: hidden;
`;

const InnerContainer = styled.div`
  height: 200px;
  display: grid;
  grid-template-columns: auto;
  filter: blur(1px);

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    height: 300px;
  }
  // #endregion
`;

const Title = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 1;
  color: ${$.color.white};
  > h1 {
    font-family: Lato Bold !important;
    font-size: 35px;
  }

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 970px;
    margin: 0 calc((100% - 970px) / 2);
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    margin: 0 ${$.layout().margin3}px;

    > h1 {
      font-family: Lato Bold !important;
      font-size: 26px;
    }
  }
  // #endregion
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;

const Banner = ({ image, title }) => (
  <Section>
    <Container>
      <Title>
        <h1>{title}</h1>
      </Title>
      <InnerContainer>
        <StyledBackgroundImage
          Tag="div"
          fluid={[
            `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
            image.fluid,
          ]}
        />
      </InnerContainer>
    </Container>
  </Section>
);

Banner.defaultProps = {
  image: { fluid: {} },
  title: '',
};

Banner.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.oneOfType([PropTypes.object]),
  }),
  title: PropTypes.string,
};

export default Banner;
