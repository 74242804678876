import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Title = styled.h2`
  font-family: Cooper Hewitt Semibold;
  text-transform: uppercase;
  color: ${$.color.blue5};
  letter-spacing: 2px;
  line-height: 1.4em;
  font-size: 20px;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 18px;
  }
  // #endregion
`;

const Details = styled.div`
  color: ${$.color.blue4};
  line-height: 1.4em;
  font-size: 18px;

  strong {
    font-family: Lato Bold;
  }

  a {
    font-family: Lato Bold;
    color: ${$.color.blue5};
    text-decoration: none;
  }

  h2 {
    font-family: Lato Bold;
    font-size: 20px;
    margin-top: ${$.layout().margin3}px;
  }

  ${$.br}

  ol, ul {
    padding-left: ${$.layout().padding3}px;
    li {
      line-height: 1.4em;
    }
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
  }
  // #endregion
`;

const Card = ({ title, details }) => (
  <Container>
    <Title>{title}</Title>
    <Details dangerouslySetInnerHTML={{ __html: details }} />
  </Container>
);

Card.defaultProps = {
  title: '',
  details: '',
};

Card.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
};

export default Card;
