
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import Banner from '../components/MiniBanner';
import MainExcerpt from '../components/onlineClasses/MainExcerpt';
import KeyFeatures from '../components/onlineClasses/KeyFeatures';
import FAQ from '../components/onlineClasses/FAQ';

const OnlineClasses = ({ location }) => (
  <>
    <SEO
      keywords={[
        'live online classes',
        'synchronous',
        'live hybrid lessons',
        'seamless experience',
        'real-time consultation',
        'active student participation',
        'onine coding classes',
        'online coding lessons',
        'future of classroom learning',
        'home-based learning',
      ]}
      location={location}
    />
    <Banner title="Home-based Learning" />
    <MainExcerpt />
    <KeyFeatures />
    <FAQ />
  </>
);

OnlineClasses.defaultProps = {
  location: {},
};

OnlineClasses.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default OnlineClasses;
