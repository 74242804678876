import PropTypes from 'prop-types';

import Logo from '../../components/partners/amazonTrainingPartner/Logo';
import MainWriteUp from '../../components/partners/amazonTrainingPartner/MainWriteUp';
import SEO from '../../components/seo';

const AmazonTrainingPartnerPage = ({ location }) => (
  <>
    <SEO
      location={location}
      title="Authorised Amazon Training Partner"
      description="Learn what we can offer as an authorised Training Partner in the Amazon Web Services (AWS) Partner Network"
    />
    <Logo />
    <MainWriteUp />
  </>
);

AmazonTrainingPartnerPage.defaultProps = {
  location: {},
};

AmazonTrainingPartnerPage.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default AmazonTrainingPartnerPage;
