import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { RemoteLink, Margin20, ContentImage } from '../Misc';
import TrainerProfile from '../TrainerProfile';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import FundingTable from '../SMUReusableComponents/FundingTable';
import InfoTable from '../SMUReusableComponents/InfoTable';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Topics',
  },
  {
    text: 'Who Should Attend',
    threshold: 0.1,
  },
  {
    text: 'Fees And Funding',
  },
  {
    text: 'Schedule',
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Able to initiate and coordinate team activity for new web projects on AWS Cloud9, design and style basic web applications and implement user controls and interactivity features',
  },
  {
    text: 'Learn how to utilise cutting edge AWS capabilities in machine vision, natural language processing, forecasting, fraud detection and machine speech through a series of hands on labs and practical projects',
  },
  {
    text: 'Learn about how to leverage the AWS Quicksight service, stunning interactive dashboards for Business Intelligence (BI) and reporting',
  },
  {
    text: 'Learn to integrate the HTML, CSS and JavaScript elements, as well as API calls to AWS Services using the AWS Amplify toolset to create a content-rich web application',
  },
  {
    text: 'Learn how to build and deploy web server and backend application functionality over the AWS Elastic Beanstalk service',
  },
  {
    text: 'Be introduced to Cloud Computing, and be exposed to the most commonly used cloud services provided by AWS',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'Aspiring Cloud architects',
  },
  {
    text: 'Data analysts',
  },
  {
    text: 'Software engineers',
  },
  {
    text: 'Technical project managers',
  },
  {
    text: 'Programme/ business managers looking for a deeply practical introduction to the AWS Cloud',
  },
  {
    text: 'Learners looking to prepare for the AWS Cloud Practitioner and AWS Solutions Architect Associate Certification exams',
  },
]);

const trainers = Utils.addKeys([
  Profiles.ian,
  Profiles.huiLiang,
  Profiles.terence,
]);

const applyNowLink =
  '/professional-training/apply-now/?c=Advanced Certificate in AWS Cloud Services and Technicals';

const enquiryLink =
  'https://academy.smu.edu.sg/programme-enquiry?course=Advanced%20Certificate%20in%20AWS%20Cloud%20Services%20and%20Technicals&nid=7041';

const AdvancedTech = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="Advanced Certificate in AWS Cloud Services and Technicals"
      />
      <MobileNavigationMenu
        items={navigationLinks}
        applyNowLink={applyNowLink}
        enquiryLink={enquiryLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              The demand for Cloud Computing continues to rise exponentially
              amid the digital transformation of organisations. Gaining
              expertise in Amazon Web Services (AWS), the leading provider of
              Cloud Computing services, has become a necessity for IT
              professionals.
            </p>
            <p>
              This course approaches cloud computing through the lens of
              developing and deploying simple applications. Participants will
              receive a hands-on introduction to the various core cloud services
              in computing, storage, networking, and AI available on Amazon Web
              Services. This class is tailored for professionals wanting to get
              started with AWS Cloud. The course is conducted through an ideal
              mix of classroom lectures, hands-on coding and technical labs, and
              various real-world business case studies.
            </p>
            <p>
              At the end of the programme, students will gain a comprehensive
              understanding of the foundation cloud services available on AWS
              and be ready to take the official, industry recognised AWS Cloud
              Practitioner Certification exam.
            </p>
            <Margin20 />
            <InfoTable
              venue="On-campus"
              starts="21 Mar 2023 (Tue)"
              fees="SGD10368.00* (as low as SGD1190.40* after maximum funding) "
              duration={
                <>
                  Please refer to respective modules for dates.
                  <br />
                  Weeknights (7pm - 10:30pm)
                  <br />
                  Saturday (9am - 6:00pm)
                </>
              }
              level="Basic"
            />
            <h3>Programme Partner</h3>
            <p>
              <strong>Supported by:</strong>
              <Margin20 />
              <ContentImage
                width={225}
                height={100}
                path="landing/aws_academy_member_institution.png"
              />
              <Margin20 />
            </p>
            <h3>Learning Objectives</h3>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            <p>
              To achieve the Advanced Certificate in AWS Cloud Services and
              Technicals, participants will need to complete the following
              modules offered by SMU Academy:
            </p>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-module-1-web-programming-aws-cloud9-7076">
                Module 1: Web Programming with AWS Cloud9
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-module-2-smart-apps-and-apis-aws-ai-services">
                Module 2: Smart Apps and APIs with AWS AI Services
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-module-3-databases-and-data-infrastructure">
                Module 3: Databases and Data Infrastructure on AWS
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-module-4-modern-web-uis-react-and-aws">
                Module 4: Modern Web UIs with React and AWS Amplify
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-module-5-building-web-apis-aws-elastic-7096">
                Module 5: Building Web APIs with AWS Elastic Beanstalk
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-aws-cloud-services-and-technicals-module-6-aws-cloud-services-and-applications">
                Module 6: AWS Cloud Services and Applications
              </RemoteLink>
            </p>
            <p>
              This course is also a part of the{' '}
              <RemoteLink href="https://academy.smu.edu.sg/advanced-diploma-cloud-management-and-applications-7756">
                Advanced Diploma in Cloud Management and Applications.
              </RemoteLink>
              .
            </p>
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
            <p>
              Targeted Jobs: AWS Cloud Architect, Solutions Architect, SysOps
              Administrator, Cloud DevOps Engineer, Systems Engineer, Business
              Analyst, and Cloud Consultant, Cloud Developer, Cloud Sales &
              Purchase Manager, Key Account Manager (Cloud), AWS Networking
              Specialist, Cloud Software Engineer, AWS Big Data Specialist.
            </p>
            <Margin20 />
            <Margin20 />
            <h3>Prerequisites</h3>
            <p>
              No prior experience in web programming or software engineering is
              required or assumed
            </p>
          </TextSection>
          <TextSection>
            <h2>Assessment</h2>
            <ul>
              <li>Multiple-Choice Questions</li>
              <li>Group assignments</li>
              <li>Individual work</li>
            </ul>
            <Margin20 />
            <Margin20 />
            <h3>Certification</h3>
            <p>
              Upon completion of all 6 modules within a maximum duration of 3
              years, participants will be awarded a digital Advanced Certificate
              in AWS Cloud Services and Technicals.
            </p>
          </TextSection>
          <TextSection position={3}>
            <h2>Fees And Funding</h2>
            <FundingTable
              firstRow={[
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="http://www.ssg.gov.sg/programmes-and-initiatives/funding/enhanced-training-support-for-smes1.html">
                    ETSS Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
              ]}
              secondRow={[
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
              ]}
              thirdRow={[
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
              ]}
            />
          </TextSection>
          <TextSection position={4}>
            <h2>Schedule</h2>
            <p>
              <strong>Start Date(s)</strong>
              <br />
              21 Mar 2023 (Tue)
            </p>
            <p>
              <strong>Intake Information</strong>
            </p>
            <Table>
              <thead>
                <tr>
                  <td className="highlight2">Modules</td>
                  <td className="highlight2">Intake 1</td>
                  <td className="highlight2">Intake 2</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight1">
                    WEB PROGRAMMING WITH AWS CLOUD9
                  </td>
                  <td>
                    31 Jan, 1, 2, & 4 Feb 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      21, 22, 23 & 25 Mar 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    SMART APPS WITH AWS AI SERVICES
                  </td>
                  <td>
                    14, 15, 16 & 18 Feb 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      04, 05, 06 & 08 Apr 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    DATABASES AND DATA INFRASTRUCTURE ON AWS
                  </td>
                  <td>
                    28 Feb, 1, 2 & 4 Mar 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      25, 26, 27 & 29 Apr 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    MODERN WEB UIS WITH REACT AND AWS AMPLIFY
                  </td>
                  <td>
                    14, 15, 16 & 18 Mar 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      02, 03, 04 & 06 May 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    BUILDING WEB APIS WITH AWS ELASTIC BEANSTALK
                  </td>
                  <td>
                    28, 29, 30 Mar & 1 Apr 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      16, 17, 18 & 20 May 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    AWS CLOUD SERVICES AND APPLICATIONS
                  </td>
                  <td>
                    11, 12, 13 & 15 Apr 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      30, 31 May, 01 & 03 Jun 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection>
            <h2>Policies</h2>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                View programme policies.
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={5}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default AdvancedTech;
