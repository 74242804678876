import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import SubHeader from './SubHeader';
import MainInformation from './MainInformation';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc((100% - ${$.layout().padding2}px) * (1.2 / 8));
  }
  // #endregion
`;

const CourseTime = memo(({ courseTime }) => (
  <Container>
    <SubHeader>Time</SubHeader>
    <MainInformation>
      <span>{`${courseTime.start} - ${courseTime.end}`}</span>
    </MainInformation>
  </Container>
));

CourseTime.propTypes = {
  courseTime: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
};

export default CourseTime;
