import { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Context } from '../context';
import Util from '../../../../utils';
import $ from '../../../../styles/global';
import BarActions from '../BarActions';
import NormalDropdown from './NormalDropdown';
import CalendarDropdown from './CalendarDropdown';

const Section = styled.section.attrs({ className: 'search-bar' })`
  margin-bottom: ${$.layout().margin3}px;
  user-select: none;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: ${$.dropShadow.normal};
  border-radius: ${$.border().radius2}px;
`;

const Divider = styled.div`
  width: 2px;
  height: 28px;
  margin-top: 12px;
  margin-bottom: 0;
  margin-left: ${$.layout().margin5}px;
  margin-right: ${$.layout().margin5}px;
  background: ${$.color.gray1};
  opacity: 0.7;
`;

const Desktop = ({ className }) => {
  const [picker, setPicker] = useState('undefined');
  const searchBarRef = useRef(null);
  const {
    searchBarUIData, searchBarUIUpdate, searchBarClear, searchBarDataUpdate,
  } = useContext(
    Context,
  );

  Util.useOutsideClick(searchBarRef, () => {
    let activeDropdown = null;

    if (searchBarRef.current.offsetParent !== null) {
      activeDropdown = searchBarRef.current.querySelector('div.active');

      if (activeDropdown !== null) {
        searchBarUIUpdate({ clicked: '' });
      }
    }
  });

  BarActions.initPikaday({
    searchBarDataUpdate,
    searchBarUIUpdate,
    searchBarUIData,
    setPicker,
    searchBarRef,
  });

  BarActions.clearReduxData({ searchBarClear });

  if (typeof window !== 'undefined') {
    BarActions.parseUrlParamsToRedux(window.location.search, searchBarDataUpdate);
  }

  return (
    <Section className={className}>
      <Container ref={searchBarRef}>
        <NormalDropdown name="allCourses" picker={picker} />
        <Divider />
        <NormalDropdown name="allLocations" picker={picker} />
        <Divider />
        <NormalDropdown name="allFormats" picker={picker} />
        <Divider />
        <CalendarDropdown text="All Dates" name="allDates" picker={picker} />
        <Divider />
        <NormalDropdown name="allAges" picker={picker} />
      </Container>
    </Section>
  );
};

Desktop.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Desktop;
