import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BarActions from '../BarActions';
import { Context } from '../context';
import {
  OptionContainer,
  LabelText,
  PillText,
  Pill,
  Dropdown,
  StyledRotatingDownChevron,
} from './DropdownStyles';

const CalendarContainer = styled.div.attrs({ className: 'calendar' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const CalendarDropdown = ({ text, name, picker }) => {
  const {
    searchBarData: { allDates: currDate },
    searchBarUIData,
    searchBarUIUpdate,
  } = useContext(Context);
  const getClassName = () => {
    let klass = 'all-dates ';

    if (searchBarUIData.clicked === name) {
      klass += 'active ';
    }
    if (currDate) {
      klass += 'clicked';
    }

    return klass;
  };

  return (
    <OptionContainer>
      <LabelText>{text}</LabelText>
      <Dropdown className={getClassName()}>
        <Pill
          onClick={() => {
            BarActions.handleOnClick({
              key: name,
              clicked: searchBarUIData.clicked,
              searchBarUIUpdate,
              picker,
            });
          }}
        >
          <PillText>
            {!currDate ? 'All Dates' : BarActions.getStringDate(currDate)}
          </PillText>
          <StyledRotatingDownChevron
            counter={searchBarUIData.rotatingCounter[name]}
          />
        </Pill>
        <CalendarContainer />
      </Dropdown>
    </OptionContainer>
  );
};

export default CalendarDropdown;

CalendarDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  picker: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
