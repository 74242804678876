import { useState } from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';
import Util from '../../utils';
import { videoLoginStatusUpdate } from '../../actions';

const FormContainer = styled.div`
  margin-bottom: ${$.layout().margin2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  width: 500px;

  @media ${$.device.mobile} {
    width: 100%;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: ${$.layout().margin3}px;
`;

const Label = styled.label`
  font-family: Aileron Heavy;
  font-size: 36px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin5}px;
`;

const Input = styled.input`
  padding: ${$.layout().padding4}px ${$.layout().padding5}px;
  border: 1px solid ${$.color.gray1};
  border-radius: ${$.border().radius3}px;
`;

const SubmitButton = styled.div`
  background-color: ${$.color.orange3};
  color: ${$.color.white};
  font-size: 24px;
  text-align: center;
  padding-top: ${$.layout().padding3}px;
  padding-bottom: ${$.layout().padding3}px;
  border-radius: ${$.border().radius2}px;
  box-shadow: ${$.dropShadow.normal};
  transition: box-shadow 0.3s ${$.easingFn.standard};
  cursor: pointer;

  &:hover {
    box-shadow: ${$.dropShadow.repressed};
  }
`;

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn] = Util.useActions([videoLoginStatusUpdate]);

  const handleSubmitForm = () => {
    if (
      typeof window !== 'undefined' &&
      username === 'Dunman' &&
      password === 'mobile@dunman'
    ) {
      localStorage.setItem('schoolsPageLoginState', true);
      loggedIn();
    }
  };

  return (
    <FormContainer>
      <Form>
        <FieldContainer>
          <Label htmlFor="username">Username:</Label>
          <Input
            name="username"
            id="username"
            placeholder="Username"
            onChange={({ target: { value } }) => {
              setUsername(value);
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <Label htmlFor="password">Password:</Label>
          <Input
            name="password"
            id="password"
            placeholder="Password"
            onChange={({ target: { value } }) => {
              setPassword(value);
            }}
          />
        </FieldContainer>
        <SubmitButton onClick={handleSubmitForm}>Submit</SubmitButton>
      </Form>
    </FormContainer>
  );
};

export default LoginForm;
