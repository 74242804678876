import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../../styles/global';

const Section = styled.section`
  margin-top: ${$.layout().margin2}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-top: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    height: calc((970px - ${$.layout().margin2}px) / 2);
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue3};
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    order: 2;
    width: calc(50% - ${$.layout().margin2 / 2}px);
    font-size: 40px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 28px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  font-family: Lato Regular;
  line-height: 1.4em;
  color: ${$.color.blue5};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    order: 3;
    width: calc(50% - ${$.layout().margin2 / 2}px);
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const CirclesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Lato Regular;
  align-items: center;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().margin2 / 2}px);
    height: calc((970px - ${$.layout().margin2}px) / 2);
    align-self: flex-start;
    order: 1;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    flex-wrap: wrap;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Circles = styled.div`
  border-radius: 9999px;
  background-color: ${$.color.orange3};
  color: ${$.color.white};
  display: flex;
  flex-direction: row;
  line-height: 1.4em;
  justify-content: center;
  align-items: center;

  &:first-child,
  &:last-child {
    background-color: ${$.color.orange2};
  }

  & div.wayofthinking-circles {
    text-align: center;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc((100% - 30px) / 2);
    height: calc((100% - 30px) / 2);

    & div.wayofthinking-circles {
      width: 75%;
    }

    &:nth-child(3),
    &:last-child {
      align-self: flex-end;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 20vw;
    height: 20vw;
    font-family: Lato Bold;

    & div.wayofthinking-circles {
      width: 80%;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: calc((100% - 30px) / 2);
    font-family: Lato Bold;
    height: calc((100vw - ${$.layout().margin3 * 2}px - 30px) / 2);

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: ${$.layout().margin3}px;
    }

    & div.wayofthinking-circles {
      width: 80%;
    }
  }
  // #endregion
`;

const WayOfThinking = () => (
  <Section>
    <Fade bottom cascade fraction={0.1} distance="100px">
      <Container>
        <Title>Coding is a Skill and Way of Thinking</Title>
        <Subtitle>
          We live in an increasingly complex world, and the future challenges
          that will confront our children will only get more complicated. In
          order to solve the problems of tomorrow, kids today need to learn how
          to break down complex issues, observe patterns in the world that
          surrounds them, identify general principles that generate these
          patterns and work methodically towards a solution. Each of these
          elements is a skill and a way of thinking, which together, encapsulate
          how a coder thinks.
        </Subtitle>
        <CirclesContainer>
          <Circles>
            <div className="wayofthinking-circles">
              Break down complex issues
            </div>
          </Circles>
          <Circles>
            <div className="wayofthinking-circles">
              Observe patterns in the world
            </div>
          </Circles>
          <Circles>
            <div className="wayofthinking-circles">
              Identify general principles that generate patterns
            </div>
          </Circles>
          <Circles>
            <div className="wayofthinking-circles">
              Work methodically towards a solution
            </div>
          </Circles>
        </CirclesContainer>
      </Container>
    </Fade>
  </Section>
);

export default WayOfThinking;
