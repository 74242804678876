import styled from 'styled-components';

import $ from '../../../../styles/global';
import ImageContainer from '../../../Level2Banner/ImageContainer';
import Banner1 from '../../../../assets/images/whyus/students/banner1.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: -100px;
    left: -50px;

    & > svg {
      width: 230px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tabletA} {
    position: absolute;
    top: -80px;
    left: -20px;

    & > svg {
      width: 150px;
    }
  }

  @media ${$.device.tabletB} {
    position: absolute;
    top: -80px;
    left: -10px;

    & > svg {
      width: 180px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Image1Container = () => <Container image={Banner1} />;

export default Image1Container;
