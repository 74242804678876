import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../styles/global';
import Level2Banner from '../components/Level2Banner';
import Image1Container from '../components/holidaycamps/banner/Image1Container';
import Image2Container from '../components/holidaycamps/banner/Image2Container';
import Image3Container from '../components/holidaycamps/banner/Image3Container';
import MobileImageContainer from '../components/holidaycamps/banner/MobileImageContainer';
import Step1 from '../components/holidaycamps/Step1';
import Step2 from '../components/holidaycamps/Step2';
import Step3 from '../components/holidaycamps/Step3';
import Comment from '../components/Comment';
import SEO from '../components/seo';

const parentComment = {
  by: 'Scott, parent of Si Ning & Si Qing',
  age: '10 & 12',
  school: 'Nanyang Pri',
  image: 'holidaycamps/comment.jpg',
  comment:
    'I have two daughters who have been - collectively - taught by ~6 different instructors over a period of 3-4 years, so I think I have a pretty good idea of the school. The combination is fantastic: teaching truly technical content at a rigorous level, in a manner which can interest and inspire my kids. They are also just nice people: always sparing time to give additional guidance if my kids require such, and they even have time to listen to / respond to my incessant questions/feedback!',
};

const StyledLevel2Banner = styled(Level2Banner)`
  h1 {
    font-size: 34px;
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tabletA} {
    .level2-title-container {
      height: 85%;
      .level2-subtitle {
        width: 90%;
      }
    }
  }

  @media ${$.device.tabletB} {
    .level2-title-container {
      height: 80%;
    }
  }
  // #endregion
`;

const HolidayCamps = ({ location }) => (
  <>
    <SEO
      keywords={['coding bootcamp Singapore', 'holiday coding camp Singapore']}
      location={location}
      title="Programming & Coding Bootcamp in Singapore - SG Code Campus"
      description="Looking to further your child’s interest in coding? Find a suitable coding bootcamp for your kid at SG Code Campus in Singapore. Sign up today."
    />
    <StyledLevel2Banner
      title="Programming and Coding Bootcamp in Singapore"
      subtitle="School’s out and our holiday camps are back! If you’re new to our community and need a hand getting started, simply follow these three easy steps below to join one of our unforgettable programming and coding bootcamps in Singapore."
      color={$.color.blue4}
      backgroundColor={$.color.blue6}
    >
      <Image1Container />
      <Image2Container />
      <Image3Container />
      <MobileImageContainer />
    </StyledLevel2Banner>
    <Step1 />
    <Step2 />
    <Step3 />
    <Comment items={parentComment} title="Parent Review" />
  </>
);

HolidayCamps.defaultProps = {
  location: {},
};

HolidayCamps.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default HolidayCamps;
