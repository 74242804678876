import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../styles/global';
import Arrow from '../../../assets/icons/thin-arrow-right.svg';

const Container = styled.div`
  width: calc(100% - 20px);
  padding: 10px;
  background-color: transparent;
  height: calc(400px - 20px);
  outline: none;
  transform: translateY(0);
  transition: all 0.35s ${$.easingFn.standard};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      transform: translateY(-0.2em);
      cursor: grab;
    }
  }
  // #endregion
`;

const InnerContainer = styled.div`
  background-color: ${$.color.white};
  color: ${$.color.black};
  font-size: 40px;
  box-shadow: ${$.dropShadow.normal};
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
`;

const FeaturedImage = styled.div`
  width: 100%;
  height: 180px;
  position: relative;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  > div {
    width: 100%;
    height: 25%;
    position: absolute;
    bottom: -1px;
    background-image: linear-gradient(
      to top left,
      ${$.color.white} 50%,
      rgba(255, 255, 255, 0) 50%
    );
  }
`;

const Info = styled.div`
  padding: ${$.layout().padding3}px;
  width: calc(100% - ${$.layout().padding3 * 2}px);
  height: calc(100% - 180px - ${$.layout().padding3 * 2}px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${$.color.black2};
  line-height: 1.2em;
  font-family: Lato Bold;
`;

const GoToButton = styled(Link)`
  display: block;
  text-decoration: none;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      > button {
        cursor: pointer;
        background-color: ${$.color.blue4};
      }
    }
  }
  // #endregion

  > button {
    outline: none;
    font-size: 14px;
    padding: 10px 15px;
    transition: all 0.35s ${$.easingFn.standard};
    background-color: ${$.color.blue5};
    border-radius: 20px;
    border: 0;
    color: ${$.color.white};
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      fill: ${$.color.white};
      width: 22px;
      height: 22px;
      margin-left: ${$.layout().margin5}px;
    }
  }
`;

const Card = ({ title, slug, img }) => (
  <Container>
    <InnerContainer>
      <FeaturedImage>
        <div />
        <img src={img} alt={`${title} featured cover`} />
      </FeaturedImage>
      <Info>
        <Title>{title}</Title>
        <GoToButton to={`/blog/${slug}/`} title="Click to view our blog post!">
          <button type="button">
            Learn more
            <Arrow />
          </button>
        </GoToButton>
      </Info>
    </InnerContainer>
  </Container>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default Card;
