import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Section = styled.section`
  background-color: ${$.accelerator.orange};
  margin-bottom: ${$.layout().margin3}px;
`;

const Container = styled.div`
  padding: 50px 0;

  h1 {
    font-family: Lato Bold;
    font-size: 40px;
    color: ${$.color.white};
  }
`;

const Banner = ({ title }) => (
  <Section id="schedule-table">
    <Container>
      <h1>{title}</h1>
    </Container>
  </Section>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Banner;
