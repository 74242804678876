import styled from 'styled-components';

import $ from '../../styles/global';

const Body = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 970px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
  }
  // #endregion
`;

const Column1 = styled.div`
  width: 70%;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    & > section > div {
      width: 100%;
    }
  }
  // #endregion
`;

export { Body, Column1 };
