const initialState = {
  clicked: '',
  rotatingCounter: {
    allCourses: 0,
    allLocations: 0,
    allFormats: 0,
    allDates: 0,
    allAges: 0,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  const { clicked: oldClicked, rotatingCounter } = newState;

  switch (type) {
    case 'SEARCH_BAR_UI_UPDATE':
      if (oldClicked !== '') {
        rotatingCounter[oldClicked] += 1;
      }
      if (payload.clicked !== '' && payload.clicked !== oldClicked) {
        rotatingCounter[payload.clicked] += 1;
      }

      newState.clicked = payload.clicked;

      return newState;
    case 'SEARCH_BAR_CLEAR':
      return {
        clicked: '',
        rotatingCounter: {
          allCourses: 0,
          allLocations: 0,
          allFormats: 0,
          allDates: 0,
          allAges: 0,
        },
      };
    default:
      return state;
  }
};
