import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Section = styled.section`
  padding: 30px 0;
`;

const Container = styled.div`
  ${$.br}

  em {
    font-style: italic;
  }

  strong {
    font-family: Lato Bold;
  }

  h2 {
    font-family: Lato Bold;
    font-size: 24px;
    border-bottom: 1px solid ${$.color.gray3};
    margin-top: 0;
    margin-bottom: 30px;
  }

  h3 {
    font-family: Lato Bold;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  p {
    font-family: Lato Light;
    font-size: 15.5px;
    line-height: 19px;
    margin-top: 0;
    margin-bottom: 15px;
  }

  ul,
  ol {
    li {
      font-size: 15.5px;
      font-family: Lato Light;
      margin-bottom: 10px;
    }
  }
`;

const TextSection = ({ children, position }) => (
  <Section id={position} className="textSection">
    <Container>{children}</Container>
  </Section>
);

TextSection.defaultProps = {
  children: <></>,
  position: '',
};

TextSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TextSection;
