import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { navigate } from 'gatsby';
import $ from '../../styles/global';
import BlueButton from '../ProfessionalTraining/BlueButton';

const Section = styled.section`
  padding: 30px 0;
`;

const Container = styled.div`
  border-radius: 15px;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    box-shadow: ${$.dropShadow.normal};
    padding: 40px 30px;
  }
  // #endregion
`;

const Title = styled.h1`
  font-family: Lato Bold;
  font-size: 40px;
  text-align: center;
  margin-bottom: 40px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
  }
  // #endregion
`;

const Footnote = styled.div`
  font-family: Lato Bold;
  font-size: 16px;
  margin: 40px 0 20px 0;
`;

const TextField = styled.div`
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-size: 16px;
  font-family: Lato Light;
  margin: 0 15px 10px 0;

  strong {
    font-family: Lato Bold;
    font-size: 18px;
  }
`;

const TextInput = styled.input`
  border: 1px solid ${$.color.gray1};
  border-radius: 15px;
  padding: 15px 20px;
  font-family: Lato Light;
  font-size: 15px;
  width: calc(100% - 40px - 2px);
`;

const Dropdown = styled.select`
  padding: 15px 20px;
  border-radius: 15px;
  border: 1px solid ${$.color.gray1};
  font-family: Lato Light;
  font-size: 15px;
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: ${$.color.red};
  font-size: 15px;
  padding-top: 5px;
  font-family: Lato Light;
`;

const ButtonContainer = styled.div`
  display: inline-block;
  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    display: block;
    & > * {
      width: 100%;
    }
  }
  // #endregion
`;

const CourseTitle = styled.div`
  font-family: Lato Bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

const ApplyNowForm = () => {
  const [form, setForm] = useState({
    nationality: 'singaporean',
    companySponsored: 'yes',
  });
  const [courseTitle, setCourseTitle] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [submitMessage, setSubmitMessage] = useState('Submit');

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    setErrorMessages((prev) => ({
      ...prev,
      [e.target.name]: '',
    }));
  };

  useEffect(() => {
    let skipSubmit = false;
    const totalKeys = Object.keys(form);

    totalKeys.forEach((key) => {
      if (form[key] === '') {
        skipSubmit = true;
      }
    });

    if (totalKeys.length < 6) {
      skipSubmit = true;
    }

    if (form.yob && form.yob.length < 4) {
      skipSubmit = true;
    }

    setDisableSubmit(skipSubmit);
  }, [form]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams) {
        const course = searchParams.get('c');

        if (course) {
          setCourseTitle(course);
        } else {
          navigate('/professional-training/');
        }
      }
    }
  }, []);

  return (
    <Section>
      <Container>
        <Title>Apply Now</Title>
        <CourseTitle>{courseTitle}</CourseTitle>
        <TextField>
          <Label>Name</Label>
          <TextInput
            value={form.name || ''}
            type="text"
            placeholder="Name"
            name="name"
            onChange={handleChange}
          />
          <ErrorMessage>{errorMessages.name || ''}</ErrorMessage>
        </TextField>
        <TextField>
          <Label>Email</Label>
          <TextInput
            type="text"
            name="email"
            onChange={handleChange}
            value={form.email || ''}
            placeholder="E.g. example@gmail.com"
          />
          <ErrorMessage>{errorMessages.email || ''}</ErrorMessage>
        </TextField>
        <TextField>
          <Label>Contact Number</Label>
          <TextInput
            type="text"
            onChange={handleChange}
            value={form.contact || ''}
            name="contact"
            placeholder="E.g. +651231232"
          />
          <ErrorMessage>{errorMessages.contact || ''}</ErrorMessage>
        </TextField>
        <TextField>
          <Label>
            Nationality<strong>*</strong>
          </Label>
          <Dropdown
            value={form.nationality || ''}
            onChange={(e) => {
              handleChange({
                target: { value: e.target.value, name: 'nationality' },
              });
            }}
          >
            <option value="singaporean">Singaporean</option>
            <option value="pr">PR</option>
            <option value="foreigner">Foreigner</option>
          </Dropdown>
        </TextField>
        <TextField>
          <Label>
            Year of Birth<strong>*</strong>
          </Label>
          <TextInput
            onChange={handleChange}
            name="yob"
            type="text"
            placeholder="E.g. 1991"
            value={form.yob || ''}
          />
          <ErrorMessage>{errorMessages.yob || ''}</ErrorMessage>
        </TextField>
        <TextField>
          <Label>
            Company Sponsored<strong>*</strong>
          </Label>
          <Dropdown
            value={form.companySponsored || ''}
            onChange={(e) => {
              handleChange({
                target: { value: e.target.value, name: 'companySponsored' },
              });
            }}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
            <option value="notsure">Not sure</option>
          </Dropdown>
        </TextField>
        <Footnote>
          *These details are needed to check subsidies and grants available to
          you
        </Footnote>
        <ButtonContainer>
          <BlueButton
            disabled={disableSubmit}
            text={submitMessage}
            onClick={() => {
              let skipSubmit = false;

              Object.keys(form).forEach((key) => {
                let test;

                switch (key) {
                  case 'email':
                    test = form.email.match(
                      /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
                    );

                    if (!test) {
                      setErrorMessages((prev) => ({
                        ...prev,
                        email: 'Invalid email.',
                      }));
                      skipSubmit = true;
                    }
                    break;
                  case 'yob':
                    if (!Number(form[key]) || form[key].length !== 4) {
                      setErrorMessages((prev) => ({
                        ...prev,
                        yob: 'Invalid year of birth.',
                      }));
                      skipSubmit = true;
                    }
                    break;
                  case 'contact':
                    test = form[key].match(
                      /[a-z]|[A-Z]|[@#!$%^&*(){}\\[\]|<>?/~`]/g
                    );

                    if (test) {
                      setErrorMessages((prev) => ({
                        ...prev,
                        contact: 'Invalid contact number.',
                      }));
                      skipSubmit = true;
                    }
                    break;
                  default:
                    break;
                }
              });

              if (!skipSubmit) {
                setDisableSubmit(true);
                setSubmitMessage('Submitting...');

                // https://tsdgr7zor8.execute-api.ap-southeast-1.amazonaws.com/dev/lambda-p-training-enquiry
                // https://93mx6ys0g1.execute-api.ap-southeast-1.amazonaws.com/prod/lambda-p-training-enquiry
                fetch(
                  'https://93mx6ys0g1.execute-api.ap-southeast-1.amazonaws.com/prod/lambda-p-training-enquiry',
                  {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...form, course: courseTitle }),
                  }
                )
                  .then(() => {
                    setSubmitMessage('Response received.');
                    setForm({
                      companySponsored: 'yes',
                      nationality: 'singaporean',
                      name: '',
                      email: '',
                      contact: '',
                      yob: '',
                    });
                    setTimeout(() => {
                      setSubmitMessage('Submit');
                    }, 1000);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }}
          />
        </ButtonContainer>
      </Container>
    </Section>
  );
};

export default ApplyNowForm;
