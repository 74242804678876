import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section`
  margin-bottom: ${$.layout().margin2}px;
`;

const Container = styled.div`
  height: 650px;
  overflow: hidden;
  position: relative;
  margin-bottom: ${$.layout().margin3}px;

  // #region
  @media ${$.device.mobile} {
    height: 300px;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const MainBanner = () => (
  <Section>
    <Container>
      <ImageContainer>
        <StaticImage
          src="../../../assets/images/partners/apple/apple-main-banner.jpg"
          alt="Apple Consultants Network image banner"
        />
      </ImageContainer>
    </Container>
  </Section>
);

export default MainBanner;
