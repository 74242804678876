import styled from 'styled-components';

import $ from '../../../styles/global';

const Section = styled.section`
  background-color: ${$.accelerator.orange};
`;

const Container = styled.div`
  padding: 50px 0;

  h1 {
    font-family: Lato Bold;
    font-size: 40px;
    color: ${$.color.white};
  }
`;

const Banner = () => (
  <Section id="schedule-table">
    <Container>
      <h1>Data Science Accelerator 2024 Schedule</h1>
    </Container>
  </Section>
);

export default Banner;
