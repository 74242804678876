import { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import '../../utils/particles';
import $ from '../../styles/global';

const Overlay = styled.section`
  background-color: ${$.accelerator.blue};
  position: relative;
`;

const ParticlesContainer = styled.div.attrs({ id: 'particles' })`
  width: 100vw !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  height: 100%;
  position: absolute;
`;

const particlesParam = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 1300,
      },
    },
    color: {
      value: '#fff',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        value: '#fff',
      },
      polygon: {
        nb_sides: 5,
      },
    },
    opacity: {
      value: 0.6,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 170,
      value: '#fff',
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 150,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};

const Background = ({ children }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.particlesJS) {
      window.particlesJS('particles', particlesParam);
    }

    return () => {
      if (
        typeof window !== 'undefined' &&
        window.pJSDom &&
        window.pJSDom.length > 0
      ) {
        window.pJSDom[0].pJS.fn.vendors.destroypJS();
        window.pJSDom = [];
      }
    };
  }, []);

  return (
    <Overlay>
      <ParticlesContainer />
      {children}
    </Overlay>
  );
};

Background.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
};

export default Background;
