import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from '../Context';
import $ from '../../../../styles/global';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc(50% - ${$.layout().margin3}px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > div {
      width: calc((100% - ${$.layout().margin5}px) * 1 / 2);
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(50% - ${$.layout().margin3}px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > div {
      width: calc((100% - ${$.layout().margin5}px) / 2);
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;

    & > div:first-child {
      margin-bottom: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const Question = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue5};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 16px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 16px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 20px;
  }
  // #endregion
`;

const Button = styled.div``;

const AnswersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > ${Button}:first-child {
    background-color: ${({ answer }) =>
      answer === 'Yes' ? $.color.blue3 : $.color.white};
    color: ${({ answer }) =>
      answer === 'Yes' ? $.color.white : $.color.blue3};
  }

  & > ${Button}:last-child {
    background-color: ${({ answer }) =>
      answer === 'No' ? $.color.blue3 : $.color.white};
    color: ${({ answer }) => (answer === 'No' ? $.color.white : $.color.blue3)};
  }

  & > div {
    width: calc(
      (100% - ${$.layout().margin4}px) / 2 - ${$.layout().padding5}px * 2
    );
    font-family: Aileron Heavy;
    text-align: center;
    border-radius: 99px;
    box-shadow: ${$.dropShadow.normal};
    cursor: pointer;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    & > div {
      font-size: 14px;
      padding: ${$.layout().padding5}px;

      &:hover {
        box-shadow: ${$.dropShadow.repressed};
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    & > div {
      font-size: 14px;
      padding: ${$.layout().padding5}px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    & > div {
      font-size: 17px;
      padding: ${$.layout().padding5}px;

      &:hover {
        box-shadow: ${$.dropShadow.repressed};
      }
    }
  }
  // #endregion
`;

const Question2 = () => {
  const {
    recommenderQ2Selection: currentSelection,
    recommenderQ2UpdateSelection: updateSelection,
    answered,
    setAnswered,
  } = useContext(Context);

  return (
    <Container>
      <Question>My child has taken formal coding lessons before:</Question>
      <AnswersContainer answer={currentSelection}>
        <Button
          onMouseDown={() => {
            updateSelection(currentSelection === 'Yes' ? '' : 'Yes');
            if (!answered) {
              setAnswered(true);
            }
          }}
        >
          Yes
        </Button>
        <Button
          onMouseDown={() => {
            updateSelection(currentSelection === 'No' ? '' : 'No');
            if (!answered) {
              setAnswered(true);
            }
          }}
        >
          No
        </Button>
      </AnswersContainer>
    </Container>
  );
};

export default Question2;
