import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import { Provider } from './context';
import CourseType from './CourseType';
import CourseDates from './CourseDates';
import CourseTime from './CourseTime';
import CourseLocation from './CourseLocation';
import CoursePrice from './CoursePrice';
import CourseButton from './CourseButton';

const Container = styled.div`
  flex-direction: column;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: ${({ event, filteredData }) => {
      if (filteredData.key && filteredData.key === event.key) {
        return 'flex';
      }
      return 'none';
    }};
    padding: ${$.layout().padding2}px ${$.layout().padding2}px;
    background-color: ${({ filteredData: { eventIndex } }) =>
      eventIndex % 2 ? $.color.blue1 : $.color.white};
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: ${({ index, length }) => length - index};
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: ${({ event, filteredData }) => {
      if (filteredData.key && filteredData.key === event.key) {
        return 'flex';
      }
      return 'none';
    }};
    background-color: ${({ filteredData: { eventIndex } }) =>
      Math.floor(eventIndex / 2) % 2 ? $.color.blue1 : $.color.white};
    padding: ${$.layout().padding2}px ${$.layout().margin3}px 0
      ${$.layout().margin3}px;
    width: ${({ filteredData: { eventIndex }, filteredDataLength }) =>
      filteredDataLength % 2 && eventIndex === filteredDataLength - 1
        ? `calc(100% - ${$.layout().margin3 * 2}px)`
        : `calc(50% - ${$.layout().margin3 * 2}px)`};
    padding-bottom: ${$.layout().padding4}px;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: ${({ event, filteredData }) => {
      if (filteredData.key && filteredData.key === event.key) {
        return 'flex';
      }
      return 'none';
    }};
    margin-bottom: ${$.layout().margin3}px;
    padding-bottom: ${$.layout().padding3}px;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 2px solid rgba(196, 196, 196, 0.4);
  }
  // #endregion
`;

const Event = ({ event, index, length, filteredData, filteredDataLength }) => {
  const { type, dates, time, location, price, url, soldOut, oneSeatLeft } =
    event;

  return (
    <Provider
      value={{
        event,
        index,
        length,
        filteredData,
        filteredDataLength,
      }}
    >
      <Container
        index={index}
        length={length}
        filteredData={filteredData}
        event={event}
        filteredDataLength={filteredDataLength}
      >
        <CourseType courseType={type} />
        <CourseDates courseDates={dates} type={type} />
        <CourseTime courseTime={time} />
        <CourseLocation courseLocation={location} />
        <CoursePrice coursePrice={price} courseStartDate={dates.startDate} />
        <CourseButton
          courseURL={url}
          oneSeatLeft={oneSeatLeft}
          courseSoldOut={soldOut}
          filteredData={filteredData}
          filteredDataLength={filteredDataLength}
        />
      </Container>
    </Provider>
  );
};

Event.defaultProps = {
  filteredData: {},
  filteredDataLength: 0,
};

Event.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    dates: PropTypes.shape({
      startDate: PropTypes.number,
      endDate: PropTypes.number,
      startDay: PropTypes.string,
      endDay: PropTypes.string,
      sessionLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      day: PropTypes.string,
      full: PropTypes.arrayOf(PropTypes.number),
      skipped: PropTypes.arrayOf(PropTypes.number),
    }),
    time: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    location: PropTypes.string,
    ages: PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    price: PropTypes.shape({
      main: PropTypes.number,
      earlyBird: PropTypes.bool,
    }),
    url: PropTypes.string,
    soldOut: PropTypes.bool,
    oneSeatLeft: PropTypes.bool,
  }).isRequired,
  filteredData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ),
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  filteredDataLength: PropTypes.number,
};

export default Event;
