import styled from 'styled-components';
import PropTypes from 'prop-types';

import switchBubble from './switchBubble';
import $ from '../../styles/global';
import { Context } from './Context';

const Container = styled.div`
  ${({ current, last }) => `
  width: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &:first-child {
    visibility: ${!current ? 'hidden' : 'visible'};
  }

  &:last-child {
    visibility: ${current === last ? 'hidden' : 'visible'};
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    // Get the area of left and right chevron by deducting the profile bubble width, the chevrons width,
    // and the expected padding from the profile pic
    &:first-child {
      left: calc(
        (100vw - ${$.layout().margin3}px * 2 - 200px - (52px * 2) - 40px) / 2
      );
      top: 100px;
    }

    &:last-child {
      right: calc(
        (100vw - ${$.layout().margin3}px * 2 - 200px - (52px * 2) - 40px) / 2
      );
      top: 100px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    &:first-child {
      left: 20px;
      top: 75px;
    }

    &:last-child {
      right: 20px;
      top: 75px;
    }
  }
  // #endregion
`}
`;

const Chevron = ({ children, left }) => (
  <Context.Consumer>
    {({ setToggleDesc, setCurrent, setDesc, current, data }) => (
      <Container
        current={current}
        last={data.length - 1}
        onClick={() => {
          const newIndex = left ? current - 1 : current + 1;

          switchBubble({
            newIndex,
            setCurrent,
            setToggleDesc,
            setDesc,
            desc: data[newIndex] ? data[newIndex].description : '',
          });
        }}
      >
        {children}
      </Container>
    )}
  </Context.Consumer>
);

Chevron.defaultProps = {
  children: <></>,
  left: false,
};

Chevron.propTypes = {
  children: PropTypes.element,
  left: PropTypes.bool,
};

export default Chevron;
