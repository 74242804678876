import SEO from '../../components/seo';
import Navbar from '../../components/DatascienceBootcampAccelerator2021/Navbar';
import Background from '../../components/DatascienceBootcampAccelerator2021/Background';
import FeaturedImage from '../../components/DatascienceBootcampAccelerator2021/ProgrammeOutline/FeaturedImage';
import Phase1 from '../../components/DatascienceBootcampAccelerator2021/ProgrammeOutline/Phase1';
import Phase2 from '../../components/DatascienceBootcampAccelerator2021/ProgrammeOutline/Phase2';
import DotsDivider from '../../components/DatascienceBootcampAccelerator2021/ProgrammeOutline/DotsDivider';
import BlogUpdates from '../../components/DatascienceBootcampAccelerator2021/BlogUpdates';
import JoinCard from '../../components/DatascienceBootcampAccelerator2021/JoinCard';

const ProgrammeOutline = () => (
  <>
    <SEO
      title="Data Science Bootcamp and Accelerator 2021 Programme Outline and Course Summary"
      description="Summary of modules of the programme outline. Learn data science by programming the AWS Deepracer."
      keywords={[
        'data science',
        'accelerator programme outline',
        'imda accelerator',
        'python programming',
      ]}
    />
    <Navbar />
    <Background id="particles-1">
      <FeaturedImage />
    </Background>
    <Background id="particles-2">
      <Phase1 />
    </Background>
    <Background id="particles-3">
      <DotsDivider />
    </Background>
    <Background id="particles-4">
      <Phase2 />
    </Background>
    <Background id="particles-5">
      <BlogUpdates />
    </Background>
    <Background id="particles-6">
      <JoinCard />
    </Background>
  </>
);

export default ProgrammeOutline;
