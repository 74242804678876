exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aws-course-schedule-jsx": () => import("./../../../src/pages/aws-course-schedule.jsx" /* webpackChunkName: "component---src-pages-aws-course-schedule-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-tags-jsx": () => import("./../../../src/pages/blog/tags.jsx" /* webpackChunkName: "component---src-pages-blog-tags-jsx" */),
  "component---src-pages-build-on-jsx": () => import("./../../../src/pages/build-on.jsx" /* webpackChunkName: "component---src-pages-build-on-jsx" */),
  "component---src-pages-coding-course-recommender-index-jsx": () => import("./../../../src/pages/coding-course-recommender/index.jsx" /* webpackChunkName: "component---src-pages-coding-course-recommender-index-jsx" */),
  "component---src-pages-coding-curriculum-philosophy-jsx": () => import("./../../../src/pages/coding-curriculum-philosophy.jsx" /* webpackChunkName: "component---src-pages-coding-curriculum-philosophy-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-coronavirus-advisory-jsx": () => import("./../../../src/pages/coronavirus-advisory.jsx" /* webpackChunkName: "component---src-pages-coronavirus-advisory-jsx" */),
  "component---src-pages-courses-apple-jsx": () => import("./../../../src/pages/courses/apple.jsx" /* webpackChunkName: "component---src-pages-courses-apple-jsx" */),
  "component---src-pages-courses-aws-jsx": () => import("./../../../src/pages/courses/aws.jsx" /* webpackChunkName: "component---src-pages-courses-aws-jsx" */),
  "component---src-pages-courses-google-jsx": () => import("./../../../src/pages/courses/google.jsx" /* webpackChunkName: "component---src-pages-courses-google-jsx" */),
  "component---src-pages-courses-smu-jsx": () => import("./../../../src/pages/courses/smu.jsx" /* webpackChunkName: "component---src-pages-courses-smu-jsx" */),
  "component---src-pages-datascience-accelerator-dunman-jsx": () => import("./../../../src/pages/datascience-accelerator/dunman.jsx" /* webpackChunkName: "component---src-pages-datascience-accelerator-dunman-jsx" */),
  "component---src-pages-datascience-accelerator-index-jsx": () => import("./../../../src/pages/datascience-accelerator/index.jsx" /* webpackChunkName: "component---src-pages-datascience-accelerator-index-jsx" */),
  "component---src-pages-datascience-accelerator-schedule-jsx": () => import("./../../../src/pages/datascience-accelerator/schedule.jsx" /* webpackChunkName: "component---src-pages-datascience-accelerator-schedule-jsx" */),
  "component---src-pages-datascience-bootcamp-accelerator-2021-index-jsx": () => import("./../../../src/pages/datascience-bootcamp-accelerator-2021/index.jsx" /* webpackChunkName: "component---src-pages-datascience-bootcamp-accelerator-2021-index-jsx" */),
  "component---src-pages-datascience-bootcamp-accelerator-2021-join-2021-programme-jsx": () => import("./../../../src/pages/datascience-bootcamp-accelerator-2021/join-2021-programme.jsx" /* webpackChunkName: "component---src-pages-datascience-bootcamp-accelerator-2021-join-2021-programme-jsx" */),
  "component---src-pages-datascience-bootcamp-accelerator-2021-programme-outline-jsx": () => import("./../../../src/pages/datascience-bootcamp-accelerator-2021/programme-outline.jsx" /* webpackChunkName: "component---src-pages-datascience-bootcamp-accelerator-2021-programme-outline-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-holiday-coding-camps-jsx": () => import("./../../../src/pages/holiday-coding-camps.jsx" /* webpackChunkName: "component---src-pages-holiday-coding-camps-jsx" */),
  "component---src-pages-imda-grad-2021-jsx": () => import("./../../../src/pages/imda-grad-2021.jsx" /* webpackChunkName: "component---src-pages-imda-grad-2021-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-us-jsx": () => import("./../../../src/pages/join-us.jsx" /* webpackChunkName: "component---src-pages-join-us-jsx" */),
  "component---src-pages-online-classes-jsx": () => import("./../../../src/pages/online-classes.jsx" /* webpackChunkName: "component---src-pages-online-classes-jsx" */),
  "component---src-pages-partners-amazon-training-partner-jsx": () => import("./../../../src/pages/partners/amazon-training-partner.jsx" /* webpackChunkName: "component---src-pages-partners-amazon-training-partner-jsx" */),
  "component---src-pages-partners-apple-consultants-network-jsx": () => import("./../../../src/pages/partners/apple-consultants-network.jsx" /* webpackChunkName: "component---src-pages-partners-apple-consultants-network-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-partners-smu-index-jsx": () => import("./../../../src/pages/partners/smu/index.jsx" /* webpackChunkName: "component---src-pages-partners-smu-index-jsx" */),
  "component---src-pages-partners-smu-micro-workshop-jsx": () => import("./../../../src/pages/partners/smu/micro-workshop.jsx" /* webpackChunkName: "component---src-pages-partners-smu-micro-workshop-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-professional-training-advanced-certificate-in-applied-data-analytics-jsx": () => import("./../../../src/pages/professional-training/advanced-certificate-in-applied-data-analytics.jsx" /* webpackChunkName: "component---src-pages-professional-training-advanced-certificate-in-applied-data-analytics-jsx" */),
  "component---src-pages-professional-training-advanced-certificate-in-aws-cloud-architecting-and-devops-jsx": () => import("./../../../src/pages/professional-training/advanced-certificate-in-aws-cloud-architecting-and-devops.jsx" /* webpackChunkName: "component---src-pages-professional-training-advanced-certificate-in-aws-cloud-architecting-and-devops-jsx" */),
  "component---src-pages-professional-training-advanced-certificate-in-aws-cloud-services-and-technicals-jsx": () => import("./../../../src/pages/professional-training/advanced-certificate-in-aws-cloud-services-and-technicals.jsx" /* webpackChunkName: "component---src-pages-professional-training-advanced-certificate-in-aws-cloud-services-and-technicals-jsx" */),
  "component---src-pages-professional-training-apply-now-jsx": () => import("./../../../src/pages/professional-training/apply-now.jsx" /* webpackChunkName: "component---src-pages-professional-training-apply-now-jsx" */),
  "component---src-pages-professional-training-architecting-on-aws-jsx": () => import("./../../../src/pages/professional-training/architecting-on-aws.jsx" /* webpackChunkName: "component---src-pages-professional-training-architecting-on-aws-jsx" */),
  "component---src-pages-professional-training-aws-cloud-practitioner-essentials-jsx": () => import("./../../../src/pages/professional-training/aws-cloud-practitioner-essentials.jsx" /* webpackChunkName: "component---src-pages-professional-training-aws-cloud-practitioner-essentials-jsx" */),
  "component---src-pages-professional-training-certificate-in-technology-foundations-establishing-foundation-in-cloud-services-and-applications-jsx": () => import("./../../../src/pages/professional-training/certificate-in-technology-foundations-establishing-foundation-in-cloud-services-and-applications.jsx" /* webpackChunkName: "component---src-pages-professional-training-certificate-in-technology-foundations-establishing-foundation-in-cloud-services-and-applications-jsx" */),
  "component---src-pages-professional-training-certificate-in-technology-foundations-jump-start-in-artificial-intelligence-machine-learning-and-deep-learning-jsx": () => import("./../../../src/pages/professional-training/certificate-in-technology-foundations-jump-start-in-artificial-intelligence-machine-learning-and-deep-learning.jsx" /* webpackChunkName: "component---src-pages-professional-training-certificate-in-technology-foundations-jump-start-in-artificial-intelligence-machine-learning-and-deep-learning-jsx" */),
  "component---src-pages-professional-training-certificate-in-technology-foundations-unearthing-the-potential-of-databases-big-data-and-data-analytics-jsx": () => import("./../../../src/pages/professional-training/certificate-in-technology-foundations-unearthing-the-potential-of-databases-big-data-and-data-analytics.jsx" /* webpackChunkName: "component---src-pages-professional-training-certificate-in-technology-foundations-unearthing-the-potential-of-databases-big-data-and-data-analytics-jsx" */),
  "component---src-pages-professional-training-index-jsx": () => import("./../../../src/pages/professional-training/index.jsx" /* webpackChunkName: "component---src-pages-professional-training-index-jsx" */),
  "component---src-pages-professional-training-professional-certificate-in-python-programming-jsx": () => import("./../../../src/pages/professional-training/professional-certificate-in-python-programming.jsx" /* webpackChunkName: "component---src-pages-professional-training-professional-certificate-in-python-programming-jsx" */),
  "component---src-pages-professional-training-sctp-advanced-certificate-in-ios-application-development-with-swift-programming-jsx": () => import("./../../../src/pages/professional-training/sctp-advanced-certificate-in-ios-application-development-with-swift-programming.jsx" /* webpackChunkName: "component---src-pages-professional-training-sctp-advanced-certificate-in-ios-application-development-with-swift-programming-jsx" */),
  "component---src-pages-professional-training-sctp-software-development-with-the-amazon-web-service-cloud-jsx": () => import("./../../../src/pages/professional-training/sctp-software-development-with-the-amazon-web-service-cloud.jsx" /* webpackChunkName: "component---src-pages-professional-training-sctp-software-development-with-the-amazon-web-service-cloud-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-schools-jsx": () => import("./../../../src/pages/schools.jsx" /* webpackChunkName: "component---src-pages-schools-jsx" */),
  "component---src-pages-student-achievements-jsx": () => import("./../../../src/pages/student-achievements.jsx" /* webpackChunkName: "component---src-pages-student-achievements-jsx" */),
  "component---src-pages-summer-scratch-competition-2021-jsx": () => import("./../../../src/pages/summer-scratch-competition-2021.jsx" /* webpackChunkName: "component---src-pages-summer-scratch-competition-2021-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-tertiary-apple-jsx": () => import("./../../../src/pages/tertiary/apple.jsx" /* webpackChunkName: "component---src-pages-tertiary-apple-jsx" */),
  "component---src-pages-tertiary-aws-jsx": () => import("./../../../src/pages/tertiary/aws.jsx" /* webpackChunkName: "component---src-pages-tertiary-aws-jsx" */),
  "component---src-pages-tertiary-index-jsx": () => import("./../../../src/pages/tertiary/index.jsx" /* webpackChunkName: "component---src-pages-tertiary-index-jsx" */),
  "component---src-templates-individual-blog-page-jsx": () => import("./../../../src/templates/IndividualBlogPage.jsx" /* webpackChunkName: "component---src-templates-individual-blog-page-jsx" */),
  "component---src-templates-individual-course-page-jsx": () => import("./../../../src/templates/IndividualCoursePage.jsx" /* webpackChunkName: "component---src-templates-individual-course-page-jsx" */),
  "component---src-templates-individual-event-page-jsx": () => import("./../../../src/templates/IndividualEventPage.jsx" /* webpackChunkName: "component---src-templates-individual-event-page-jsx" */),
  "component---src-templates-individual-md-page-jsx": () => import("./../../../src/templates/IndividualMDPage.jsx" /* webpackChunkName: "component---src-templates-individual-md-page-jsx" */),
  "component---src-templates-individual-tag-page-jsx": () => import("./../../../src/templates/IndividualTagPage.jsx" /* webpackChunkName: "component---src-templates-individual-tag-page-jsx" */),
  "component---src-templates-lesson-jsx": () => import("./../../../src/templates/Lesson.jsx" /* webpackChunkName: "component---src-templates-lesson-jsx" */)
}

