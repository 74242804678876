import JoinUs from '../components/JoinUs';
import SEO from '../components/seo';

const JoinUsPage = () => (
  <>
    <SEO
      title="Join Us"
      description="Interested to join our team of instructors? Contact us now"
    />
    <JoinUs />
  </>
);

export default JoinUsPage;
