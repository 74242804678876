import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const ProfileImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40%;
  align-self: flex-start;
  top: 30px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const ProfileImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  top: -30px;
  left: 25px;
  border: 0.5px solid #16303e;
  box-shadow: ${$.dropShadow.repressed};

  & > * {
    width: 100%;
    height: 100%;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    left: calc(50% - 75px);
  }
  // #endregion
`;

const ProfileBox = styled.div`
  border-radius: 32px;
  box-shadow: 0px 4px 30px 0px rgba(23, 49, 63, 0.6);
  border-radius: 32px;
  background: radial-gradient(
    152.64% 127.74% at 25.6% 78.1%,
    #16303e 44.99%,
    #344d55 96.49%
  );
  padding: 140px 25px 25px 25px;

  & > * {
    color: ${$.color.white};
    &:first-child {
      font-size: 20px;
      font-family: Lato Bold;
      margin-bottom: 10px;
    }
    &:not(:first-child) {
      font-family: Lato Light;
      font-size: 15px;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 20px;
    text-align: center;

    & > * {
      color: ${$.color.white};
      &:first-child {
        font-size: 22px;
      }
      &:not(:first-child) {
        font-size: 16px;
      }
    }
  }
  // #endregion
`;

const Story = styled.div`
  padding-top: 30px;
  font-size: 15px;
  line-height: 18px;
  font-family: Lato Light;
  width: 55%;

  ${$.br}

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 90%;
  }
  // #endregion
`;

const TrainerProfile = ({ image, name, qualification, children }) => (
  <Container>
    <ProfileImageContainer>
      <ProfileImage>{image}</ProfileImage>
      <ProfileBox>
        <div>{name}</div>
        {qualification.map(({ text, key }) => (
          <div key={key}>{text}</div>
        ))}
      </ProfileBox>
    </ProfileImageContainer>
    <Story>{children}</Story>
  </Container>
);

TrainerProfile.defaultProps = {
  name: '',
  children: <></>,
  image: <></>,
  qualification: [],
};

TrainerProfile.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]),
  image: PropTypes.element,
  qualification: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default TrainerProfile;
