import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../styles/global';

const CheckboxContainer = styled.div`
  position: relative;
`;

const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 16px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0;
  white-space: nowrap;
  width: 16px;
  opacity: 0;
  z-index: 100000;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

const StyledCheckbox = styled.div`
  ${({ checked }) => `
  width: 16px;
  height: 16px;
  background: ${$.color.blue3};
  border-radius: 3px;
  opacity: ${checked ? 1 : 0.45};

  ${Icon} {
    visibility: ${checked ? 'visible' : 'hidden'};
  }
`}
`;

const Checkbox = ({ checked, ...props }) => (
  <CheckboxContainer>
    <HiddenCheckBox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default Checkbox;
