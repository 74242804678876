import styled from 'styled-components';

import $ from '../../../styles/global';
import ImageContainer from '../../Level2Banner/ImageContainer';
import Banner1 from '../../../assets/images/holidaycamps/banner1.svg';

const Container = styled(ImageContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: absolute;
    top: -110px;
    left: -50px;

    & > svg {
      width: 140px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tabletA} {
    position: absolute;
    top: -100px;
    left: -15px;

    & > svg {
      width: 120px;
    }
  }

  @media ${$.device.tabletB} {
    position: absolute;
    top: -100px;
    left: -10px;

    & > svg {
      width: 120px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const Image1Container = () => <Container image={Banner1} />;

export default Image1Container;
