import Seo from '../components/seo';
import Banner from '../components/ProfessionalTraining/Banner';
import DiscoverCourses from '../components/ProfessionalTraining/DiscoverCourses';
import InstructorsFrom from '../components/ProfessionalTraining/InstructorsFrom';
import Partners from '../components/ProfessionalTraining/Partners';
import CodingForEveryone from '../components/landing/CodingForEveryone';
import ClientsCollaborations from '../components/ProfessionalTraining/ClientsCollaborations';

const IndexPage = () => (
  <>
    <Seo title="IT Courses in Singapore" />
    <Banner />
    <InstructorsFrom />
    <Partners />
    <DiscoverCourses />
    <CodingForEveryone />
    <ClientsCollaborations />
  </>
);

export default IndexPage;
