import styled from 'styled-components';

import PropTypes from 'prop-types';
import $ from '../../../styles/global';
import FbIcon from '../../../assets/icons/fb.svg';
import TwIcon from '../../../assets/icons/tw.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AuthorPic = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ img }) => img});
  border-radius: 999px;
  width: 32px;
  height: 32px;
  margin-right: ${$.layout().margin4}px;
`;

const AuthorContent = styled.div``;

const AuthorName = styled.div`
  font-family: Lato Regular;
  color: ${$.color.blue4};
  font-size: 14px;
`;

const PublishedDate = styled.div`
  color: ${$.color.gray2};
  font-family: Lato Regular;
  font-size: 14px;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  > *:first-child {
    margin-right: ${$.layout().margin5}px;
  }
`;

const SocialLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer noopener',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.4s ease;

  &:hover {
    opacity: 0.5;
  }

  & > svg {
    fill: ${$.color.gray2};
    height: 20px;
    width: 20px;
  }
`;

const BottomInfo = ({ slug, publishedAt, timeToRead, author }) => (
  <Container>
    <Author>
      <AuthorPic img={author.profile_image} />
      <AuthorContent>
        <AuthorName>{author.name}</AuthorName>
        <PublishedDate>{`${publishedAt} · ${timeToRead} min read`}</PublishedDate>
      </AuthorContent>
    </Author>
    <SocialLinks>
      <SocialLink
        href={`https://www.facebook.com/sharer.php?u=https://www.sgcodecampus.com/blog/${slug}/`}
      >
        <FbIcon />
      </SocialLink>
      <SocialLink
        href={`https://twitter.com/intent/tweet?url=https://www.sgcodecampus.com/blog/${slug}/`}
      >
        <TwIcon />
      </SocialLink>
    </SocialLinks>
  </Container>
);

BottomInfo.defaultProps = {
  slug: '',
  publishedAt: '',
  author: {},
  timeToRead: 0,
};

BottomInfo.propTypes = {
  author: PropTypes.oneOfType([PropTypes.object]),
  publishedAt: PropTypes.string,
  timeToRead: PropTypes.number,
  slug: PropTypes.string,
};

export default BottomInfo;
