import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import Button from '../../Button';

const StyledLink = styled.a`
  text-decoration: none;
  pointer-events: ${({ courseSoldOut }) =>
    courseSoldOut ? 'none' : 'default'};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    align-self: center;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    width: calc(
      ${({ filteredDataLength, filteredData: { eventIndex } }) =>
        filteredDataLength % 2 && eventIndex === filteredDataLength - 1
          ? `50% - ${$.layout().margin3}px`
          : '100%'}
    );
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const StyledButton = styled(Button)`
  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    width: 100%;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const OneSeatLeft = styled.div`
  font-family: Lato Regular;
  font-size: 15px;
  text-align: center;
  color: ${$.color.red};
  padding-top: ${$.layout().padding5}px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: ${({ show }) => (show === true ? 'block' : 'none')};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    opacity: ${({ show }) => (show === true ? 1 : 0)};
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: ${({ show }) => (show === true ? 'block' : 'none')};
  }
  // #endregion
`;

// react.memo this component
const CourseButton = memo(
  ({
    courseURL,
    courseSoldOut,
    filteredData,
    filteredDataLength,
    oneSeatLeft,
  }) => (
    <StyledLink
      href={courseURL}
      target="_blank"
      rel="noopener noreferrer"
      courseSoldOut={courseSoldOut}
      filteredData={filteredData}
      filteredDataLength={filteredDataLength}
    >
      <StyledButton
        normalColor={courseSoldOut === true ? $.color.gray1 : $.color.orange3}
        clickedColor={$.color.orange3}
      >
        {courseSoldOut === true ? 'Sold Out' : 'Sign Up'}
      </StyledButton>
      <OneSeatLeft show={oneSeatLeft === true && courseSoldOut !== true}>
        1 seat left!
      </OneSeatLeft>
    </StyledLink>
  )
);

CourseButton.defaultProps = {
  courseSoldOut: false,
  filteredData: {},
  filteredDataLength: 0,
  oneSeatLeft: false,
};

CourseButton.propTypes = {
  courseURL: PropTypes.string.isRequired,
  courseSoldOut: PropTypes.bool,
  oneSeatLeft: PropTypes.bool,
  filteredData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ),
  filteredDataLength: PropTypes.number,
};

export default CourseButton;
