import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../../styles/global';
import Utils from '../../../../utils';
import items from '../../items.json';
import LeftChevron from '../../../../assets/icons/left-chevron.svg';

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout().margin3}px;

  &:hover {
    cursor: pointer;
  }

  > svg {
    fill: ${$.color.black};
    width: 12px;
    height: 12px;
  }

  > div {
    color: ${$.color.black};
    font-size: 17px;
    font-family: Lato Light;
    margin-left: ${$.layout().margin5}px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${$.layout().margin4}px;
  > * {
    margin-bottom: ${$.layout().margin3}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLink = styled(({ link, className, onClick, children }) => {
  if (link.indexOf('http') > -1) {
    return (
      <a
        href={link}
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={link} onClick={onClick} className={className}>
      {children}
    </Link>
  );
})`
  text-decoration: none;
  color: ${$.color.black};
  font-family: Lato Light;
  font-size: 14.5px;
  &:visited {
    color: ${$.color.black};
  }
`;

const list = Utils.addKeys(
  items.map(({ subLinks, ...rest }) => ({
    subLinks: Utils.addKeys(subLinks),
    ...rest,
  }))
);

/**
 * 1. User tap on a tab in the sidebar.
 * 2. The container is populated and slides in immediately.
 * 3. User tap to go back.
 * 4. The container slides back to original position. 400ms later, the content
 * is removed.
 */
const SecondLayer = ({ showSecondLayer, setShowSecondLayer, setOpen }) => {
  const [content, setContent] = useState(false);

  useEffect(() => {
    let timeout;

    if (showSecondLayer.state) {
      setContent(true);
    } else {
      timeout = setTimeout(() => {
        setContent(false);
      }, 400);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showSecondLayer.state]);

  return (
    <Container>
      {content && (
        <>
          <Title
            onClick={() => {
              setShowSecondLayer((prev) => ({
                state: false,
                index: prev.index,
              }));
            }}
          >
            <LeftChevron />
            <div>{list[showSecondLayer.index].dropdownText}</div>
          </Title>
          <LinksContainer>
            {list[showSecondLayer.index].subLinks.map(
              ({ key, navbarSubText, subLink }) => (
                <StyledLink
                  onClick={() => {
                    setOpen(false);
                  }}
                  link={subLink}
                  key={key}
                >
                  {navbarSubText}
                </StyledLink>
              )
            )}
          </LinksContainer>
        </>
      )}
    </Container>
  );
};

SecondLayer.defaultProps = {
  setOpen: () => {},
  setShowSecondLayer: () => {},
  showSecondLayer: {},
};

SecondLayer.propTypes = {
  setOpen: PropTypes.func,
  setShowSecondLayer: PropTypes.func,
  showSecondLayer: PropTypes.shape({
    index: PropTypes.number,
    state: PropTypes.bool,
  }),
};

export default SecondLayer;
