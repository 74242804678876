export default {
  color: {
    white: '#FFFFFF',
    gray1: '#C4C4C4',
    gray2: '#9E9E9E',
    gray3: '#bfbfbf',
    black: '#000000',
    black2: '#333333',
    black3: '#222222',
    black4: '#323232',
    orange1: '#FFAF6E',
    orange2: '#F98D43',
    orange3: '#F37021',
    orange4: '#D85700',
    orange5: '#E85E00',
    blue1: '#EBFBFF',
    blue2: '#88FFFC',
    blue3: '#055399',
    blue4: '#114060',
    blue5: '#1B75BC',
    blue6: '#33B6FF',
    red: '#FF0000',
  },
  accelerator: {
    blue: '#2f9aec',
    grey: '#F0F0F0',
    orange: '#FF8533',
  },
  proTraining: {
    blue: '#223c4a',
    orange1: '#F48200',
    orange2: '#f58102',
    bluegray: '#363C42',
  },
  tertiary: {
    orange: '#EA7024',
  },
  easingFn: {
    standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)',
    decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  },
  baseNum: 8,
  layout() {
    return {
      margin1: this.baseNum * 16,
      margin2: this.baseNum * 8,
      margin3: this.baseNum * 4,
      margin4: this.baseNum * 2,
      margin5: this.baseNum,
      padding1: this.baseNum * 8,
      padding2: this.baseNum * 4,
      padding3: this.baseNum * 3,
      padding4: this.baseNum * 2,
      padding5: this.baseNum,
    };
  },
  border() {
    return {
      radius1: this.baseNum * 4,
      radius2: this.baseNum * 2,
      radius3: this.baseNum,
      radius4: this.baseNum / 2,
    };
  },
  ellipsis: `
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  `,
  dropShadow: {
    normal: '0px 3px 8px rgba(0, 0, 0, 0.15)',
    repressed: '0px 8px 12px rgba(0, 0, 0, 0.15)',
  },
  device: {
    mobile: 'only screen and (max-width: 767px)',
    tabletA: 'only screen and (min-width: 768px) and (max-width: 969px)',
    tabletB: 'only screen and (min-width: 970px) and (max-width: 1199px)',
    tablet: 'only screen and (min-width: 768px) and (max-width: 1199px)',
    desktop: 'only screen and (min-width: 1200px)',
  },
  fontSizes: {
    small: '0.75rem',
    normal: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
    xxxlarge: '2.5rem',
    xxxxlarge: '3rem',
  },
  fontFamily: {
    title: {
      heavy: 'Aileron Heavy',
      heavyItalic: 'Aileron Heavy Italic',
      bold: 'Aileron Bold',
      boldItalic: 'Aileron Bold Italic',
      semiBold: 'Aileron SemiBold',
      semiBoldItalic: 'Aileron SemiBold Italic',
      regular: 'Aileron Regular',
    },
    subtitle: {
      heavy: 'Cooper Hewitt Heavy',
      bold: 'Cooper Hewitt Bold',
      boldItalic: 'Cooper Hewitt Bold Italic',
      medium: 'Cooper Hewitt Medium',
      mediumItalic: 'Cooper Hewitt Medium Italic',
      semiBold: 'Cooper Hewitt SemiBold',
      semiBoldItalic: 'Cooper Hewitt SemiBold Italic',
      light: 'Cooper Hewitt Light',
    },
    body: {
      black: 'Lato Black',
      blackItalic: 'Lato Black Italic',
      heavy: 'Lato Heavy',
      heavyItalic: 'Lato Heavy Italic',
      bold: 'Lato Bold',
      boldItalic: 'Lato Bold Italic',
      semiBold: 'Lato SemiBold',
      semiBoldItalic: 'Lato SemiBold Italic',
      medium: 'Lato Medium',
      mediumItalic: 'Lato Medium Italic',
      regular: 'Lato Regular',
      hairline: 'Lato Hairline',
      hairlineItalic: 'Lato Hairline Italic',
      italic: 'Lato Italic',
      light: 'Lato Light',
      lightItalic: 'Lato Light Italic',
      thin: 'Lato Thin',
      thinItalic: 'Lato ThinItalic',
    },
  },
  br: `br {
    content: " ";
    line-height: 100%; 
    display: block;
    margin: 0.75em 0;
  }`,
};
