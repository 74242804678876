import { createContext } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

const Provider = ({ children, value }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

Provider.defaultProps = {
  children: <></>,
  value: {},
};

Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export { Provider, Context };
