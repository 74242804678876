import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../../styles/global';
import Utils from '../../../../utils';
import items from '../../items.json';
// import SignInButton from '../../SignInButton';
// import RightChevron from '../../../../assets/icons/right-chevron.svg';

const Container = styled.div`
  > * {
    margin-bottom: ${$.layout().margin3}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    fill: ${$.color.blue4};
    width: 12px;
    height: 12px;
  }
`;

const Text = styled.div`
  color: ${$.color.black};
  font-size: 15px;
  font-family: Lato Light;
  margin-right: ${$.layout().margin5}px;

  &:hover {
    cursor: pointer;
  }
`;

const TextLink = styled(Link)`
  color: ${$.color.black};
  font-size: 15px;
  font-family: Lato Light;
  text-decoration: none;
  margin-right: ${$.layout().margin5}px;
  margin-bottom: ${$.layout().margin3}px;
  display: block;
`;

const Divider = styled.div`
  height: 1px;
  width: 90%;
  margin-top: ${$.layout().margin3}px;
  background-color: ${$.color.gray1};
`;

const list = Utils.addKeys(items);

const FirstLayer = ({ setShowSecondLayer, setOpen }) => (
  <Container>
    {list.map(({ dropdownText, individualLink, key }, index) =>
      individualLink ? (
        <TextLink
          key={key}
          to={individualLink}
          onClick={() => {
            setOpen(false);
          }}
        >
          {dropdownText}
        </TextLink>
      ) : (
        <Tab
          key={key}
          onClick={() => {
            setShowSecondLayer({ state: true, index });
          }}
        >
          <Text>{dropdownText}</Text>
          {/* <RightChevron /> */}
        </Tab>
      )
    )}
    <Divider />
    {/* <SignInButton /> */}
  </Container>
);

FirstLayer.defaultProps = {
  setShowSecondLayer: () => {},
  setOpen: () => {},
};

FirstLayer.propTypes = {
  setShowSecondLayer: PropTypes.func,
  setOpen: PropTypes.func,
};

export default FirstLayer;
