import { MiniBanner } from '../components/AWSCourseSchedulePage';
import SEO from '../components/seo';
import ScheduleTable from '../components/AWSCourseSchedulePage/ScheduleTable';

const AWSCourseSchedulePage = () => {
  return (
    <>
      <SEO title="AWS Course Schedule" />
      <MiniBanner title="AWS Course Schedule" />
      <ScheduleTable />
    </>
  );
};

export default AWSCourseSchedulePage;
