import styled from 'styled-components';
import { navigate } from 'gatsby';

import $ from '../../styles/global';

const Container = styled.div`
  position: sticky;
  bottom: 0;
`;

const Button = styled.div`
  background-color: ${$.accelerator.orange};
  color: ${$.color.white};
  font-family: Lato Bold;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px 16px;
  min-width: 120px;
  position: absolute;
  bottom: 30px;
  right: 120px;
`;

const HoverSignUpButton = () => (
  <Container>
    <Button
      onClick={() => {
        if (typeof window !== 'undefined') {
          // Tiktok tracking as requested by Mingz.
          window?.ttq?.track('AddToWishlist');
        }

        navigate('/datascience-accelerator/#sign-up-process');
      }}
    >
      Learn how to sign up
    </Button>
  </Container>
);

export default HoverSignUpButton;
