import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import { Title, Subtitle, Label, Body } from './common';

const Container = styled.div`
  grid-area: learnFromIndustryExperts;
`;

const InstructorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${({ theme }) => theme.layout().margin2}px;
`;

const InstructorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: ${({ theme }) => theme.layout().margin2}px;

  & > ${Body} {
    ${({ theme }) => {
      return theme.br;
    }}
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    gap: ${({ theme }) => theme.layout().margin3}px;
  }
`;

const ImageContainer = styled.div``;

const ImageColumn = styled.div`
  flex: 0 0 30%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${({ theme }) => theme.layout().padding3}px;

  & img {
    width: 100%;
    border-radius: ${({ theme }) => theme.border().radius2}px;
    box-shadow: ${({ theme }) => theme.dropShadow.normal};
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: 'image name' 'image credentials';
    grid-gap: ${({ theme }) => theme.layout().padding3}px;

    & ${ImageContainer} {
      grid-area: image;
    }

    & ${Subtitle} {
      grid-area: name;
    }

    & ${Body} {
      grid-area: credentials;
    }
  }
`;

const Overview = () => {
  const { instructors, trainingPartners } = useContext(Context);

  return (
    <Container id="instructors">
      <Title>
        {trainingPartners.some(({ id }) => id === 'aws')
          ? 'Learn from an AWS Authorised Instructor'
          : 'Learn from the Industry Experts'}
      </Title>
      <InstructorsContainer>
        {instructors.map(({ id, name, credentials, bio, image }) => (
          <InstructorContainer key={id}>
            <ImageColumn>
              <ImageContainer>
                <img src={image} alt={name} />
              </ImageContainer>
              <Subtitle>{name}</Subtitle>
              <Body dangerouslySetInnerHTML={{ __html: credentials }} />
            </ImageColumn>
            <Body dangerouslySetInnerHTML={{ __html: bio }} />
          </InstructorContainer>
        ))}
      </InstructorsContainer>
    </Container>
  );
};

export default Overview;
