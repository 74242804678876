import Banner from './Banner';
import Content from './Content';

const JoinUs = () => (
  <>
    <Banner />
    <Content />
  </>
);

export default JoinUs;
