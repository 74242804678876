import PropTypes from 'prop-types';
import styled from 'styled-components';

import SEO from '../components/seo';
import Banner from '../components/learnmore/Banner';
import OperatingHours from '../components/learnmore/contactus/OperatingHours';
import CampusLocation from '../components/learnmore/contactus/CampusLocation';
import info from '../components/campusInfo.json';
import Util from '../utils';
import $ from '../styles/global';
import ContactUsImage from '../assets/images/learnmore/contactus/contact-us-banner.svg';

const StyledContactUsImage = styled(ContactUsImage)`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 200px !important;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 200px !important;
  }
  // #endregion
`;

const campuses = Util.addKeys(info.campuses).map(({ address, ...item }) => ({
  ...item,
  address: Util.addKeys(address.map((add) => ({ value: add }))),
}));

const offpeak = Util.addKeys(info.offpeak.map((item) => ({ value: item })));

const peak = Util.addKeys(info.peak.map((item) => ({ value: item })));

const ContactUs = ({ location }) => (
  <>
    <SEO
      location={location}
      title="Contact Us"
      description="Reach out to us, or check out our campuses in person"
    />
    <Banner
      title="Contact Us"
      subtitle="Have a question or want to check out our campuses? Here are a few ways you can reach us."
      image={StyledContactUsImage}
    />
    <OperatingHours />
    {campuses.map((props, index) => (
      <CampusLocation {...props} offpeak={offpeak} peak={peak} index={index} />
    ))}
  </>
);

ContactUs.defaultProps = {
  location: {},
};

ContactUs.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default ContactUs;
