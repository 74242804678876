import { useState } from 'react';

import styled from 'styled-components';
import Tabs from '../../ProfessionalTrainingCoursePage/Tabs';
import Courses from '../../ProfessionalTrainingCoursePage/Courses';
import Items from '../../ProfessionalTrainingCoursePage/Items';
import Banner from './Banner';

const Section = styled.section`
  padding: 40px 0;
  margin-bottom: 40px !important;
  background-color: #f7f7f7;
`;

const DiscoverCourses = () => {
  const [selected, setSelected] = useState('aws');

  return (
    <Section>
      <Banner />
      <Tabs items={Items} selected={selected} setSelected={setSelected} />
      <Courses items={Items} selected={selected} />
    </Section>
  );
};

export default DiscoverCourses;
