const parseGhostPagesToEvents = (events) =>
  events
    .map((event) => {
      const dateObject = new Date(event.node.excerpt.split('\n')[0].trim());
      const date = `${dateObject.getDate()}/${dateObject.getMonth()}/${dateObject.getFullYear()}`;
      const time = event.node.excerpt.split('\n')[1].trim();
      const admissionFee = event.node.excerpt.split('\n')[2].trim();

      return {
        ...event.node,
        dateObject,
        date,
        time,
        admissionFee,
        venue: event.node.twitter_description,
        featureImage: event.node.feature_image,
        eventExternalUrl: event.node.codeinjection_head,
      };
    })
    .sort((a, b) => {
      return a.dateObject - b.dateObject;
    });

export default parseGhostPagesToEvents;
