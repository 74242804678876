import parseStringToGatsbyLink from './parseStringToGatsbyLink';
import parseObjectToQueryParams from './parseObjectToQueryParams';
import parseTimeToDayName from './parseTimeToDayName';
import parseTimeToDayMonth from './parseTimeToDayMonth';
import parseGhostPagesToEvents from './parseGhostPagesToEvents';

export default {
  parseTimeToDayName,
  parseObjectToQueryParams,
  parseStringToGatsbyLink,
  parseTimeToDayMonth,
  parseGhostPagesToEvents,
};
