import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section`
  background-color: transparent;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    padding: 50px 0 80px 0;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: 50px 0px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding-bottom: 50px;
  }
  // #endregion
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  filter: brightness(60%);

  > * {
    width: 100%;
    height: 100%;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100vw;
    height: 400px;
    position: relative;
  }
  // #endregion
`;

const Title = styled.h1`
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  color: ${$.color.white};
  font-family: Aileron Heavy;
  font-size: 60px;
  text-shadow: 1px 1px ${$.color.black};

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 50px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 40px;
    word-break: break-word;
  }
  // #endregion
`;

const FeaturedImage = () => (
  <Section>
    <Container>
      <ImageContainer>
        <StaticImage
          src="../../../assets/images/awsaccelerator2021/aws-deepracer-img-4.jpg"
          alt="Join AWS Deepracer image banner"
        />
      </ImageContainer>
      <Title>Join the 2021 Programme</Title>
    </Container>
  </Section>
);

export default FeaturedImage;
