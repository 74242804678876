import Seo from '../../components/seo';
import ACACAD from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/ACACAD';

const ACACADPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <ACACAD />
  </>
);

export default ACACADPage;
