import { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Context } from '../context';
import BarActions from '../BarActions';
import {
  DropdownText,
  PillContainer,
  PillText,
  StyledRotatingDownChevron,
} from './DropdownStyles';

// Create a container for Pikaday. It does nothing, just need a container
// to bind the calendar to.
const CalendarContainer = styled.div.attrs({ className: 'calendar' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const CalendarDropdown = ({ text, name, picker }) => {
  const {
    searchBarData: { allDates },
    searchBarUIData,
    searchBarUIUpdate,
  } = useContext(Context);

  return (
    <DropdownText
      className={
        searchBarUIData.clicked === name ? 'all-dates active' : 'all-dates'
      }
    >
      <PillContainer
        onClick={() => {
          BarActions.handleOnClick({
            key: name,
            searchBarUIUpdate,
            clicked: searchBarUIData.clicked,
            picker,
          });
        }}
      >
        <PillText>
          {allDates !== '' ? BarActions.getStringDate(allDates) : text}
        </PillText>
        <StyledRotatingDownChevron
          counter={searchBarUIData.rotatingCounter.allDates}
        />
      </PillContainer>
      <CalendarContainer />
    </DropdownText>
  );
};

CalendarDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  picker: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default CalendarDropdown;
