/**
 * Converts unix time to string
 * @param {number} time - The unix time in integer
 * @returns {string}
 */
const parseTimeToDayMonth = time => {
  const date = new Date(time);

  return date.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    timeZone: 'Asia/Hong_Kong',
  });
};

export default parseTimeToDayMonth;
