import { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import $ from '../../../styles/global';

const Section = styled.section`
  ${({ index }) =>
    index % 2 !== 0 ? `background-color: ${$.color.blue1};` : ''}
  padding: ${$.layout().padding1}px 0;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding2}px 0;
  }
  // #endregion
`;

const Container = styled.div`
  display: flex;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    flex-direction: ${({ index }) => (index % 2 !== 0 ? 'row-reverse' : 'row')};
    justify-content: space-between;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column-reverse;
  }
  // #endregion
`;

const GoogleMaps = styled.div`
  width: calc(100% - 400px);

  > iframe {
    width: 100%;
    height: 100%;
  }

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    height: 370px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    height: 370px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
    > iframe {
      height: 40vw;
      max-height: 350px;
    }
  }
  // #endregion
`;

const CampusName = styled.div`
  font-family: Cooper Hewitt Semibold;
  color: ${$.color.orange3};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: ${$.layout().margin4}px;
  font-size: 20px;
  word-break: break-all;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 22px;
  }
  // #endregion
`;

const InfoText = styled.div`
  color: ${$.color.blue4};
  line-height: 1.4em;
  font-size: 18px;

  b {
    font-family: Lato Bold;
  }
`;

const LastInfoText = styled(InfoText)`
  margin-bottom: ${$.layout().margin3}px;
`;

const Info = styled.div`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-right: ${$.layout().margin4}px;
    width: 400px;
  }
  // #endregion
`;

const OperatingHours = styled.div`
  margin-top: ${$.layout().margin3}px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-top: ${$.layout().margin4}px;
  }
  // #endregion
`;

const OperatingHoursTitle = styled.div`
  font-family: Lato Bold;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin4}px;
  font-size: 18px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 20px;
  }
  // #endregion
`;

const PeriodTitle = styled.div`
  font-family: Lato Bold;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin4}px;
  font-size: 18px;
`;

const Address = styled.a`
  text-decoration: 2px underline ${$.color.blue4};
  color: ${$.color.blue4};
  font-family: Lato Bold;
  line-height: 1.4em;
  font-size: 19px;

  &:visited {
    color: ${$.color.blue4};
    text-decoration: 2px underline ${$.color.blue4};
  }
`;

const CampusLocation = ({
  address,
  name,
  googlemaps,
  peak,
  index,
  outgoingMaps,
}) => (
  <Section index={index}>
    <Fade bottom distance="100px">
      <Container index={index}>
        <Info>
          <CampusName
            dangerouslySetInnerHTML={{
              __html: name,
            }}
          />
          <Address
            href={outgoingMaps}
            target="_blank"
            rel="noopener noreferrer"
          >
            {address.map(({ value, key }) => (
              <Fragment key={key}>
                {value}
                <br />
              </Fragment>
            ))}
          </Address>
          {['Penang, Malaysia', 'Paya Lebar (Partner Location)'].indexOf(
            name
          ) === -1 && (
            <OperatingHours>
              <OperatingHoursTitle>Campus Operating Hours</OperatingHoursTitle>
              <PeriodTitle>
                Off-Peak Period (Jan - May &amp; Aug - Sep)
              </PeriodTitle>
              <LastInfoText>
                Mon - Sun: <b>By appointment only</b>
              </LastInfoText>
              <PeriodTitle>Peak Period (Jun - Jul &amp; Oct - Dec)</PeriodTitle>
              {peak.map(({ value, key }) => (
                <InfoText key={key}>{value}</InfoText>
              ))}
              <InfoText>
                Sat &amp; Sun: <b>By appointment only</b>
              </InfoText>
            </OperatingHours>
          )}
        </Info>
        <GoogleMaps>
          <iframe
            src={`${googlemaps}${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`}
            frameBorder="0"
            title={`${name}_map`}
          />
        </GoogleMaps>
      </Container>
    </Fade>
  </Section>
);

CampusLocation.defaultProps = {
  address: [],
  name: '',
  googlemaps: '',
  peak: [],
  outgoingMaps: '',
  index: 0,
};

CampusLocation.propTypes = {
  address: PropTypes.oneOfType([PropTypes.array]),
  name: PropTypes.string,
  googlemaps: PropTypes.string,
  outgoingMaps: PropTypes.string,
  peak: PropTypes.oneOfType([PropTypes.array]),
  index: PropTypes.number,
};

export default CampusLocation;
