import { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import PhoneIcon from '../../assets/icons/phone.svg';
import Button from '../Button';
import { Title, Subtitle } from './FooterStyles';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    grid-area: footerContacts;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    grid-area: footerContacts;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    order: 2;
  }
  // #endregion
`;

const Column = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;

    & > div:not(:last-child) {
      margin-bottom: ${$.layout().margin4}px;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;

    & > div:not(:last-child) {
      margin-bottom: ${$.layout().margin4}px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    color: ${$.color.blue2};

    & > div {
      margin-bottom: ${$.layout().margin3}px;
    }
  }
  // #endregion
`;

const DetailsContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    & > div:first-child {
      margin-right: ${$.layout().margin3}px;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    & > div:first-child {
      margin-right: ${$.layout().margin3}px;
    }
  }
  // #endregion
`;

const Details = styled.a`
  text-decoration: none;
  color: ${$.color.blue2};
  line-height: 1.4em;
  display: block;
  &:visited {
    color: ${$.color.blue2};
  }
`;

const MailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & svg {
    height: 15px;
    stroke-width: 0px;
    fill: ${$.color.blue2};
    margin-right: ${$.layout().margin4}px;
  }

  & a {
    text-decoration: none;
    color: ${$.color.blue2};
    font-family: Lato Bold;
  }

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    & svg {
      margin-right: ${$.layout().margin5}px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    & svg {
      height: 13px;
    }

    & a {
      color: ${$.color.white};
      font-family: Lato Regular;
    }
  }
  // #endregion
`;

const PhoneContainer = styled(MailContainer)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    align-items: flex-start;

    & svg {
      stroke-width: 1px;
      stroke: ${$.color.blue2};
      height: 18px;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    align-items: flex-start;

    & svg {
      stroke-width: 1px;
      stroke: ${$.color.blue2};
      height: 18px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    align-items: center;
    margin-bottom: 0px !important;

    & svg {
      height: 17px;
    }
  }
  // #endregion
`;

const PhoneDetailsContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    & a {
      margin-bottom: ${$.layout().margin3}px;
      display: block;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    & a {
      margin-bottom: ${$.layout().margin3}px;
      display: block;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    & > *:not(a) {
      display: none;
    }
  }
  // #endregion
`;

const StyledButton = styled(Button)`
  font-size: 16px;
`;

const Contacts = ({ campuses, phoneLineHours, phone }) => (
  <Container>
    <Title>Reach Us</Title>
    <DetailsContainer>
      <Column>
        {campuses.map(({ name, outgoingMaps, address, key }) => (
          <div key={key}>
            <Subtitle
              dangerouslySetInnerHTML={{
                __html: name,
              }}
            />
            <Details
              target="_blank"
              href={outgoingMaps}
              rel="noopener noreferrer"
            >
              {address.map(({ value, key: addressKey }, index) => (
                <Fragment key={addressKey}>
                  {value}
                  {index === address.length - 1 ? '' : <br />}
                </Fragment>
              ))}
            </Details>
          </div>
        ))}
      </Column>
      <Column>
        <MailContainer>
          <StyledButton
            onClick={() => {
              window.open(
                'mailto:enquiries@sgcodecampus.com?subject=Enquiry%20from%20Website'
              );
            }}
          >
            Enquire Now
          </StyledButton>
        </MailContainer>
        <PhoneContainer>
          <PhoneIcon />
          <PhoneDetailsContainer>
            <a href={`tel:${phone.replace(/-| /, '')}`}>{phone}</a>
            <Subtitle>Phone Line Hours</Subtitle>
            {phoneLineHours.map(({ value, key }) => (
              <div key={key}>{value}</div>
            ))}
          </PhoneDetailsContainer>
        </PhoneContainer>
      </Column>
    </DetailsContainer>
  </Container>
);

Contacts.propTypes = {
  campuses: PropTypes.oneOfType([PropTypes.array]).isRequired,
  phoneLineHours: PropTypes.oneOfType([PropTypes.array]).isRequired,
  phone: PropTypes.string.isRequired,
};

export default Contacts;
