import { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Utils from '../../../../utils';
import $ from '../../../../styles/global';
import FirstLayer from './FirstLayer';
import SecondLayer from './SecondLayer';

const SidebarGlobalCSS = createGlobalStyle`
  body {
    /* Prevent body from scrollable if any component width spill out */
    overflow-x: hidden;
    .layout-body {
      transform: translateX(0);
      transition: all 0.25s ${$.easingFn.standard};
    }
  }

  body.show-side-bar {
    /* Prevent body from scrollable when sidebar is open (As usual, only iPhone ignore this) */
    overflow-y: hidden;
    .layout-body {
      transform: translateX(-280px);
      /* Set all components in layout to stop listening to pointer events. Had to do this because the promo banner is at the top and I need the navbar to be excluded from this behaviour. */
      .layout-container, .layout-backtotop, .layout-promo, .layout-footer, .layout-hover-sign-up, .layout-deepracer-modal {
        pointer-events: none;
      }
    }
  }
`;

const Container = styled.div`
  width: 280px;
  right: -280px;
  height: 100vh;
  position: absolute;
  pointer-events: none;
  display: none;
  overflow: hidden;
  top: 0;
  border-left: 1px solid ${$.color.gray1};

  > * {
    padding-top: ${$.layout().padding2}px;
    height: 100%;
    width: calc(100% - ${$.layout().padding4 * 2}px);
    padding-left: ${$.layout().padding4}px;
    padding-right: ${$.layout().padding4}px;
    position: absolute;
    top: 0;
    &:last-child {
      background-color: ${$.color.white};
      pointer-events: none;
      transform: translateX(-300px);
      transition: transform 0.35s ease;
    }
  }

  &.show {
    display: block;
    pointer-events: initial;
  }

  &.show-second {
    > * {
      &:last-child {
        pointer-events: initial;
        transform: translateX(0);
      }
    }
  }
`;

/**
 * 1. Sidebar is hidden using display: none.
 * 2. User clicked on the burger menu icon.
 * 3. Set the sidebar container to display: block. The content of the sidebar is added here.
 * 4. 50ms later, the sidebar slides in and the layout also slides together to
 * the left.
 * 5. User clicks the burger menu icon again.
 * 6. Sidebar is immediately hidden by sliding to the right.
 * 7. 400ms later, the content of the sidebar is removed and sidebar container is set to display: none.
 */
const Sidebar = ({ open, setOpen }) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [preloadSidebar, setPreloadSidebar] = useState(false);
  const [showSecondLayer, setShowSecondLayer] = useState({
    state: false,
    index: 0,
  });

  /**
   * Controls timing of when to slide the sidebar in/out along with the timing
   * to populate the content of the sidebar.
   */
  useEffect(() => {
    let timeout;
    const cb = () => {
      if (Utils.isDesktop() === true) {
        setOpen(false);
      }
    };

    if (open) {
      setPreloadSidebar(true);
      setTimeout(() => {
        setToggleSidebar(true);
      }, 50);

      if (typeof window !== 'undefined') {
        window.addEventListener('resize', cb);
      }
    } else {
      setToggleSidebar(false);
      setTimeout(() => {
        setPreloadSidebar(false);
        setShowSecondLayer((prev) => ({
          state: false,
          index: prev.index,
        }));
      }, 400);
    }

    return () => {
      clearTimeout(timeout);
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', cb);
      }
    };
  }, [open]);

  return (
    <>
      <SidebarGlobalCSS />
      {toggleSidebar && (
        <Helmet
          bodyAttributes={{
            class: 'show-side-bar',
          }}
        />
      )}
      <Container
        className={`${preloadSidebar ? 'show' : ''}${
          showSecondLayer.state ? ' show-second' : ''
        }`}
      >
        <FirstLayer setShowSecondLayer={setShowSecondLayer} setOpen={setOpen} />
        <SecondLayer
          showSecondLayer={showSecondLayer}
          setShowSecondLayer={setShowSecondLayer}
          setOpen={setOpen}
        />
      </Container>
    </>
  );
};

Sidebar.defaultProps = {
  open: false,
  setOpen: () => {},
};

Sidebar.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
};

export default Sidebar;
