import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { RemoteLink, Margin20 } from '../Misc';
import TrainerProfile from '../TrainerProfile';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import InfoTable from '../SMUReusableComponents/InfoTable';
import FundingTable from '../SMUReusableComponents/FundingTable';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';
import Testimonial from '../Testimonial';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Topics',
  },
  {
    text: 'Who Should Attend',
    threshold: 0.1,
  },
  {
    text: 'Fees And Funding',
  },
  {
    text: 'Schedule',
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'An introduction to large scale data analysis with Cloud Computing by harnessing the power of virtual machines deployed on the Amazon Web Services cloud',
  },
  {
    text: 'Build a strong analytical mindset along with the technical skills to uncover, analyse and evaluate patterns from data ',
  },
  {
    text: 'Complete a practical capstone project to add to their portfolio to showcase their Python and Data Analysis competencies',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'Data science professionals',
  },
  {
    text: 'Managers looking to explore predictive modelling',
  },
  {
    text: 'Those interested in learning and applying advanced data analysis techniques',
  },
]);

const trainers = Utils.addKeys([
  Profiles.ian,
  Profiles.sunChang,
  Profiles.philipBeh,
]);

const applyNowLink =
  '/professional-training/apply-now/?c=Advanced Certificate in Applied Data Analytics';

const enquiryLink =
  'https://academy.smu.edu.sg/programme-enquiry?course=Advanced%20Certificate%20in%20Applied%20Data%20Analytics&nid=4541';

const ACADA = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="Advanced Certificate in Applied Data Analytics"
      />
      <MobileNavigationMenu
        items={navigationLinks}
        applyNowLink={applyNowLink}
        enquiryLink={enquiryLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              Learn to use a variety of data techniques drawn from the fields of
              machine learning, data visualisation, Big Data and many others to
              interpret large and complex datasets in an applied setting.
              Participants in this course will delve further in data analysis
              with prior knowledge in Python programming, and enhance their
              analytical skills.
            </p>
            <Margin20 />
            <InfoTable
              starts="12 Sep 2023 (Tue)"
              venue="Online"
              fees="SGD10368.00* (as low as SGD1190.40* after maximum funding)"
              duration={
                <>
                  {' '}
                  Please refer to respective modules for dates. <br />
                  Weeknights (7pm - 10:30pm)
                  <br />
                  Saturday (9am - 6:00pm)
                </>
              }
              level="Intermediate"
            />
            <h3>Learning Objectives</h3>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            <p>
              To achieve the Advanced Certificate in Applied Data Analytics,
              participants will need to complete the following modules offered
              by SMU Academy in sequential order:
            </p>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-applied-data-analytics-linear-models-practice-4511">
                Applying Data Analytics to Sales and Marketing through Linear
                Models
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-applied-data-analytics-advanced-methods-classification-and-regression-4516">
                Avoiding Pitfalls in Business Analysis through Model
                Specification
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-applied-data-analytics-web-scraping-and-text-mining-4521">
                Business Intelligence with Web Scraping and Text Mining
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-applied-data-analytics-advanced-data-visualisation-python-4526">
                Storytelling with Advanced Data Visualisations
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-applied-data-analytics-big-data-dask-4531">
                Understanding Big Data with Cloud Computing
              </RemoteLink>
              <br />
              <RemoteLink href="https://academy.smu.edu.sg/advanced-certificate-applied-data-analytics-capstone-project-applied-data-analytics-4536">
                Executing Projects in Applied Data Analytics
              </RemoteLink>
            </p>
            <p>
              This course is also a part of the{' '}
              <RemoteLink href="https://academy.smu.edu.sg/advanced-diploma-python-programming-and-applied-data-analytics-4731">
                Advanced Diploma in Python Programming and Applied Data
                Analytics
              </RemoteLink>
              .
            </p>
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
            <Margin20 />
            <Margin20 />
            <h3>Prerequisites</h3>
            <p>
              Experience in Python programming (equivalent to that attained in
              <RemoteLink href="https://academy.smu.edu.sg/professional-certificate-python-programming-3266">
                Professional Certificate in Python Programming programme
              </RemoteLink>
              )
            </p>
            <Margin20 />
            <h3>System Requirements</h3>
            <p>
              Functional Laptop: <br />
              (1) CPU must be of at least Intel Core I3
              <br />
              (2) GPU must have an integrated graphics card
              <br />
              (3) RAM must be of at least 4GB
            </p>
          </TextSection>
          <TextSection>
            <h2>Assessment</h2>
            <ul>
              <li>Classroom exercises</li>
              <li>Project presentations</li>
            </ul>
            <Margin20 />
            <Margin20 />
            <h3>Certification</h3>
            <p>
              Upon completion of all 6 modules within a maximum duration of 3
              years, participants will be awarded a digital Advanced Certificate
              in Applied Data Analytics.
            </p>
          </TextSection>
          <TextSection position={3}>
            <h2>Fees And Funding</h2>
            <FundingTable
              firstRow={[
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="http://www.ssg.gov.sg/programmes-and-initiatives/funding/enhanced-training-support-for-smes1.html">
                    ETSS Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$3,110.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
              ]}
              secondRow={[
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$1,190.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
              ]}
              thirdRow={[
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$10,368</strong>
                  <br />
                  (No Funding)
                </>,
              ]}
            />
          </TextSection>
          <TextSection position={4}>
            <h2>Schedule</h2>
            <p>
              <strong>Start Date(s)</strong>
              <br />
              12 Sep 2023 (Tue)
            </p>
            <p>
              <strong>Intake Information</strong>
            </p>
            <Table>
              <thead>
                <tr>
                  <td className="highlight2">Modules</td>
                  <td className="highlight2">Intake 8</td>
                  <td className="highlight2">Intake 9</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight1">
                    APPLYING DATA ANALYTICS TO SALES AND MARKETING THROUGH
                    LINEAR MODELS
                  </td>
                  <td>
                    9, 10 & 13 May 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      12, 13 & 16 Sep 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    AVOIDING PITFALLS IN BUSINESS ANALYSIS THROUGH MODEL
                    SPECIFICATION
                  </td>
                  <td>
                    6, 7 & 10 Jun 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      3, 4 & 7 Oct 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    BUSINESS INTELLIGENCE WITH WEB SCRAPING AND TEXT MINING
                  </td>
                  <td>
                    20, 21 & 24 Jun 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      24, 25 & 28 Oct 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    STORYTELLING WITH ADVANCED DATA VISUALISATIONS
                  </td>
                  <td>
                    11, 12 & 15 Jul 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      14, 15 & 18 Nov 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    UNDERSTANDING BIG DATA WITH CLOUD COMPUTING
                  </td>
                  <td>
                    1, 2 & 5 Aug 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      5, 6 & 9 Dec 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="highlight1">
                    EXECUTING PROJECTS IN APPLIED DATA ANALYTICS
                  </td>
                  <td>
                    22, 23 & 26 Aug 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                  <td>
                    <strong>
                      2, 3 & 6 Jan 2024
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection>
            <h2>Policies</h2>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                View programme policies.
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={5}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
          <TextSection>
            <h2>Testimonial</h2>
            <Testimonial
              name="Weilun Liang, William"
              title="Senior Technical Services Engineer, Singapore Airlines"
            >
              <h3>
                I appreciate how detailed and dedicated the lecturers, Dr Chang
                Sun, Bin Huang and Natasha Waliany are, and how
                beginner-friendly they have made the course to be.
              </h3>
              <p>
                It all started with my curiosity about the current data boom and
                wanting to keep up with the industry trends. Today, I am
                delighted to share that I have successfully achieved the
                Advanced Diploma in Applied Data Analytics (Python) from SMU
                Academy!
              </p>

              <p>
                Along with my Capstone Project mates, I enjoyed the process of
                using data analytics to analyse crime trends in Washington DC
                and the opportunity to explore different ensemble learning
                methods for classification, regression, and prediction during
                our training!
              </p>
            </Testimonial>
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default ACADA;
