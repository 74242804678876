import styled from 'styled-components';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import items from './items.json';

const Section = styled.section``;

const Container = styled.div``;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  & > *:not(:last-child) {
    margin-bottom: 120px;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Video = styled.iframe`
  display: block;
  border: none;
  background-color: ${$.color.black};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 720px;
    height: 400px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 720px;
    height: 400px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100vw;
    height: 300px;
  }
  // #endregion
`;

const VideoTitle = styled.h2`
  font-family: Aileron Heavy;
  font-size: 25px;
  margin-bottom: ${$.layout().margin4}px;
  text-transform: uppercase;
`;

const VideoDescription = styled.div`
  margin-bottom: ${$.layout().margin4}px;
`;

const videos = Utils.addKeys(items);

const Videos = () => (
  <Section>
    <Container>
      <List>
        {videos.map(({ key, src, title, description }) => (
          <VideoContainer>
            <VideoTitle>{title}</VideoTitle>
            {description && <VideoDescription>{description}</VideoDescription>}
            <Video src={src} key={key} title={title} />
          </VideoContainer>
        ))}
      </List>
    </Container>
  </Section>
);

export default Videos;
