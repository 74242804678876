import React, { useContext } from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';
import { Context } from './context';
import { Title, Body, OutboundLink } from './common';
import DoubleDownChevron from '../../assets/icons/double-down-chevron.svg';

const Container = styled.div`
  grid-area: courseOutline;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${({ theme }) => theme.layout().margin4}px;

  & > svg {
    align-self: center;
    width: 24px;
  }
`;

const OutlineContainer = styled.div`
  padding: ${({ theme }) => theme.layout().padding3}px;
  background-color: ${({ theme }) => theme.color.blue1};
  border-radius: ${({ theme }) => theme.border().radius2}px;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};

  & ${Body} {
    font-family: ${({ theme }) => theme.fontFamily.body.semiBold};
    margin-bottom: ${({ theme }) => theme.layout().margin3}px;
  }
`;

const Overview = () => {
  const { courseOutline } = useContext(Context);

  return (
    <Container id="course-outline">
      <Title>Course Outline</Title>
      {courseOutline.map(({ id, description, link, date }, index) => (
        <React.Fragment key={id}>
          <OutlineContainer>
            <Body>{description}</Body>
            <OutboundLink
              href={link}
              color={$.color.blue3}
              background={$.color.white}
            >
              {date}
            </OutboundLink>
          </OutlineContainer>
          {index === courseOutline.length - 1 ? null : <DoubleDownChevron />}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default Overview;
