import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  min-height: 500px;
  height: 700px;
  position: relative;
  overflow: hidden;
  background: ${$.color.white} 50%;

  &:before {
    content: '';
    background: url(${({ featureImage }) => featureImage});
    background-size: cover;
    background-position: center;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    border-radius: ${$.border().radius4}px;
    margin: 0 -10vw -165px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin: 0 -${$.layout().margin3}px -165px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    min-height: 200px;
    height: 400px;
    margin: 0 -${$.layout().margin3}px -100px;
  }
  // #endregion
`;

const PostBanner = ({ featureImage }) => (
  <Container featureImage={featureImage} />
);

PostBanner.defaultProps = {
  featureImage: '',
};

PostBanner.propTypes = {
  featureImage: PropTypes.string,
};

export default PostBanner;
