import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';
import SEO from '../components/seo';
import Banner from '../components/blog/post/Banner';
import ContentContainer from '../components/blog/post/ContentContainer';
import TaggedIn from '../components/blog/post/TaggedIn';
import SocialIcons from '../components/blog/post/SocialIcons';
import NavBar from '../components/blog/NavBar';

const Section = styled.section`
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Container = styled.div``;

const ProgressBar = styled(({ percentage, ...rest }) => {
  const width = percentage === 0 ? '0%' : `${percentage}%`;

  return <div {...rest} style={{ width }} />;
})`
  background-color: ${$.color.orange2};
  z-index: 2000;
  top: 0;
  position: fixed;
  height: 3px;
  user-select: none;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    height: 2px;
  }
  // #endregion
`;

const IndividualBlogPage = ({
  pageContext: {
    slug,
    title,
    html,
    excerpt,
    timeToRead,
    publishedAt,
    featureImage,
    primaryAuthor,
    metaTitle,
    metaDescription,
    keywords,
    tags,
  },
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const progress = () => {
      const windowHeight = document.body.scrollHeight;
      const scrollPosition = window.scrollY;

      setPercentage(
        (scrollPosition / (windowHeight - window.innerHeight)) * 100
      );
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', progress);
      window.addEventListener('resize', progress);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', progress);
        window.removeEventListener('resize', progress);
      }
    };
  }, []);

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={keywords}
      />
      <ProgressBar percentage={percentage} />
      <Section>
        <Container>
          <Banner featureImage={featureImage} />
          <NavBar path={slug} />
          <ContentContainer
            title={title}
            html={html}
            excerpt={excerpt}
            timeToRead={timeToRead}
            publishedAt={publishedAt}
            slug={slug}
            primaryAuthor={primaryAuthor}
            tags={tags}
          />
          <TaggedIn tags={tags}>Tagged in</TaggedIn>
          <SocialIcons slug={slug} />
        </Container>
      </Section>
    </>
  );
};

IndividualBlogPage.defaultProps = {};

IndividualBlogPage.propTypes = {
  pageContext: PropTypes.shape({
    keywords: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    html: PropTypes.string,
    excerpt: PropTypes.string,
    timeToRead: PropTypes.number,
    publishedAt: PropTypes.string,
    featureImage: PropTypes.string,
    primaryAuthor: PropTypes.objectOf(PropTypes.string),
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default IndividualBlogPage;
