import styled from 'styled-components';

import $ from '../../../../../styles/global';

const Title = styled.div`
  font-family: Aileron Heavy;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 30px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 23px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-top: ${$.layout().margin4}px;
    font-size: 24px;
  }
  // #endregion
`;

const Prize = styled.div`
  font-family: Aileron Heavy;
  margin-bottom: ${$.layout().margin5}px;
  font-size: 18px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 20px;
  }
  // #endregion
`;

const Students = styled.div`
  font-family: Lato Regular;
  font-size: 16px;
  margin-bottom: ${$.layout().margin5}px;
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  margin-bottom: ${$.layout().margin5}px;
  ${({ position, textColor }) => (position === 'left'
    ? `border-right: 13px solid ${textColor};`
    : `border-left: 13px solid ${textColor};`)}

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

export {
  Title, Prize, Students, Arrow,
};
