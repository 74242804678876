import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section`
  margin: ${$.layout().margin3}px 0;
`;

const Container = styled.div``;

const Logo = styled.div`
  width: 100%;
  margin: ${$.layout().margin2}px 0;
  > * {
    width: 200px;
    margin: 0 auto;
    display: block;
  }
`;

const AWSLogo = () => (
  <Section>
    <Container>
      <Logo>
        <StaticImage
          src="../../../assets/images/partners/aws/aws-partner-logo.png"
          alt="AWS Training Partner logo"
        />
      </Logo>
    </Container>
  </Section>
);

export default AWSLogo;
