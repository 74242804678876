import SEO from '../../components/seo';

import Banner from '../../components/Tertiary/Banner';
import IconCards from '../../components/Tertiary/IconCards';
import Attend from '../../components/Tertiary/Attend';
import Successes from '../../components/Tertiary/Successes';
import Begin from '../../components/Tertiary/Begin';

const TertiaryPage = () => (
  <>
    <SEO title="Tertiary | Get officially certified with tech giants" />
    <Banner />
    <IconCards />
    <Attend />
    <Successes />
    <Begin />
  </>
);

export default TertiaryPage;
