import styled from 'styled-components';

import Cards from './Cards';

const Section = styled.section``;

const Container = styled.div``;

const Policies = () => (
  <Section>
    <Container>
      <Cards />
    </Container>
  </Section>
);

export default Policies;
