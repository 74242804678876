import SEO from '../../components/seo';
import Navbar from '../../components/DatascienceBootcampAccelerator2021/Navbar';
import Background from '../../components/DatascienceBootcampAccelerator2021/Background';
import FeaturedImage from '../../components/DatascienceBootcampAccelerator2021/Join2021Programme/FeaturedImage';
import TwoSessions from '../../components/DatascienceBootcampAccelerator2021/Join2021Programme/TwoSessions';
import ApplicationDetails from '../../components/DatascienceBootcampAccelerator2021/Join2021Programme/ApplicationDetails';

const ProgrammeOutline = () => (
  <>
    <SEO
      title="Register for the Data Science Bootcamp and Accelerator 2021"
      description="Register for Info session for the Data Science Bootcamp and Accelerator 2021. Download and submit the application form."
      keywords={[
        'data science bootcamp registration',
        'aws accelerator registration',
        'data science secondary',
      ]}
    />
    <Navbar />
    <Background id="particles-1">
      <FeaturedImage />
    </Background>
    <Background id="particles-2">
      <TwoSessions />
    </Background>
    <Background id="particles-3">
      <ApplicationDetails />
    </Background>
  </>
);

export default ProgrammeOutline;
