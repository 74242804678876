import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import '../../utils/particles';
import $ from '../../styles/global';

const particlesParam = {
  particles: {
    number: {
      value: 120,
      density: {
        enable: true,
        value_area: 1000,
      },
    },
    color: {
      value: $.color.white,
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: $.color.white,
      },
      polygon: {
        nb_sides: 5,
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 8,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 250,
      color: $.color.white,
      opacity: 0.4,
      width: 2,
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 150,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};

const ParticleContainer = styled.div`
  height: 100%;
`;

const Particle = ({ children, id }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.particlesJS) {
      window.particlesJS(id, particlesParam);
    }

    return () => {
      if (
        typeof window !== 'undefined' &&
        window.pJSDom &&
        window.pJSDom.length > 0
      ) {
        window.pJSDom[0].pJS.fn.vendors.destroypJS();
        window.pJSDom = [];
      }
    };
  }, []);

  return <ParticleContainer id={id}>{children}</ParticleContainer>;
};

Particle.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
};

Particle.defaultProps = {
  children: null,
};

export default Particle;
