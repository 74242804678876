import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from './Card';
import $ from '../../styles/global';

const Container = styled.div``;

const InnerContainer = styled.div`
  opacity: 0;
  transform: translateY(2em);
  transition: opacity 0.35s ease-in-out, transform 0.3s ease;
  will-change: opacity, transform;

  &.fade {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CourseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 25px;
  width: 100%;
  margin-bottom: 20px;
  height: 100%;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
  // #endregion
`;

const Courses = ({ items, selected }) => {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [triggerAnimation, setTriggerAnimation] = useState(true);

  useEffect(() => {
    setTriggerAnimation((prev) => !prev);
    setTimeout(() => {
      setSelectedCourses(
        () => items.filter(({ value }) => value === selected)[0].courses
      );
    }, 380);
    setTimeout(() => {
      setTriggerAnimation((prev) => !prev);
    }, 420);
  }, [selected]);

  return (
    <Container>
      <InnerContainer className={triggerAnimation ? 'fade' : ''}>
        <CourseContainer className="show">
          {selectedCourses.map(
            (
              { buttonMessage, link, image, title, desc, level, venue, fee },
              selectedCoursesIndex
            ) => (
              <Card
                key={`${selected}_card_${selectedCoursesIndex}`}
                buttonMessage={buttonMessage}
                link={link}
                level={level}
                title={title}
                desc={desc}
                venue={venue}
                image={image}
                fee={fee}
              />
            )
          )}
        </CourseContainer>
      </InnerContainer>
    </Container>
  );
};

Courses.propTypes = {
  selected: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.element,
      key: PropTypes.string,
      desc: PropTypes.string,
      buttonMessage: PropTypes.string,
      link: PropTypes.string,
      venue: PropTypes.string,
      level: PropTypes.string,
      fee: PropTypes.string,
    })
  ),
};

Courses.defaultProps = {
  items: [],
  selected: 'python',
};

export default Courses;
