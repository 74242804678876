import { memo, useState, useContext } from 'react';
import styled from 'styled-components';

import $ from '../../../../styles/global';
import context from '../context';
import PopUp from './PopUp';
import DownChevron from '../../../../assets/icons/down-chevron.svg';

const Container = styled.div`
  color: ${$.color.blue6};
  font-size: 12px;
  & svg {
    stroke: ${$.color.blue6};
    fill: ${$.color.blue6};
    margin-left: ${$.layout().margin5}px;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: inline-block;
    position: relative;
    font-family: Lato Regular;
    line-height: 18px;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;

    & * {
      user-select: none;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    flex-basis: 100px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    flex-basis: 100px;
  }
  // #endregion
`;

const StyledDownChevron = styled(DownChevron)`
  height: 6px;
`;

const FullScheduleLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid ${$.color.blue6};
  justify-content: space-between;
  > span {
    width: 100%;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    justify-content: flex-start;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    position: relative;
    right: calc(
      ${({ filteredData: { eventIndex }, filteredDataLength }) =>
        filteredDataLength % 2 && eventIndex === filteredDataLength - 1
          ? `( 100vw - ${$.layout().margin3 * 2}px ) / 2 + ${
              $.layout().margin3
            }px`
          : '0px'}
    );
  }
  // #endregion
`;

const FullSchedule = memo(() => {
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const { filteredData, filteredDataLength } = useContext(context);

  return (
    <Container>
      <FullScheduleLink
        onClick={() => {
          setPopUpVisibility(!popUpVisibility);
        }}
        filteredData={filteredData}
        filteredDataLength={filteredDataLength}
      >
        <span>Full Schedule</span>
        <StyledDownChevron />
      </FullScheduleLink>
      <PopUp
        popUpVisibility={popUpVisibility}
        setPopUpVisibility={setPopUpVisibility}
      />
    </Container>
  );
});

export default FullSchedule;
