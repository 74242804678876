import styled from 'styled-components';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import HuiLiangImage from '../../../assets/images/tertiary/aws/trainer-huiliang.svg';
import EricImage from '../../../assets/images/tertiary/aws/trainer-eric.svg';
import YongYangImage from '../../../assets/images/tertiary/aws/trainer-yongyang.svg';
// import SGCCLogo from '../../../assets/images/tertiary/sgcc-logo.svg';
// import AWSPartnerLogo from '../../../assets/images/tertiary/aws-partner-logo.svg';
import AWSAuthorisedInstructor from '../../../assets/images/tertiary/aws-authorized-instructor.svg';
import AWSCertifiedSolutionsArchitect from '../../../assets/images/tertiary/aws-solutions-architect-associate.svg';
import AWSSysOps from '../../../assets/images/tertiary/aws-sysops-administrator-associate.svg';

const Section = styled.section`
  background-color: ${$.color.blue5};
`;

const Container = styled.div`
  padding: 100px 0;
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const Card = styled.div`
  text-align: center;
  width: 32%;

  & svg {
    width: 200px;
    position: relative;
    z-index: 1;
    margin-bottom: ${$.layout().margin4}px;
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const TrainerName = styled.div`
  font-size: 20px;
  font-family: Lato Bold;
  color: ${$.color.black};
  margin-bottom: ${$.layout().margin4}px;
`;

const Qualification = styled.div`
  font-size: 20px;
  font-family: Lato Bold;
  color: ${$.color.black};
  opacity: 0.6;
  margin-bottom: ${$.layout().margin5}px;
`;

const AWSLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${$.layout().margin3}px;

  & > svg {
    width: 100px;
    &:first-child {
      margin-right: ${$.layout().margin5}px;
    }
    &.resize-svg {
      width: 88px;
    }
  }
`;

// const SGCCLogoContainer = styled.div`
//   margin-bottom: ${$.layout().margin3}px;

//   & > svg {
//     width: 80px;
//   }
// `;

// const AWSPartnerLogoContainer = styled.div`
//   margin-top: ${$.layout().margin2}px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-end;

//   & > svg {
//     width: 100px;
//   }
// `;

const items = Utils.addKeys([
  {
    name: 'Hui Liang',
    img: <HuiLiangImage />,
    qualifications: Utils.addKeys([
      {
        value: 'Authorised AWS Instructor',
      },
      {
        value: 'AWS Certified Solutions Architect',
      },
    ]),
    certs: [<AWSCertifiedSolutionsArchitect />, <AWSAuthorisedInstructor />],
  },
  {
    name: 'Eric',
    img: <EricImage />,
    qualifications: Utils.addKeys([
      {
        value: 'Authorised AWS Instructor',
      },
      {
        value: 'AWS Certified Solutions Architect',
      },
    ]),
    certs: [<AWSCertifiedSolutionsArchitect />, <AWSAuthorisedInstructor />],
  },
  {
    name: 'Yong Yang',
    img: <YongYangImage />,
    qualifications: Utils.addKeys([
      {
        value: 'AWS Certified Solutions Architect, SysOps Associate',
      },
    ]),
    certs: [
      <AWSSysOps className="resize-svg" />,
      <AWSCertifiedSolutionsArchitect />,
    ],
  },
]);

const Trainers = () => (
  <Section>
    <Container>
      <Title>Trainers</Title>
      <List>
        {items.map(({ name, img, qualifications, certs, key }) => (
          <Card key={key}>
            {img}
            <TrainerName>{name}</TrainerName>
            {qualifications.map(({ key: qKey, value }) => (
              <Qualification key={qKey}>{value}</Qualification>
            ))}
            <AWSLogos>
              {certs[0]}
              {certs[1]}
            </AWSLogos>
          </Card>
        ))}
      </List>
    </Container>
  </Section>
);

export default Trainers;
