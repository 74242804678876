import styled from 'styled-components';
import shortid from 'shortid';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';

import $ from '../../styles/global';
import SignOut from './SignOut';
import LoginForm from './LoginForm';
import items from './items.json';

const Section = styled.section``;

const Container = styled.div`
  padding-top: 32px;
`;

const VideoLinks = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: ${$.layout().margin3}px;
  border: 1px solid ${$.color.gray1};

  & > * {
    padding: ${$.layout().padding5}px;
    text-decoration: none;
    color: ${$.color.blue5};
    font-family: Lato Bold;
    border-bottom: 1px solid ${$.color.gray1};

    &:last-child {
      border-bottom: none;
    }

    &:visited {
      color: ${$.color.blue5};
    }
  }
`;

const videosData = items.map((ele) => ({
  ...ele,
  key: shortid.generate(),
}));

const Videos = () => {
  const videoLoginStatus = useSelector((state) => state.videoLoginStatus);

  return (
    <Section>
      <Container>
        {videoLoginStatus.loggedIn ? (
          <>
            <SignOut />
            <VideoLinks>
              {videosData.map(({ title, link, key }) => (
                <Link key={key} title={title} to={link}>
                  {title}
                </Link>
              ))}
            </VideoLinks>
          </>
        ) : (
          <LoginForm />
        )}
      </Container>
    </Section>
  );
};

export default Videos;
