import { Fragment, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import Bubble from './Bubble';
import Dropdown from './Dropdown';

const Container = styled.div`
  width: 100%;
  margin-top: ${$.layout().margin2}px;
  display: grid;
  grid-template-columns: repeat(
    3,
    calc((970px - ${$.layout().margin3 * 2}px) / 3)
  );
  grid-template-areas: ${({ templateAreas: { bubbles, dropdowns } }) => {
    let str = '';

    bubbles.forEach((bubble, index) => {
      str += `${bubble} ${dropdowns[index]} `;
    });

    return str;
  }};

  grid-column-gap: ${$.layout().margin3}px;
  grid-row-gap: ${$.layout().margin3}px;
  align-items: stretch;
`;

const GridView = ({ data, className, expandProfileFirst }) => {
  const [currentBubble, setCurrentBubble] = useState(
    expandProfileFirst
      ? data.reduce(
          (prev, { bubble: { anchor } }, index) =>
            anchor === expandProfileFirst ? index : prev,
          null
        )
      : null
  );
  const [dropdownState, setDropdownState] = useState(!!expandProfileFirst);
  const leftOver = data.length % 3;

  const getGridTemplateAreas = () => {
    const totalRows = (data.length - leftOver) / 3;
    const bubbles = [];
    const dropdowns = [];
    let totalBubbles = [];
    let i = 0;

    // Creates profile x 3 in an array cell.
    // Add . for empty profiles.
    data.forEach((_, index) => {
      totalBubbles.push(`bubble_${index}`);

      if (data.length - 1 === index || totalBubbles.length === 3) {
        bubbles.push(
          `"${totalBubbles[0]} ${totalBubbles[1] || '.'} ${
            totalBubbles[2] || '.'
          }"`
        );
        totalBubbles = [];
      }
    });

    // Creates description x 3 in an array cell.
    for (i = 0; i <= totalRows; i += 1) {
      dropdowns.push(`"dropdown_${i} dropdown_${i} dropdown_${i}"`);

      // If we have a row that is less than 3, tack on as an extra row.
      if (i === totalRows - 1 && leftOver > 0) {
        dropdowns.push(
          `"dropdown_${totalRows} dropdown_${totalRows} dropdown_${totalRows}"`
        );
      }
    }

    return { bubbles, dropdowns };
  };

  return (
    <Container
      className={`desktop-gridview ${className}`}
      data={data}
      templateAreas={getGridTemplateAreas()}
    >
      {data.map(({ key, bubble }, index) => {
        // Add the <dropdown> as the fourth element.
        // However, if we have a row that has less than 3 elements, tack on
        // the <desc> as soon as we reach the end of the loop.
        const multipleOf3s = (index + 1) % 3 === 0 && index !== 0;
        const leftOverOf3s = leftOver > 0 && index === data.length - 1;
        const addDropdown = multipleOf3s || leftOverOf3s;

        return (
          <Fragment key={key}>
            <Bubble
              bubble={bubble}
              index={index}
              currentBubble={currentBubble}
              setCurrentBubble={setCurrentBubble}
              dropdownState={dropdownState}
              setDropdownState={setDropdownState}
            />
            {addDropdown && (
              <Dropdown
                dropdown={
                  data[currentBubble === null ? 0 : currentBubble].dropdown
                }
                row={Math.floor(index / 3)}
                currentBubble={currentBubble}
                dropdownState={dropdownState}
                setDropdownState={setDropdownState}
                anchor={bubble.anchor}
              />
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

GridView.defaultProps = {
  data: [],
  className: '',
};

GridView.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  className: PropTypes.string,
  expandProfileFirst: PropTypes.string.isRequired,
};

export default GridView;
