import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  description,
  lang,
  meta,
  keywords,
  title,
  location,
  jsonLD,
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
      sitePage {
        path
      }
    }
  `);
  const metaDescription = description || siteMetadata.description;
  const metaTitle = title || siteMetadata.title;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:title',
            content: metaTitle,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: location.origin + location.pathname,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: siteMetadata.author,
          },
          {
            name: 'twitter:title',
            content: metaTitle,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: 'keywords',
                  content: keywords.join(', '),
                }
              : []
          )
          .concat(meta)}
      >
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "SG Code Campus",
            "url": "https://www.sgcodecampus.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "EducationalOrganization",
            "name": "SG Code Campus",
            "alternateName": "SG Code Campus",
            "url": "https://www.sgcodecampus.com",
            "logo": "",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+65 3129-4406",
              "contactType": "customer service",
              "areaServed": "SG"
            },
            "sameAs": [
              "https://www.facebook.com/sgcodecampus/",
              "https://www.instagram.com/sgcodecampus/",
              "https://twitter.com/sgcodecampus/"
            ]
          }
          `}
        </script>
        {jsonLD ? <script type="application/ld+json">{jsonLD}</script> : ''}
      </Helmet>
      <img
        height="1"
        width="1"
        style={{
          display: 'none',
        }}
        src="https://www.facebook.com/tr?id=1654575968194699&ev=PageView&noscript=1"
        alt=""
      />
    </>
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: '',
  location: {},
  title: '',
  jsonLD: '',
};

SEO.propTypes = {
  jsonLD: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object])
  ),
};

export default SEO;
