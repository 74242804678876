import { useState, useEffect, Fragment, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import OrangeButton from '../ProfessionalTraining/OrangeButton';

const Column2 = styled.div`
  width: calc(30% - 10px);
  padding: 30px 0 30px 10px;
  position: sticky;
  top: 80px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const MovingCircle = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  transition: all 0.3s ease;
  top: 0;
  left: calc(10% - 10px);
  background: radial-gradient(
    152.64% 127.74% at 25.6% 78.1%,
    #457b8c 44.99%,
    #6c9ea8 96.49%
  );
  transition: all 0.3s ease;
  z-index: 1;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  ${({ total }) => {
    let str = '';

    Array.from({ length: total }).forEach((_, index) => {
      str += `
      &.c${index} {
        top: ${index * 45}px;
      }
    `;
    });

    return str;
  }}
`;

const JourneyMenuContainer = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: auto;
  grid-gap: 0;
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 999px;
  background: radial-gradient(
    152.64% 127.74% at 25.6% 78.1%,
    #457b8c 44.99%,
    #6c9ea8 96.49%
  );
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.3s ease;

  & > * {
    border-radius: 999px;
    background-color: ${$.color.white};
    width: 10px;
    height: 10px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const SectionName = styled.div`
  font-family: Lato Regular;
  font-size: 16px;
  line-height: 18px;
  color: #457b8c;

  &.enlarge {
    font-family: Lato Bold;
  }
`;

const Line = styled.div`
  width: 4px;
  height: 25px;
  background-color: #457b8c;
  margin: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30% 10px 20%;

  & > a {
    &:first-child {
      text-transform: uppercase;
      margin-bottom: 15px;
      min-width: calc(145px - 60px);

      & > div {
        display: block;
        text-align: center;
      }
    }
    &:last-child {
      display: block;
      color: ${$.proTraining.orange2};
      font-family: Lato Bold;
      font-size: 14px;
      &:visited {
        color: ${$.proTraining.orange2};
      }
    }
  }
`;

const DesktopNavigationMenu = ({
  items,
  applyNowLink,
  enquiryLink,
  disableApplyNowButton,
}) => {
  const [position, setPosition] = useState(0);
  const scrollingRef = useRef(false);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const onIntersection = (entries) => {
        entries.forEach((entry) => {
          const entryId = entry.target.id;

          if (
            entry.intersectionRatio > 0 &&
            entryId &&
            entry.isIntersecting &&
            scrollingRef.current === false
          ) {
            setPosition(Number(entryId));
          }
        });
      };

      // Use the observer to observe an element
      document.querySelectorAll('.textSection').forEach((node, index) => {
        const observer = new IntersectionObserver(onIntersection, {
          root: null,
          rootMargin: '0px',
          threshold:
            items.filter((_, itemIndex) => itemIndex === index)[0]?.threshold ||
            0.2,
        });

        observer.observe(node);
      });
    }
  }, []);

  return (
    <Column2>
      <JourneyMenuContainer>
        <MovingCircle total={items.length} className={`c${position}`} />
        <InnerContainer>
          {items.map(({ text, key }, index) => (
            <Fragment key={key}>
              <Circle
                onClick={() => {
                  if (typeof document === 'undefined') {
                    return;
                  }

                  const element = document.getElementById(index);
                  const headerOffset = 70;
                  const elementPosition = element.getBoundingClientRect().top;
                  const offsetPosition =
                    elementPosition + window.scrollY - headerOffset;

                  scrollingRef.current = true;
                  setPosition(index);
                  window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                  });
                  setTimeout(() => {
                    scrollingRef.current = false;
                  }, 1000);
                }}
              >
                <div />
              </Circle>
              <SectionName className={position === index ? 'enlarge' : ''}>
                {text}
              </SectionName>
              {items.length > index + 1 && (
                <>
                  <Line />
                  <div />
                </>
              )}
            </Fragment>
          ))}
        </InnerContainer>
        <ButtonContainer>
          <OrangeButton
            text={disableApplyNowButton ? 'TBA' : 'Apply Now'}
            link={applyNowLink}
            disabled={disableApplyNowButton}
          />
          <a href={enquiryLink} target="_blank" rel="noopener noreferrer">
            Send Enquiry
          </a>
        </ButtonContainer>
      </JourneyMenuContainer>
    </Column2>
  );
};

DesktopNavigationMenu.defaultProps = {
  applyNowLink: '',
  enquiryLink: '',
  disableApplyNowButton: false,
};

DesktopNavigationMenu.propTypes = {
  disableApplyNowButton: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      anchor: PropTypes.string,
    })
  ).isRequired,
  applyNowLink: PropTypes.string,
  enquiryLink: PropTypes.string,
};

export default DesktopNavigationMenu;
