import { useContext } from 'react';

import { Context } from './context';
import CourseCard from './CourseCard';

const CourseCardWithContext = () => {
  const {
    title,
    coursePartnerLink,
    courseCard: {
      image,
      nextStartDate: { upcomingModule, date, day },
      modulesInformation,
      courseLevel,
      feesInformation,
    },
  } = useContext(Context);

  return (
    <CourseCard
      title={title}
      coursePartnerLink={coursePartnerLink}
      image={image}
      upcomingModule={upcomingModule}
      date={date}
      day={day}
      modulesInformation={modulesInformation}
      courseLevel={courseLevel}
      feesInformation={feesInformation}
    />
  );
};

export default CourseCardWithContext;
