import styled from 'styled-components';

import $ from '../styles/global';
import Button from './Button';
import SingaporeCorona from '../assets/images/advisory/singapore-border-corona.png';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout().padding2}px 0;
`;

const Message = styled.div`
  color: ${$.color.blue4};
  line-height: 1.5em;
  font-size: 17px;
  margin-bottom: ${$.layout().margin3}px;

  strong,
  b {
    font-family: Lato Bold;
  }

  em {
    font-style: italics;
  }

  ${$.br}

  ol,
  ul {
    padding-left: ${$.layout().padding4}px;
    li {
      margin-bottom: ${$.layout().margin5}px;
    }
  }

  a {
    text-decoration: none;
    font-family: Lato Bold;
    color: ${$.color.blue5};
    &:visited {
      color: ${$.color.blue5};
    }
  }
`;

const BoldenMessage = styled(Message)`
  font-family: Lato Bold;
`;

const ExtraContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > *:first-child {
    margin-bottom: ${$.layout().margin3}px;
  }
`;

const StyledLink = styled.a`
  display: inline-block;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 350px;
  text-decoration: none;
  margin-bottom: ${$.layout().margin4}px;
  > button {
    width: 100%;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  border: 1px solid ${$.color.gray1};
  max-width: 550px;
  margin-bottom: ${$.layout().margin3}px;
`;

const Title = styled.h2`
  color: ${$.color.blue4};
  font-family: Aileron Heavy;
  margin-bottom: ${$.layout().margin3}px;
`;

const travelDeclarationForm =
  'https://docs.google.com/forms/d/e/1FAIpQLSf0QPLhMBA2H4o4t7AQKgswctESYz73_K-SEg5ZujOeAGmLtQ/viewform?usp=send_form';

const CoronaAdvisory = () => (
  <Section>
    <Container>
      <Title>DORSCON Orange and what that means for SG Code Campus</Title>
      <Message>Dear Parents,</Message>
      <Message>
        As you may know, Singapore has just raised its Disease Outbreak Response
        System Condition (DORSCON) level from yellow to orange on Friday (Feb
        7). More details are available from this{' '}
        <a
          href="https://www.channelnewsasia.com/news/singapore/wuhan-coronavirus-dorscon-orange-singapore-risk-assessment-12405180"
          target="_blank"
          rel="noopener noreferrer"
        >
          Channel News Asia Article
        </a>
        .
      </Message>
      <Message>
        Lessons at SG Code Campus will proceed as usual, and our team continues
        to remain extra vigilant to keep the Campus safe and healthy for
        everyone. Our precautionary measures include temperature taking and
        compulsory hand sanitisation prior to entering class. We have also taken
        the extra step to wipe down all campus laptops after each class with
        industry-grade and child-friendly sanitizers. You can also read{' '}
        <a
          href="https://www.healthhub.sg/live-healthy/1204/when-a-mask-is-a-must"
          target="_blank"
          rel="noopener noreferrer"
        >
          this useful article
        </a>{' '}
        for guidelines by Health Promotion Board Singapore on when to wear a
        mask.
      </Message>
      <Message>
        <strong>Safety measures that we have put in place:</strong>
      </Message>
      <Message>
        <ol>
          <li>
            Temperature taking - we will take the temperature of every student,
            parent and staff entering our Campuses. Anyone with fever or
            flu-like symptoms will be refused entry, with parents and/or
            guardians being informed as necessary.{' '}
            <em>
              (NEW) Our staff will now take their temperature twice a day to
              comply with DORSCON Orange.
            </em>
          </li>
          <li>
            Hand Sanitising - All students and staff will be asked to sanitise
            their hands upon entering our Campuses.
          </li>
          <li>
            Equipment cleaning - Our laptops and classroom equipment will be
            cleaned after every class. Parents, do note that this might cause
            slight delays in the start and end times of lessons.
          </li>
          <li>
            Travel Declaration - We will be sending out an online travel
            declaration form to all our current weekly students and kindly
            request for the form to be completed as soon as possible. You can
            also use this{' '}
            <a
              href={travelDeclarationForm}
              target="_blank"
              rel="noopener noreferrer"
            >
              link
            </a>{' '}
            or the button below to access the form.
          </li>
          <li>
            Leave of Absence - Students and staff who have returned from
            mainland China on 15 Jan or later are kindly requested to take a
            Leave of Absence (LOA) from SGCC for a period of{' '}
            <strong>14 days</strong>, starting from the first day after return.
            For example, if you return to Singapore on 28 Jan (Tue), the LOA
            would last from 29 Jan to 11 Feb, with the date of return to SGCC
            being 12 Feb (Wed), assuming you remain well and symptom-free for
            the entire period. Once again, this precaution is to ensure our
            shared environment remains a healthy and hygienic place for everyone
            concerned.
          </li>
        </ol>
      </Message>
      <Message>
        <strong>Terms and policies for affected students:</strong>
      </Message>
      <Message>
        <ul>
          <li>
            Should your child be placed on a leave of absence or quarantined,
            students will be provided with a weblink to watch a video recording
            of the lesson that they missed or have an online make-up lesson
            arranged for them. Please reach out to us at{' '}
            <a href="mailto:enquiries@sgcodecampus.com">
              enquiries@sgcodecampus.com
            </a>{' '}
            to arrange the make-up.
          </li>
          <li>
            In the event that classes of on-going weekly courses or camps are
            cancelled, or if your child is quarantined for an extended period of
            time, a prorated credit note will be issued to you.
          </li>
          <li>
            In the event that courses that have not yet started are cancelled, a
            full cash refund will be provided to you.
          </li>
        </ul>
      </Message>
      <Message>
        Once again, we thank all parents and students for your continued
        cooperation and for practising good personal hygiene and social
        responsibility. If your children are feeling unwell, we advise you to
        bring them to a doctor and we would appreciate if they remained at home
        until they recover.
      </Message>
      <BoldenMessage>
        {
          "Note: From now till 30 April, SG Code Campus will be conducting ALL classes online as per the government's mandate to suspend all centre-based classes. For more information, please visit our "
        }
        <a href="/online-classes/">Online Classes webpage.</a>
      </BoldenMessage>
      <Message>
        Take care and stay safe,
        <br />
        The SG Code Campus Team
      </Message>
      <ExtraContent>
        <StyledLink
          href={travelDeclarationForm}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>Travel Declaration Form</Button>
        </StyledLink>
        <StyledImg
          src={SingaporeCorona}
          alt="Singapore's border control measures against COVID-19"
        />
      </ExtraContent>
    </Container>
  </Section>
);

export default CoronaAdvisory;
