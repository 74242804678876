import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import SubHeader from './SubHeader';
import MainInformation from './MainInformation';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc((100% - ${$.layout().padding2}px) * (1 / 8));
  }
  // #endregion
`;

const CourseLocation = memo(({ courseLocation }) => (
  <Container>
    <SubHeader>Location</SubHeader>
    <MainInformation>
      <span>{courseLocation}</span>
    </MainInformation>
  </Container>
));

CourseLocation.propTypes = {
  courseLocation: PropTypes.string.isRequired,
};

export default CourseLocation;
