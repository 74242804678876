import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import Dropdown from './Dropdown';

const Container = styled.div`
  grid-area: whatWillYouLearn;
`;

const ListContainer = styled.div``;

const List = styled.ul``;

const ListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.layout().margin5}px;
`;

const WhatWillYouLearn = () => {
  const { whatWillYouLearn } = useContext(Context);

  return (
    <Container>
      <Dropdown
        title="What Will You Learn?"
        contentLength={whatWillYouLearn.length * 1.8}
      >
        <ListContainer>
          <List>
            {whatWillYouLearn.map(({ id, name }) => (
              <ListItem key={id}>{name}</ListItem>
            ))}
          </List>
        </ListContainer>
      </Dropdown>
    </Container>
  );
};

export default WhatWillYouLearn;
