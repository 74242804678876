import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../../styles/global';

const Section = styled.section`
  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue3};
  font-size: 30px;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    font-size: 40px;
    text-align: center;
  }
  // #endregion
`;

const Subtitle = styled.div`
  font-family: Lato Regular;
  color: ${$.color.blue5};
  word-wrap: normal;
  line-height: 1.4em;
  hyphens: auto;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
  }
  // #endregion
`;

const ConceptualUnderstanding = () => (
  <Section>
    <Fade bottom cascade fraction={0.1} distance="100px">
      <Title>Conceptual Understanding is Key to Thinking Like a Coder</Title>
      <Subtitle>
        <p>
          We want kids to learn not just one programming language or two, but
          core concepts that cut across programming languages and that are
          universal to the way a coder needs to think. We believe that
          understanding the core concepts are more valuable in the long-run
          because the usefulness of a specific programming language is sometimes
          short-lived and/or limited by the problems its creators wanted to
          solve.
        </p>
        <p>
          This is why each of our introductory courses in the Basics and
          Principles roadmap, and the start of every lesson thereafter, will
          continue to focus primarily on computer science concepts before diving
          into syntax (i.e. the grammatical rules of a programming language).
          Introductory courses will be concept-heavy but light on
          build-experience so that Code Campers have a strong conceptual
          foundation to build on. Follow-on courses, conversely, will be
          build-heavy.
        </p>
      </Subtitle>
    </Fade>
  </Section>
);

export default ConceptualUnderstanding;
