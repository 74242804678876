/*eslint-disable */

import smoothscroll from 'smoothscroll-polyfill';

import Root from './src/components/Root';
import Layout from './src/components/Layout';
import './src/styles/reset.css';
import './src/styles/global.css';
import './src/styles/fonts.css';

/**
 * Polyfill for smooth scrolling in mobile
 */
smoothscroll.polyfill();

const wrapRootElement = Root;

/**
 * This is used for adding the Layout component to wrap each page component.
 * Showing Back to top is also determined here.
 */
const wrapPageElement = ({ element: page, props }) => {
  const {
    location: { pathname, hostname },
  } = props;
  const showBackToTop = () => {
    const urls = ['schedule', 'blog'];
    let show = false;

    urls.forEach((url) => {
      if (show === false && pathname.indexOf(url) > -1) {
        show = true;
      }
    });

    return show;
  };

  return (
    <Layout
      location={pathname}
      hostname={hostname}
      showBackToTop={showBackToTop()}
    >
      {page}
    </Layout>
  );
};

/**
 * Runs whenever users move from one page to another.
 */
const onRouteUpdate = ({ location: { pathname = '' } }) => {
  const allEventIds = [];
  const repeatedIds = {};
  const weirdSession = {};
  let expiredCourses = [];
  let eventList = '';
  const {
    siteMetadata: { env },
  } = require('./gatsby-config.js');
  const currDate = Date.now();

  /**
   * Run only during dev mode. Shows expired courses in schedule page.
   */
  if (env === 'development' && pathname.indexOf('/schedule') > -1) {
    eventList = require('./src/masterCourseEventList.json');
    console.info('Checking for duplicate ids...');
    console.info('Checking for weird num of sessions...');

    eventList.forEach(({ events, courseTitle }) => {
      events.forEach(
        ({ url, sessionNumOfDays, dates: { startDate, endDate } }) => {
          let eventId = url.match(/\d+$/g);

          if (eventId !== null) {
            eventId = eventId[0];

            if (sessionNumOfDays > 8) {
              weirdSession[eventId] = sessionNumOfDays;
            }

            if (
              currDate >= startDate ||
              currDate >= startDate - 1000 * 60 * 60 * 24 * 2
            ) {
              expiredCourses.push({
                start: new Date(startDate).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                }),
                end: new Date(endDate).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                }),
                id: eventId,
              });
            }

            if (allEventIds.indexOf(eventId) === -1) {
              allEventIds.push(eventId);
            } else {
              repeatedIds[courseTitle] = eventId;
            }
          }
        }
      );
    });

    if (Object.keys(repeatedIds).length > 0) {
      console.group('Found duplicates');
      console.table(repeatedIds);
      console.groupEnd('Found duplicates');
    } else {
      console.info('No duplicates found.');
    }

    if (Object.keys(weirdSession).length > 0) {
      ``;
      console.group('Found weird session days');
      console.table(weirdSession);
      console.groupEnd('Found weird session days');
    } else {
      console.info('No weird session days found');
    }

    if (expiredCourses.length > 0) {
      console.group();
      console.log('Found expired courses');
      expiredCourses.forEach(({ end, start, id }) => {
        console.log({ start, end }, id);
      });
      console.info(
        expiredCourses
          .map(({ id }) => {
            return id;
          })
          .join(',')
      );
      console.groupEnd();
    } else {
      console.info('No expired courses found');
    }
  }
};

/**
 * variable added outside of shouldUpdateScroll for it to persist.
 */
let savedBlogScrollPosition;

/**
 * 1. Sets up scroll position for blog homepage to prevent previous reading
 * position from being lost.
 * 2. Sets up scroll position to replace # anchor.
 */
// TODO: This method has bug. Not working properly. Disable for the time being.
const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  const isBlogHomePage = (route) => {
    const { location } = route || { location: null };

    if (location === null) {
      return false;
    }

    if (
      location.href === `${location.origin}/blog/` ||
      location.href === `${location.origin}/blog` ||
      (location.href.indexOf('?page=') > -1 &&
        location.href.indexOf('/blog') > -1)
    ) {
      return true;
    }

    return false;
  };

  const getAnchor = (() => {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get('anchor');
  })();

  // 1. Save the scroll position on blog home page if we have moved to another page
  // 2. Load back in scroll position once we return to blog home page
  if (isBlogHomePage(prevRouterProps) === true) {
    savedBlogScrollPosition = getSavedScrollPosition(prevRouterProps.location);
  }

  if (
    isBlogHomePage(routerProps) === true &&
    typeof savedBlogScrollPosition !== 'undefined'
  ) {
    return savedBlogScrollPosition;
  }

  if (getAnchor !== null) {
    return getAnchor;
  }

  return false;
};

export default {};

export { wrapRootElement, wrapPageElement, onRouteUpdate };
