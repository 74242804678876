import Util from '../utils';

// Start with 'all' in state to ensure that these checkboxes are checked true when first loaded.
// We store the rotating chevron's counter here to have a uniform way of incrementing numbers and
// dispatching the numbers.
const initialState = {
  allCourses: ['all'],
  allLocations: ['all'],
  allFormats: ['all'],
  allDates: '',
  allAges: ['all'],
  allEventIds: [''],
  allSubCourses: [''],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  const searchParams = [];

  switch (type) {
    // When it comes to string-based values, we merely update the state.
    // When it comes to an array value, we check if the bool given is true or false.
    // If true, we add the value into the array. Otherwise, we remove the value from the array.
    case 'SEARCH_BAR_DATA_UPDATE':
      // Make sure allSubCourses and allEventIds are cleared once user
      // selects another course in the dropdown. Since allSubCourses and allEventIds are not
      // interactable through UI, we need to help them remove it.
      if (typeof payload.allCourses !== 'undefined') {
        newState.allSubCourses = [''];
        newState.allEventIds = [''];
      }

      Object.keys(payload).forEach((key) => {
        switch (key) {
          case 'allSubCourses':
          case 'allEventIds':
          case 'allDates':
            newState[key] = payload[key];
            break;
          default:
            Object.keys(payload[key]).forEach((givenItem) => {
              const value = payload[key][givenItem];
              const removeItem = newState[key].indexOf(givenItem);

              if (value === false) {
                if (removeItem !== -1) {
                  newState[key].splice(removeItem, 1);
                }
              } else if (newState[key].indexOf(givenItem) === -1) {
                newState[key].push(givenItem);
              }
            });
            break;
        }
      });

      Object.keys(newState).forEach((key) => {
        if (typeof URLSearchParams !== 'undefined') {
          switch (key) {
            case 'allSubCourses':
            case 'allEventIds':
              if (newState[key][0] !== '') {
                searchParams.push(Util.parseObjectToQueryParams({ [key]: newState[key] }));
              }
              break;
            default:
              if (newState[key] !== '' && newState[key][0] !== 'all') {
                searchParams.push(Util.parseObjectToQueryParams({ [key]: newState[key] }));
              }
          }
        }
      });

      if (typeof window !== 'undefined') {
        window.history.replaceState(
          '',
          '',
          `${window.location.origin}${window.location.pathname}${
            window.location.pathname === '/schedule/' ? '' : '/'
          }${searchParams.toString() ? '?' : ''}${searchParams.join('&')}`,
        );
      }

      return newState;
    case 'SEARCH_BAR_CLEAR':
      return {
        allCourses: ['all'],
        allLocations: ['all'],
        allFormats: ['all'],
        allDates: '',
        allAges: ['all'],
        allEventIds: [''],
        allSubCourses: [''],
      };
    default:
      return state;
  }
};
