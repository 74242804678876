import Seo from '../../components/seo';
import SCTPSwift from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/SCTPSwift';

const SCTPSwiftPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <SCTPSwift />
  </>
);

export default SCTPSwiftPage;
