import SEO from '../../components/seo';

import Navbar from '../../components/DatascienceBootcampAccelerator2021/Navbar';
import Background from '../../components/DatascienceBootcampAccelerator2021/Background';
import Opening from '../../components/DatascienceBootcampAccelerator2021/IndexPage/Opening';
import About from '../../components/DatascienceBootcampAccelerator2021/IndexPage/About';
import BlogUpdate from '../../components/DatascienceBootcampAccelerator2021/BlogUpdates';
import JoinCard from '../../components/DatascienceBootcampAccelerator2021/JoinCard';

const DatascienceBootcampAccelerator2021 = () => (
  <>
    <SEO
      title="Data Science Bootcamp and Accelerator 2021 for Secondary and Junior College Students"
      description="Free Python Programing and Cloud Computing for Singapore Secondary and Junior college students."
      keywords={[
        'Python Programming',
        'python programming singapore',
        'data science for teens',
        'data science secondary',
      ]}
    />
    <Navbar />
    <Background id="particles-1">
      <Opening />
    </Background>
    <Background id="particles-2">
      <About />
    </Background>
    <Background id="particles-3">
      <BlogUpdate />
    </Background>
    <Background id="particles-4">
      <JoinCard />
    </Background>
  </>
);

export default DatascienceBootcampAccelerator2021;
