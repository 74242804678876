import styled from 'styled-components';

import $ from '../../../styles/global';

const SubInformation = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-family: Cooper Hewitt Semibold;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: ${$.color.blue5};
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

export default SubInformation;
