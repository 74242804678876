import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import policiesData from './items.json';
import Util from '../../../utils';
import Card from './Card';
import $ from '../../../styles/global';

const data = Util.addKeys(policiesData);

const Container = styled.div`
  margin-top: ${$.layout().margin3}px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    margin-top: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Cards = () => (
  <Container>
    <Fade distance="100px" bottom>
      {data.map((ele) => (
        <Card {...ele} />
      ))}
    </Fade>
  </Container>
);

export default Cards;
