import { useState } from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';
import Utils from '../../utils';

const Section = styled.section`
  background-color: ${$.accelerator.blue};
`;

const Container = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 50px 0;
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
  }
  // #endregion
`;

const Info = styled.div`
  width: 55%;
  & > h2 {
    color: ${$.color.white};
    font-size: 40px;
    font-family: Lato Black;
    margin-bottom: ${$.layout().margin3}px;
  }

  & > p {
    font-family: Lato Regular;
    font-size: 16px;
    line-height: 1.3em;
    color: ${$.color.white};
    opacity: 0.8;
    margin-bottom: ${$.layout().margin3}px;

    & > span {
      font-family: Lato Black;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin2}px;

    & > h2 {
      font-size: 34px;
    }
  }
  // #endregion
`;

const Buttons = styled.div`
  ${({ clicked }) => `
  display: flex;
  flex-direction: row;
  align-items: center;

  & > button {
    border: 1px solid ${$.color.white};
    background-color: transparent;
    color: ${$.color.white};
    font-size: 18px;
    font-family: Lato Bold;
    padding: 8px 12px;
    min-width: 100px;
    border-radius: 5px;
    transition: all 0.3s ${$.easingFn.standard};

    &:hover {
      cursor: pointer;
      background-color: ${$.color.white};
      color: ${$.accelerator.blue};
    }

    &:not(:last-child) {
      margin-right: ${$.layout().margin4}px;
    }

    &:${clicked === 'A' ? 'first-child' : 'last-child'} {
      background-color: ${$.color.white};
      color: ${$.accelerator.blue};
    }
  }
`}
`;

const List = styled.div`
  width: 40%;
  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Row = styled.div`
  margin-bottom: ${$.layout().margin4}px;
  padding-bottom: ${$.layout().padding4}px;
  border-bottom: 2px solid ${$.color.white};
`;

const Timing = styled.div`
  display: flex;
  flex-direction: row;
  color: ${$.color.white};
  font-family: Lato Bold;
  font-size: 14px;
  margin-bottom: ${$.layout().margin5}px;

  & > span {
    &:first-child {
      margin-right: ${$.layout().margin3}px;
    }
    &:last-child {
      opacity: 0.8;
    }
  }
`;

const CourseName = styled.div`
  font-family: Lato Bold;
  color: ${$.color.white};
  font-size: 16px;
  line-height: 1.3em;
`;

const SeeAll = styled.div`
  color: ${$.color.white};
  text-align: center;
  font-family: Lato Bold;
  font-size: 15px;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
    }
  }
  // #endregion
`;

const items = {
  A: Utils.addKeys([
    {
      time: '6 hours',
      course:
        'Accelerator Programme Introduction - Machine Learning, AWS DeepRacer League',
    },
    {
      time: '8 hours',
      course: 'AWS Cloud Practitioner Essentials',
    },
    {
      time: '3 hours',
      course: 'Data Structures - Lists',
    },
    {
      time: '3 hours',
      course: 'Data Structures - Dictionaries',
    },
    {
      time: '7 hours',
      course: 'Object Oriented Programming',
    },
    {
      time: '6 hours',
      course: 'Data Munging with the Numpy and Pandas libraries',
    },
    {
      time: '5 hours',
      course: 'Intro to Machine Learning with BigML',
    },
    {
      time: '7 hours',
      course: 'Web Programming',
    },
    {
      time: '7 hours',
      course: 'Creating your own web API with Flask',
    },
    {
      time: '3 hours',
      course:
        'Planning and Executing a Data Science project (part 1) - Designing and Prototyping',
    },
    {
      time: '3 hours',
      course:
        'Planning and Executing a Data Science project (part 2) - Collaboration for Software Development',
    },
    {
      time: '8 hours',
      course: 'Practical Data Science with Amazon Sagemaker',
    },
    {
      time: '8 hours',
      course: 'Deploying ML models to the BigML cloud service',
    },
    {
      time: '2 hours',
      course:
        'Deep Diving into the AWS Cloud - S3, DynamoDB, Elastic Beanstalk',
    },
    {
      time: '2 hours',
      course:
        'Modelling image data - Using the AWS Rekognition API for Machine Vision',
    },
    {
      time: '2 hours',
      course:
        'Modelling unstructured text data - Natural Language Processing (NLP)',
    },
    {
      time: '2 hours',
      course:
        'Modelling unstructured text data - Using the Transcribe, Textract and Comprehend APIs for Natural Language Processing applications',
    },
    {
      time: '2 hours',
      course: 'Modelling unstructured text data - Large Language Models on AWS',
    },
    {
      time: '3 hours',
      course: 'Planning and Executing a Data Science project (part 3)',
    },
    {
      time: '2 hours',
      course: 'Are machines capable of creativity? - Gen AI on Amazon Bedrock',
    },
    {
      time: '1 hour',
      course: 'Career session featuring AWS guest speakers',
    },
    {
      time: '20 hours',
      course: 'Planning and Executing a Data Science project (part 4)',
    },
  ]),
  B: Utils.addKeys([
    {
      time: '6 hours',
      course:
        'Accelerator Programme Introduction - Machine Learning, AWS DeepRacer League',
    },
    {
      time: '8 hours',
      course: 'AWS Cloud Practitioner Essentials',
    },
    {
      time: '4 hours',
      course: 'Data Munging with the Numpy and Pandas libraries',
    },
    {
      time: '4 hours',
      course: 'Intro to Machine Learning and Deep Learning',
    },
    {
      time: '4 hours',
      course: 'Deep Learning Fundamentals with fastai and PyTorch',
    },
    {
      time: '4 hours',
      course: 'Foundations of Computer Vision and Image Classification',
    },
    {
      time: '4 hours',
      course: 'Modelling Tabular Data',
    },
    {
      time: '4 hours',
      course: 'Convolutional Neural Networks and ResNets',
    },
    {
      time: '7 hours',
      course: 'Web Programming',
    },
    {
      time: '7 hours',
      course: 'Creating your own web API with Flask',
    },
    {
      time: '3 hours',
      course:
        'Planning and Executing a Data Science project (part 1) - Designing and Prototyping',
    },
    {
      time: '3 hours',
      course:
        'Planning and Executing a Data Science project (part 2) - Collaboration for Software Development',
    },
    {
      time: '8 hours',
      course: 'Practical Data Science with Amazon Sagemaker',
    },
    {
      time: '8 hours',
      course: 'Architecting on the AWS Cloud',
    },
    {
      time: '2 hours',
      course:
        'Deep Diving into the AWS Cloud - S3, DynamoDB, Elastic Beanstalk',
    },
    {
      time: '2 hours',
      course:
        'Modelling image data - Using the AWS Rekognition API for Machine Vision',
    },
    {
      time: '2 hours',
      course:
        'Modelling unstructured text data - Natural Language Processing (NLP)',
    },
    {
      time: '2 hours',
      course:
        'Modelling unstructured text data - Using the Transcribe, Textract and Comprehend APIs for Natural Language Processing applications',
    },
    {
      time: '2 hours',
      course: 'Modelling unstructured text data - Large Language Models on AWS',
    },
    {
      time: '3 hours',
      course: 'Planning and Executing a Data Science project (part 3)',
    },
    {
      time: '2 hours',
      course:
        'Are machines capable of creativity? - Generative AI with Amazon Bedrock',
    },
    {
      time: '1 hour',
      course: 'Career session featuring AWS guest speakers',
    },
    {
      time: '20 hours',
      course: 'Planning and Executing a Data Science project (part 4)',
    },
  ]),
};

const Learn = () => {
  const [clicked, setClicked] = useState('A');
  const [seeAll, setSeeAll] = useState(false);

  const handleOnClick = (e) => {
    setClicked(e.target.innerText === 'Track A' ? 'A' : 'B');
  };

  return (
    <Section>
      <Container>
        <Info>
          <h2>What you will learn</h2>
          <p>
            Please ensure that you can make it for{' '}
            <span>at least 75% of the sessions</span> before you sign up.
            Sessions will be conducted face-to-face at SG Code Campus&apos;
            large classrooms at Tanjong Pagar, though students can elect to
            attend <span>up to 50% of the sessions online</span>. Students will
            attend different tracks of the programme depending on their level of
            technical preparation as determined by SG Code Campus
          </p>
          <Buttons clicked={clicked}>
            <button onClick={handleOnClick}>Track A</button>
            <button onClick={handleOnClick}>Track B</button>
          </Buttons>
        </Info>
        <List>
          <div>
            {items[clicked]
              .slice(0, seeAll ? items[clicked].length : 3)
              .map(({ key, time, course }, index) => (
                <Row key={key}>
                  <Timing>
                    <span>{index + 1}</span>
                    <span>{time}</span>
                  </Timing>
                  <CourseName>{course}</CourseName>
                </Row>
              ))}
          </div>
          {items[clicked].length > 3 && (
            <SeeAll
              onClick={() => {
                setSeeAll((prev) => !prev);
              }}
            >
              {seeAll ? 'See Less' : 'See All'}
            </SeeAll>
          )}
        </List>
      </Container>
    </Section>
  );
};

export default Learn;
