import styled from 'styled-components';
import $ from '../../../styles/global';

const Container = styled.div``;

const Title = styled.h2`
  font-family: Lato Bold;
  font-size: ${$.fontSizes.xlarge};
  line-height: 48px;
  color: ${$.proTraining.bluegray};
  margin-bottom: 30px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 35px;
    line-height: 43px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 35px;
    line-height: 43px;
  }
  // #endregion
`;

const Content = styled.p`
  font-size: 15px;
  font-family: Lato Light;
  color: ${$.color.black};
  line-height: 18px;
  margin-bottom: 40px;
  width: 50%;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: 60%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Banner = () => (
  <Container>
    <Title>Discover Courses</Title>
    <Content>
      Discover the perfect course to ignite your passion for learning, advance
      your career, or empower your team to succeed in the ever-evolving world of
      technology.
    </Content>
  </Container>
);

export default Banner;
