import styled from 'styled-components';

import $ from '../../../styles/global';
// import AWSLogoWhite from '../../../assets/images/awsaccelerator2021/aws-logo-white.png';
// import AWSLogoBlack from '../../../assets/images/awsaccelerator2021/aws-logo-black.png';
import DeepracerLogo from '../../../assets/images/awsaccelerator2021/deepracer-logo.png';
// import CloudComputingLogo from '../../../assets/images/awsaccelerator2021/cloud-computing-logo.png';
import IMDALogo from '../../../assets/images/awsaccelerator2021/imda-logo.png';
import DunmanLogo from '../../../assets/images/awsaccelerator2021/dunman-logo.png';
import AWSLogo from '../../../assets/images/awsaccelerator2021/aws-logo.png';
import TampinesTCLogo from '../../../assets/images/awsaccelerator2021/tampines-town-council-logo.png';

const Section = styled.section`
  background-image: linear-gradient(
    350deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(16, 21, 29, 1) 50%
  );
  background-size: 100%;
  padding: 150px 0 60px 0;

  & > * {
    /* 
    * We can't change stacking context because Safari can't do mix-blend-mode
    * properly otherwise. I think it's ok to let the particlesJS
    * canvas be on top of the opening logo section.
    */
    /* position: relative; */
    top: 40px;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: 80px 0 60px 0;
  }
  // #endregion
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column-reverse;
    justify-content: initial;
  }
  // #endregion
`;

const InnerContainer = styled.div`
  width: 60%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Title = styled.h1`
  font-family: Aileron Heavy !important;
  font-size: 60px !important;
  color: ${$.color.white};
  line-height: 0.9em;
  mix-blend-mode: difference;
  /* word-break: break-all; */
  /* hyphens: manual; */
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 50px !important;
    br {
      display: none;
    }
  }
  // #endregion
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: ${$.color.black2};
  line-height: 1.5em;
  text-shadow: 1px 0px #fff;
  margin-bottom: ${$.layout().margin4}px;
  position: relative;
  top: 40px;
`;

const Logo = styled.div`
  width: 300px;
  z-index: 0;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 250px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const StyledAWSLogoBlack = styled.img`
  width: 100%;
  /* @media ${$.device.mobile} {
    display: none;
  } */
`;

const StyledAWSLogoWhite = styled.img`
  width: 100%;

  @media ${$.device.desktop} {
    display: none;
  }

  @media ${$.device.tablet} {
    display: none;
  }
`;

const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  top: 40px;

  & > * {
    height: 50px;
    &:not(:last-child) {
      margin-right: ${$.layout().margin3}px;
    }
  }
`;

const MiniLogo = styled.img`
  margin-bottom: ${$.layout().margin4}px;
`;

const Opening = () => (
  <Section>
    <Container>
      <InnerContainer>
        <Title>
          {`Data Science `}
          <br />
          {`Bootcamp & `}
          <br />
          {` Accelerator`}
        </Title>
        <Subtitle>
          Fully sponsored by IMDA and AWS and run in partnership with Dunman
          Secondary School and the Tampines Town Council
        </Subtitle>
        <LogosContainer>
          <MiniLogo src={AWSLogo} />
          <MiniLogo src={IMDALogo} />
          <MiniLogo src={DunmanLogo} />
          <MiniLogo src={TampinesTCLogo} />
        </LogosContainer>
      </InnerContainer>
      <Logo>
        <StyledAWSLogoBlack src={DeepracerLogo} />
        {/* <StyledAWSLogoWhite src={AWSLogoWhite} /> */}
      </Logo>
    </Container>
  </Section>
);

export default Opening;
