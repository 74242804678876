import styled from 'styled-components';
import { useSelector } from 'react-redux';

import DesktopBar from './Desktop';
import MobileBar from './Mobile';
import $ from '../../../styles/global';
import { Provider } from './context';
import {
  searchBarDataUpdate as dataUpdate,
  searchBarUIUpdate as UIUpdate,
  searchBarClear as clear,
} from '../../../actions';
import Util from '../../../utils';

// We rely on media query instead to show mobile/desktop components. The reason being React
// does not fully validate differences attributes/markups
// when the page first load, causing a mix of incorrect components showing when the page first load.
// This is not a bug but rather, a performance decision.
// We can trigger another render where it will then render correctly using useEffect,
// but this is an expensive move.
// https://github.com/gatsbyjs/gatsby/issues/9849
// https://reactjs.org/docs/react-dom.html#hydrate
const StyledDesktopAndTabletBar = styled(DesktopBar)`
  display: block;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const StyledMobileBar = styled(MobileBar)`
  display: none;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: block;
  }
  // #endregion
`;

const SearchBar = () => {
  const searchBarData = useSelector(({ searchBarData: barData }) => barData);
  const searchBarUIData = useSelector(({ searchBarUIData: UIData }) => UIData);
  const [searchBarDataUpdate, searchBarUIUpdate, searchBarClear] =
    Util.useActions([dataUpdate, UIUpdate, clear]);

  return (
    <Provider
      value={{
        searchBarData,
        searchBarUIData,
        searchBarDataUpdate,
        searchBarUIUpdate,
        searchBarClear,
      }}
    >
      <StyledDesktopAndTabletBar />
      <StyledMobileBar />
    </Provider>
  );
};

export default SearchBar;
