import styled from 'styled-components';

import $ from '../../../styles/global';

const SubHeader = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-family: Cooper Hewitt Bold;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${$.color.orange2};
    margin-bottom: calc(${$.layout().margin5}px / 2);
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

export default SubHeader;
