import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import $ from '../styles/global';
import SEO from '../components/seo';
import Util from '../utils';
import Level2Banner from '../components/Level2Banner';
import LeftImageContainer from '../components/blog/banner/LeftImageContainer';
import RightImageContainer from '../components/blog/banner/RightImageContainer';
import MobileImageContainer from '../components/blog/banner/MobileImageContainer';
import Posts from '../components/blog/Posts';

const IndividualTagPage = ({
  data: {
    allGhostPost: { edges },
    ghostAuthor: { profile_image: defaultProfileImage },
  },
  pageContext: { postCount, name, metaTitle, metaDescription },
  path,
}) => {
  const posts = Util.addKeys(
    edges.map(
      ({
        node: {
          tags,
          published_at: publishedAt,
          primary_author: author,
          ...rest
        },
      }) => ({
        tags: Util.addKeys(tags),
        publishedAt: new Date(publishedAt).toLocaleDateString('en-GB', {
          year: 'numeric',
          day: 'numeric',
          month: 'short',
        }),
        ...rest,
        primary_author: {
          id: author.id,
          name: author.name,
          profile_image: author.profile_image || defaultProfileImage,
        },
      })
    )
  );

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <Level2Banner
        title={name}
        postsWithTag={postCount}
        path={path}
        color={$.color.white}
        backgroundColor={$.color.blue4}
      >
        <LeftImageContainer />
        <RightImageContainer />
        <MobileImageContainer />
      </Level2Banner>
      <Posts
        posts={posts.slice(1, posts.length)}
        featured={posts.slice(0, 1)[0]}
      />
    </>
  );
};

IndividualTagPage.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object,
    ghostAuthor: PropTypes.object,
  }).isRequired,
  pageContext: PropTypes.shape({
    postCount: PropTypes.number,
    name: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export const query = graphql`
  query FilteredPostsWithTag($slug: String) {
    allGhostPost(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          excerpt
          slug
          title
          feature_image
          published_at
          time_to_read
          primary_author {
            id
            name
            profile_image
          }
          tags {
            name
            slug
          }
        }
      }
    }
    ghostAuthor(name: { eq: "SG Code Campus" }) {
      profile_image
    }
  }
`;

export default IndividualTagPage;
