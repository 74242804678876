import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: Lato Regular;
  line-height: 16.8px;
  justify-content: center;
`;

const Button = styled.button`
  text-decoration: none;
  background: ${({ disabled }) =>
    disabled
      ? $.color.gray1
      : `radial-gradient(
        152.64% 127.74% at 25.6% 78.1%,
        #16303e 44.99%,
        #344d55 96.49%
      )`};
  color: ${$.color.white};
  border-radius: 54px;
  box-shadow: ${({ disabled }) =>
    disabled
      ? '0px 4px 30px 0px rgba(23, 49, 63, 0.4)'
      : '0px 4px 30px 0px rgba(23, 49, 63, 0.6)'};
  padding: 12px 30px;
  min-width: 120px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: none;
  z-index: 1;
  transition: left 0.3s, transform 0.3s, color 0.5s;
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}

  &:hover {
    color: #16303e;
    cursor: pointer;
    & > span {
      transform: translateY(0) scale(2);
    }
  }

  & > span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: ${$.color.white};
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.5s;
    top: 0;
    transition-delay: calc((var(--n) - 1) * 0.05s);
    z-index: -1;

    &:nth-child(2) {
      --n: 1;
    }

    &:nth-child(3) {
      --n: 2;
    }

    &:nth-child(4) {
      --n: 3;
    }

    &:nth-child(5) {
      --n: 4;
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    padding: 9px 27px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 20px;
  }
  // #endregion
`;

const BlueButton = ({ text, onClick, disabled }) => (
  <Button onClick={onClick} disabled={disabled}>
    <Container>{text}</Container>
    <span />
    <span />
    <span />
    <span />
  </Button>
);

BlueButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
BlueButton.defaultProps = {
  text: 'Submit',
  onClick: () => {},
  disabled: false,
};

export default BlueButton;
