/**
 * Converts an object with key-value pairs to a query string.
 * @param {Object} obj - The key-value pairs we want to convert to query params.
 * @returns {String}
 */
const parseObjectToQueryParams = (obj) => {
  const searchParams = new URLSearchParams('');

  Object.keys(obj).forEach((key) => {
    searchParams.append(key, obj[key]);
  });

  return searchParams.toString();
};

export default parseObjectToQueryParams;
