import { useEffect } from 'react';

/**
 * Due to us using react-reveal/fade and some quirky DOM element placement,
 * using hashtag to make the browser scroll to the DOM element has been giving
 * some quirky results. shouldUpdateScroll is giving a bug.
 * We are using this method to scroll over to the element.
 *
 * Make sure to add the 'anchor id' to the component's className.
 *
 * @param {string} anchor - What is the className of the DOM element you wish to scroll to?
 * @param {func} callback - The function to run if a matching anchor is found.
 */
const useAnchor = callback => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      const hasAnchor = searchParams.get('anchor');
      const node = hasAnchor
        ? document.getElementsByClassName(hasAnchor)
        : null;
      const navbarHeight = 89;

      if (hasAnchor && node && node.length > 0) {
        window.scrollTo(
          0,
          node[0].offsetTop > navbarHeight
            ? node[0].offsetTop - navbarHeight
            : node[0].offsetTop
        );
      }

      if (hasAnchor && callback) {
        callback();
      }
    }
  }, []);
};

export default useAnchor;
