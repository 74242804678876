import styled from 'styled-components';

import $ from '../../styles/global';

const Title = styled.div`
  text-transform: capitalize;
  color: ${$.color.blue2};
  font-family: Aileron Heavy;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 30px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 24px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 26px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  text-transform: uppercase;
  font-family: Cooper Hewitt Semibold;
  font-size: 15px;
  letter-spacing: 2.3px;
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-family: Cooper Hewitt Medium;
    font-size: 11px;
    letter-spacing: 2.4px;
    margin-bottom: ${$.layout().margin5}px;
    color: ${$.color.white};
  }
  // #endregion
`;

export { Title, Subtitle };
