import { graphql, useStaticQuery } from 'gatsby';

import $ from '../../styles/global';
import Level2Banner from '../../components/Level2Banner';
import LeftImageContainer from '../../components/blog/banner/LeftImageContainer';
import RightImageContainer from '../../components/blog/banner/RightImageContainer';
import MobileImageContainer from '../../components/blog/banner/MobileImageContainer';
import Posts from '../../components/blog/Posts';
import Util from '../../utils';
import SEO from '../../components/seo';

const Blog = () => {
  const {
    allGhostPost,
    ghostAuthor: { profile_image: defaultProfileImage },
  } = useStaticQuery(graphql`
    query {
      allGhostPost(
        sort: { order: DESC, fields: published_at }
        filter: { slug: { nin: "data-schema" } }
      ) {
        edges {
          node {
            excerpt
            slug
            title
            feature_image
            published_at
            time_to_read
            primary_author {
              id
              name
              profile_image
            }
            tags {
              name
              slug
            }
          }
        }
      }
      ghostAuthor(name: { eq: "SG Code Campus" }) {
        profile_image
      }
    }
  `);

  const posts = Util.addKeys(
    allGhostPost.edges.map(
      ({
        node: {
          tags,
          primary_author: author,
          published_at: publishedAt,
          ...rest
        },
      }) => ({
        tags: Util.addKeys(tags),
        publishedAt: new Date(publishedAt).toLocaleDateString('en-GB', {
          year: 'numeric',
          day: 'numeric',
          month: 'short',
        }),
        ...rest,
        primary_author: {
          id: author.id,
          name: author.name,
          profile_image: author.profile_image || defaultProfileImage,
        },
      })
    )
  );

  return (
    <>
      <SEO
        title="The SG Code Campus blog"
        description="The SG Code Campus blog"
      />
      <Level2Banner
        title="By the Fireside"
        subtitle="The SG Code Campus blog"
        path="/blog/"
        color={$.color.white}
        backgroundColor={$.color.blue4}
      >
        <LeftImageContainer />
        <RightImageContainer />
        <MobileImageContainer />
      </Level2Banner>
      <Posts
        posts={posts.slice(1, posts.length)}
        featured={posts.slice(0, 1)[0]}
      />
    </>
  );
};

export default Blog;
