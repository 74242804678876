import Seo from '../../components/seo';
import TechFoundation from '../../components/ProfessionalTrainingIndividualPage/IndividualPages/TechFoundation';

const TechFoundationPage = () => (
  <>
    <Seo title="Coding Courses in Singapore" />
    <TechFoundation />
  </>
);

export default TechFoundationPage;
