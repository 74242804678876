import styled from 'styled-components';
import $ from '../../../styles/global';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout().margin3}px 0;
  font-size: 16px;
  p {
    line-height: 1.3em;
    margin-bottom: ${$.layout().margin4}px;
  }
  h2 {
    font-size: 24px;
    font-family: Lato Bold;
    margin-top: ${$.layout().margin2}px;
    margin-bottom: ${$.layout().margin4}px;
  }
  ol {
    margin-bottom: ${$.layout().margin4}px;
    li {
      line-height: 1.3em;
      padding-left: ${$.layout().padding4}px;
      margin-bottom: ${$.layout().margin5}px;
    }
  }
`;

const Content = () => (
  <Section>
    <Container>
      <p>
        We are committed to safeguarding the privacy of our visitors and
        members. This Privacy Policy outlines our policy and responsibility in
        relation to the collection, use and disclosure of your personal data.
      </p>
      <h2>Collection of Personal Data</h2>
      <p>
        The types of personal data that we collect depend on the purposes for
        the collection or the nature of the activity/transaction involved. We
        may collect the following types of data which may amount to personal
        data:
      </p>
      <ol>
        <li>
          Information about your computer and about your visits to and use of
          this website, such as your IP address, geographical location, browser
          type, length of visit and number of page views
        </li>
        <li>
          Information relating to any transactions or in relation to the web
          portal, including information relating to any class bookings
        </li>
        <li>
          Information that you provide to us for the purpose of class
          registration
        </li>
        <li>Any other information such as demographics and/or interests</li>
        <li>Pictures and videos taken during classes</li>
      </ol>
      <p>
        If you provide personal data relating to your dependent or children, you
        represent and warrant that the consent of that third party has been
        given/obtained for our collection, use and disclosure of their personal
        data in connection with the purposes specified below and that such
        personal data is accurate and complete.
      </p>
      <h2>Purposes for Collection, Use and Disclosure</h2>
      <p>
        We may collect, use and/or disclose personal data for the following
        purposes:
      </p>
      <ol>
        <li>
          To provide you with updates and recommendations on our programs and
          promotions.
        </li>
        <li>
          To solicit feedback/reviews on education programs or suggestions to
          improve our services.
        </li>
        <li>
          To investigate and respond to your enquiries, feedback, requests, or
          complaints.
        </li>
        <li>To generate internal reports for audit and management purpose.</li>
        <li>
          To compile usage statistics, help us understand the interests of our
          users and to improve the user experience.
        </li>
        <li>To comply with applicable regulations and laws.</li>
        <li>
          Pictures and videos maybe used in our website, third-party websites,
          apps, printed publications and other marketing materials
        </li>
      </ol>
      <h2>Disclosure to Third Parties</h2>
      <p>
        We will use reasonable efforts to ensure that our employees and other
        third parties who are involved in the collection, use and disclosure of
        personal data will observe and adhere to the terms of this Privacy
        Policy.
      </p>
      <p>
        We will not disclose or transfer any personal information collected to
        any third parties except as outlined in this privacy policy.
      </p>
      <p>
        We may disclose your personal data to law enforcement or government
        agencies for security and legal investigation purposes.
      </p>
      <h2>Cookies</h2>
      <p>
        SG Code Campus&apos;s website collects &quot;cookies&quot; to enhance
        your experience while browsing the website. A cookie is a text file that
        is placed on your browser to store and track information about your use
        of our website such as the frequency of use, profiles of users and their
        preferred sites. While this cookie can tell us when you enter our sites
        and which pages you visit, it cannot read data off your hard disk.
      </p>
      <p>SG Code Campus uses cookies for the following purposes:</p>
      <ol>
        <li>
          To build up a profile of how you and other users use our websites
          and/or other third party websites;
        </li>
        <li>To establish usage statistics;</li>
        <li>
          To analyse your access to the website in order to improve operability
          or to identify the cause of problems on SG Code Campus website;
        </li>
        <li>
          Measure and research the effectiveness of our interactive online
          content, features, advertisements, and other communications;
        </li>
        <li>Make your online experience more efficient and enjoyable.</li>
      </ol>
      <p>
        You can choose to accept or decline cookies. Most web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. This may prevent you from
        taking full advantage of the website.
      </p>
      <p>
        Our website may, from time to time, contain links to and from third
        party websites. If you follow a link to any of these websites, please
        note that these websites may or may not have their own have their own
        privacy policies and that we do not accept any responsibility or
        liability for these policies. Please check these policies before you
        submit any personal data to these websites.
      </p>
      <h2>Consent</h2>
      <p>
        This Policy applies to all Personal Data that you may provide to us and
        the Personal Data we hold about you. By providing us with your Personal
        Data or by accessing, using or viewing the applicable website or any of
        its services, functions or contents (including transmitting, caching or
        storing of any such Personal Data), you shall be deemed to have agreed
        to each and all the terms, conditions, and notices in this Policy.
      </p>
      <p>
        You may submit a request to withdraw your consent at any time by
        contacting us via enquiries@sgcodecampus.com.
      </p>
      <p>
        Do note, in many circumstances, we need to use your Personal Data in
        order for us to provide you with products or services which you require
        or have requested. If you do not provide us with the required Personal
        Data, or if you do not accept this Policy (or any amendments thereto) or
        withdraw your consent to our use and/or disclosure of your personal data
        for these purposes, it may not be possible for us to continue to serve
        you or provide you with the products and services that you require or
        have requested.
      </p>
      <h2>Modification to Privacy Policy</h2>
      <p>
        We reserve the right to modify this privacy policy at any time. To
        protect your interests, please visit this page periodically to review
        the latest and most current Privacy Policy.
      </p>
    </Container>
  </Section>
);

export default Content;
