import { useState, useEffect } from 'react';
import styled from 'styled-components';

import DownChevron from '../../assets/icons/down-chevron.svg';

const Section = styled.section`
  padding: ${({ theme }) => theme.layout().padding3}px 0;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-family: ${({ theme }) => theme.fontFamily.subtitle.semiBold};
  color: ${({ theme }) => theme.color.blue4};
`;

const Container = styled.div``;

const List = styled.ul`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.layout().padding1}px;
  list-style-type: none;
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.blue4};
`;

const links = [
  { id: 1, name: 'Overview', href: '#overview' },
  { id: 2, name: 'Course Outline', href: '#course-outline' },
  { id: 3, name: 'Course Information', href: '#course-information' },
  { id: 4, name: 'Instructors', href: '#instructors' },
];

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownMenu = styled.select`
  // Strip all default styles
  appearance: none;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.blue4};

  &:focus {
    outline: none;
  }

  // Add custom styles
  width: 100%;
  padding: ${({ theme }) => theme.layout().padding4}px
    ${({ theme }) => theme.layout().padding3}px;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  border-radius: ${({ theme }) => theme.border().radius2}px;
`;

const DropdownOption = styled.option``;

const ChevronContainer = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.layout().padding3}px;
  top: 0;
  width: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MiniNavBar = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  const handleSelectChange = (e) => {
    if (typeof window === 'undefined') return;
    window.location.href = e.target.value;
  };

  const handleResize = () => {
    if (typeof window === 'undefined') return;
    setIsMobileView(window.innerWidth <= 768);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      if (typeof window === 'undefined') return;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Section>
      <Container>
        {isMobileView ? (
          <DropdownContainer>
            <DropdownMenu
              name="mobileMiniNavBar"
              id="mobileMiniNavBar"
              onChange={handleSelectChange}
            >
              {links.map(({ id, name, href }) => (
                <DropdownOption key={id} value={href}>
                  {name}
                </DropdownOption>
              ))}
            </DropdownMenu>
            <ChevronContainer>
              <DownChevron />
            </ChevronContainer>
          </DropdownContainer>
        ) : (
          <List>
            {links.map(({ id, name, href }) => (
              <li key={id}>
                <StyledLink href={href}>{name}</StyledLink>
              </li>
            ))}
          </List>
        )}
      </Container>
    </Section>
  );
};

export default MiniNavBar;
