import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../../styles/global';

const Container = styled.figure`
  margin: ${$.layout().margin2}px 0;
  height: 550px;
  width: 100%;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 100vw;
    position: relative;
    height: 550px;
    left: -${$.layout().padding2}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100vw;
    position: relative;
    height: 450px;
    left: -${$.layout().padding2}px;
    margin: ${$.layout().margin3}px 0;
  }
  // #endregion
`;

const Images = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    height: 500px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    height: 500px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    height: 400px;
  }
  // #endregion
`;

const Caption = styled.figcaption`
  text-align: center;
  font-size: 14.5px;
  color: ${$.color.black2};
  padding: ${$.layout().padding4}px;
  height: calc(50px - ${$.layout().padding4 * 2}px);
`;

const ImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity ${$.easingFn.standard} 2.2s;

  &.show {
    opacity: 1;
  }

  > * {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const AutoFadeImages = () => {
  const [autoFade, setAutoFade] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setAutoFade((prev) => {
        switch (prev) {
          case 1:
            return 2;
          case 2:
            return 3;
          default:
            return 1;
        }
      });
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <Images title="SG Code Campus previous event images">
        <ImageWrapper className={autoFade === 1 ? 'show' : ''}>
          <StaticImage
            src="../../../../assets/images/awsaccelerator2021/aws-deepracer-img-2.jpg"
            alt="AWS Deepracer event image 1"
          />
        </ImageWrapper>
        <ImageWrapper className={autoFade === 2 ? 'show' : ''}>
          <StaticImage
            src="../../../../assets/images/awsaccelerator2021/aws-deepracer-img-3.jpg"
            alt="AWS Deepracer event image 2"
          />
        </ImageWrapper>
        <ImageWrapper className={autoFade === 3 ? 'show' : ''}>
          <StaticImage
            src="../../../../assets/images/awsaccelerator2021/aws-deepracer-img-4.jpg"
            alt="AWS Deepracer event image 3"
          />
        </ImageWrapper>
      </Images>
      <Caption>SG Code Campus students and instructors</Caption>
    </Container>
  );
};

export default AutoFadeImages;
