import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../styles/global';

const Container = styled.div`
  position: relative;
  margin-bottom: 80px;
  margin-top: 50px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    margin-bottom: 50px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 50px;
  }
  // #endregion
`;

const Foreground = styled.div`
  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
  }
  // #endregion
`;

const Background = styled.div`
  background: radial-gradient(
    103.83% 111.89% at 25.6% 78.1%,
    #f7814d 44.99%,
    #fdb483 96.49%
  );

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 550px;
    height: 400px;
    position: relative;
    left: calc(970px - 550px);
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: 550px;
    height: 400px;
    position: relative;
    left: calc(100% - 550px);
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 550px;
    height: 450px;
    position: absolute;
    left: -10px;
    top: -80px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 80%;
    height: 70vw;
    position: absolute;
    left: -15px;
    top: -70px;
  }
  // #endregion
`;

const ImageContainer = styled.div`
  overflow: hidden;

  & > * {
    width: 100%;
    height: 100%;
  }

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 60%;
    position: relative;
    top: 80px;
    right: 50px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: 60%;
    position: relative;
    top: 80px;
    right: 55px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 75%;
    position: relative;
    right: -7.5%;
    top: 20px;
    margin-bottom: 30px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: 30px;
  }
  // #endregion
`;

const Info = styled.div`
  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    width: 33%;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletB} {
    width: 33%;
  }
  // #endregion
`;

const Title = styled.h2`
  font-family: Lato Bold;
  font-size: 40px;
  line-height: 45px;
  color: ${$.proTraining.bluegray};
  margin-bottom: 15px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 35px;
    line-height: 40px;
    margin-top: 20px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 35px;
    line-height: 40px;
  }
  // #endregion
`;

const Content = styled.p`
  margin-bottom: 35px;
  font-family: Lato Light;
  font-size: 15px;
  line-height: 18px;
  color: #2c2c2c;
`;

const Banner = () => (
  <Container>
    <Background />
    <Foreground>
      <Info>
        <Title>Courses</Title>
        <Content>
          Discover the perfect course to ignite your passion for learning,
          advance your career, or empower your team to succeed in the
          ever-evolving world of technology.
        </Content>
      </Info>
      <ImageContainer>
        <StaticImage
          src="../../assets/images/awsaccelerator2021/aws-deepracer-img-2.jpg"
          alt="banner image"
        />
      </ImageContainer>
    </Foreground>
  </Container>
);

export default Banner;
