import styled from 'styled-components';
import { Link } from 'gatsby';

import PropTypes from 'prop-types';
import $ from '../../../styles/global';

const Container = styled.div`
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion
`;

const Tag = styled(Link)`
  text-decoration: none;
  display: inline-block;
  font-family: Cooper Hewitt Medium;
  color: ${$.color.blue5};
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: text-shadow 0.4s ${$.easingFn.standard};
  &:visited {
    color: ${$.color.blue5};
  }

  &:hover {
    text-shadow: 0px 0px 2px ${$.color.gray1};
  }
`;

const Tags = ({ slug, name }) => (
  <Container>
    <Tag to={`/blog/tag/${slug}/`}>{name}</Tag>
  </Container>
);

Tags.defaultProps = {
  slug: '',
  name: '',
};

Tags.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
};

export default Tags;
