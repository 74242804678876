/**
 * Converts unix time to day name (E.g. Mon, Tue)
 * @param {number} time - The unix time in integer
 * @returns {string}
 */
const parseTimeToDayName = time => {
  const date = new Date(time);

  return date.toLocaleString('en-US', {
    weekday: 'short',
    timeZone: 'Asia/Hong_Kong',
  });
};

export default parseTimeToDayName;
