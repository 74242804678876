import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../../styles/global';
import Button from '../../../Button';

const Container = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    text-align: center;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    text-align: center;
  }
  // #endregion
`;

const RegistrationContainer = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > * {
      margin-right: ${$.layout().margin4}px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > * {
      margin-right: ${$.layout().margin4}px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    > * {
      margin-bottom: ${$.layout().margin4}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // #endregion
`;

const Text = styled.div`
  color: ${$.color.orange3};
  line-height: 1.4em;

  a {
    color: ${$.color.orange4};
    text-decoration: none;
    font-family: Lato Bold;
  }
`;

const RegisterButton = styled(Button)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    min-width: 240px;
    margin-left: ${$.layout().margin4}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    min-width: 240px;
    margin-top: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Registration = () => (
  <Container>
    <RegistrationContainer>
      <Text>
        If you can&apos;t find what you need, please fill in this enquiry form
        and we&apos;ll get back to you!
      </Text>
      <RegisterButton
        normalColor={$.color.orange3}
        clickedColor={$.color.blue5}
        onClick={() => {
          window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSeDjtfkjdlQKxtJH8ws9zyQAtpM9KTyc3ptcXNo5-s78d56AQ/viewform?embedded=true',
            '_blank'
          );
        }}
      >
        Enquiry Form
      </RegisterButton>
    </RegistrationContainer>
    <Text>
      {
        'Alternatively, you can read more about how we arrived at these recommendations based on '
      }
      <Link to="/blog/how-kids-teens-start-coding/">
        what is most developmentally appropriate course for your child here.
      </Link>
    </Text>
  </Container>
);

export default Registration;
