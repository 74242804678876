import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import {
  Age,
  Title,
  Description,
  Subtitle,
  StyledButton,
} from '../OverlayStyles';

const Container = styled.div.attrs({ className: 'card-overlay' })`
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transform: translateY(-2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  text-align: center;
  background-color: ${({ bgColor }) => bgColor};
  height: calc(
    400px + ${$.layout().padding2}px - (${$.layout().padding3}px * 2)
  );
  padding: ${$.layout().padding3}px;
  border-radius: ${({
    corners: { topLeft, topRight, bottomLeft, bottomRight },
  }) => {
    const top = {
      left: topLeft ? `${$.border().radius1}px` : '0',
      right: topRight ? `${$.border().radius1}px` : '0',
    };
    const bottom = {
      left: bottomLeft ? `${$.border().radius1}px` : '0',
      right: bottomRight ? `${$.border().radius1}px` : '0',
    };

    return `${top.left} ${top.right} ${bottom.right} ${bottom.left}`;
  }};

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const StyledDescription = styled(Description)`
  flex-grow: 1;
`;

const Overlay = ({
  color,
  age,
  title,
  subtitle,
  button,
  subColor,
  description,
  corners,
}) => (
  <Container bgColor={color} corners={corners}>
    <Age>{age}</Age>
    <Title>{title}</Title>
    <Subtitle textColor={subColor}>{subtitle}</Subtitle>
    <StyledDescription>{description}</StyledDescription>
    <StyledButton
      clickedColor={$.color.white}
      normalColor={$.color.white}
      clickedText={color}
      normalText={color}
    >
      {button}
    </StyledButton>
  </Container>
);

Overlay.defaultProps = {
  color: '',
  age: '',
  title: '',
  subtitle: '',
  button: '',
  corners: {
    topLeft: false,
    topRight: false,
    bottomLeft: false,
    bottomRight: false,
  },
  subColor: '',
  description: '',
};

Overlay.propTypes = {
  color: PropTypes.string,
  age: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.string,
  corners: PropTypes.shape({
    topLeft: PropTypes.bool,
    topRight: PropTypes.bool,
    bottomLeft: PropTypes.bool,
    bottomRight: PropTypes.bool,
  }),
  subColor: PropTypes.string,
  description: PropTypes.string,
};

export default Overlay;
