import styled from 'styled-components';

import $ from '../../styles/global';
import Utils from '../../utils';

const Section = styled.section`
  overflow: hidden;
  background-color: ${$.color.black};
`;

const Container = styled.div`
  width: 100vw !important;
  margin: 0 !important;
  position: relative;
  padding: 100px 0;
`;

const ExtraFilterBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${$.color.blue5};
  opacity: 0.2;
`;

const ImageContainer = styled.div`
  ${({ img }) => `
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("${img}");
  filter: blur(4px) brightness(0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  `}
`;

const Cards = styled.div`
  padding: 100px 0;
  margin: 0 calc((100vw - 970px) / 2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    justify-content: initial;

    & > *:not(:last-child) {
      margin-bottom: ${$.layout().margin1}px;
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin: 0 ${$.layout().margin3}px;
    flex-direction: column;
    justify-content: initial;

    & > *:not(:last-child) {
      margin-bottom: ${$.layout().margin1}px;
    }
  }
  // #endregion
`;

const Card = styled.div`
  color: ${$.color.white};
  width: 28%;
  text-align: center;

  & > div {
    &:first-child {
      font-size: 60px;
      font-family: Lato Bold;
      margin-bottom: ${$.layout().margin3}px;
    }
    &:last-child {
      font-size: 18px;
      font-family: Lato Light;
      line-height: 1.3em;
    }
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    width: 100%;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const items = Utils.addKeys([
  {
    title: '8000+',
    desc: 'students of all ages who have completed programmes with us',
  },
  {
    title: '4.9/5.0',
    desc: 'overall rating from kids, teens and working professionals who learn with us',
  },
  {
    title: '6+ years',
    desc: "running Singapore' best technology programmes since 2016",
  },
]);

const Successes = () => (
  <Section>
    <Container>
      <ImageContainer
        img={Utils.importOneImage('tertiary/index/tertiary-2.jpg')}
      />
      <ExtraFilterBackground />
      <Cards>
        {items.map(({ key, title, desc }) => (
          <Card key={key}>
            <div>{title}</div>
            <div>{desc}</div>
          </Card>
        ))}
      </Cards>
    </Container>
  </Section>
);

export default Successes;
