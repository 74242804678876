const initialState = {
  show: false,
  video: '',
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case 'MODAL_UPDATE':
      if (payload.show === false) {
        return { ...state, show: false };
      }

      return { ...state, ...payload };
    default:
      return state;
  }
};
