import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './context';
import Dropdown from './Dropdown';

const Container = styled.div`
  grid-area: syllabus;
`;

const ListTitle = styled.h3``;

const ListsContainer = styled.div``;

const ListContainer = styled.div``;

const List = styled.ul``;

const ListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.layout().margin5}px;
`;

const Syllabus = () => {
  const { syllabus } = useContext(Context);

  return (
    <Container>
      <Dropdown title="Syllabus" contentLength={syllabus.length * 30}>
        <ListsContainer>
          {syllabus.map(({ id, title, content }) => (
            <ListContainer key={id}>
              <ListTitle>{title}</ListTitle>
              <List>
                {content.map(({ id, name }) => (
                  <ListItem key={id}>{name}</ListItem>
                ))}
              </List>
            </ListContainer>
          ))}
        </ListsContainer>
      </Dropdown>
    </Container>
  );
};

export default Syllabus;
