import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';
import WeeklyIcon from '../../../assets/icons/schedule/weekly.svg';
import HolidayCampIcon from '../../../assets/icons/schedule/holidaycamp.svg';
import TrialClasses from '../../../assets/icons/schedule/trialclasses.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(120px + ${$.layout().margin5}px);

  & > svg {
    width: 50px;
    height: 50px;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc((100% - ${$.layout().padding2}px) * (1 / 8));
    height: 100%;

    & > svg {
      width: 35px;
      height: 35px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin4}px;

    & > svg {
      margin-right: ${$.layout().margin5}px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;

    & > svg {
      margin-right: ${$.layout().margin5}px;
    }
  }
  // #endregion
`;

const Text = styled.div.attrs(({ courseType }) => ({
  style: {
    color: courseType === 'Weekly Classes' ? $.color.blue3 : $.color.blue6,
  },
}))`
  font-family: Cooper Hewitt Semibold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 15px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 14px;
    text-align: left;
    padding: 0px;
    position: relative;
    top: 3px;
  }
  // #endregion
`;

const CourseType = memo(({ courseType }) => (
  <Container>
    {courseType === 'Weekly Classes' ? <WeeklyIcon /> : ''}
    {courseType === 'Holiday Camp' ? <HolidayCampIcon /> : ''}
    {courseType === 'Trial Classes' ? <TrialClasses /> : ''}
    <Text courseType={courseType}>
      {courseType.split(' ')[0]}
      {!!courseType.split(' ')[1] && (
        <>
          <br />
          {courseType.split(' ')[1]}
        </>
      )}
    </Text>
  </Container>
));

CourseType.propTypes = {
  courseType: PropTypes.string.isRequired,
};

export default CourseType;
