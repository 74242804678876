import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/global';

const Container = styled.div`
  margin-bottom: ${$.layout().margin3}px;
  display: flex;
  flex-direction: row;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    border-radius: ${$.border().radius1}px;
    box-shadow: ${$.dropShadow.normal};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    border-radius: ${$.border().radius1}px;
    box-shadow: ${$.dropShadow.normal};
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const Image = styled.div`
  background: url(${({ image }) => image});
  width: 40%;
  background-size: cover;
  background-position: center;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    border-top-right-radius: ${$.border().radius1}px;
    border-bottom-right-radius: ${$.border().radius1}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    border-top-right-radius: ${$.border().radius1}px;
    border-bottom-right-radius: ${$.border().radius1}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    height: 340px;
  }
  // #endregion
`;

const Info = styled.div`
  background-color: ${$.color.blue4};
  text-align: initial;
  padding: ${$.layout().padding2}px ${$.layout().padding3}px;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: calc(60% - ${$.layout().padding3 * 2}px);
    border-top-left-radius: ${$.border().radius1}px;
    border-bottom-left-radius: ${$.border().radius1}px;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(60% - ${$.layout().padding3 * 2}px);
    border-top-left-radius: ${$.border().radius1}px;
    border-bottom-left-radius: ${$.border().radius1}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding2}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.white};
  margin-bottom: ${$.layout().margin3}px;
  font-size: 28px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 24px;
  }
  // #endregion
`;

const Desc = styled.div`
  ${$.br}

  line-height: 1.4em;
  font-size: 17px;
  color: ${$.color.white};

  a {
    font-family: Lato Bold;
    text-decoration: none;
    color: ${$.color.white};
  }

  ul,
  ol {
    padding-left: ${$.layout().padding3}px;
    li {
      margin-bottom: ${$.layout().margin5}px;
      line-height: 1.4em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const JobCard = ({ title, image, description }) => (
  <Container>
    <Info>
      <Title>{title}</Title>
      <Desc dangerouslySetInnerHTML={{ __html: description }} />
    </Info>
    <Image image={image} />
  </Container>
);

JobCard.defaultProps = {
  title: '',
  image: '',
  description: '',
};

JobCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

export default JobCard;
