import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import { previousBlogPageUpdate } from '../../actions';
import Button from '../Button';
import Card from './Card';
import Util from '../../utils';
import $ from '../../styles/global';

const Section = styled.section`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: relative;
    top: -70px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    position: relative;
    top: -50px;
  }
  // #endregion
`;

const Container = styled.div``;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    margin-top: ${$.layout().margin4}px;
  }
  // #endregion
`;

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  &.hide {
    display: none;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: 50vw;
    position: relative;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 80vw;
    position: relative;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Posts = ({ posts, featured }) => {
  const [page, setPage] = useState(() => {
    const params =
      typeof window !== 'undefined'
        ? new URLSearchParams(window.location.search)
        : null;
    const currPage = params !== null ? params.get('page') : 0;

    return parseInt(currPage, 10) || 1;
  });
  const postsPerPage = 5;
  const remainingPosts = posts.length % postsPerPage;
  const pages = Math.floor(posts.length / postsPerPage) + remainingPosts;
  const arrangement = { twos: [], threes: [] };
  const indexesPerPage = [];
  // keeps track of blog pagination, so it remains the same whenever user goes back
  // adding scroll position can be found in gatsby-browser exports.shouldScrollUpdate
  const [updatePreviousBlogPage] = Util.useActions([previousBlogPageUpdate]);

  /**
   * Arrange cards into 3 cards, 2 cards or just 1 card in a row
   * 3 -> 3 cards in a row. Use 30% width cards.
   * 2 -> 2 cards in a row. Use 50% width cards.
   * 1 -> 1 card in a row. Use 100% width cards.
   * twos -> The position and size of this card if a row can go up to two cards in a row.
   * threes -> The position and size of this card if a row can go up to three cards
   * in a row.
   * @param {number} length - Total card for current row
   */
  const getArrangementPerRow = (length) => {
    const threes = {
      remaining: length % 3,
      perRows: (length - (length % 3)) / 3,
      arrangement: [],
    };
    const twos = {
      remaining: length % 2,
      perRows: (length - (length % 2)) / 2,
      arrangement: [],
    };
    const addRemaining = (by) => {
      if (by.remaining > 0) {
        if (by.remaining === 1) {
          by.arrangement.push(1);
        } else {
          by.arrangement.push(2, 2);
        }
      }
    };

    Array.from({ length: threes.perRows }).forEach(() => {
      threes.arrangement.push(3, 3, 3);
    });

    Array.from({ length: twos.perRows }).forEach(() => {
      twos.arrangement.push(2, 2);
    });

    addRemaining(threes);
    addRemaining(twos);

    return { twos: twos.arrangement, threes: threes.arrangement };
  };

  // Depending on total pages, we will base our card arrangement depending on total cards per page
  Array.from({ length: pages }).forEach((_, index) => {
    const firstItem = index * postsPerPage;
    const lastItem = firstItem + postsPerPage;
    const perRow = getArrangementPerRow(
      posts.slice(firstItem, lastItem).length
    );

    // we arrange card indexes into pages so we know which card belongs to which page
    // despite having a flat array
    indexesPerPage.push([firstItem, lastItem - 1]);

    // we merge cards into a single array for easy looping
    arrangement.twos = [...arrangement.twos, ...perRow.twos];
    arrangement.threes = [...arrangement.threes, ...perRow.threes];
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && page > 1) {
      const fullUrl = `${window.location.origin}${window.location.pathname}${
        window.location.pathname === '/blog' ? '/' : ''
      }`;

      window.history.replaceState('', '', `${fullUrl}?page=${page}`);
    }
  }, [page]);

  /**
   * { pos: 1, size: 1 } -> First card in the row of 1 card. Create a 100% width card.
   * { pos: 2, size: 3 } -> Second card in the row of 3 cards. Create a 30% width card.
   * { pos: 2, size: 2 } -> Last card in the row of 2 cards. Create a 50% width card.
   */

  return (
    <Section>
      <Container>
        <Fade distance="100px" bottom>
          <ListContainer>
            <Card
              {...featured}
              threes={{ pos: 1, size: 1 }}
              twos={{ pos: 1, size: 1 }}
            />
            {posts.map(({ key, ...post }, index) => {
              // We use indexesPerPage to tell us which card belongs to which row,
              // and create new indexes based on that info
              const newIndex = (() => {
                let currPage = 1;

                indexesPerPage.forEach((itemTotal, pageNum) => {
                  const [firstItem, lastItem] = itemTotal;

                  if (index >= firstItem && index <= lastItem) {
                    currPage = pageNum;
                  }
                });

                return index - currPage * postsPerPage;
              })();

              return (
                <Card
                  {...post}
                  key={key}
                  hidden={index >= postsPerPage * page}
                  twos={{
                    pos: (newIndex % 2) + 1,
                    size: arrangement.twos[index],
                  }}
                  updatePreviousBlogPage={updatePreviousBlogPage}
                  threes={{
                    pos: (newIndex % 3) + 1,
                    size: arrangement.threes[index],
                  }}
                />
              );
            })}
          </ListContainer>
        </Fade>
        {page * postsPerPage <= posts.length && (
          <StyledButton
            onClick={() => {
              setPage((prev) => prev + 1);
            }}
          >
            Load More
          </StyledButton>
        )}
      </Container>
    </Section>
  );
};

Posts.propTypes = {
  posts: PropTypes.oneOfType([PropTypes.array]).isRequired,
  featured: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Posts;
