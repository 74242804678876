import { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../context';
import data from '../items.json';
import Menu from '../Menu';
import BarActions from '../BarActions';
import {
  DropdownText,
  PillContainer,
  PillText,
  StyledRotatingDownChevron,
} from './DropdownStyles';

const NormalDropdown = ({ name, picker }) => {
  const {
    searchBarData,
    searchBarUIData,
    searchBarDataUpdate,
    searchBarUIUpdate,
  } = useContext(Context);

  return (
    <DropdownText className={searchBarUIData.clicked === name ? 'active' : ''}>
      <PillContainer
        onClick={() => {
          BarActions.handleOnClick({
            key: name,
            searchBarUIUpdate,
            clicked: searchBarUIData.clicked,
            picker,
          });
        }}
      >
        <PillText>
          {BarActions.getStringifiedChoices({
            availableChoices: data[name],
            chosenChoices: searchBarData[name],
          })}
        </PillText>
        <StyledRotatingDownChevron
          counter={searchBarUIData.rotatingCounter[name]}
        />
      </PillContainer>
      <Menu
        onChange={(value, checked) => {
          const checkedItems = {};

          checkedItems[value] = checked;

          BarActions.handleOnChange({
            key: name,
            checkedItems,
            searchBarData,
            searchBarDataUpdate,
          });
        }}
        list={(data[name] || []).map((item) => {
          const newItem = { ...item };

          newItem.checked = searchBarData[name].indexOf(newItem.value) > -1;

          return newItem;
        })}
      />
    </DropdownText>
  );
};

NormalDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  picker: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default NormalDropdown;
