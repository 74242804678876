import PropTypes from 'prop-types';

import { Margin20, RemoteLink } from '../Misc';
import Table from '../Table';
import QnADropdown from '../QnADropdown';

const FundingTable = ({ firstRow, secondRow, thirdRow }) => (
  <>
    <Table>
      <thead>
        <tr>
          <td className="highlight1">Participant Profile</td>
          <td className="highlight2">Self-Sponsored</td>
          <td className="highlight2">SME (Company Sponsored)</td>
          <td className="highlight2">Non-SME (Company Sponsored)</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="highlight1">
            Singapore Citizen &lt; 40 years old
            <br />
            Permanent Resident
            <br />
            LTVP+
          </td>
          {firstRow.map((elem, index) => (
            <td key={`sgcitizen_${index}`}>{elem}</td>
          ))}
        </tr>
        <tr>
          <td className="highlight1">Singapore Citizen ≥ 40 years old</td>
          {secondRow.map((elem, index) => (
            <td key={`sgcitizenolder_${index}`}>{elem}</td>
          ))}
        </tr>
        <tr>
          <td className="highlight1">International Participant</td>
          {thirdRow.map((elem, index) => (
            <td key={`international_${index}`}>{elem}</td>
          ))}
        </tr>
      </tbody>
    </Table>
    <p>
      <em>All prices include 8% GST</em>
    </p>
    <Margin20 />
    <p>
      <strong>Post Secondary Education Account (PSEA)</strong>
      <br />
      PSEA can be utilised for subsidised programmes eligible for SkillsFuture
      Credit support. Click{' '}
      <RemoteLink href="https://www.myskillsfuture.gov.sg/content/portal/en/career-resources/career-resources/how-to-guides/all-you-need-to-know-about-psea-and-how-you-can-use-it-for-your-upskilling-journey.html">
        here
      </RemoteLink>{' '}
      to find out more.
    </p>
    <Margin20 />
    <p>
      <strong>Self Sponsored</strong>
    </p>
    <QnADropdown ques="SkillsFuture Credit">
      Singapore Citizens aged 25 and above may use their SkillsFuture Credits to
      pay for the course fees. The credits may be used on top of existing course
      fee funding.
      <br />
      This is only applicable to <strong>self-sponsored</strong> participants.
      Applications via{' '}
      <RemoteLink href="https://www.myskillsfuture.gov.sg/content/portal/en/index.html">
        MySkillsFuture.sg
      </RemoteLink>{' '}
      must be made within 60 days before course commencement.
      <br />
      SMU Academy has to be informed on the Claim ID no. and claim amount in
      order for the correct payable course fee amount to be reflected on the
      invoice. Please click{' '}
      <RemoteLink href="https://www.myskillsfuture.gov.sg/content/portal/en/header/faqs/skillsfuture-credit.html">
        here
      </RemoteLink>
      for more details on SkillsFuture Credit.
    </QnADropdown>
    <QnADropdown ques="Workfare Skills Support Scheme">
      Workfare Skills Support (WSS) Scheme in replacement of Workfare Training
      Support (WTS) Scheme:
      <br />
      With effect from <strong>1 July 2020</strong>, applicants who wish to
      apply for the WTS Scheme may refer to the WSS Scheme instead. Please click{' '}
      <RemoteLink href="https://www.mom.gov.sg/-/media/mom/documents/budget2020/factsheet-workfare-skills-support-scheme.pdf?la=en&hash=EC176C8C553ECE399239FFD63CACB13B">
        here
      </RemoteLink>{' '}
      for more details.
    </QnADropdown>
    <p>
      <strong>Company Sponsored</strong>
    </p>
    <QnADropdown ques="Enhanced Training Support Scheme">
      <ul>
        <li>Organisation must be registered or incorporated in Singapore</li>
        <li>
          Employment size of not more than 200 or with annual sales turnover of
          not more than $100 million
        </li>
        <li>
          Trainees must be hired in accordance with the Employment Act and fully
          sponsored by their employers for the course
        </li>
        <li>
          Trainees must be Singapore Citizens or Singapore Permanent Residents
        </li>
        <li>Trainees must not be a full-time national serviceman</li>
      </ul>
      Please click{' '}
      <RemoteLink href="https://www.ssg.gov.sg/programmes-and-initiatives/training/enhanced-training-support-for-smes.html">
        here
      </RemoteLink>{' '}
      for more information on ETSS.
    </QnADropdown>
    <QnADropdown ques="Absentee Payroll">
      Companies who sponsor their employees for the course may apply for
      Absentee Payroll{' '}
      <RemoteLink to="https://www.gobusiness.gov.sg/enterprisejobskills/programmes-and-initiatives/upgrade-skills/course-fee-absentee-payroll-funding/">
        here
      </RemoteLink>
      . For more information, please refer to:
      <br />
      <RemoteLink href="https://psgsfts.enterprisejobskills.gov.sg/Callbackhandler/PdfViewer.aspx?IsAPGuide=True">
        AP Guide (Non-SME Companies)
      </RemoteLink>
      <br />
      <RemoteLink href="https://psgsfts.enterprisejobskills.gov.sg/Callbackhandler/PdfViewer.aspx?IsSMEGuide=True">
        Declaration Guide (SME Companies)
      </RemoteLink>
    </QnADropdown>
  </>
);

FundingTable.defaultProps = {
  firstRow: [],
  secondRow: [],
  thirdRow: [],
};

FundingTable.propTypes = {
  firstRow: PropTypes.arrayOf(PropTypes.element),
  secondRow: PropTypes.arrayOf(PropTypes.element),
  thirdRow: PropTypes.arrayOf(PropTypes.element),
};

export default FundingTable;
