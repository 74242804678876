import styled from 'styled-components';

import $ from '../../styles/global';
import Util from '../../utils';
import { videoLoginStatusUpdate } from '../../actions';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${$.layout().margin3}px;
`;

const SignOutButton = styled.div`
  color: ${$.color.blue3};
  align-self: flex-end;
  cursor: pointer;
`;

const SignOut = () => {
  const [loggedOut] = Util.useActions([videoLoginStatusUpdate]);
  const handleSignOut = () => {
    if (typeof window !== 'undefined') {
      localStorage.clear('schoolsPageLoginState');
      loggedOut();
    }
  };

  return (
    <Container>
      <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
    </Container>
  );
};

export default SignOut;
