import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import SubHeader from './SubHeader';
import MainInformation from './MainInformation';
import SubInformation from './SubInformation';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc((100% - ${$.layout().padding2}px) * (1 / 8));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    flex-grow: 10;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const EarlyBirdInfo = styled(SubInformation)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-family: Lato Regular;
    color: ${$.color.orange2};
    text-transform: initial;
    font-size: 11px;
    font-style: italic;
    text-align: center;
    margin-top: ${$.layout().margin5}px;
  }
  // #endregion
`;

const StyledMainInformation = styled(MainInformation)`
  text-align: center;
  display: flex;
  flex-direction: column;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    align-items: center;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    align-items: flex-start;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    align-items: flex-start;
  }
  // #endregion
`;

const MainPrice = styled.div`
  position: relative;
`;

const CoursePrice = memo(
  ({
    coursePrice: { main, earlyBird, perLesson, preGST },
    courseStartDate,
  }) => {
    const showPrice = () => {
      if (typeof perLesson !== 'undefined') {
        return `$${perLesson} per lesson`;
      }

      if (main === 0) {
        return 'Free';
      }

      return `$${main} w/GST <br /> ($${preGST} w/o GST)`;
    };

    return (
      <Container>
        <SubHeader>Price</SubHeader>
        <StyledMainInformation>
          <MainPrice dangerouslySetInnerHTML={{ __html: showPrice() }} />
        </StyledMainInformation>
        {earlyBird &&
        courseStartDate - new Date().getTime() > 14 * 24 * 60 * 60 * 1000 ? (
          <EarlyBirdInfo>Early Bird Prices Available</EarlyBirdInfo>
        ) : (
          ''
        )}
      </Container>
    );
  }
);

CoursePrice.propTypes = {
  coursePrice: PropTypes.shape({
    main: PropTypes.number,
    perLesson: PropTypes.number,
    earlyBird: PropTypes.bool,
    preGST: PropTypes.number,
  }).isRequired,
  courseStartDate: PropTypes.number.isRequired,
};

export default CoursePrice;
