import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import LeftChevron from '../Chevron/LeftChevron';
import RightChevron from '../Chevron/RightChevron';
import Bubble from './Bubble';
import Chevron from './Chevron';
import { Context } from './Context';

const TopSliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

const Slider = styled.div`
  ${({ counter }) => `
  display: flex;
  flex-direction: row;
  ${`transform: translateX(calc(-${counter} * (100vw - ${
    $.layout().margin3
  }px * 2)));`}
  transition: transform 0.5s ${$.easingFn.standard};
`}
`;

/**
 * To ensure the div does not take up space, it's best to animate max-height and opacity,
 * since display property can't be animated. To tweak the animation speed, use cubic-bezier.
 */
const Description = styled.div`
  font-family: Lato Regular;
  color: ${$.color.blue5};
  background-color: ${$.color.blue1};
  margin-bottom: ${$.layout().margin3}px;
  line-height: 1.4em;
  position: relative;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
    padding: ${$.layout().padding2}px;
    left: -${$.layout().padding2}px;
    width: calc(100vw - ${$.layout().padding2}px * 2);
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 16px;
    padding: ${$.layout().padding2}px;
    left: -${$.layout().padding2}px;
    width: calc(100vw - ${$.layout().padding2}px * 2);
  }
  // #endregion
`;

const TopSlider = () => (
  <Context.Consumer>
    {({ current, desc, data, setCurrent, setDesc }) => (
      <Fade bottom distance="100px">
        <TopSliderContainer
          className={data.map(({ bubble: { anchor } }) => anchor).join(' ')}
        >
          <Chevron left>
            <LeftChevron />
          </Chevron>
          <Slider counter={current}>
            {data.map(
              ({ bubble: { anchor, content }, avatarURL, key }, index) => (
                <Bubble
                  key={key}
                  index={index}
                  counter={current}
                  anchor={anchor}
                  avatarURL={avatarURL}
                  desc={data[index].description}
                  setCurrent={setCurrent}
                  setDesc={setDesc}
                >
                  {content}
                </Bubble>
              )
            )}
          </Slider>
          <Chevron left={false}>
            <RightChevron />
          </Chevron>
        </TopSliderContainer>
        <Description>{desc}</Description>
      </Fade>
    )}
  </Context.Consumer>
);

export default TopSlider;
