import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => {
    return theme.layout().padding3;
  }}px;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.device.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const EventsContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

EventsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

EventsContainer.defaultProps = {};

export default EventsContainer;
