import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import Util from '../../../utils';
import SubHeader from './SubHeader';
import MainInformation from './MainInformation';
import SubInformation from './SubInformation';
import FullSchedule from './FullSchedule';

const Container = styled.div`
  width: 100%;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    width: calc((100% - ${$.layout().padding2}px) * (1.7 / 8));
  }
  // #endregion

  // NOTE: Tablets and below
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  // #endregion
`;

const CourseDates = memo(({ courseDates, type }) => {
  const startDayMonth = Util.parseTimeToDayMonth(courseDates.startDate);
  const endDayMonth = courseDates.endDate
    ? Util.parseTimeToDayMonth(courseDates.endDate)
    : null;
  const hideEndDay =
    typeof courseDates.endDay === 'undefined' ||
    (type === 'Weekly Classes' && courseDates.startDay === courseDates.endDay);

  return (
    <Container>
      <SubHeader>Dates</SubHeader>
      <MainInformation>
        {`${startDayMonth} ${endDayMonth ? `- ${endDayMonth}` : ''}, `}
        <span>
          {hideEndDay === true
            ? courseDates.startDay
            : `${courseDates.startDay} - ${courseDates.endDay}`}
        </span>
      </MainInformation>
      <SubInformation>
        {`${courseDates.sessionLength} hr`}
        {` ${hideEndDay === true ? 'session' : 'sessions'}`}
        {`${
          hideEndDay === true
            ? ''
            : ` X ${courseDates.sessionNumOfDays} ${courseDates.day}`
        }`}
      </SubInformation>
      <FullSchedule />
    </Container>
  );
});

CourseDates.propTypes = {
  type: PropTypes.string.isRequired,
  courseDates: PropTypes.shape({
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    startDay: PropTypes.string,
    endDay: PropTypes.string,
    sessionNumOfDays: PropTypes.number,
    sessionLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    day: PropTypes.string,
    full: PropTypes.arrayOf(PropTypes.number),
    skipped: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default CourseDates;
