import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Provider } from './Context';
import $ from '../../../styles/global';
import {
  recommenderQ1UpdateSelection as q1Update,
  recommenderQ2UpdateSelection as q2Update,
} from '../../../actions';
import data from '../../../masterCourseEventListWithKeys';
import Questions from './Questions';
import Registration from './Registration';
import CoursesScheduleViewer from '../../CoursesScheduleViewer';
import Banner from '../../../assets/images/courserecommender/no-campus.svg';
import Util from '../../../utils';

const Section = styled.section`
  margin-top: ${$.layout().margin2}px;
`;

const Container = styled.div`
  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  // #endregion
`;

const StyledBanner = styled(Banner)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: none;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    margin: 0 auto ${$.layout().margin2}px auto;
    width: 70%;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
    width: 100%;
  }
  // #endregion
`;

const CSVContainer = styled.div`
  ${({ q1Answer, q2Answer }) =>
    q1Answer && q2Answer
      ? 'height: auto;'
      : `height: 0px;
    overflow: hidden;`}
`;

const memoizedFilteringLogic = () => {
  const cache = {};
  return (arr, q1Answer, q2Answer) => {
    const stringifiedAnswers = `q1Answer: ${q1Answer}, q2Answer: ${q2Answer}`;
    if (stringifiedAnswers in cache) {
      return cache[stringifiedAnswers];
    }
    const result = arr.filter((ele) => {
      if (q2Answer !== 'No') {
        return false;
      }
      if (
        ['7', '8'].includes(q1Answer) &&
        ['Basics1'].includes(ele.courseName)
      ) {
        return true;
      }
      if (
        ['9', '10'].includes(q1Answer) &&
        ['Basics3'].includes(ele.courseName)
      ) {
        return true;
      }
      if (
        ['11', '12'].includes(q1Answer) &&
        ['JrPython1'].includes(ele.courseName)
      ) {
        return true;
      }
      if (['13', '14', '15', '16'].includes(q1Answer)) {
        if (['Principles1'].includes(ele.courseName)) {
          return true;
        }
        if (
          ['15', '16'].includes(q1Answer) &&
          ['OLevel'].includes(ele.courseName)
        ) {
          return true;
        }
      }
      if (['17', '18'].includes(q1Answer)) {
        if (['Principles1'].includes(ele.courseName)) {
          return true;
        }

        if (['Java1'].includes(ele.courseName)) {
          return true;
        }
      }

      return false;
    });

    cache[stringifiedAnswers] = result;

    return result;
  };
};

const filteringLogic = memoizedFilteringLogic();

const Recommender = () => {
  const [answered, setAnswered] = useState(false);
  const recommenderQ1Selection = useSelector(
    ({ recommenderQ1Selection: q1 }) => q1
  );
  const recommenderQ2Selection = useSelector(
    ({ recommenderQ2Selection: q2 }) => q2
  );
  const [recommenderQ1UpdateSelection, recommenderQ2UpdateSelection] =
    Util.useActions([q1Update, q2Update]);

  useEffect(
    () => () => {
      recommenderQ1UpdateSelection('');
      recommenderQ2UpdateSelection('');
    },
    []
  );

  return (
    <Provider
      value={{
        answered,
        setAnswered,
        recommenderQ1Selection,
        recommenderQ2Selection,
        recommenderQ1UpdateSelection,
        recommenderQ2UpdateSelection,
      }}
    >
      <Section>
        <Container>
          <StyledBanner />
          <Questions />
          <CSVContainer
            q1Answer={recommenderQ1Selection}
            q2Answer={recommenderQ2Selection}
          >
            <CoursesScheduleViewer
              data={data}
              q2Answer={recommenderQ2Selection}
              filteredData={filteringLogic(
                data,
                recommenderQ1Selection,
                recommenderQ2Selection
              )}
              noCourseBannerTitle="There are currently no courses that fit your criteria."
              noCourseBannerSubtitle='Let us know your preferred course schedule by filling in this <a href="https://forms.gle/d2bj5upmM2LsC1UM8" target="_blank" rel="noreferrer noopener">form</a> or contact us at <a href="mailto:enquiries@sgcodecampus.com">enquiries@sgcodecampus.com</a>.'
              priorExpBannerSubtitle='Students with prior coding experience, including students currently studying Computing for the iGCSE, IB or "O" Levels, are required to take a placement test before being placed in our advanced classes. To enquire about the placement test, please click <a href="https://docs.google.com/forms/d/e/1FAIpQLSeDjtfkjdlQKxtJH8ws9zyQAtpM9KTyc3ptcXNo5-s78d56AQ/viewform?embedded=true" target="_blank" rel="noreferrer noopener">here.</a>'
            />
          </CSVContainer>
          <Registration />
        </Container>
      </Section>
    </Provider>
  );
};

export default Recommender;
