import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import Header from './Header';

const Section = styled.section`
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-top: ${$.layout().margin1}px;
    margin-bottom: ${$.layout().margin1}px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-top: ${$.layout().margin2}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-top: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Container = styled.div``;

const Step1 = () => (
  <Section>
    <Fade bottom distance="100px">
      <Container>
        <Header
          title="Step 1: Find a suitable coding bootcamp for your child"
          subtitle="As one of Singapore’s largest coding schools for kids and teens, we offer the deepest and most comprehensive programme and curriculum in this industry. Choose from over 30 thoughtfully designed, developmentally appropriate coding courses that marry a traditional Computer Science education with a real-world focus on leading-edge technologies and current trends.<br />Whether you’re looking for coding enrichment classes or to get a head start on your academic computer science subjects, we’ve got just the right course for you. Do also check out some of our special holiday-only coding bootcamps in Singapore for your kids to spend a fruitful school vacation!<br />Still not sure how to get started? Don’t worry! Read our <a href='/blog/how-kids-teens-start-coding/'>article on how to get your kid or teenager started with coding</a> or allow us to <a href='/coding-course-recommender/'>recommend a course</a> to you. We will answer all your basic questions!"
        />
      </Container>
    </Fade>
  </Section>
);

export default Step1;
