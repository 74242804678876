import { useContext } from 'react';
import styled from 'styled-components';

import { OutboundLink } from './common';
import { Context } from './context';
import Particles from './Particles';

const Section = styled.section`
  background-color: ${({ theme }) => {
    return theme.color.blue5;
  }};
`;

const Container = styled.div`
  display: flex;
  position: relative;
  min-height: 620px;

  @media ${({ theme }) => {
      return theme.device.desktop;
    }} {
    width: 1100px !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  @media ${({ theme }) => {
      return theme.device.tablet;
    }} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${({ theme }) => {
      return theme.device.mobile;
    }} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const DetailsContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => {
      return theme.device.desktop;
    }} {
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
  }

  @media ${({ theme }) => {
      return theme.device.tablet;
    }} {
    justify-content: center;
    align-items: stretch;
  }

  @media ${({ theme }) => {
      return theme.device.mobile;
    }} {
    justify-content: flex-start;
    align-items: stretch;
  }
`;

const Title = styled.h1`
  font-family: ${({ theme }) => {
    return theme.fontFamily.title.heavy;
  }};
  font-size: ${({ theme }) => {
    return theme.fontSizes.xxxlarge;
  }};
  color: ${({ theme }) => {
    return theme.color.white;
  }};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: ${({ theme }) => {
    return theme.layout().margin4;
  }}px;

  @media ${({ theme }) => {
      return theme.device.mobile;
    }} {
    font-size: ${({ theme }) => {
      return theme.fontSizes.xlarge;
    }};
  }
`;

const Subtitle = styled.h3`
  color: ${({ theme }) => {
    return theme.color.white;
  }};
  font-family: ${({ theme }) => {
    return theme.fontFamily.body.regular;
  }};

  & a {
    cursor: pointer;
    color: ${({ theme }) => {
      return theme.color.blue2;
    }};

    :visited {
      color: ${({ theme }) => {
        return theme.color.blue2;
      }};
    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => {
    return theme.layout().margin2;
  }}px;

  @media ${({ theme }) => {
      return theme.device.desktop + ', ' + theme.device.tablet;
    }} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: ${({ theme }) => {
      return theme.layout().margin4;
    }}px;
  }

  @media ${({ theme }) => {
      return theme.device.mobile;
    }} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: ${({ theme }) => {
      return theme.layout().margin4;
    }}px;
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-self: stretch;

  @media ${({ theme }) => {
      return theme.device.desktop;
    }} {
    width: 80%;
  }
`;

const Banner = () => {
  const { title, description, coursePartnerLink } = useContext(Context);

  return (
    <Section>
      <Container>
        <DetailsContainer>
          <Title>{title}</Title>
          <LinksContainer>
            <OutboundLink href={coursePartnerLink}>Learn More</OutboundLink>
            <OutboundLink
              href={`mailto:enquiries@sgcodecampus.com?subject=${title}`}
            >
              Contact Us
            </OutboundLink>
          </LinksContainer>
          <Subtitle>{description}</Subtitle>
        </DetailsContainer>
        <BackgroundContainer>
          <Particles id={title.replace(/[^a-zA-Z0-9]/g, '-')} />
        </BackgroundContainer>
      </Container>
    </Section>
  );
};

export default Banner;
