import PhaseCard from './PhaseCard';
import addKeys from '../../../utils/addKeys';

const cardContent = addKeys([
  {
    title: 'Python Accelerated',
    text: 'In this module, students will continue to expand develop their Python skill set with Lists and Dictionaries. Students will learn to understand these data structures as bundles of simpler data used to represent more complex entities through data composition. In addition, this module will cover Object Oriented Programming paradigm, a powerful problem-solving approach that allows programmer to think about the behaviour of their program in terms of a collection of objects and how they interact, and enables the solving of complex problems.',
  },
  {
    title: 'Introduction to Data Science',
    text: 'Data Science is a multidisciplinary field comprising of Statistics, Machine Learning, Data Visualization, Database Programming and Computing. Through the introduction fo CRSIP-DM methodology, students will be exposed to field of computer science that deals with programming machines that finds patterns in data, an important part of solving business problems.',
  },
  {
    title: 'Data Munging with Numpy and Pandas Libraries',
    text: 'In this module, students will learn data wrangling, in which they will load data from external sources into their Python environment for processing and analysis in a Python environment.',
  },
  {
    title: 'Planning and Executing a Data Science Project Part 1',
    text: 'This is a hands-on module where students will practice how to conduct the process of storytelling to explain the patterns found. After being introduced to the AGILE framework, students will be split into groups and work with a Teaching Assistance on how to craft the user requirements for a business problem.',
  },
  {
    title: 'GUI Programming with Python',
    text: 'Learn to create interactive user interfaces and frontend with Python and bring your app ideas to life.',
  },
  {
    title: 'Incorporating External APIS into Your Applications',
    text: 'Students will have the opportunity to understand Application programming Interfaces (APIs) as programming tools that allows programmers to add third-party developed functionality and data to their applications.',
  },
  {
    title: 'AWS Cloud',
    text: 'During this module, students will be introduced to the AWS Console, AWS S3 service as a flexible file storage on cloud, IAM service for securing cloud applications, DynamoDB service for persistent structured data storage, Elastic Beanstalk for deploying application servers, Most importantly, they will use AWS services and host data-driven applications using Dash.',
  },
  {
    title: 'Planning and Executing a Data Science Project Part 2',
    text: 'In this module, students will gain an understanding on how to evaluate and deploy a data science project through examining a case study. They will learn how Netflix increased the accuracy of movie recommendations to its users by more than 10% in 2008 by using its user review data. At this time, students will also be introduced to a selection of challenge statements provided by the Tampines Town Council that they will address for their capstone project.',
  },
  {
    title: 'Machine Learning with AWS Sagemaker',
    text: 'Students will be introduced to AWS Sagemaker as an environment to run Machine Learning in the cloud. They will be exposed to Linear Regression, Logistic Regression and Regularisation. Overall, they will have a strong understanding of Machine Learning as computing the search of an appropriate explanatory function for a given dataset.',
  },
  {
    title: 'Planning and Executing a Data Science Project Part 3',
    text: 'Students will begin on their capstone data science project using CRISP-DM framework and AWS cloud services. Throughout the project, students will be guided by Teaching Assistants in the planning and execution of their work.',
  },
  {
    title: 'Deep Learning with Neural Networks',
    text: 'Learn how neural networks work and why they are the most powerful and effective tools in the field of artificial intelligence today. Build applications for machine vision and natural language processing use cases with Convolutional and Recurrent Neural Networks.',
  },
  {
    title: 'AWS Deeplens',
    text: 'Students will explore the AWS DeepLens for onsite real-time image capture. They will also be deploying machine vision models directly on the DeepLens and learning how to configure the DeepLens console. In addition, students will use numerous APIs including the Transcribe API which will turn speech to text.',
  },
  {
    title: 'Planning and Executing a Data Science Project Part 4',
    text: "Students will continue to work towards the completion of their capstone project. Along the way, students will be able to attend webinars hosted by AWS that showcases Data Scientists recounting how Data Science is relevant and crucial in today's world.",
  },
]);

const Phase2 = () => (
  <PhaseCard
    number={2}
    title={`Accelerator (19 June - 30 Nov 2021)`}
    content={cardContent}
  />
);

export default Phase2;
