import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../../styles/global';
import AutoFadeImages from './AutoFadeImages';
import BootcampTable from './BootcampTable';
import AcceleratorTable from './AcceleratorTable';

const Section = styled.section`
  background-color: transparent;
  padding: 80px 0;
`;

const Container = styled.div``;

const Title = styled.h2`
  font-family: Aileron Heavy;
  color: ${$.color.black2};
  font-size: 50px;
  margin-bottom: ${$.layout().margin3}px;
`;

const Subtitle = styled.h3`
  font-family: Aileron Heavy;
  color: ${$.color.black2};
  font-size: 30px !important;
  margin-bottom: ${$.layout().margin4}px;
`;

const Content = styled.div`
  font-size: 16px;
  color: ${$.color.black2};
  font-family: Lato Regular;
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin3}px;
  position: relative;
  z-index: 0;

  strong {
    font-family: Lato Bold;
    font-size: 15.5px;
  }

  a {
    font-family: Lato Bold;
    font-size: 15.5px;
    text-decoration: none;
    color: ${$.color.blue5};
    &:visited {
      color: ${$.color.blue5};
    }
  }

  ${$.br}
`;

const About = () => (
  <Section>
    <Container>
      <Title>About</Title>
      <Content>
        {'The '}
        <strong>Data Science Bootcamp and Accelerator Programme</strong>
        {` is an exciting new initiative for students aimed at introducing them to Python Programming and Deep learning using various cutting edge technologies from `}
        <strong>Amazon Web Services</strong>
        {` (AWS).`}
        <br />
        {`This programme, open to students enrolled in Singapore Ministry of
          Education (MOE) Secondary Schools and Junior College is fully
          subsidised for successful applicants, and each student participant of
          either the Bootcamp or Accelerator will receive an AWS cloud
          account endowed with US$100 worth of cloud computing credits. Each
          participant of the Accelerator segment will further receive AWS
          devices (`}
        <strong>DeepLens camera</strong>
        {` and `}
        <strong>DeepComposer keyboard</strong>
        {`) worth more than US$350, all kindly sponsored by AWS.`}
        <br />
        Applicants may choose to attend either one of, or both programmes,
        depending on their level of preparation and commitment.
        <br />
        Both programmes were successfully conducted and recently culminated in
        the graduation ceremony on 19 Feb 2022. (Click{' '}
        <Link to="/imda-grad-2021/">here</Link> for snippets of the ceremony and
        project showcases)
      </Content>
      <Subtitle>Bootcamp</Subtitle>
      <Content>
        The Bootcamp is an introductory programme that teaches budding techies
        about Python Programming and Deep Learning through programming the AWS
        DeepRacer - an autonomous self-driving model car. Students with little
        or no prior experience in computer programming are strongly encouraged
        to sign up.
        <br />
        {`Participants will learn about `}
        <Link to="/datascience-bootcamp-accelerator-2021/programme-outline/">
          Python Fundamentals
        </Link>
        {` and Cloud Computing over 22 hours during Week 1 June holidays. Due to COVID-19 social distancing measures, the Bootcamp will run in an
          entirely online format.`}
        <br />
        {`The inaugural Bootcamp culminates with a
          thrilling AWS DeepRacer league held (virtually) at a model car
          racetrack in `}
        <a
          href="https://dunmansec.moe.edu.sg/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dunman Secondary School
        </a>
        {`. The skills learned will be put to the test as students remotely
          program their cars over a live video stream and speed around to emerge
          victorious.`}
        <br />
        The schedule for the Bootcamp is as follows:
      </Content>
      <BootcampTable />
      <AutoFadeImages />
      <Subtitle>Accelerator</Subtitle>
      <Content>
        The Bootcamp will be followed by the Accelerator, an 88-hour programme
        where students dive into modern Data Science with Python and explore
        Deep Learning and its applications through devices like the AWS DeepLens
        and DeepComposer.
        <br />
        The Accelerator culminates in a capstone data science project developed
        using the skills acquired to address challenge statements inspired by
        issues encountered in the running of Tampines estate provided by
        Tampines Town Council. To facilitate their learning, each student will
        be provided with a DeepLens and DeepComposer sponsored by AWS.
        <br />
        Participants from the Bootcamp who perform well will be invited to join
        the Accelerator. Prospective applicants who do not attend the Bootcamp
        but have had prior programming experience are also invited to sign up
        for the Accelerator - admission to the programme will be subject to
        availability and based on their performance on an online coding test.
        <br />
        The schedule (may be subject to change) for the Accelerator is as
        follows (please ensure that you can attend at least 75% of the
        Accelerator sessions before you sign up):
      </Content>
      <AcceleratorTable />
      <Content>
        We are now in discussion with IMDA to bring you the next iterations of
        the accelerator programme for 2022 and beyond. If you are keen, do
        register your interest with us, and we will keep you posted!
      </Content>
    </Container>
  </Section>
);

export default About;
