import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { Margin20, RemoteLink } from '../Misc';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import QnADropdown from '../QnADropdown';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Course Objectives',
  },
  {
    text: 'Course Outline',
  },
  {
    text: 'Certification',
  },
  {
    text: 'Price',
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Make architectural decisions based on AWS architectural principles and best practices',
  },
  {
    text: 'Leverage AWS services to make your infrastructure scalable, reliable, and highly available',
  },
  {
    text: 'Leverage AWS Managed Services to enable greater flexibility and resiliency in an infrastructure',
  },
  {
    text: 'Make an AWS-based infrastructure more efficient to increase performance and reduce costs',
  },
  {
    text: 'Use the Well-Architected Framework to improve architectures with AWS solutions',
  },
]);

const courseOutline = Utils.addKeys([
  {
    text: 'The Well-Architected Framework',
  },
  {
    text: 'Networking with AWS',
  },
  {
    text: 'Core AWS concepts, knowledge, and services, including designing your environment and making your environment highly available',
  },
  {
    text: 'Event-driven scaling',
  },
  {
    text: 'Automation',
  },
  {
    text: 'Decoupling',
  },
  {
    text: 'Serverless designs',
  },
  {
    text: 'How to grow your architecture from small to extremely large',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'Solutions architects',
  },
  {
    text: 'Solution-design engineers',
  },
  {
    text: 'Developers seeking an understanding of AWS architecting',
  },
]);

const enquiryLink =
  'https://www.ntuclearninghub.com/enquiry-form?course_name=ARCHITECTING%20ON%20AWS&course_code=E-AOA&course_type=ITT';

const ArchitectingOnAWS = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner image={bgImage} title="Architecting on AWS" />
      <MobileNavigationMenu
        items={navigationLinks}
        enquiryLink={enquiryLink}
        disableApplyNowButton
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              This certification & training course covers the fundamentals of
              building IT infrastructure on the AWS platform. Students learn how
              to optimize the AWS Cloud by understanding how AWS services fit
              into cloud-based solutions. In addition, students explore AWS
              Cloud best practices and design patterns for architecting optimal
              IT solutions on AWS, and build a variety of infrastructures in
              guided, hands-on activities. The course also covers how to create
              fledgling architectures and build them into robust and adaptive
              solutions.
            </p>
            <Table>
              <tbody>
                <tr>
                  <td className="highlight1">Course Code</td>
                  <td>E-AOA</td>
                </tr>
                <tr>
                  <td className="highlight1">Course Duration</td>
                  <td>24 hours</td>
                </tr>
                <tr>
                  <td className="highlight1">Medium of Instruction</td>
                  <td>English</td>
                </tr>
                <tr>
                  <td className="highlight1">Trainer:Trainee Ratio</td>
                  <td>1:20</td>
                </tr>
                <tr>
                  <td className="highlight1">Mode of Delivery</td>
                  <td>Online Instructor-Led Training via VLC</td>
                </tr>
                <tr>
                  <td className="highlight1">Courseware</td>
                  <td>AWS</td>
                </tr>
                <tr>
                  <td className="highlight1">Lab</td>
                  <td>Qwiklabs</td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection position={1}>
            <h2>Course Objectives</h2>
            <p>In this course, you will learn how to:</p>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
            <Margin20 />
            <p>
              <strong>Prerequisites</strong>
            </p>
            <p>Learners are assumed to have:</p>
            <ul>
              <li>
                Taken the AWS Cloud Practitioner Essentials classroom or digital
                training
              </li>
              <li>Working knowledge of distributed systems</li>
              <li>Familiarity with general networking concepts</li>
              <li>Familiarity with IP addressing</li>
              <li>Working knowledge of multi-tier architectures</li>
              <li>Familiarity with cloud computing concepts</li>
            </ul>
          </TextSection>
          <TextSection position={2}>
            <h2>Course Outline</h2>
            {courseOutline.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
            <Margin20 />
            <Margin20 />
            <p>
              <strong>Who Should Attend</strong>
            </p>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem key={key} number={index + 1} text={text} />
            ))}
          </TextSection>
          <TextSection position={3}>
            <h2>Certification</h2>
            <p>
              <strong>Certificate of Completion from NTUC LearningHub</strong>
            </p>
            <p>
              Upon meeting at least 75% attendance, participants will receive a
              Certificate of Completion from NTUC LearningHub.
            </p>
            <Margin20 />
            <Margin20 />
            <p>
              <strong>External Certification Exam</strong>
            </p>
            <p>
              External Certification Exam The participant will be able to
              attempt the AWS Certified Solutions Architect &ndash; Associate
              exam after attending this course. Upon passing the exam, the
              participant will receive AWS Certified Solutions Architect &ndash;
              Associate accreditation from AWS.
            </p>
            <p>
              LHub will issue 1 x AWS Exam Voucher (worth USD150) for AWS
              Certified Solutions Architect &ndash; Associate exam. Participants
              must attempt the exam before the exam voucher expiry date. Please
              note that LHub will not be issuing any exam voucher replacement.{' '}
            </p>
            <Margin20 />
            <Margin20 />
            <p>
              <strong>Exam Overview</strong>
            </p>
            <Table>
              <tbody>
                <tr>
                  <td className="highlight1">Level</td>
                  <td>Associate</td>
                </tr>
                <tr>
                  <td className="highlight1">Length</td>
                  <td>130 minutes to complete the exam</td>
                </tr>
                <tr>
                  <td className="highlight1">Cost</td>
                  <td>USD150</td>
                </tr>
                <tr>
                  <td className="highlight1">Format</td>
                  <td>
                    65 questions, either multiple choice or multiple responses
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection position={4}>
            <h2>Price</h2>
            <Table>
              <thead>
                <tr>
                  <td className="highlight1" />
                  <td className="highlight1">Individual Sponsored</td>
                  <td className="highlight1">Non-SME (Company Sponsored)</td>
                  <td className="highlight1">SME (Company Sponsored)</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="highlight2">
                    For International Participants (Full Course Fee)
                  </td>
                  <td>
                    <strong>SGD2700.00 w/ GST</strong> (SGD2500.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD2700.00 w/ GST</strong> (SGD2500.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD2700.00 w/ GST</strong> (SGD2500.00 w/o GST)
                  </td>
                </tr>
                <tr>
                  <td className="highlight2">For Singapore Citizens and PRs</td>
                  <td>
                    <strong>SGD950.00 w/ GST</strong> (SGD750.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD950.00 w/ GST</strong> (SGD750.00 w/o GST)
                  </td>
                  <td>
                    <strong>SGD950.00 w/ GST</strong> (SGD750.00 w/o GST)
                  </td>
                </tr>
              </tbody>
            </Table>
            <h3>Funding Eligibility Criteria</h3>
            <p>
              <strong>Individual Sponsored Trainee</strong>
            </p>
            <ul>
              <li>
                Singapore Citizens or Singapore Permanent Residents (Students
                are not supported)
              </li>
              <li>
                The participants shall meet the minimum set of entry
                requirements for the course(s) within the Programme
              </li>
              <li>Trainee must achieve at least 75% attendance</li>
              <li>
                Participant must submit their highest education certificate
                during course registration.
              </li>
              <li>
                NTUC LearningHub reserves the right to claw back the funded
                amount from trainee if he/she did not meet the eligibility
                criteria
              </li>
            </ul>
            <QnADropdown ques="SkillsFuture Credit">
              Eligible Singapore Citizens can use their SkillsFuture Credit to
              offset course fee payable after funding
            </QnADropdown>
            <QnADropdown ques="UTAP">
              This course is eligible for Union Training Assistance Programme
              (IUTAP). NTUC members can enjoy up to 50% funding (capped at $250
              per year) under (UTAP)
            </QnADropdown>
            <Margin20 />
            <Margin20 />
            <Margin20 />
            <p>
              <strong>Company Sponsored Trainee</strong>
            </p>
            <ul>
              <li>
                Singapore Citizens or Singapore Permanent Residents (Students
                are not supported)
              </li>
              <li>
                The participants shall meet the minimum set of entry
                requirements for the course(s) within the Programme
              </li>
              <li>Trainee must achieve at least 75% attendance</li>
              <li>
                Participant must submit their highest education certificate
                during course registration.
              </li>
              <li>
                NTUC LearningHub reserves the right to claw back the funded
                amount from trainee if he/she did not meet the eligibility
                criteria
              </li>
              <li>
                The Company shall submit an externally audited Statement of
                Grant Claim (SOGC) for programmes with funding $ 100,000 and
                above or where the cumulative disbursement to the company is $
                100,000 and above; and submit both audited SOGC and externally
                audited Income & Expenditure (I&E) report for programmes with
                funding above $ 5 million. Refer to clauses 8 and 9 of Part B:
                Terms and Conditions for more information. (Applicable for
                company-sponsored participants only)
              </li>
              <li>
                The participants and/or the Company{' '}
                <strong>
                  <u>
                    shall not receive any other funding from government sources
                  </u>
                </strong>{' '}
                in respect of the actual grant disbursed pursuant to this Letter
                of Offer for the course(s) within the Programme; and vi. The
                course(s) within the Programme shall commence no earlier than
                the start date and complete no later than the &quot;End
                Date&quot;.
              </li>
            </ul>
            <Margin20 />
            <Margin20 />
            <p>
              Terms & Conditions apply. NTUC LearningHub reserve the right to
              make changes or improvements to any of the products described in
              this document without prior notice.
            </p>
            <p>
              Prices are subject to other{' '}
              <RemoteLink href="https://www.ntuclearninghub.com/contact-us-2/faq">
                LHUB miscellaneous fees
              </RemoteLink>
              .
            </p>
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          disableApplyNowButton
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default ArchitectingOnAWS;
