import { useContext } from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';
import { Context } from './context';
import { Title, Body, OutboundLink } from './common';

const Container = styled.div`
  grid-area: startDate;
`;

const DetailsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.layout().margin3}px;
`;

const StartDate = () => {
  const {
    coursePartnerLink,
    courseCard: {
      nextStartDate: { upcomingModule, date, day },
    },
  } = useContext(Context);

  return (
    <Container>
      <Title>Start Date</Title>
      <DetailsContainer>
        <Body>Next Upcoming Course: {upcomingModule}</Body>
        <Body>
          {date} ({day})
        </Body>
      </DetailsContainer>
      <OutboundLink
        color={$.color.blue3}
        background={$.color.white}
        href={coursePartnerLink}
      >
        Registration via SMU Academy
      </OutboundLink>
    </Container>
  );
};

export default StartDate;
