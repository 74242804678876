import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../styles/global';
import Util from '../../../utils';

const Container = styled.div`
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin3}px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    padding-left: ${$.layout().padding4}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Cooper Hewitt Medium;
  font-size: 14px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-right: ${$.layout().margin4}px;
    position: relative;
    align-self: flex-start;
    flex-shrink: 0;
    bottom: -11px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-right: ${$.layout().margin4}px;
    position: relative;
    align-self: flex-start;
    flex-shrink: 0;
    bottom: -11px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-family: Cooper Hewitt Medium;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: ${$.layout().padding5 + 4}px ${$.layout().padding4}px
    ${$.layout().padding5}px;
  background-color: ${$.color.white};
  margin-right: ${$.layout().margin4}px;
  margin-bottom: ${$.layout().margin4}px;
  border-radius: 999px;
  overflow: hidden;
  box-shadow: ${$.dropShadow.normal};
  transition: box-shadow 0.4s ${$.easingFn.standard};
  text-decoration: none;
  color: ${$.color.blue5};

  &:hover {
    box-shadow: ${$.dropShadow.repressed};
  }

  &:visited {
    color: ${$.color.blue5};
  }
`;

const TaggedIn = ({ tags }) => (
  <Container>
    <Title>Tagged in:</Title>
    <LinksContainer>
      {Util.addKeys(tags).map(({ key, name, slug }) => (
        <StyledLink key={key} to={`/blog/tag/${slug}/`}>
          {name}
        </StyledLink>
      ))}
    </LinksContainer>
  </Container>
);

TaggedIn.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default TaggedIn;
