import { memo, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import context from './context';
import Event from './Event';

const Container = styled.div`
  margin-bottom: ${$.layout().margin3}px;
  display: ${({ data, filteredData }) =>
    filteredData.length !== 0
      ? filteredData.reduce((prev, curr) => {
          if (curr.key === data.key) {
            return 'block';
          }
          if (prev !== 'block') {
            return 'none';
          }
          return prev;
        }, '')
      : 'none'};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding4}px 0px ${$.layout().padding2}px;
    border-radius: ${$.border().radius1}px;
    box-shadow: ${$.dropShadow.normal};
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding4}px 0px ${$.layout().padding2}px;
    margin-left: -${$.layout().margin3}px;
    margin-right: -${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin2}px;

    & > div:last-child {
      border-bottom: none;
    }
  }
  // #endregion
`;

const TitleContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding4}px ${$.layout().padding2}px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding4}px ${$.layout().margin3}px;
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.orange3};
  font-size: 34px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: inline-block;
    font-size: 22px;
    color: ${$.color.orange4};
    margin-right: ${$.layout().margin4}px;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion
`;

const Subtitle = styled.span`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    color: ${$.color.orange2};
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    color: ${$.color.orange3};
  }
  // #endregion
`;

const MainDivider = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    height: 1px;
    width: calc(100% - ${$.layout().padding2 * 2}px);
    margin: 0px auto ${$.layout().margin4}px;
    background-color: ${$.color.gray1};
    opacity: 0.5;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const LearnMoreLink = styled(Link)`
  color: ${$.color.blue6};
  text-decoration: none;
  text-transform: capitalize;
  border-bottom: 2px solid ${$.color.blue6};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
  }
  // #endregion

  // NOTE: Tablets and above
  // #region
  @media ${$.device.tablet} {
    font-size: 14px;
    line-height: 18px;
    vertical-align: middle;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-family: Lato Bold;
    font-size: 16px;
    padding-bottom: 2px;
  }
  // #endregion
`;

const Ages = styled.div`
  color: ${$.color.blue3};
  font-family: Aileron Heavy;
  font-size: 24px;
  text-transform: uppercase;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: none;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const ScheduleZIndexContainer = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: relative;
    z-index: ${({ index, length }) => length - index};
  }
  // #endregion
`;

const AgeSubtitle = styled.span`
  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion
`;

const EventContainer = styled.div`
  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }
  // #endregion
`;

const Schedule = memo(({ data, index, length }) => {
  const { courseTitle, ages, courseSubtitle, url, events } = data;
  const { filteredData } = useContext(context);

  return (
    <ScheduleZIndexContainer index={index} length={length}>
      <Fade bottom distance="100px" fraction={0.01}>
        <Container data={data} filteredData={filteredData}>
          <TitleContainer>
            <Title>
              {courseTitle}{' '}
              {ages && ages.start ? (
                <Subtitle>
                  {courseSubtitle && `- ${courseSubtitle}`}
                  <AgeSubtitle>{` (Ages ${ages.start} - ${ages.end})`}</AgeSubtitle>
                </Subtitle>
              ) : (
                ''
              )}
            </Title>
            {ages && ages.start ? (
              <Ages>{`Ages ${ages.start} - ${ages.end}`}</Ages>
            ) : (
              ''
            )}
            {typeof url !== 'undefined' && (
              <LearnMoreLink to={url}>Learn more</LearnMoreLink>
            )}
          </TitleContainer>
          <MainDivider />
          <EventContainer>
            {events.map(({ key, ...event }, i) => (
              <Event
                index={i}
                key={key}
                event={{ key, ...event }}
                length={events.length}
                filteredData={filteredData.reduce((prev, curr) => {
                  if (curr.key && data.key === curr.key) {
                    return curr.events.reduce((prev1, curr1, eventIndex) => {
                      if (curr1.key === key) {
                        return { ...curr1, eventIndex };
                      }
                      return prev1;
                    }, {});
                  }
                  return prev;
                }, {})}
                filteredDataLength={filteredData.reduce((prev, curr) => {
                  if (curr.key && data.key === curr.key) {
                    return curr.events.length;
                  }
                  return prev;
                }, 0)}
              />
            ))}
          </EventContainer>
        </Container>
      </Fade>
    </ScheduleZIndexContainer>
  );
});

Schedule.defaultProps = {
  index: 0,
  length: 0,
};

Schedule.propTypes = {
  data: PropTypes.shape({
    courseName: PropTypes.string,
    courseTitle: PropTypes.string,
    ages: PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    courseSubtitle: PropTypes.string,
    key: PropTypes.string,
    url: PropTypes.string,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        dates: PropTypes.shape({
          startDate: PropTypes.number,
          endDate: PropTypes.number,
          startDay: PropTypes.string,
          endDay: PropTypes.string,
          sessionLength: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          day: PropTypes.string,
          full: PropTypes.arrayOf(PropTypes.number),
          skipped: PropTypes.arrayOf(PropTypes.number),
        }),
        time: PropTypes.shape({
          start: PropTypes.string,
          end: PropTypes.string,
        }),
        location: PropTypes.string,
        ages: PropTypes.shape({
          start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        price: PropTypes.shape({
          main: PropTypes.number,
          earlyBird: PropTypes.bool,
        }),
        url: PropTypes.string,
      })
    ),
  }).isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
};

export default Schedule;
