import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import Button from '../Button';

const Section = styled.section`
  background-color: ${$.color.blue1};
  margin-bottom: ${$.layout().margin3}px;
`;

const Container = styled.div`
  padding: ${$.layout().padding3}px 0;
`;

const Message = styled.h2`
  text-align: center;
  color: ${$.color.orange3};
  margin-bottom: ${$.layout().margin4}px;
  font-family: Aileron Heavy;
  font-size: 22px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-family: Aileron Bold;
    font-size: 15px;
  }
  // #endregion
`;

const Center = styled.div`
  text-align: center;
`;

const MiniBanner = () => (
  <Section>
    <Container>
      <Message>
        Not sure where to start your kid&apos;s coding journey? Let us help you
        out!
      </Message>
      <Center>
        <Link to="/coding-course-recommender/">
          <Button normalColor={$.color.blue5} normalText={$.color.white}>
            Course Recommender
          </Button>
        </Link>
      </Center>
    </Container>
  </Section>
);

export default MiniBanner;
