import styled from 'styled-components';
import PropTypes from 'prop-types';

import TeamProfilesDesktop from './Desktop';
import TeamProfilesMobile from './Mobile';

const Section = styled.section``;

const TeamProfiles = ({ onlyIncludes, expandProfileFirst }) => (
  <Section>
    <TeamProfilesDesktop
      onlyIncludes={onlyIncludes}
      expandProfileFirst={expandProfileFirst}
    />
    <TeamProfilesMobile
      onlyIncludes={onlyIncludes}
      expandProfileFirst={expandProfileFirst}
    />
  </Section>
);

TeamProfiles.defaultProps = {
  onlyIncludes: [],
  expandProfileFirst: '',
};

TeamProfiles.propTypes = {
  onlyIncludes: PropTypes.arrayOf(PropTypes.string),
  expandProfileFirst: PropTypes.string,
};

export default TeamProfiles;
