import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Container = styled.div`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
  // #endregion
`;

const ImageContainer = ({ className, image: Image }) => (
  <Container className={className}>
    <Image />
  </Container>
);

ImageContainer.propTypes = {
  className: PropTypes.string.isRequired,
  image: PropTypes.func.isRequired,
};

export default ImageContainer;
