import PropTypes from 'prop-types';
import styled from 'styled-components';

import switchBubble from './switchBubble';
import $ from '../../styles/global';
import Util from '../../utils';

const Container = styled.div`
  ${({ counter, index }) => `
  display: flex;
  width: calc(
    100vw - (${$.layout().margin3}px * 2) - (${$.layout().padding2}px * 2)
  );
  padding-top: ${$.layout().padding3}px;
  padding-bottom: ${$.layout().padding5}px;
  padding-left: ${$.layout().padding2}px;
  padding-right: ${$.layout().padding2}px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: ${counter === index ? '1' : '0'};
  transition: opacity 0.5s ${$.easingFn.standard};
`}
`;

const BubblePic = styled.img`
  border-radius: 999px;
  margin-bottom: ${$.layout().margin4}px;
  cursor: pointer;
  object-fit: cover;
  box-shadow: ${$.dropShadow.normal};

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 200px;
    height: 200px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 160px;
    height: 160px;
  }
  // #endregion
`;

const Bubble = ({
  index,
  counter,
  avatarURL,
  children,
  anchor,
  setCurrent,
  setDesc,
  desc,
}) => {
  Util.useAnchor(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const hasAnchor = searchParams.get('anchor');
    if (anchor === hasAnchor) {
      setTimeout(() => {
        switchBubble({
          newIndex: index,
          setCurrent,
          setDesc,
          desc,
        });
      }, 1000);
    }
  });

  return (
    <Container counter={counter} index={index}>
      <BubblePic src={avatarURL} alt={`${anchor} profile img`} />
      {children}
    </Container>
  );
};

Bubble.defaultProps = {
  index: 0,
  counter: 0,
  avatarURL: '',
  children: <></>,
  anchor: '',
  setCurrent: () => {},
  setDesc: () => {},
  desc: <></>,
};

Bubble.propTypes = {
  index: PropTypes.number,
  counter: PropTypes.number,
  anchor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  avatarURL: PropTypes.string,
  setDesc: PropTypes.func,
  setCurrent: PropTypes.func,
  desc: PropTypes.element,
};

export default Bubble;
