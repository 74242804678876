import { graphql } from 'gatsby';

import Seo from '../../components/seo';
import { Provider } from '../../components/courses';
import { Banner, Cards } from '../../components/courses/coursesPage';

export const query = graphql`
  query {
    allCoursesJson(
      filter: { trainingPartners: { elemMatch: { id: { eq: "apple" } } } }
    ) {
      edges {
        node {
          id
          trainingPartners {
            id
            name
          }
          title
          slug
          description
          learningGoals
          coursePartnerLink
          skillPills {
            id
            name
          }
          courseCard {
            image
            nextStartDate {
              upcomingModule
              date
              day
            }
            modulesInformation
            courseLevel
            feesInformation
          }
          overview
          courseOutline {
            id
            description
            link
            date
          }
          whoShouldAttend {
            id
            name
          }
          instructors {
            id
            name
            credentials
            profileLink
            image
            bio
          }
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          eq: "partners/apple/sgcc-apple-consultants-network.png"
        }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const AppleCoursesIndexPage = ({ data }) => {
  return (
    <>
      <Provider
        value={{
          trainingPartner: 'apple',
          courses: data.allCoursesJson.edges.map(({ node }) => node),
          banner: {
            title: 'Swift (Apple iOS) Certification',
            className: 'apple',
            description:
              'Advance your expertise with professional IT training and certifications from an Apple Consultants Network member. Gain in-demand skills in iOS development and app development with Swift, all taught by highly skilled Apple-certified instructors. Benefit from our innovative training programs designed to equip you with the knowledge and practical skills needed to succeed in the ever-evolving tech industry.',
            image: data.allFile.edges[0].node.childImageSharp.gatsbyImageData,
          },
        }}
      >
        <Seo title="Swift (Apple iOS) Certification | SG Code Campus" />
        <Banner />
        <Cards />
      </Provider>
    </>
  );
};

export default AppleCoursesIndexPage;
