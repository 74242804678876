import Util from './utils';

import data from './masterCourseEventList.json';

export default Util.addKeys(data).map((ele) => ({
  ...ele,
  events: Util.addKeys(ele.events).sort(
    (prev, curr) => prev.dates.startDate - curr.dates.startDate
  ),
}));
