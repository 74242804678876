import styled from 'styled-components';

import $ from '../../../../styles/global';
import Grid from './Grid';

const Section = styled.section`
  margin-top: ${$.layout().margin3}px;
  margin-bottom: ${$.layout().margin3}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    > * {
      margin: 0 !important;
      width: 100% !important;
    }
  }
  // #endregion
`;

const Achievements = () => (
  <Section>
    <Grid />
  </Section>
);

export default Achievements;
