import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled(Link)`
  border-radius: ${({ theme }) => theme.border().radius1}px;
  box-shadow: ${({ theme }) => theme.dropShadow.normal};
  overflow: hidden;
  transition: box-shadow 0.3s ${({ theme }) => theme.easingFn.standard};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    box-shadow: ${({ theme }) => theme.dropShadow.repressed};
  }
`;

const FeatureImageContainer = styled.div`
  width: 100%;
  height: 300px;
  background-color: lightgrey;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-family: Cooper Hewitt Semibold;
  color: ${({ theme }) => theme.color.blue3};

  padding-left: ${({ theme }) => theme.layout().padding3}px;
  padding-right: ${({ theme }) => theme.layout().padding3}px;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${({ theme }) => {
      return theme.layout().padding5;
    }}px
    ${({ theme }) => theme.layout().padding5}px;
  padding: ${({ theme }) => theme.layout().padding3}px;
  padding-top: 0px;

  font-size: ${({ theme }) => theme.fontSizes.normal};
`;

const DetailsTitle = styled.div`
  font-family: Cooper Hewitt Semibold;
  color: ${({ theme }) => theme.color.blue3};
`;

const DetailsData = styled.div`
  color: ${({ theme }) => theme.color.black2};
`;

const EventCard = ({
  title,
  date,
  time,
  admissionFee,
  tags,
  slug,
  featureImage,
}) => {
  return (
    <Container to={`/events/${slug}`}>
      <FeatureImageContainer>
        <img src={featureImage} alt={title} />
      </FeatureImageContainer>
      <Title>{title}</Title>
      <DetailsContainer>
        {[
          {
            title: 'Date',
            data: date,
          },
          {
            title: 'Time',
            data: time,
          },
          {
            title: 'Categories',
            data: tags.map((tag) => tag.name).join(', '),
          },
          {
            title: 'Admission Fee',
            data: admissionFee,
          },
        ].map(({ title, data }, index) => {
          return (
            <React.Fragment key={`${title}_${index}`}>
              <DetailsTitle>{title}</DetailsTitle>
              <DetailsData>{data}</DetailsData>
            </React.Fragment>
          );
        })}
      </DetailsContainer>
    </Container>
  );
};

EventCard.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  admissionFee: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  slug: PropTypes.string.isRequired,
  featureImage: PropTypes.string.isRequired,
};

EventCard.defaultProps = {
  title: '',
  details: '',
  html: '',
};

export default EventCard;
