import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import { RemoteLink, Margin20 } from '../Misc';
import TrainerProfile from '../TrainerProfile';
import Table from '../Table';
import MobileNavigationMenu from '../MobileNavigationMenu';
import FundingTable from '../SMUReusableComponents/FundingTable';
import InfoTable from '../SMUReusableComponents/InfoTable';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
  },
  {
    text: 'Topics',
  },
  {
    text: 'Who Should Attend',
    threshold: 0.1,
  },
  {
    text: 'Fees And Funding',
  },
  {
    text: 'Schedule',
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const learningObjectives = Utils.addKeys([
  {
    text: 'Gain exposure to systems and tools to handle tabular data (Microsoft Excel, Google Sheets, SQL DBMS)',
  },
  {
    text: 'Obtain a basic understanding of Relational Database Theory',
  },
  {
    text: 'Learn the fundamentals, properties and challenges of handling Big Data',
  },
  {
    text: 'Learn how data can be represented in tabular form',
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'Professionals seeking to tackle real-world problems with storing, analysing and visualising data',
  },
  {
    text: 'Anyone with an interest in learning basic data analysis techniques with accessible resources',
  },
  {
    text: 'Professionals looking to apply data-driven practices into their line of work',
  },
  {
    text: 'Professionals looking for practical experience with industry experts',
  },
  {
    text: 'No prior experience or background required',
  },
]);

const topics = Utils.addKeys([
  {
    text: 'Learn to make basic SQL queries to extract and summarise data',
  },
  {
    text: 'Learn to load data into visualisation tools like Tableau',
  },
  {
    text: 'Create interactive dashboards to visualise and analyse data',
  },
  {
    text: 'Learn how to handle big data in the cloud',
  },
]);

const trainers = Utils.addKeys([
  Profiles.ian,
  Profiles.sunChang,
  Profiles.natasha,
]);

const applyNowLink =
  '/professional-training/apply-now/?c=Certificate in Technology Foundations: Unearthing the Potential of Databases, Big Data and Data Analytics';

const enquiryLink =
  'https://academy.smu.edu.sg/programme-enquiry?course=Certificate%20in%20Technology%20Foundations%3A%20Unearthing%20the%20Potential%20of%20Databases%2C%20Big%20Data%20and%20Data%20Analytics&nid=4556';

const BigData = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="Certificate in Technology Foundations: Unearthing the Potential of Databases, Big Data and Data Analytics"
      />
      <MobileNavigationMenu
        items={navigationLinks}
        applyNowLink={applyNowLink}
        enquiryLink={enquiryLink}
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              Collecting data through technology has changed the game of
              analysis in businesses today. With accessible tools, companies are
              able to churn and gain reliable insights from Big Data
              efficiently. In this course, participants will learn to create
              reports with analytic and data visualisation tools. This enables
              participants to build their own interactive data dashboard for
              storytelling. Participants will also be exposed to the challenges
              and handling large Big Data in practice.
            </p>
            <Margin20 />
            <InfoTable
              venue="On-campus"
              starts="14 Sep 2023 (Thu)"
              fees="SGD1728.00* (as low as SGD198.40* after maximum funding)"
              duration={
                <>
                  3 days
                  <br />
                  Weeknights (7pm - 10:30pm)
                  <br />
                  Saturday (9am - 6:00pm)
                </>
              }
              level="Basic"
            />
            <h3>Learning Objectives</h3>
            {learningObjectives.map(({ key, text }, index) => (
              <ListItem number={index + 1} text={text} key={key} />
            ))}
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            {topics.map(({ key, text }, index) => (
              <ListItem text={text} key={key} number={index + 1} />
            ))}
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
          </TextSection>
          <TextSection>
            <h2>Assessment</h2>
            <p>Individual assessment</p>
            <Margin20 />
            <Margin20 />
            <h3>Certification</h3>
            <p>
              Upon meeting the attendance and assessment criteria, participants
              will be awarded a digital certificate for participating in each
              module. Please refer to our{' '}
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                course policies
              </RemoteLink>{' '}
              to view the attendance and assessment criteria.
            </p>
            <p>
              Upon completion of all modules required for this programme within
              a maximum duration of 3 years, participants will be awarded a
              digital certificate.
            </p>
          </TextSection>
          <TextSection position={3}>
            <h2>Fees And Funding</h2>
            <FundingTable
              firstRow={[
                <>
                  <strong>$518.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="http://www.ssg.gov.sg/programmes-and-initiatives/funding/enhanced-training-support-for-smes1.html">
                    ETSS Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$518.40</strong>
                  <br />
                  (After SSG Funding 70%)
                </>,
              ]}
              secondRow={[
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
                <>
                  <strong>$198.40</strong>
                  <br />
                  (After SSG Funding 70% +{' '}
                  <RemoteLink href="https://www.skillsfuture.gov.sg/enhancedsubsidy">
                    MCES Funding 20%
                  </RemoteLink>
                  )
                </>,
              ]}
              thirdRow={[
                <>
                  <strong>$1,728</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$1,728</strong>
                  <br />
                  (No Funding)
                </>,
                <>
                  <strong>$1,728</strong>
                  <br />
                  (No Funding)
                </>,
              ]}
            />
          </TextSection>
          <TextSection position={4}>
            <h2>Schedule</h2>
            <p>
              <strong>Start Date(s)</strong>
              <br />
              14 Sep 2023 (Thu)
            </p>
            <p>
              <strong>Intake Information</strong>
            </p>
            <Table>
              <thead>
                <tr>
                  <td className="highlight2">Intakes</td>
                  <td className="highlight2">Dates</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Intake 5</td>
                  <td>
                    16, 17 & 18 Mar 2023
                    <br />
                    [Fully Subscribed]
                  </td>
                </tr>
                <tr>
                  <td>Intake 6</td>
                  <td>
                    <strong>
                      14, 15 & 16 Sep 2023
                      <br />
                      [Open for Registration]
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TextSection>
          <TextSection>
            <h2>Policies</h2>
            <p>
              <RemoteLink href="https://academy.smu.edu.sg/smua-policies">
                View programme policies.
              </RemoteLink>
            </p>
          </TextSection>
          <TextSection position={5}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          items={navigationLinks}
          applyNowLink={applyNowLink}
          enquiryLink={enquiryLink}
        />
      </Body>
    </>
  );
};

export default BigData;
