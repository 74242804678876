import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Banner from '../Banner';
import { Body, Column1 } from '../Body';
import DesktopNavigationMenu from '../DesktopNavigationMenu';
import TextSection from '../TextSection';
import ListItem from '../ListItem';
import TrainerProfile from '../TrainerProfile';
import MobileNavigationMenu from '../MobileNavigationMenu';
import * as Profiles from '../SMUReusableComponents/TrainerProfiles';

const navigationLinks = Utils.addKeys([
  {
    text: 'Overview',
    threshold: 0.0,
  },
  {
    text: 'Topics',
    threshold: 0.0,
  },
  {
    text: 'Who Should Attend',
    threshold: 0.0,
  },
  {
    text: 'Speaker/Training Bio',
    threshold: 0.0,
  },
]);

const whoShouldAttend = Utils.addKeys([
  {
    text: 'AWS Cloud Solutions Architect',
  },
  {
    text: 'DevOps Engineer',
  },
  {
    text: 'Cloud Security Engineer',
  },
  {
    text: 'Cloud Infrastructure Engineer',
  },
  {
    text: 'Full Stack Developer',
  },
  {
    text: 'Cloud Operations Manager',
  },
]);

const trainers = Utils.addKeys([
  Profiles.ian,
  Profiles.huiLiang,
  Profiles.yongYang,
]);

const enquiryLink = 'mailto:enquiries@sgcodecampus.com';

const SCTPAWS = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "landing/banner" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = getImage(edges[1].node.childImageSharp);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Banner
        image={bgImage}
        title="(SCTP) Software Development with the Amazon Web Service (AWS) Cloud"
      />
      <MobileNavigationMenu
        enquiryLink={enquiryLink}
        items={navigationLinks}
        disableApplyNowButton
      />
      <Body>
        <Column1>
          <TextSection position={0}>
            <h2>Overview</h2>
            <p>
              This programme is tailored for professionals in Singapore
              interested in transitioning to the software development industry
              but lack the technical skills required. Taught by a team of Amazon
              Web Services (AWS) Authorised Instructors and experienced industry
              professionals, learn the latest and most relevant information
              about software development.
            </p>
          </TextSection>
          <TextSection position={1}>
            <h2>Topics</h2>
            <ul>
              <li>Programming Fundamentals</li>
              <li>Cloud Computing</li>
              <li>Containerisation</li>
              <li>Continuous Integration and Deployment (CI/CD)</li>
              <li>
                AWS services like Elastic Container Service (ECS) and Elastic
                Kubernetes Service (EKS)
              </li>
              <li>
                Using Python web development frameworks to create dynamic and
                responsive web applications and deploy these web applications on
                AWS
              </li>
            </ul>
          </TextSection>
          <TextSection position={2}>
            <h2>Who Should Attend</h2>
            {whoShouldAttend.map(({ text, key }, index) => (
              <ListItem
                key={key}
                number={index + 1}
                pointerColor={$.proTraining.bluegray}
                text={text}
              />
            ))}
          </TextSection>
          <TextSection position={3}>
            <h2>Speaker/Training Bio</h2>
            {trainers.map(({ name, key, image: img, story, qualification }) => (
              <TrainerProfile
                key={key}
                name={name}
                image={img}
                qualification={qualification}
              >
                {story}
              </TrainerProfile>
            ))}
          </TextSection>
        </Column1>
        <DesktopNavigationMenu
          enquiryLink={enquiryLink}
          items={navigationLinks}
          disableApplyNowButton
        />
      </Body>
    </>
  );
};

export default SCTPAWS;
