import styled from 'styled-components';

import $ from '../../../styles/global';

const MainInformation = styled.div`
  font-family: Aileron Heavy;
  color: ${$.color.blue3};
  font-size: 18px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 14px;
    margin-bottom: ${$.layout().margin5 / 2}px;
  }
  // #endregion

  // NOTE: Tablets and below
  // #region
  @media ${$.device.tablet} {
    margin-right: ${$.layout().margin4}px;

    & > span {
      font-size: 15px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    margin-right: ${$.layout().margin4}px;

    & > span {
      font-size: 15px;
    }
  }
  // #endregion
`;

export default MainInformation;
