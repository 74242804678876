import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/global';

const Container = styled.div``;

const Title = styled.h2`
  font-family: Aileron Heavy;
  color: ${$.color.orange3};
  margin-bottom: ${$.layout().margin3}px;
  font-size: 36px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 30px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  color: ${$.color.orange3};
  line-height: 1.4em;
  margin-bottom: ${$.layout().margin2}px;

  & a {
    font-family: Lato Bold;
    color: ${$.color.blue5};
    text-decoration: none;

    &:visited {
      color: ${$.color.blue5};
    }
  }

  ${$.br}
`;

const Header = ({ title, subtitle }) => (
  <Container>
    <Title>{title}</Title>
    {subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
  </Container>
);

Header.defaultProps = {
  subtitle: '',
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default Header;
