import styled from 'styled-components';

import $ from '../../../styles/global';
import items from './items.json';
import Card from './Card';
import Utils from '../../../utils';

const Section = styled.section`
  background: radial-gradient(
    103.83% 111.89% at 25.6% 78.1%,
    #f7814d 44.99%,
    ${$.proTraining.orange1} 96.49%
  );
  margin-bottom: 0px !important;
`;

const Container = styled.div`
  padding: 40px 0;
`;

const Title = styled.h2`
  font-family: Lato Bold;
  color: ${$.color.black};
  font-size: ${$.fontSizes.xlarge};
  text-align: center;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 38px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  text-align: center;
  color: ${$.color.black};
  font-size: 16px;
  font-family: Lato Regular;
`;

const Cards = styled.div`
  margin-top: ${$.layout().margin2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap;
  justify-content: space-between;
  width: 100%;
  gap: ${$.layout().margin4}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const cardList = Utils.addKeys(items);

const Partners = () => (
  <Section>
    <Container>
      <Title>Our Partners</Title>
      <Subtitle>Teaming up with the best names in Technology</Subtitle>
      <Cards>
        {cardList.map(({ logo, description, className, alt, key }) => (
          <Card
            key={key}
            alt={alt}
            img={logo}
            description={description}
            className={className}
          />
        ))}
      </Cards>
    </Container>
  </Section>
);

export default Partners;
