import styled from 'styled-components';

import $ from '../../../../styles/global';
import ImageContainer from '../../../Level2Banner/ImageContainer';
import MobileImage from '../../../../assets/images/whyus/students/mobile.svg';

const Container = styled(ImageContainer)`
  display: none;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    position: absolute;
    top: 20px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 200px;

    & > svg {
      width: 90%;
    }
  }
  // #endregion
`;

const Image4Container = () => <Container image={MobileImage} />;

export default Image4Container;
