import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
    right: -20px;
  }
  // #endregion
`;

const Square1 = styled.div`
  /* clip-path: polygon(0 0, 50% 0, 50% 70%, 0 70%); */
  background-color: ${$.proTraining.blue};
  border-right: 30px solid ${$.proTraining.blue};
  width: 120px;
  height: 120px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    display: block;
    color: ${$.color.white};
    font-family: Lato Regular;
    font-size: 13px;
    line-height: 1em;
    &:nth-child(2) {
      font-size: 26px;
    }
    &:nth-child(3) {
      font-size: 12px;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 110px;
    height: 110px;

    & > span {
      line-height: 1.1em;
      &:nth-child(2) {
        font-size: 23px;
      }
    }
  }
  // #endregion
`;

const Square2 = styled.div`
  clip-path: polygon(30px 0, 100% 0, 100% 100%, 0% 100%);
  position: relative;
  left: -40px;
  border: 2px solid ${$.proTraining.blue};
  border-left: none;
  padding-left: 30px;
  border-top-left-radius: 0px;
  background-color: ${$.color.white};
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 116px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    display: block;
    color: ${$.proTraining.blue};
    font-family: Lato Light;
    font-size: 12px;
    text-align: center;
    line-height: 1em;
  }

  & .smu {
    margin: 5px 0;
    width: 160px;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    width: 180px;

    & .smu {
      width: 100px;
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: 180px;
    height: 105px;

    & > span {
      font-size: 14.5px;
      &:last-child {
        font-size: 13px;
      }
    }
    & .smu {
      width: 90px;
    }
  }
  // #endregion
`;

const BenefitsContainer = () => (
  <Container>
    <Square1>
      <span>Up to</span>
      <span>90%</span>
      <span>Course Subsidy</span>
    </Square1>
    <Square2>
      <span>Learn at</span>
      <StaticImage
        src="../../../assets/images/landing/partners/smu.png"
        alt="smu academy logo"
        className="smu"
      />
      <span>with our Affiliate Faculty</span>
    </Square2>
  </Container>
);

export default BenefitsContainer;
