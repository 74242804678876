import styled from 'styled-components';

import $ from '../../styles/global';
import Button from '../Button';

const Age = styled.h2`
  font-family: Cooper Hewitt Bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: ${$.layout().margin5}px;
  color: ${$.color.white};
`;

const Title = styled.h2`
  font-family: Aileron Heavy;
  font-size: 45px;
  color: ${$.color.white};
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion
`;

const Subtitle = styled.h2`
  font-family: Cooper Hewitt Semibold;
  font-size: 14px;
  color: ${({ textColor }) => textColor};
  text-transform: uppercase;
  letter-spacing: 2px;
  height: 40px;
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    height: 60px;
  }
  // #endregion
`;

const Description = styled.div`
  font-family: Lato Regular;
  font-size: 16px;
  line-height: 1.4em;
  color: ${$.color.white};
  margin-bottom: ${$.layout().margin4}px;
`;

const StyledButton = styled(Button)`
  font-family: Aileron Heavy;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export { Age, Title, Description, StyledButton, Subtitle };
