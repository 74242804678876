import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import $ from '../../../styles/global';

const Section = styled.section`
  background-color: ${({ twos }) => (twos ? $.color.white : $.color.blue1)};
  padding: ${$.layout().padding3}px 0;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-bottom: ${({ last }) => (last ? $.layout().margin1 : 0)}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: 0;
  }
  // #endregion
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ twos }) => (twos === true ? 'row' : 'row-reverse')};
  align-items: center;
  width: 100%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
  }
  // #endregion
`;

const Image = styled.div`
  width: 50%;
  border: 1px solid ${$.color.gray1};
  border-radius: ${$.border().radius2}px;
  > * {
    border-radius: inherit;
    width: 100%;
    height: 350px;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100vw;
    border: none;
    border-radius: 0;
    > * {
      height: 400px;
    }
  }
  // #endregion
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${$.layout().padding2}px;
  padding: ${({ twos }) =>
      twos
        ? `${$.layout().padding2}px 0px ${$.layout().padding2}px ${
            $.layout().padding2
          }px`
        : `${$.layout().padding2}px ${$.layout().padding2}px ${
            $.layout().padding2
          }px`}
    0px;
  width: calc(50% - ${$.layout().padding2}px);

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;
  font-size: 40px;
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin4}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 35px;
  }
  // #endregion
`;

const Message = styled.div`
  font-family: Lato Regular;
  color: ${$.color.blue5};
  line-height: 1.3em;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    line-height: 1.5em;
  }
  // #endregion
`;

const Card = ({ img, title, message, twos, last, alt }) => (
  <Section twos={twos} last={last}>
    <Container twos={twos}>
      <Image>
        <GatsbyImage image={img} alt={alt} />
      </Image>
      <Info twos={twos}>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Info>
    </Container>
  </Section>
);

Card.defaultProps = {
  img: {},
  title: '',
  message: '',
  twos: false,
  last: false,
  alt: '',
};

Card.propTypes = {
  twos: PropTypes.bool,
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  alt: PropTypes.string,
  message: PropTypes.string,
  last: PropTypes.bool,
};

export default Card;
