import styled from 'styled-components';

import $ from '../../styles/global';
import Button from '../Button';

const Age = styled.div`
  font-family: Cooper Hewitt Bold;
  letter-spacing: 3px;
  text-transform: uppercase;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 20px;
    color: ${({ textColor }) => textColor};
    margin: ${$.layout().margin3}px 0;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    font-size: 20px;
    color: ${({ textColor }) => textColor};
    margin: ${$.layout().margin3}px 0;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 18px;
    color: ${$.color.white};
    text-align: center;
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const Title = styled.div`
  font-family: Aileron Heavy;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 48px;
    color: ${({ textColor }) => textColor};
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Tablets and above
  // #region
  @media ${$.device.tablet} {
    font-size: 36px;
    color: ${({ textColor }) => textColor};
    margin-bottom: ${$.layout().margin5}px;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
    color: ${$.color.white};
    margin-bottom: ${$.layout().margin4}px;
    text-align: center;
  }
  // #endregion
`;

const Subtitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  font-family: Cooper Hewitt Medium;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-size: 16px;
    color: ${$.color.orange3};

    flex-grow: 1;
  }
  // #endregion

  // NOTE: Tablets and above
  // #region
  @media ${$.device.tablet} {
    font-size: 16px;
    color: ${$.color.orange3};

    flex-grow: 1;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    font-size: 15px;
    text-align: center;
    color: ${$.color.white};
    margin-bottom: ${$.layout().margin4}px;
  }
  // #endregion
`;

const MobileButton = styled(Button)`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    display: none;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

export {
  Age, Title, Subtitle, MobileButton,
};
