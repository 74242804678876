import { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Context } from '../../courses';

const Section = styled.section``;

const Container = styled.div`
  padding: ${({ theme }) => theme.layout().padding1}px 0;
  color: ${({ theme }) => theme.color.blue4};
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.title.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.xxxlarge};
  margin-bottom: ${({ theme }) => theme.layout().margin3}px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.layout().padding3}px 0
    ${({ theme }) => theme.layout().padding1}px;

  & > * {
    flex: 0 0 30%;
  }

  & > .aws {
    flex: 0 0 20%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    & > * {
      flex: 0 0 50%;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    & > * {
      flex: 0 0 80%;
    }
  }
`;

const Paragraph = styled.p``;

const Banner = () => {
  const {
    banner: { title, className, description, image },
  } = useContext(Context);

  return (
    <Section>
      <Container>
        <LogoContainer>
          <GatsbyImage className={className} image={image} alt="" />
        </LogoContainer>
        <Title>{title}</Title>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Container>
    </Section>
  );
};

export default Banner;
