import styled from 'styled-components';

import $ from '../../styles/global';

const Section = styled.section`
  overflow: hidden;
  background-color: ${$.color.black};
`;

const Container = styled.div`
  width: 100vw !important;
  margin: 0 !important;
  position: relative;
  background-color: ${$.color.orange3};
`;

const Info = styled.div`
  position: relative;
  padding: 100px 0;
  margin: 0 calc((100vw - 970px) / 2);

  & > .text {
    font-family: Lato Regular;
    font-size: 18px;
    color: ${$.color.white};
    line-height: 1.3em;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin: 0 ${$.layout().margin3}px;
  }
  // #endregion
`;

const Title = styled.h2`
  font-size: 40px;
  color: ${$.color.white};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin2}px;

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tablet} {
    font-size: 34px;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 34px;
    margin-bottom: ${$.layout().margin2}px;
  }
  // #endregion
`;

const Attend = () => (
  <Section>
    <Container>
      <Info>
        <Title>Who should attend</Title>
        <div className="text">
          Open to all beginners interested in pursuing careers in tech and
          tech-adjacent fields. We welcome the following groups of students:
          <ul>
            <li>Post O-level/A-level</li>
            <li>In Pre-U</li>
            <li>Attending Polytechnic or University</li>
            <li>NSFs</li>
          </ul>
        </div>
      </Info>
    </Container>
  </Section>
);

export default Attend;
