import { Link } from 'gatsby';
import shortid from 'shortid';

/**
 * Takes in a string, and convert internal link strings to actual Gatsby link components
 * Returns an array of the string with the link components spliced in it.
 * You can directly use the results of this util function into a JSX component.
 * @param {string} subject - String with <Link><linkURL>|<linkText></Link> in it.
 * Even if there aren't any matching substrings in it, it will just return an array of one item
 * You can still use it directly as a children of a component.
 * @param {React.Component} [ LinkComponent ] - Optional parameter to use your own
 * Link component instead. If not passed in, it will use the default styled Link component here
 * @returns {array} - returns an array of spliced in original string and Gatsby Link components
 */
const parseStringToGatsbyLink = (subject, LinkComponent = Link) =>
  subject.split(/<Link>|<\/Link>/).map((ele) => {
    if (/\/.+?\|.+/.test(ele)) {
      const key = shortid.generate();
      return (
        <LinkComponent key={key} to={ele.split('|')[0]}>
          {ele.split('|')[1]}
        </LinkComponent>
      );
    }
    return ele;
  });

export default parseStringToGatsbyLink;
