import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../../../styles/global';
import Util from '../../../../../../utils';
import ListItem from './ListItem';

const DropdownListContainer = styled.div`
  position: absolute;
  padding: 0px ${$.layout().padding5}px;
  width: calc(100% - ${$.layout().padding5 * 2}px);
  box-shadow: ${$.dropShadow.normal};
  background-color: ${$.color.white};
  margin-top: ${$.layout().margin5}px;
  z-index: 9999;
  left: 0px;
  top: 32px;
`;

const List = styled.ul`
  margin: ${$.layout().margin4}px 0px;
  padding: 0px;
  max-height: 200px;
  list-style: none;
  z-index: 9999;
  overflow-y: auto;
  text-align: left;
  display: ${({ displayState }) => (displayState ? 'block' : 'none')};

  > li:last-child {
    margin-bottom: 0;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-right: ${$.layout().margin5}px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(5, 83, 153, 0.45);
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${$.color.blue3};
    border-radius: 6px;
  }
`;

const ageArray = Util.addKeys([
  { age: '7' },
  { age: '8' },
  { age: '9' },
  { age: '10' },
  { age: '11' },
  { age: '12' },
  { age: '13' },
  { age: '14' },
  { age: '15' },
  { age: '16' },
  { age: '17' },
  { age: '18' },
]);

const DropdownList = ({ displayState }) => (
  <DropdownListContainer>
    <List displayState={displayState}>
      {ageArray.map(({ age, key }) => (
        <ListItem key={key}>{age}</ListItem>
      ))}
    </List>
  </DropdownListContainer>
);

DropdownList.propTypes = {
  displayState: PropTypes.bool.isRequired,
};

export default DropdownList;
