import PropTypes from 'prop-types';

import Seo from '../components/seo';
import Banner from '../components/ScratchCompetition/Banner';
import Details from '../components/ScratchCompetition/Details';

const SummerScratchCompetition2021 = ({ location }) => (
  <>
    <Seo
      location={location}
      title="Summer Scratch Competition 2021"
      description="Scratch competition for primary school children in Singapore"
      keywords={[
        'scratch competition singapore',
        'scratch competition primary school',
        'primary coding competition',
      ]}
    />
    <Banner />
    <Details />
  </>
);

SummerScratchCompetition2021.defaultProps = {
  location: {},
};

SummerScratchCompetition2021.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default SummerScratchCompetition2021;
