import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import TitleBar from './TitleBar';
import Tags from './Tags';

const Section = styled.section`
  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    position: relative;
    top: -70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    position: relative;
    top: -50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  // #endregion
`;

const Container = styled.div`
  background-color: ${$.color.white};
  position: relative;
  padding: ${$.layout().padding3}px ${$.layout().margin4}px 0px;

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    margin-left: -${$.layout().margin3}px !important;
    margin-right: -${$.layout().margin3}px !important;
    padding: ${$.layout().padding2}px ${$.layout().margin3}px 0px;
    min-height: 340px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: ${$.layout().margin2}px;
      z-index: -1;
      display: block;
      width: 25px;
      height: 300px;
      background: rgba(39, 44, 49, 0.15);
      filter: blur(5px);
    }

    &:before {
      transform: rotate(-5deg);
      left: 0px;
    }

    &:after {
      transform: rotate(5deg);
      right: 0px;
    }
  }
  // #endregion

  // NOTE: Tablets
  // #region
  @media ${$.device.tablet} {
    margin-left: -${$.layout().margin4}px !important;
    margin-right: -${$.layout().margin4}px !important;
    min-height: 320px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: ${$.layout().margin4}px;
      z-index: -1;
      display: block;
      width: 25px;
      height: 300px;
      background: rgba(39, 44, 49, 0.15);
      filter: blur(5px);
    }

    &:before {
      transform: rotate(-5deg);
      left: 0px;
    }

    &:after {
      transform: rotate(5deg);
      right: 0px;
    }
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    min-height: 300px;
  }
  // #endregion
`;

const TagsContainer = ({ data }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = data.filter(({ node: { name } }) => {
    if (name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  });

  return (
    <Section>
      <Container>
        <TitleBar
          totalCount={filteredData.length}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <Tags data={filteredData} />
      </Container>
    </Section>
  );
};

TagsContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default TagsContainer;
