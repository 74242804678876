import { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import context from './context';

const Container = styled.div`
  margin-bottom: ${$.layout().margin3}px;
  display: ${({ show }) => (show ? 'block' : 'none')};

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding1}px;
    border-radius: ${$.border().radius1}px;
    box-shadow: ${$.dropShadow.normal};
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: 100%;
  }
  // #endregion

  // NOTE: Mobile and below
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const Subtitle = styled.div`
  color: ${$.color.blue3};
  line-height: 1.4em;
  font-family: Aileron Heavy;
  font-size: 18px;

  & > a {
    color: ${$.color.blue6};
    text-decoration: none;
  }

  // NOTE: Desktops and above
  // #region
  @media ${$.device.desktop} {
    font-family: Lato Regular;

    & > a {
      font-family: Lato Bold;
      cursor: pointer;
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 17px;
  }
  // #endregion
`;

const PriorExpBanner = ({ show }) => {
  const { priorExpBannerSubtitle } = useContext(context);

  return (
    <Container show={show}>
      <Subtitle
        dangerouslySetInnerHTML={{
          __html: priorExpBannerSubtitle,
        }}
      />
    </Container>
  );
};

PriorExpBanner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default PriorExpBanner;
